import { FC } from 'react';
import styles from './CTACard.module.scss';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text from '../../../atoms/typographie/Text';

export interface ICTACardProps {
  title: string;
  text: string;
  imgSrc: string;
  imgAltText: string;
  ctaText?: string;
  onClick?: any;
  link?: string;
}

const CTACard: FC<ICTACardProps> = ({ title, text, imgSrc, imgAltText, ctaText, link, onClick }) => {
  return (
    <div className={styles.ctaCard}>
      <img className={styles.image} src={imgSrc} alt={imgAltText} />

      <div className={styles.textWrapper}>
        <Headline tag={'h2'} size={HEADLINE_SIZE.H2} align={'center'}>
          {title}
        </Headline>

        <Text align={'center'} tag={'p'}>
          {text}
        </Text>
      </div>

      {(link || onClick) && ctaText ? (
        <div className={styles.ctaWrapper}>
          <CTAButton
            expand={'block'}
            link={link ? link : undefined}
            onClick={onClick ? onClick : undefined}
            buttonText={ctaText}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CTACard;
