import { FC } from 'react';
import LinkElement from '../LinkElement';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose';
//TODO delete File

export interface ICompletedFormCheckProps {
  pathname: string;
  status: boolean;
  positiveText: string;
  negativeText: string;
  testId?: string;
  text: string;
  showStatus?: boolean;
}

const CompletedFormCheck: FC<ICompletedFormCheckProps> = ({
  status,
  pathname,
  positiveText,
  negativeText,
  testId = 'complete-form-check',
  text,
  showStatus = true,
}) => {
  return (
    <LinkElement
      showStatus={showStatus}
      text={text}
      routerLink={pathname}
      data-testid={testId}
      icon={status ? faCheck : faClose}
      color={status ? 'blue' : 'red'}
      iconText={status ? positiveText : negativeText}
    />
  );
};

export default CompletedFormCheck;
