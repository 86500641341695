import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import { TEXT_COLOR } from '../../atoms/typographie/Text';
import { SIGN_IN_METHOD } from '@wohnsinn/ws-ts-lib';
import styles from './AuthProviderLoginButtons.module.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const AuthProviderLoginButtons: FC<{ handleUserRegistrationWithAuthProvider: any }> = ({
  handleUserRegistrationWithAuthProvider,
}) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.buttonWrapper}>
      <CTAButton
        type={'button'}
        expand={'block'}
        buttonText={t('signInWithGoogle.label')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        color={TEXT_COLOR.TEXT_COLOR_DARK}
        img={t('signInWithGoogle.icon')}
        onClick={() => handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.GOOGLE)}
      />
      <CTAButton
        type={'button'}
        expand={'block'}
        buttonText={t('signInWithApple.label')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        color={TEXT_COLOR.TEXT_COLOR_DARK}
        img={t('signInWithApple.icon')}
        onClick={() => handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.APPLE)}
      />
    </div>
  );
};

export default AuthProviderLoginButtons;
