import styles from './LandlordLandingTestimonialSection.module.scss';
import Headline, { HEADLINE_COLOR } from '../../../../../component/atoms/typographie/Headline';
import Avatar, { AVATAR_SIZE } from '../../../../../component/atoms/Avatar';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../../../../component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';

const LandlordLandingTestimonialSection = () => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={t('logos.dahmann_logo')} alt="Dahmann Logo" />
      </div>
      <Headline color={HEADLINE_COLOR.HEADLINE_COLOR_LIGHT} align={'center'}>
        “Mit Wohnsinn verzichten wir im gesamten Vermietungsprozess vollständig auf Papier”
      </Headline>
      <div className={styles.avatar}>
        <Avatar size={AVATAR_SIZE.lg} avatarUrl={t('images.michael_eichberger')} />
      </div>
      <Text color={TEXT_COLOR.TEXT_COLOR_LIGHT} align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
        Michael Eichberger, Makler
      </Text>
      <Text color={TEXT_COLOR.TEXT_COLOR_LIGHT} align={'center'}>
        Dahmann Immobilien, Köln
      </Text>
    </div>
  );
};

export default LandlordLandingTestimonialSection;
