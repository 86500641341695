import { FC, PropsWithChildren } from 'react';
import styles from './TextBlockSection.module.scss';

export interface TextBlockSectionProps {
  title?: string;
}

const TextBlockSection: FC<PropsWithChildren<TextBlockSectionProps>> = ({ children, title }) => {
  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default TextBlockSection;
