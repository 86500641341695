import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { IonText } from '@ionic/react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../typographie/Text';

interface IFormErrorMessageProps {
  error: FieldError;
  testId?: string;
}

const errorHandler = (error: FieldError, translate: TFunction): string => {
  let errorString = error.message;
  if (!error.message) {
    const newError: FieldError = Object.values(error)[0] as unknown as FieldError;
    errorString = newError?.message;
  }

  if (errorString.includes('must be a string') && errorString.includes('text')) {
    return translate('messageToShort');
  }
  if (errorString.includes('is not allowed to be empty') && errorString.includes('email')) {
    return translate('noEmptyEmail');
  }
  if (errorString.includes('must be a valid email') && errorString.includes('email')) {
    return translate('invalidEmail');
  }

  if (errorString.includes('length must be at least') && errorString.includes('text')) {
    return translate('messageToShort');
  }

  if (errorString.includes('auth/wrong-password')) {
    return translate('incorrectPasswort');
  }

  if (errorString.includes('match the required pattern: /\\d/')) {
    return translate('passwordRequiresNumber');
  }

  if (errorString.includes('fails to match the required pattern: /\\W|_/')) {
    return translate('passwordRequiresSpecialCharacter');
  }

  if (errorString.includes('"passwordRepeat" must be [ref:password]')) {
    return translate('notMatchingPasswords');
  }

  if (errorString.includes('monthlyIncome" must be greater than or equal to 100')) {
    return translate('incomeToLow');
  }

  if (errorString.includes('auth/user-not-found')) {
    return translate('userNotFound');
  }

  if (errorString.includes('toManyAttempts')) {
    return translate('toManyAttempts');
  }

  if (errorString.includes('length must be at least')) {
    return translate('toLessCharacters');
  }

  if (errorString.includes('must be a valid date') && errorString.includes('earliestMoveIn')) {
    return translate('selectEarliestMoveIn');
  }

  if (errorString.includes('must be one of') && errorString.includes('apartmentType')) {
    return translate('selectApartmentType');
  }

  if (errorString.includes('must be a positive number')) {
    return translate('positiveNumber');
  }

  if (errorString.includes('must be a number')) {
    return translate('mustBeNumber');
  }

  if (errorString.includes('must be greater than or equal to 1000')) {
    return translate('selectYear');
  }

  if (errorString.includes('must be one of') || errorString.includes('must be a boolean')) {
    return translate('pleaseSelect');
  }

  if (
    errorString.includes('is required') ||
    errorString.includes('must be a string') ||
    errorString.includes('not allowed to be empty') ||
    errorString.includes('contains an invalid value')
  ) {
    return translate('completeRequest');
  }

  if (errorString.includes('did not seem to be a phone number')) {
    return translate('enterValidPhone');
  }

  if (errorString.includes('dateOfBirth') && errorString.includes('must be a valid date')) {
    return translate('minBirthYear');
  }

  if (errorString.includes('postalCode" length must be 5 characters')) {
    return translate('enterPostalCode');
  }

  if (errorString.includes('website" must be a valid uri')) {
    return translate('enterValidUri');
  }
};

const FormErrorMessage: FC<IFormErrorMessageProps> = ({ error, testId = 'errorMessage' }) => {
  const { t } = useTranslation('common', { keyPrefix: 'component.atoms.formElement.FormErrorMessage' });

  return (
    error && (
      <IonText color="danger">
        {error && (
          <Text color={TEXT_COLOR.TEXT_COLOR_DANGER} tag={'p'} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
            {errorHandler(error, t)}
          </Text>
        )}
      </IonText>
    )
  );
};

export default FormErrorMessage;
