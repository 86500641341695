import { FC } from 'react';
import styles from './CheckList.module.scss';

export interface ICheckListProps {
  items: string[];
}

const CheckList: FC<ICheckListProps> = ({ items }) => {
  const renderCheckListItems = () =>
    items.map((item, index) => (
      <li className={styles.item} key={index}>
        <img data-testid={`checkList.img-${index}`} src={'/assets/icon/check.svg'} alt="" />
        <span data-testid={`checklist.item-${index}`}>{item}</span>
      </li>
    ));
  return <ul className={styles.list}>{renderCheckListItems()}</ul>;
};

export default CheckList;
