import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../core/const/routes';
import LandlordLandingView from '../view/landlord/landing/LandlordLandingView';
import ActivateAccountView from '../view/landlord/account/ActivateAccountView';
import RedirectView from '../view/general/RedirectView';
import RegistrationTunnelView from '../view/landlord/RegistrationTunnelView';
import { useTranslation } from 'react-i18next';
import AGBView from '../view/general/AGB';
import PrivacyView from '../view/general/PrivacyView';
import ImprintView from '../view/general/ImprintView';

const NotVerifiedLandlordRouter = () => {
  const { t } = useTranslation('common');

  return (
    <Switch>
      <Route exact path={t(ROUTES.landlord.landing.path)}>
        <LandlordLandingView />
      </Route>

      <Route exact path={t(ROUTES.general.agb.path)}>
        <AGBView />
      </Route>
      <Route exact path={t(ROUTES.general.privacy.path)}>
        <PrivacyView />
      </Route>
      <Route exact path={t(ROUTES.general.imprint.path)}>
        <ImprintView />
      </Route>

      {/* NOT VERIFIED LANDLORD ROUTER */}
      <Route path={t(ROUTES.landlord.account.activateAccount.path)}>
        <ActivateAccountView />
      </Route>
      <Route exact path={t(ROUTES.general.redirect.path)} component={RedirectView} />
      <Route exact path={`${t(ROUTES.general.redirect.path)}/:redirectType`} component={RedirectView} />
      <Route exact path={t(ROUTES.landlord.register.path)} component={RegistrationTunnelView} />
      <Redirect to={t(ROUTES.landlord.account.activateAccount.path)} />
    </Switch>
  );
};

export default NotVerifiedLandlordRouter;
