import { FormContext } from '../../../../../core/context/form.context';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import InputText from '../../../../atoms/formElement/InputText';
import Dropdown from '../../../../atoms/formElement/Dropdown';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from '../../../../../core/const/select-options';
import {
  APARTMENT_MAIN_INFORMATION_SCHEMA,
  APARTMENT_TYPE,
  IApartment,
  IApartmentMainInformation,
  ISelectOption,
} from '@wohnsinn/ws-ts-lib';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../../../atoms/formElement/InputOptionList';
import TextInput from '../../../../atoms/formElement/InputText';
import DateInput from '../../../../atoms/formElement/DateInput';
import { addYears } from 'date-fns';
import { FC, useContext, useEffect, useState } from 'react';
import { FirebaseFunctionsContext } from '../../../../../core/context/firebase-functions.context';
import useToast from '../../../../../core/hook/toast.hook';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { DEFAULT_APARTMENT_MAIN_INFORMATION } from '../../../../../view/landlord/apartment/ApartmentOverviewView/default-apartment-data';
import FB_FUNCTION_URLS from '../../../../../core/const/fb-function-names';
import FormHeader from '../../../../atoms/FormHeader';
import FormFooter from '../../../../atoms/FormFooter';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import SplitLine from '../../../../atoms/SplitLine';

export const APARTMENT_ROOMS_OPTION_LIST: ISelectOption<number>[] = [...new Array(5)].map((_, i) => {
  return {
    label: `${(i < 4 ? i + 1 : '5+').toString()}`,
    value: i + 1,
  };
});

export const APARTMENT_TYPES_SELECT_OPTION_LIST: ISelectOption<APARTMENT_TYPE>[] = [
  { value: APARTMENT_TYPE.BASEMENT, label: 'Kellergeschosswohnung' },
  { value: APARTMENT_TYPE.GROUND, label: 'Erdgeschosswohnung' },
  { value: APARTMENT_TYPE.LEVEL, label: 'Etagenwohnung' },
  { value: APARTMENT_TYPE.ROOF, label: 'Dachgeschosswohnung' },
];

const ApartmentMainInformationForm: FC<{ scrollToTop: () => any; apartment: IApartment; onCreate?: () => any }> = ({
  apartment,
  onCreate,
  scrollToTop,
}) => {
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const { sendInfoToast } = useToast();
  const { t } = useTranslation('common');
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { control, handleSubmit, reset, watch, setValue, clearErrors } = useForm<{
    mainInformation: IApartmentMainInformation;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(
      Joi.object({
        mainInformation: APARTMENT_MAIN_INFORMATION_SCHEMA,
      })
    ),
    defaultValues: { mainInformation: DEFAULT_APARTMENT_MAIN_INFORMATION },
  });

  const hasMinPeriod = watch('mainInformation.hasMinPeriod');
  const hasFixedTerm = watch('mainInformation.hasFixedTerm');
  const apartmentType = watch('mainInformation.address.apartmentType');

  useEffect(() => {
    if (apartment) {
      reset({ mainInformation: apartment.mainInformation });
    }
  }, [apartment]);

  const onSuccess = async (res: any): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    await sendInfoToast('toast.success');
    scrollToTop();
    onCreate ? onCreate() : null;
  };

  const onFormError = (err: any) => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    console.error('Error on ApartmentMainInformationForm', err);
  };

  const onFormSubmit = (): Promise<boolean | void> | void => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    if (apartment?.id) {
      return firebaseFunctionsService
        .callFbFunction(FB_FUNCTION_URLS.apartments.root.update, {
          params: { apartmentId: apartment.id },
          body: watch(),
        })
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    } else {
      return firebaseFunctionsService
        .callFbFunction(FB_FUNCTION_URLS.apartments.root.add, { body: watch() })
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (!hasMinPeriod) {
      setValue('mainInformation.minPeriod', null);
    } else {
      setValue('mainInformation.minPeriod', 1);
    }
  }, [hasMinPeriod]);

  useEffect(() => {
    if (!(apartmentType === APARTMENT_TYPE.LEVEL || apartmentType === APARTMENT_TYPE.ROOF)) {
      setValue('mainInformation.address.floor', null);
    }
  }, [apartmentType]);

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(onFormSubmit, (err) => onFormError(err))} noValidate autoComplete="off">
        <FormHeader title={'Stamminformationen'} buttonSubmitMode={buttonSubmitMode} />

        <IonGrid className={'wohnsinn-forms-grid'}>
          <FormSectionTitle title={'Adresse'} />
          <IonRow>
            <IonCol size="12">
              <InputText
                type={'text'}
                label={t('address.street.label')}
                name={'mainInformation.address.street'}
                required
              />
              <InputText
                type={'text'}
                label={t('address.houseNumber.label')}
                name={'mainInformation.address.houseNumber'}
                required
              />
              <InputText
                type={'text'}
                name={'mainInformation.address.postalCode'}
                label={t('address.postalCode.label')}
                required
              />
              <InputText type={'text'} label={t('address.city.label')} name={'mainInformation.address.city'} required />
              <Dropdown
                label={t('apartment.address.apartmentType.label')}
                placeholder={t('apartment.address.apartmentType.label')}
                name="mainInformation.address.apartmentType"
                optionList={getTranslatedOptions(APARTMENT_TYPES_SELECT_OPTION_LIST, t)}
              />
              {apartmentType === APARTMENT_TYPE.LEVEL && (
                <InputText
                  type={'number'}
                  pattern="[0-9]*"
                  name={'mainInformation.address.floor'}
                  label={t('apartment.address.floor.label')}
                  required
                />
              )}
              {apartmentType === APARTMENT_TYPE.ROOF && (
                <InputText
                  type={'number'}
                  pattern="[0-9]*"
                  name={'mainInformation.address.floor'}
                  label={t('apartment.address.floor.label')}
                  required
                />
              )}
              <InputOptionList
                mode={'radio'}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                label={t('apartment.address.publishAddress')}
                name={'mainInformation.address.hasPublishedAddress'}
                required
              />

              <SplitLine />

              <FormSectionTitle title={'Wohnung'} />
              <InputText
                type={'number'}
                pattern="[0-9]*"
                label={t('apartment.mainInformation.size.label')}
                name={'mainInformation.size'}
                required
                unit={t('apartment.cost.size.placeholder')}
              />

              <InputOptionList
                columns={RADIO_INPUT_COLUMNS_COUNT.FIVE}
                label={t('apartment.mainInformation.rooms.label')}
                mode={'radio'}
                options={getTranslatedOptions(APARTMENT_ROOMS_OPTION_LIST, t)}
                name={'mainInformation.rooms'}
                required
              />

              <SplitLine />

              <FormSectionTitle title={'Miete'} />

              <InputText
                name={'mainInformation.earliestMoveIn'}
                label={t('apartment.mainInformation.earliestMoveIn.label')}
              />

              <InputOptionList
                mode={'radio'}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                label={t('apartment.mainInformation.hasMinPeriod.label')}
                name={'mainInformation.hasMinPeriod'}
              />

              {hasMinPeriod && (
                <TextInput
                  label={t('apartment.mainInformation.minPeriod.label')}
                  name={'mainInformation.minPeriod'}
                  required
                />
              )}

              <InputOptionList
                mode={'radio'}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                label={t('apartment.mainInformation.hasFixedTerm.label')}
                name={'mainInformation.hasFixedTerm'}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
              />

              {hasFixedTerm && (
                <DateInput
                  startDate={new Date()}
                  endDate={addYears(new Date(), 50)}
                  label={t('apartment.mainInformation.fixedTerm.label')}
                  name={'mainInformation.fixedTerm'}
                  required
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentMainInformationForm;
