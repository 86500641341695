import { useContext } from 'react';
import { IonSelect, IonSelectOption, isPlatform } from '@ionic/react';
import FormErrorMessage from '../FormErrorMessage';
import { FormContext } from '../../../../core/context/form.context';
import { Controller } from 'react-hook-form';
import { ISelectOption } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../typographie/Text';

export interface InputSuggestionsDropdownProps<T = unknown> {
  disabled?: boolean;
  hideErrorMessage?: boolean;
  label: string;
  name: string;
  testId?: string;
  required?: boolean;
  optionList: ISelectOption<T>[];
  multiple?: boolean;
  helperText?: string;
  placeholder?: string;
  onChange?: any;
}

const Dropdown = <T,>(props: InputSuggestionsDropdownProps<T>) => {
  const { control } = useContext(FormContext);
  const { t } = useTranslation('common');

  const {
    disabled,
    label,
    name,
    testId,
    required,
    hideErrorMessage = false,
    optionList,
    multiple = false,
    placeholder = '',
    helperText,
    onChange,
  } = props;

  const handleIonChange = (field: any, e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    field.onChange(e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className={'floating-wrapper'}>
            <div className={'floating-field'}>
              <IonSelect
                className={'floating-select'}
                cancelText={t('cancel')}
                interface={isPlatform('mobile') || isPlatform('mobileweb') ? 'action-sheet' : 'alert'}
                disabled={disabled}
                data-testid={testId}
                value={field.value}
                onIonChange={(e) => handleIonChange(field, e)}
                onIonBlur={field.onBlur}
                multiple={multiple}
              >
                {optionList.map((o) => (
                  <IonSelectOption key={`${name}-${o.value}`} value={o.value}>
                    {o.label}
                  </IonSelectOption>
                ))}
              </IonSelect>

              <label htmlFor={name} className={`floating-label ${field.value ? 'isInputFilled' : ''}`}>
                <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                  {label} {label && required ? '*' : ''}
                </Text>
              </label>
            </div>

            {helperText && (
              <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
                {helperText}
              </Text>
            )}

            {!hideErrorMessage && <FormErrorMessage error={error} />}
          </div>
        );
      }}
    />
  );
};

export default Dropdown;
