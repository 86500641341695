import ModalWrapper from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { SIGN_IN_METHOD, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { FC, useContext, useEffect, useState } from 'react';
import UserContext from '../../../../../core/context/user.context';
import TextInput from '../../../../atoms/formElement/InputText';
import { FormContext } from '../../../../../core/context/form.context';
import ModalContext from '../../../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import styles from './RegisterUserModal.module.scss';
import { getAdditionalUserInfo, UserCredential } from 'firebase/auth';
import useToast from '../../../../../core/hook/toast.hook';
import MixpanelTrackingServiceContext from '../../../../../core/context/mixpanel-tracking-service.context';
import CheckList from '../../../../atoms/CheckList';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import LOCAL_STORAGE_KEYS from '../../../../../core/enum/local-storage.enum';
import RegistrationFormWrapper from '../../../../organisms/forms/signIn/RegistrationFormWrapper';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { USER_CREDENTIALS_FORM_SCHEMA } from '../../../../organisms/SwiperTunnel/LandlordRegistrationTunnel/Steps/RegisterLandlord';

const RegisterUserModal: FC = () => {
  const { mixpanelTrackingService } = useContext(MixpanelTrackingServiceContext);
  const { closeModal } = useContext(ModalContext);
  const { sendInfoToast } = useToast();
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', {
    keyPrefix: 'component.molecules.modals.signIn.RegisterUserModal',
  });
  const { userService, authService, tenantProfile, user } = useContext(UserContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { control, handleSubmit, reset, watch } = useForm<{
    organization: string;
    email: string;
    password: string;
    passwordRepeat: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
  });

  useEffect(() => {
    if (window?.location?.pathname.includes('matching')) {
      mixpanelTrackingService.trackOpenModal(MODAL_IDENTIFIER.REGISTER_USER, 'matching');
    } else {
      mixpanelTrackingService.trackOpenModal(MODAL_IDENTIFIER.REGISTER_USER, window?.location?.pathname);
    }
  }, []);

  const checkListItems = ['Exklusive Wohnungen', '100 % Kostenfrei', 'Bessere Chancen'];

  useEffect(() => {
    if (tenantProfile) {
      (async () => {
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
          await userService.transferNotLoggedInUserRatings(user, tenantProfile);
        }
        closeModal();
      })();
    }
  }, [tenantProfile]);
  const handleUserRegistrationWithAuthProvider = async (signInMethod: SIGN_IN_METHOD): Promise<void> => {
    const credentials: UserCredential = await authService.authProviderLogin(signInMethod);
    const moreInfo = await getAdditionalUserInfo(credentials);

    if (moreInfo.isNewUser) {
      await authService.handleUserRegistrationWithAuthProvider(credentials, USER_TYPE.TENANT);

      if (localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
        await userService.transferNotLoggedInUserRatings(user, tenantProfile);
      }
    }
    closeModal();
  };

  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const signInMethod = await authService.fetchSignInMethodsForEmail(watch().email);

    if (!signInMethod) {
      await authService.handleUserRegistrationWithEmailAndPassword(watch().email, watch().password, USER_TYPE.TENANT);

      if (localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
        await userService.transferNotLoggedInUserRatings(user, tenantProfile);
      }

      closeModal();
      return;
    }

    if ('password') {
      await sendInfoToast(t('toast.signIn.emailAccountExistsAlready'));
      reset({ password: '', passwordRepeat: '' });
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      return;
    }

    if ('google.com' || 'apple.com') {
      if (signInMethod.includes('google.com')) {
        await sendInfoToast(t('toast.googleAccountExistsAlready'));
        await handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.GOOGLE);
      }

      if (signInMethod.includes('apple.com')) {
        t('toast.appleAccountExistsAlready');
        await sendInfoToast(t('toast.appleAccountExistsAlready'));
        await handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.APPLE);
      }

      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      return;
    }
  };

  const handleFormError = (errors: FieldErrors) => {
    console.error(errors);
  };

  return (
    <ModalWrapper icon={t('logo.black.logoSrc')}>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
          <RegistrationFormWrapper
            buttonSubmitMode={buttonSubmitMode}
            handleUserRegistrationWithAuthProvider={handleUserRegistrationWithAuthProvider}
          >
            <div className={styles.checkList}>
              <CheckList items={checkListItems} />
            </div>

            <TextInput label={t('email.label')} name={'email'} type={'email'} required />
            <TextInput label={t('password.label')} type={'password'} name={'password'} required />
            <TextInput label={t('passwordRepeat.label')} type={'password'} name={'passwordRepeat'} required />
          </RegistrationFormWrapper>
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default RegisterUserModal;
