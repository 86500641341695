import styles from './BookmarkApartmentCard.module.scss';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { APARTMENT_TYPE, IApplication, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { IonAlert, IonRouterLink, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import getFormattedApartmentType from '../../../../core/helper/get-formatted-apartment-type';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Badge, { BADGE_COLORS } from '../../../atoms/Badge';
import { ROUTES } from '../../../../core/const/routes';
import UserContext from '../../../../core/context/user.context';

export interface IBookmarkApartmentCard {
  application: IApplication;
  matchingMode: MATCHING_MODE;
  apartmentClick: () => any;
  actionHandler: () => any;
  applyAction: () => any;
}

const BookmarkApartmentCard: FC<IBookmarkApartmentCard> = ({
  application,
  apartmentClick,
  applyAction,
  actionHandler,
  matchingMode,
}) => {
  const { t } = useTranslation('common');
  const router = useIonRouter();
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.address' });
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const { address, media } = application;
  const { tenantProfile } = useContext(UserContext);
  return (
    <div className={`${styles.wrapper} ${application.isChatDisabled ? styles.disabled : ''}`}>
      <ConfirmDislikeAlert
        action={actionHandler}
        isOpen={isDeleteAlertOpen}
        setIsOpen={setIsDeleteAlertOpen}
        id={application.apartmentId}
        matchingMode={matchingMode}
      />
      <IonRouterLink className={styles.link} onClick={apartmentClick} />
      <div
        style={{
          backgroundImage: `url('${
            media?.thumbnailUrl ? media.thumbnailUrl : media?.url ? media.url : t('apartmentPlaceholder')
          }')`,
        }}
        className={styles.image}
      >
        {/*<button className={styles.delete} onClick={() => setIsDeleteAlertOpen(true)}>*/}
        {/*  <img*/}
        {/*    className={styles.icon}*/}
        {/*    src={matchingMode === MATCHING_MODE.NOPE ? b('bookmarkIcon') : b('deleteIcon')}*/}
        {/*    alt={matchingMode === MATCHING_MODE.NOPE ? 'Like' : 'Dislike'}*/}
        {/*  />*/}
        {/*</button>*/}
      </div>

      <div className={styles.content}>
        <Headline size={HEADLINE_SIZE.H3}>
          {address.hasPublishedAddress ? `${address.street} ${address.houseNumber}` : ''}
          {address.hasPublishedAddress ? <br /> : null}
          {address.postalCode} {address.city}
        </Headline>
        <div className={styles.bottom}>
          <div>
            <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {`${application.warmRent} € • ${application.rooms} ${t('apartment.mainInformation.rooms.label')}  • ${
                application.size
              } ${t('qm')}`}
              <br />
              {address.apartmentType === APARTMENT_TYPE.LEVEL
                ? address.floor > 0
                  ? `${address.floor}. ${t('level')}`
                  : ''
                : getFormattedApartmentType(address.apartmentType, a)}
            </Text>
          </div>

          {application?.isChatDisabled ? (
            <div className={styles.disabledBadgeWrapper}>
              <Badge text={t('disabled')} color={BADGE_COLORS.DISABLED} />
            </div>
          ) : tenantProfile?.aboutMe && tenantProfile?.household && tenantProfile?.personalInformation ? (
            <CTAButton onClick={applyAction} buttonText={t('apply')} />
          ) : (
            <CTAButton
              onClick={() => router.push(t(ROUTES.tenant.account.applicationFolder.profile))}
              buttonText={t('apply')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ConfirmDislikeAlert: FC<{
  id: string;
  isOpen: boolean;
  matchingMode: MATCHING_MODE;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  action: () => any;
}> = ({ isOpen, setIsOpen, id, action, matchingMode }) => {
  const { t: b } = useTranslation('common', { keyPrefix: 'component.molecules.Cards.BookmarkApartmentCard' });
  return (
    <IonAlert
      header={matchingMode !== MATCHING_MODE.NOPE ? b('removeLike') : b('removeDislike')}
      id={id}
      isOpen={isOpen}
      buttons={[
        {
          text: 'Abbrechen',
          role: 'cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            action();
          },
        },
      ]}
      onDidDismiss={() => setIsOpen(false)}
    />
  );
};

export default BookmarkApartmentCard;
