import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ROUTES } from '../../../core/const/routes';
import PageLayout from '../../../component/organisms/PageLayout';
import ThreeColumnsLayoutContext from '../../../core/context/three-columns-layout-context';
import ThreeColumnsLayoutWrapper from '../../../component/organisms/ThreeColumnsLayout/ThreeColumnsLayoutWrapper';
import BookmarksWrapper from '../../../component/organisms/BookmarksWrapper';
import MobileHeaderButton from '../../../component/atoms/Buttons/MobileHeaderButton';
import Section from '../../../component/atoms/Section';
import { IonCol, IonRow } from '@ionic/react';
import CTACard from '../../../component/molecules/Cards/CTACard';
import UserContext from '../../../core/context/user.context';
import PageTitle from '../../../component/molecules/PageTitle';
import CTAButton, { BUTTON_STYLE } from '../../../component/atoms/Buttons/CTAButton';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import ApplicationListContext from '../../../core/context/application-list.context';
import MixpanelTrackingServiceContext from '../../../core/context/mixpanel-tracking-service.context';

const BookmarksView: FC = () => {
  const { t } = useTranslation('common');
  const { t: b } = useTranslation('common', { keyPrefix: 'view.BookmarksView' });
  const { user } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const { mixpanelTrackingService } = useContext(MixpanelTrackingServiceContext);

  const [showLeftSideBar, setShowLeftSideBar] = useState<boolean>(true);
  const [showRightSideBar, setShowRightSideBar] = useState<boolean>(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [showInfoButton, setShowInfoButton] = useState<boolean>(true);
  const { maybeList, nopeList, isLoading } = useContext(ApplicationListContext);

  const ShowApartmentInfoButton = () => {
    const handleInfoButton = () => {
      setShowLeftSideBar(!showLeftSideBar);
      setShowRightSideBar(!showRightSideBar);
    };

    if (showRightSideBar) {
      return <MobileHeaderButton text={t('showInfo')} hideText icon={faTimes} onClick={handleInfoButton} />;
    }
  };

  const showLeft = () => {
    setShowRightSideBar(false);
    setShowLeftSideBar(true);
  };

  const showMiddle = () => {
    setShowRightSideBar(false);
    setShowLeftSideBar(false);
  };

  const showRight = () => {
    setShowLeftSideBar(!showLeftSideBar);
    setShowRightSideBar(!showRightSideBar);
  };

  useEffect(() => {
    mixpanelTrackingService.trackEnterPage('Merkliste');
  }, []);

  const onClickLogin = () => {
    return openModal({ id: MODAL_IDENTIFIER.SIGN_IN });
  };

  const onClickRegister = () => {
    return openModal({ id: MODAL_IDENTIFIER.REGISTER_USER });
  };

  if (!user) {
    return (
      <PageLayout pageTitle={'Merkliste'} hideTabBar={false}>
        <Section>
          <IonCol>
            <PageTitle
              pageTitle={'Logge dich ein um deine Merkliste zu sehen'}
              secondPageTitle={'Du kannst dich bewerben und deine Wunschlisten bearbeiten, sobald du eingeloggt bist.'}
            />
          </IonCol>
        </Section>
        <IonRow>
          <IonCol size={'12'} sizeMd={'3'}>
            <CTAButton expand={'block'} onClick={onClickLogin} buttonText={t('signIn')} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size={'12'} sizeMd={'3'}>
            <CTAButton
              expand={'block'}
              buttonStyle={BUTTON_STYLE.SECONDARY}
              onClick={onClickRegister}
              buttonText={t('signUp')}
            />
          </IonCol>
        </IonRow>
      </PageLayout>
    );
  }

  if (isLoading) {
    return <div>loading</div>;
  }

  if (!maybeList?.length && !nopeList?.length) {
    return (
      <PageLayout hideTabBar={false} pageTitle={t(ROUTES.tenant.bookmark.title)} disableScroll>
        <Section center>
          <IonCol size={'12'} sizeMd={'6'}>
            <CTACard
              title={b('noApartmentSaved.title')}
              text={b('noApartmentSaved.text')}
              imgSrc={t('pictogram.bookmark')}
              imgAltText={b('bookmarkIcon')}
            />
          </IonCol>
        </Section>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      headerEndButton={showInfoButton ? <ShowApartmentInfoButton /> : null}
      fullWidthPage
      pageTitle={t(ROUTES.tenant.bookmark.title)}
      disableScroll
      hideTabBar={false}
      appHeaderBottomBorder
    >
      <ThreeColumnsLayoutContext.Provider
        value={{
          showLeftSideBar,
          showRightSideBar,
          showBackButton,
          setShowBackButton,
          setShowInfoButton,
          showInfoButton,
          showLeft,
          showRight,
          showMiddle,
        }}
      >
        <ThreeColumnsLayoutWrapper>
          <BookmarksWrapper />
        </ThreeColumnsLayoutWrapper>
      </ThreeColumnsLayoutContext.Provider>
    </PageLayout>
  );
};

export default BookmarksView;
