import styles from './LandlordLandingNavigation.module.scss';
import { IonGrid, IonRouterLink } from '@ionic/react';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../../../../component/atoms/typographie/Text';
import CTAButton, { BUTTON_STYLE } from '../../../../../component/atoms/Buttons/CTAButton';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import ModalContext from '../../../../../core/context/modal.context';
import { useContext, useState } from 'react';
import { ROUTES } from '../../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { PopupModal } from 'react-calendly';

const LandlordLandingNavigation = () => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  return (
    <nav className={styles.navigation}>
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_CATCH_UP_LINK}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
      <IonGrid fixed>
        <div className={`${styles.navigationContent} dark`}>
          <IonRouterLink className={styles.logo} routerLink={'/'}>
            <div className={`${styles.appLogo} app-logo app-logo--black`} />
          </IonRouterLink>
          <div className={styles.links}>
            <Text mobileAlign={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_LIGHT}>
              Vetrieb: +49 221 954 904 28
            </Text>
          </div>
          <div className={styles.buttonWrapper}>
            <CTAButton
              buttonStyle={BUTTON_STYLE.TERTIARY_LIGHT}
              buttonText={'Demo buchen'}
              onClick={() => setIsCalendlyOpen(true)}
            />
            <CTAButton
              buttonStyle={BUTTON_STYLE.SECONDARY}
              onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}
              buttonText={'Einloggen'}
            />
            <CTAButton buttonText={'Kostenlos inserieren'} link={t(ROUTES.landlord.register.path)} />
          </div>
        </div>
      </IonGrid>
    </nav>
  );
};

export default LandlordLandingNavigation;
