import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import styles from './Pagination.module.scss';
import Text, { TEXT_COLOR } from '../../atoms/typographie/Text';

const Pagination: FC<{ setCurrentPage: Dispatch<SetStateAction<number>>; pages: number[]; currentPage: number }> = ({
  pages,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <div className={styles.pagination}>
      {pages.map((some, i) => {
        if (i === 0) {
          return (
            <button
              className={`${styles.paginationItem} ${i === currentPage - 1 ? styles.active : ''}`}
              key={`pagination-${i}`}
              onClick={() => setCurrentPage(i + 1)}
            >
              <Text
                tag={'span'}
                color={i === currentPage - 1 ? TEXT_COLOR.TEXT_COLOR_LIGHT : TEXT_COLOR.TEXT_COLOR_DARK}
              >
                {i + 1}
              </Text>
            </button>
          );
        }

        if (i === pages.length - 1) {
          return (
            <button
              className={`${styles.paginationItem} ${i === currentPage - 1 ? styles.active : ''}`}
              key={`pagination-${i}`}
              onClick={() => setCurrentPage(i + 1)}
            >
              <Text
                tag={'span'}
                color={i === currentPage - 1 ? TEXT_COLOR.TEXT_COLOR_LIGHT : TEXT_COLOR.TEXT_COLOR_DARK}
              >
                {pages.length}
              </Text>
            </button>
          );
        }

        if (i === currentPage - 3 || i < currentPage - 3 || i === currentPage + 1 || i > currentPage + 1) {
          return (
            <Fragment key={`pagination-${i}`}>
              {i === currentPage - 3 ? '.' : ''}
              {i === currentPage - 4 ? '.' : ''}
              {i === currentPage - 5 ? '.' : ''}
              {i === currentPage + 1 ? '.' : ''}
              {i === currentPage + 2 ? '.' : ''}
              {i === currentPage + 3 ? '.' : ''}
            </Fragment>
          );
        }

        return (
          <button
            className={`${styles.paginationItem} ${i === currentPage - 1 ? styles.active : ''}`}
            key={`pagination-${i}`}
            onClick={() => setCurrentPage(i + 1)}
          >
            <Text tag={'span'} color={i === currentPage - 1 ? TEXT_COLOR.TEXT_COLOR_LIGHT : TEXT_COLOR.TEXT_COLOR_DARK}>
              {i + 1}
            </Text>
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;
