import HeadlineText from '../../../../../component/molecules/HeadlineText';
import styles from './LandlordLandingCustomersSection.module.scss';
import Text, { TEXT_WEIGHT } from '../../../../../component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';

const LandlordLandingCustomersSection = () => {
  const { t } = useTranslation('common');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.LandlordLanding.customers' });

  return (
    <div className={styles.wrapper}>
      <HeadlineText headline={l('headline')} text={l('subtitle')} />

      <div className={styles.customerWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>
            <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
              Makler
            </Text>
          </div>
          <div className={styles.content}>
            <div className={styles.imgWrapper}>
              <img src={t('logos.dahmann_logo')} alt="Dahmann Logo" />
            </div>

            <div className={styles.imgWrapper}>
              <img src={t('logos.bellassai_sigmund_logo')} alt="Bellassai und Sigmund Logo" />
            </div>

            <div className={styles.imgWrapper}>
              <img src={t('logos.labrig_mortag_logo')} alt="Labrig und Mortag Logo" />
            </div>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>
            <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
              Verwaltungen
            </Text>
          </div>
          <div className={styles.content}>
            <div className={styles.imgWrapper}>
              <img src={t('logos.mvgm_logo')} alt="MVGM Logo" />
            </div>

            <div className={styles.imgWrapper}>
              <img src={t('logos.sps_logo')} alt="SPS Logo" />
            </div>

            <div className={styles.imgWrapper}>
              <img src={t('logos.optimarum_logo')} alt="Optimarum Logo" />
            </div>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>
            <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
              Bestandshalter
            </Text>
          </div>
          <div className={styles.content}>
            <div className={styles.imgWrapper}>
              <img src={t('logos.metropol_logo')} alt="Metropol Logo" />
            </div>
            <div className={styles.imgWrapper}>
              <img src={t('logos.ivvg_logo')} alt="IVVG Logo" />
            </div>
            <div className={styles.imgWrapper}>
              <img src={t('logos.be_logo')} alt="BE Group Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandlordLandingCustomersSection;
