import { createContext } from 'react';
import { ChatService } from '../service/chat.service';

export interface IChatServiceContextProps {
  chatService: ChatService;
}

const ApartmentServiceContext = createContext<IChatServiceContextProps>({
  chatService: null,
});

export default ApartmentServiceContext;
