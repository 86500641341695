import { APARTMENT_TYPE } from '@wohnsinn/ws-ts-lib';

const getFormattedApartmentType = (type: APARTMENT_TYPE, transLateFn: (key: string) => string) => {
  switch (type) {
    case APARTMENT_TYPE.APARTMENT:
      return transLateFn('apartmentType.options.apartment');
    case APARTMENT_TYPE.BASEMENT:
      return transLateFn('apartmentType.options.basement');
    case APARTMENT_TYPE.GARAGE:
      return transLateFn('apartmentType.options.garage');
    case APARTMENT_TYPE.GROUND:
      return transLateFn('apartmentType.options.ground');
    case APARTMENT_TYPE.HOUSE:
      return transLateFn('apartmentType.options.house');
    case APARTMENT_TYPE.LEVEL:
      return transLateFn('apartmentType.options.level');
    case APARTMENT_TYPE.ROOF:
      return transLateFn('apartmentType.options.roof');
    case APARTMENT_TYPE.NONE:
      return transLateFn('apartmentType.options.unknown');
  }
};

export default getFormattedApartmentType;
