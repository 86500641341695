import { createContext } from 'react';
import { IMatchListResponse, ITenantFilterParams } from '@wohnsinn/ws-ts-lib';

export interface ITenantFilterParamsContextProps {
  fetchMatchList: any;
  loadingMatchData: boolean;
  matchData: IMatchListResponse;
  tenantFilterParams: ITenantFilterParams;
  updateTenantFilterParams: (data: ITenantFilterParams) => Promise<void>;
}

const TenantFilterParamsContext = createContext<ITenantFilterParamsContextProps>({
  fetchMatchList: null,
  loadingMatchData: null,
  matchData: null,
  tenantFilterParams: null,
  updateTenantFilterParams: null,
});

export default TenantFilterParamsContext;
