import CTAButton, { getSubmitIcon } from '../../../../atoms/Buttons/CTAButton';
import ModalWrapper from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { EMAIL_PASSWORD_SIGN_IN_FORM_SCHEMA, SIGN_IN_METHOD, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from '../../../../../core/enum/local-storage.enum';
import { fetchSignInMethodsForEmail, getAdditionalUserInfo, UserCredential } from 'firebase/auth';
import UserContext from '../../../../../core/context/user.context';
import { FC, useContext, useEffect, useState } from 'react';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import TextInput from '../../../../atoms/formElement/InputText';
import { FormContext } from '../../../../../core/context/form.context';
import { ROUTES } from '../../../../../core/const/routes';
import ModalContext from '../../../../../core/context/modal.context';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import useToast from '../../../../../core/hook/toast.hook';
import SplitLine from '../../../../atoms/SplitLine';
import AuthProviderLoginButtons from '../../../AuthProviderLoginButtons';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { useIonRouter } from '@ionic/react';

const SignInModal: FC = () => {
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.signIn.SignInModal' });
  const { authService, userService, user, tenantProfile, activeUserType } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { sendWarningToast, sendInfoToast } = useToast();
  const router = useIonRouter();

  const { control, handleSubmit, setValue } = useForm<{ email: string; password: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { email: '', password: '' },
    resolver: joiResolver(EMAIL_PASSWORD_SIGN_IN_FORM_SCHEMA),
  });

  useEffect(() => {
    const email = localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL);
    if (email) {
      setValue('email', email);
    }
  }, []);

  useEffect(() => {
    if (user && (activeUserType === USER_TYPE.LANDLORD || activeUserType === USER_TYPE.TENANT)) {
      router.push('/');
      closeModal();
    }
  }, [activeUserType]);

  const onEmailPasswordFormSubmit = async (formData: { email: string; password: string }): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      const signInMethod = await fetchSignInMethodsForEmail(authService.auth, formData.email);
      if (!signInMethod.length) {
        sendInfoToast(t('toast.signIn.givenEmailAccountDoesNotExist', { replace: { email: formData.email } }));
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }

      if (signInMethod.includes('password')) {
        await onSuccessSubmit(formData.email, formData.password);
      }

      if (signInMethod.includes('google.com')) {
        console.debug('already registered with google');
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
        return handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.GOOGLE);
      }

      if (signInMethod.includes('apple.com')) {
        console.debug('already registered with apple');
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
        return handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.APPLE);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUserRegistrationWithAuthProvider = async (signInMethod: SIGN_IN_METHOD): Promise<void> => {
    const credentials: UserCredential = await authService.authProviderLogin(signInMethod);
    const moreInfo = await getAdditionalUserInfo(credentials);

    if (moreInfo.isNewUser) {
      await authService.handleUserRegistrationWithAuthProvider(credentials, USER_TYPE.TENANT);

      if (localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
        await userService.transferNotLoggedInUserRatings(user, tenantProfile);
      }
    }
  };

  useEffect(() => {
    if (tenantProfile && activeUserType === USER_TYPE.TENANT) {
      (async () => {
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
          await userService.transferNotLoggedInUserRatings(user, tenantProfile);
        }
        closeModal();
      })();
    }
  }, [tenantProfile]);

  const onSuccessSubmit = async (email: string, password: string): Promise<boolean | void> => {
    try {
      await authService.emailAndPasswordLogin(email, password);
    } catch (error) {
      if (JSON.stringify(error).includes('auth/wrong-password')) {
        await sendWarningToast(t('toast.signIn.givenPasswordIsWrong'));
      } else if (JSON.stringify(error).includes('auth/invalid-email')) {
        await sendWarningToast(t('toast.signIn.invalidEmailAddress'));
      } else if (JSON.stringify(error).includes('auth/too-many-requests')) {
        await sendWarningToast(t('toast.signIn.userIsLockedBecauseOfTwoManyRequests'));
      } else if (JSON.stringify(error).includes('auth/internal-error')) {
        await sendWarningToast(t('toast.signIn.unknown'));
      }
    }
  };

  const handleLandlordClick = () => {
    router.push(t(ROUTES.landlord.register.path));
    closeModal();
  };

  return (
    <ModalWrapper title={m('title')} icon={t('logo.black.logoSrc')}>
      <form autoComplete={'off'} onSubmit={handleSubmit((data) => onEmailPasswordFormSubmit(data), console.error)}>
        <FormContext.Provider value={{ control }}>
          <TextInput label={t('email.label')} name={'email'} type={'email'} required />
          <TextInput label={t('password.label')} name={'password'} type={'password'} required />
          <button className={'hidden'}>submit</button>
        </FormContext.Provider>

        <CTAButton
          spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
          icon={getSubmitIcon(buttonSubmitMode)}
          expand={'block'}
          buttonText={t(ROUTES.signIn.title)}
        />

        <SplitLine text={t('or')}></SplitLine>

        <AuthProviderLoginButtons handleUserRegistrationWithAuthProvider={handleUserRegistrationWithAuthProvider} />

        <Text align={'center'}>
          <Text
            tag={'span'}
            align={'center'}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {m('noAccountYet')}
          </Text>
          <br />
          <button type={'button'} onClick={() => openModal({ id: MODAL_IDENTIFIER.REGISTER_USER })}>
            <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
              {m('createNow')}
            </Text>
          </button>
        </Text>
        <SplitLine size={'small'} />

        <Text align={'center'}>
          <Text
            tag={'span'}
            align={'center'}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {`${t('landlord')}?`}
          </Text>
          <br />

          <button type={'button'} onClick={handleLandlordClick}>
            <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
              {t('advertiseNow')}
            </Text>
          </button>
        </Text>
      </form>
    </ModalWrapper>
  );
};

export default SignInModal;
