import ItemList, { TItemListItem } from '../ItemList';
import { FC } from 'react';
import { IApartmentEnergySupply } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';

export interface IApartmentEnergySupplyProps {
  energySupply: IApartmentEnergySupply;
}

const ApartmentEnergySupply: FC<IApartmentEnergySupplyProps> = ({ energySupply }) => {
  const { t } = useTranslation('common', { keyPrefix: 'apartment.energySupply' });
  const { t: c } = useTranslation('common');
  const items: TItemListItem[] = [];

  if (energySupply) {
    if (energySupply.buildYear) {
      items.push({ key: 'buildYear', label: t('buildYear'), value: energySupply.buildYear });
    }

    if (energySupply.condition && energySupply.condition.length) {
      items.push({
        key: 'condition',
        label: t('condition.label'),
        value: t(`condition.options.${energySupply.condition}`),
      });
    }

    if (energySupply.heatingSystem && energySupply.heatingSystem.length) {
      items.push({
        key: 'heatingSystem',
        label: t('heatingSystem.label'),
        value: t(`heatingSystem.options.${energySupply.heatingSystem}`),
      });
    }

    if (energySupply.customHeatingSystem) {
      items.push({
        key: 'customHeatingSystem',
        label: t('customHeatingSystem'),
        value: energySupply.customHeatingSystem,
      });
    }

    if (energySupply.yearOfLastActivity) {
      items.push({
        key: 'yearOfLastActivity',
        label: t('yearOfLastActivity'),
        value: energySupply.yearOfLastActivity,
      });
    }

    if (energySupply.energyEfficiency && energySupply.energyEfficiency.length) {
      items.push({
        key: 'energyEfficiency',
        label: t('energyEfficiency'),
        value: energySupply.energyEfficiency,
      });
    }

    items.push({
      key: 'isEnergyPassNecessary',
      label: t('isEnergyPassNecessary'),
      value: energySupply.isEnergyPassAvailable ? c('yes') : c('no'),
    });

    items.push({
      key: 'isEnergyPassAvailable',
      label: t('isEnergyPassAvailable'),
      value: energySupply.isEnergyPassAvailable ? c('yes') : c('no'),
    });

    if (energySupply.energyPassType) {
      items.push({
        key: 'energyPassType',
        label: t('energyPassType.label'),
        value: t(`energyPassType.options.${energySupply.energyPassType}`),
      });
    }

    if (energySupply.energyConsumption) {
      items.push({
        key: 'energyConsumption',
        label: t('energyConsumption'),
        value: energySupply.energyConsumption,
      });
    }

    items.push({
      key: 'isEnergyConsumptionForHotWaterIncluded',
      label: t('isEnergyConsumptionForHotWaterIncluded'),
      value: energySupply.isEnergyConsumptionForHotWaterIncluded ? c('yes') : c('no'),
    });
  }

  return energySupply ? <ItemList testId={'energySupply'} items={items} /> : <></>;
};

export default ApartmentEnergySupply;
