import styles from './MobileApartmentPreviewCard.module.scss';
import { APARTMENT_TYPE, IApartment } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import { IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../../atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import getFormattedApartmentType from '../../../../core/helper/get-formatted-apartment-type';
import { IApartmentWithRating } from '../../../../view/landlord/organization/OrganizationPortfolioView';
import TagList from '../../TagList';
import { TAG_COLOR } from '../../../atoms/Tag';

export interface IMobileApartmentPreviewCard {
  apartment: IApartmentWithRating | IApartment;
  route: string;
}

const MobileApartmentPreviewCard: FC<IMobileApartmentPreviewCard> = ({ apartment, route }) => {
  const { address } = apartment.mainInformation;
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.address' });

  const basics = [
    { key: 'warmRent', label: `💰 ${apartment.cost?.warmRent ? `${apartment.cost.warmRent} €` : 'keine angabe'}` },
    { key: 'rooms', label: `🛋️ ${apartment?.mainInformation?.rooms} ${t('apartment.mainInformation.rooms.label')}` },
    { key: 'size', label: `📐 ${apartment?.mainInformation?.size} m²` },
  ];

  const level =
    address?.apartmentType === APARTMENT_TYPE.LEVEL
      ? address?.floor > 0
        ? `${address?.floor}. ${t('level')}`
        : ''
      : getFormattedApartmentType(address?.apartmentType, a);

  if (level) {
    basics.push({ key: 'level', label: `🪜 ${level}` });
  }

  return (
    <IonRouterLink routerLink={route} className={styles.apartmentCard}>
      <div className={styles.contentWrapper}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${
              apartment.media && apartment.media.length ? apartment.media[0].url : t('apartmentPlaceholder')
            })`,
          }}
        ></div>

        <div className={styles.content}>
          <Headline tag={'span'} size={HEADLINE_SIZE.H3}>
            {address.hasPublishedAddress ? `${address.street} ${address.houseNumber}` : 'Adresse auf Anfragee'}
          </Headline>
          <br />
          <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_DARK} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
            {address.postalCode} {address.city}
          </Text>
          <div className={styles.bottom}>
            <TagList color={TAG_COLOR.BLUE} size={'small'} testId={'apartmentBasics'} tags={basics} />
          </div>
        </div>
      </div>
    </IonRouterLink>
  );
};

export default MobileApartmentPreviewCard;
