import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { ROUTES } from '../../../../../../core/const/routes';
import UserContext from '../../../../../../core/context/user.context';
import SwiperTunnelStepsWrapper from '../../../Layout/SwiperTunnelStepsWrapper';
import SwiperTunnelTitle from '../../../Layout/SwiperTunnelTitle';

const StepConfirmEmail: FC<{ allowRedirect: boolean }> = ({ allowRedirect }) => {
  const { user } = useContext(UserContext);
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.RegisterLandlordSteps.StepConfirmEmail' });
  const router = useIonRouter();

  useEffect(() => {
    if (allowRedirect && user?.isEmailVerified) {
      router.push(`${c(ROUTES.general.redirect.path)}/register`);
    }
  }, [user, allowRedirect]);

  return (
    <SwiperTunnelStepsWrapper>
      <SwiperTunnelTitle image={c('pictogram.mail')} title={t('title')} subtitle={t('subtitle')} />
    </SwiperTunnelStepsWrapper>
  );
};

export default StepConfirmEmail;
