import styles from './FilterBar.module.scss';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import { FC, useContext, useState } from 'react';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import TenantFilterParamsContext from '../../../core/context/tenant-filter-params.context';
import CTAButton from '../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { faSlidersUp } from '@fortawesome/pro-solid-svg-icons';
import useWindowSize from '../../../core/hook/windowsize.hook';

export enum FILTER_TABS {
  LOCATION = 'LOCATION',
  NONE = 'NONE',
  PERSONS = 'PERSONS',
  RENT = 'RENT',
  ROOMS = 'ROOMS',
}

const FilterBar: FC = () => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const [activeTab, setActiveTab] = useState(FILTER_TABS.NONE);
  const { isSmallerMd } = useWindowSize();

  const handleClickOnFilter = (filter: FILTER_TABS) => {
    let filterId: MODAL_IDENTIFIER = null;
    switch (filter) {
      case FILTER_TABS.LOCATION:
        filterId = MODAL_IDENTIFIER.FILTER_LOCATION;
        setActiveTab(FILTER_TABS.LOCATION);
        break;
      case FILTER_TABS.ROOMS:
        filterId = MODAL_IDENTIFIER.FILTER_ROOMS;
        setActiveTab(FILTER_TABS.ROOMS);
        break;
      case FILTER_TABS.RENT:
        filterId = MODAL_IDENTIFIER.FILTER_RENT;
        setActiveTab(FILTER_TABS.RENT);
        break;
      case FILTER_TABS.PERSONS:
        setActiveTab(FILTER_TABS.PERSONS);
        filterId = MODAL_IDENTIFIER.FILTER_PERSONS;
        break;
      default:
        filterId = null;
    }

    if (!filterId) return;
    openModal({ id: filterId, data: { setActiveTab } });
  };

  return (
    <>
      <div className={`${styles.wrapper} ${activeTab !== FILTER_TABS.NONE ? styles.active : ''}`}>
        <button
          onClick={() => handleClickOnFilter(FILTER_TABS.LOCATION)}
          className={`${styles.location} ${activeTab === FILTER_TABS.LOCATION ? styles.active : ''}`}
        >
          {tenantFilterParams?.location?.place_name ? (
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_FOOTER}>
              Wohin?
            </Text>
          ) : null}
          <Text
            tag={'span'}
            align={'left'}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={tenantFilterParams?.location?.place_name ? TEXT_COLOR.TEXT_COLOR_DARK : TEXT_COLOR.TEXT_COLOR_ACCENT}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
          >
            {tenantFilterParams?.location?.place_name
              ? tenantFilterParams.location.place_name
              : 'Wo möchtest du wohnen?'}
          </Text>
        </button>

        <FilterToggle
          label={'Wie teuer?'}
          onClick={() => handleClickOnFilter(FILTER_TABS.RENT)}
          param={tenantFilterParams?.warmRent}
          unit={'€'}
        />
        <FilterToggle
          label={'Wer zieht ein?'}
          onClick={() => handleClickOnFilter(FILTER_TABS.PERSONS)}
          param={tenantFilterParams?.numberOfPeopleMovingIn}
          unit={'Personen'}
        />

        <CTAButton
          onClick={() => openModal({ id: MODAL_IDENTIFIER.FILTER_MODAL })}
          icon={faSlidersUp}
          expand={'block'}
          buttonText={t('Filter')}
        />
      </div>
    </>
  );
};

const FilterToggle: FC<{ onClick: any; param: string | number; label: string; unit?: string }> = ({
  onClick,
  param,
  label,
  unit,
}) => (
  <button className={styles.filterToggle} onClick={onClick}>
    {param ? (
      <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_FOOTER}>
        {label}
      </Text>
    ) : null}
    <div style={{ textAlign: param ? 'left' : 'center' }}>
      <Text
        tag={'span'}
        align={'left'}
        variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
        color={param ? TEXT_COLOR.TEXT_COLOR_DARK : TEXT_COLOR.TEXT_COLOR_ACCENT}
        weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
      >
        {param ? param : label}
      </Text>{' '}
      {param && unit ? (
        <Text
          tag={'span'}
          align={'left'}
          type={TEXT_TYPE.TEXT_TYPE_CAPTION}
          variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
          color={param ? TEXT_COLOR.TEXT_COLOR_DARK : TEXT_COLOR.TEXT_COLOR_ACCENT}
          weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
        >
          {param && unit ? unit : ''}
        </Text>
      ) : null}
    </div>
  </button>
);

export default FilterBar;
