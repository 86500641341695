import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chat from '../index';
import ThreeColumnsLayoutMiddle from '../../ThreeColumnsLayout/ChatLayoutMiddle';
import ThreeColumnsLayoutLeftSide from '../../ThreeColumnsLayout/ThreeColumnsLayoutLeftSide';
import ChatListItem from '../../../molecules/ChatListItem';
import { IApplication, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import ApplicationServiceContext from '../../../../core/context/application-service.context';
import ThreeColumnsLayoutContext from '../../../../core/context/three-columns-layout-context';
import ApartmentInformation from '../../ApartmentInformation';
import ThreeColumnsLayoutRightSide from '../../ThreeColumnsLayout/ThreeColumnsLayoutRightSide';
import ApplicationListContext from '../../../../core/context/application-list.context';
import useWindowSize from '../../../../core/hook/windowsize.hook';
import { ROUTES } from '../../../../core/const/routes';
import { IonCol, IonGrid } from '@ionic/react';
import CTACard from '../../../molecules/Cards/CTACard';
import Section from '../../../atoms/Section';
import styles from '../Chat.module.scss';
import useApartment from '../../../../core/hook/apartment.hook';

const TenantChatWrapper: FC = () => {
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationListView' });
  const { applicationService } = useContext(ApplicationServiceContext);
  const { setPageTitle, showMiddle, showLeft } = useContext(ThreeColumnsLayoutContext);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const { apartment } = useApartment(selectedApplication?.apartmentId);
  const { likeList: applicationList } = useContext(ApplicationListContext);
  const { isSmallerMd } = useWindowSize();

  const handleApplicationSelection = (application: IApplication, isInitial: boolean): Promise<void> => {
    setSelectedApplication(application);

    if (!isInitial) {
      showMiddle();
    } else {
      showLeft();
    }

    if (isInitial && isSmallerMd) {
      setPageTitle(t(ROUTES.tenant.chat.title));
    } else {
      setPageTitle(
        `${
          application.address.hasPublishedAddress
            ? application.address.street
            : `${application.address.postalCode} ${application.address.city}`
        }`
      );
    }

    return applicationService.markUnread(application, USER_TYPE.TENANT);
  };

  useEffect(() => {
    if (!selectedApplication && applicationList.length) {
      handleApplicationSelection(applicationList[0], true);
    }

    if (selectedApplication && applicationList.length) {
      applicationService.markUnread(selectedApplication, USER_TYPE.TENANT);
    }
  }, [applicationList]);

  if (!applicationList.length) {
    return (
      <div className={styles.emptyApplicationsWrapper}>
        <IonGrid>
          <Section center>
            <IonCol size={'12'} sizeMd={'6'}>
              <CTACard
                title={a('noApplications.title')}
                text={a('noApplications.text')}
                imgSrc={t('pictogram.chatMessage')}
                imgAltText={t('bookmarkIcon')}
              />
            </IonCol>
          </Section>
        </IonGrid>
      </div>
    );
  }

  return (
    <>
      <ThreeColumnsLayoutLeftSide>
        <ul className={styles.list}>
          {applicationList.map((a) => (
            <ChatListItem
              key={a.apartmentId}
              isActive={a.apartmentId === selectedApplication?.apartmentId}
              itemClickHandler={(application) => handleApplicationSelection(application, false)}
              application={a}
              hasUnreadMessages={!!a.unreadLandlordMessagesRef?.length}
            />
          ))}
        </ul>
      </ThreeColumnsLayoutLeftSide>

      <ThreeColumnsLayoutMiddle>
        {selectedApplication && <Chat key={selectedApplication.apartmentId} application={selectedApplication} />}
      </ThreeColumnsLayoutMiddle>

      <ThreeColumnsLayoutRightSide>
        {!!apartment && <ApartmentInformation key={`${apartment.id}`} apartment={apartment} />}
      </ThreeColumnsLayoutRightSide>
    </>
  );
};

export default TenantChatWrapper;
