import { FC } from 'react';
import styles from './FormSectionTitle.module.scss';
import Text, { TEXT_COLOR, TEXT_VARIANT, TEXT_WEIGHT } from '../typographie/Text';

export interface IFormSectionTitleProps {
  title: string;
  subtitle?: string;
}

const FormSectionTitle: FC<IFormSectionTitleProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.wrapper}>
      <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} tag={'span'}>
        {title}
      </Text>{' '}
      <br />
      {subtitle ? (
        <Text tag={'span'} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {subtitle}
        </Text>
      ) : null}
    </div>
  );
};

export default FormSectionTitle;
