import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../core/const/routes';
import PageLayout from '../../../component/organisms/PageLayout';
import Section from '../../../component/atoms/Section';

const ImprintView = () => {
  const { t } = useTranslation('common');
  return (
    <PageLayout pageTitle={t(ROUTES.general.imprint.title)}>
      <Section>
        <div>
          Wohnsinn GmbH
          <br />
          Martinsfeld 12
          <br />
          50676 Köln <br />
          Telefon: +49 221 954 904 28
          <br />
          E-Mail: <a href="mailto:kontakt@wohnsinn.com">kontakt@wohnsinn.com</a>
          <br />
          Vertretungsberechtigte Geschäftsführer: Christian Philippi & Felix Weiß
          <br />
          Handelsregister: Amtsgericht Köln
          <br />
          Registernummer: HRB 105211
        </div>
      </Section>
    </PageLayout>
  );
};

export default ImprintView;
