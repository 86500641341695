import { IApartment } from '@wohnsinn/ws-ts-lib';
import { useContext, useEffect, useState } from 'react';
import ApartmentServiceContext from '../context/apartment-service.context';
import { useParams } from 'react-router';
import { onSnapshot } from 'firebase/firestore';

export interface IUseApartmentProps {
  apartment: IApartment;
  isLoading: boolean;
}

const useApartment = (apartmentIdFromProps?: string): IUseApartmentProps => {
  let { apartmentId } = useParams<{ apartmentId: string }>();
  const { apartmentService } = useContext(ApartmentServiceContext);
  const [apartment, setApartment] = useState<IApartment>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  if (apartmentIdFromProps) {
    apartmentId = apartmentIdFromProps;
  }

  const fetchApartment = async () => {
    setIsLoading(true);
    onSnapshot(
      apartmentService.getApartmentRef(apartmentId),
      (apartmentListSnap) => {
        const apartment = apartmentListSnap.docs.map((doc) => doc.data())[0];
        setApartment(apartment);
      },
      (err) => console.error('error on useApartmentHook: ', err)
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (apartmentId) {
      fetchApartment();
    } else {
      setIsLoading(false);
    }
  }, [apartmentId]);

  return { apartment, isLoading };
};

export default useApartment;
