import { FC, useContext } from 'react';
import { IUpload, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import DocumentUploadButton from './DocumentUploadButton';
import UserContext from '../../../core/context/user.context';
import { useDropzone } from 'react-dropzone';
import StorageServiceContext from '../../../core/context/storage-service.context';
import { generateUUID } from '@ionic/cli/lib/utils/uuid';

export enum UPLOAD_TYPE {
  SCHUFA = 'SCHUFA',
  INCOME_PROOF = 'INCOME_PROOF',
  APARTMENT_MEDIA = 'APARTMENT_MEDIA',
  INTRODUCTION_VIDEO = 'INTRODUCTION_VIDEO',
}

const DocumentUploadDropZone: FC<{
  uploadType: UPLOAD_TYPE;
  onUploadButtonClick?: () => void;
  onFileSelection?: () => void;
  onUploadListCreation?: (uploadList: IUpload[], uploadType: UPLOAD_TYPE) => void;
}> = ({ onUploadButtonClick, uploadType, onFileSelection, onUploadListCreation }) => {
  const { tenantProfile } = useContext(UserContext);
  const { storageService } = useContext(StorageServiceContext);

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (onFileSelection) {
      onFileSelection();
    }

    const newFileUploadPromiseList: Promise<IUpload>[] = Array.from(acceptedFiles).map(async (file) => {
      const id = generateUUID();
      return await storageService.createFileUpload(file, {
        id,
        creatorId: tenantProfile.uid,
        mediaType: MEDIA_TYPE.DOCUMENT,
        uploadType,
      });
    });

    const uploadList: IUpload[] = await Promise.all(newFileUploadPromiseList);

    if (onUploadListCreation) {
      onUploadListCreation(uploadList, uploadType);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: true,
  });

  return (
    tenantProfile && (
      <div {...getRootProps()}>
        <input
          id={uploadType === UPLOAD_TYPE.SCHUFA ? 'schufaCheckFileInput' : 'incomeProofFileInput'}
          {...getInputProps()}
        />
        <DocumentUploadButton onClick={() => (onUploadButtonClick ? onUploadButtonClick() : open())} />
      </div>
    )
  );
};

export default DocumentUploadDropZone;
