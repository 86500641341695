import { createContext } from 'react';
import { IUserWithRoles, USER_TYPE, ILandlordProfile, ITenantProfile } from '@wohnsinn/ws-ts-lib';
import UserService from '../service/user.service';
import AuthService from '../service/auth.service';
import OrganizationService from '../service/organisation.service';

const UserContext = createContext<{
  authService: AuthService;
  activeUserType: USER_TYPE;
  landlordProfile: ILandlordProfile | null;
  tenantProfile: ITenantProfile | null;
  user: IUserWithRoles | null;
  userService: UserService;
  organizationService: OrganizationService | null;
}>({
  authService: null,
  activeUserType: USER_TYPE.NONE,
  landlordProfile: null,
  tenantProfile: null,
  user: null,
  userService: null,
  organizationService: null,
});

export default UserContext;
