import Breadcrumbs from '../index';
import { IonBreadcrumb } from '@ionic/react';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

const AccountBreadcrumbs: FC<{ page: any }> = ({ page }) => {
  const { t } = useTranslation('common');
  return (
    <Breadcrumbs backRoute={t(ROUTES.tenant.account.router)}>
      <IonBreadcrumb routerLink={t(ROUTES.tenant.account.router)}>{t(ROUTES.tenant.account.title)}</IonBreadcrumb>
      <IonBreadcrumb routerLink={t(page.path)}>{t(page.title)}</IonBreadcrumb>
    </Breadcrumbs>
  );
};

export default AccountBreadcrumbs;
