import ApartmentMainInformationForm from '../../../../component/organisms/forms/apartment/ApartmentMainInformationForm';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import FormTabs from '../../../../component/organisms/FormTabs';
import { IonCol, useIonRouter } from '@ionic/react';
import scrollTo from '../../../../core/helper/scroll-to';
import { useRef } from 'react';
import PageTitle from '../../../../component/molecules/PageTitle';
import Section from '../../../../component/atoms/Section';

const ApartmentCreateView = () => {
  const { t } = useTranslation('common');
  const router = useIonRouter();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  return (
    <PageLayout contentRef={contentRef} pageTitle={t(ROUTES.landlord.apartment.new.title)}>
      <Section>
        <IonCol>
          <PageTitle pageTitle={t(ROUTES.landlord.apartment.new.title)} />
        </IonCol>
      </Section>
      <FormTabs tabs={[{ label: 'Wohnung erstellen', value: 'create' }]} activeTab={'create'} setActiveTab={null}>
        <ApartmentMainInformationForm
          scrollToTop={() => scrollTo(contentRef)}
          onCreate={() => router.push(t(ROUTES.landlord.apartment.list.path))}
          apartment={null}
        />
      </FormTabs>
    </PageLayout>
  );
};

export default ApartmentCreateView;
