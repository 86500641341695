import { FC, useContext, useEffect, useState } from 'react';
import useApartment from '../../core/hook/apartment.hook';
import ApartmentInformation from '../../component/organisms/ApartmentInformation';
import MapboxMap from '../../component/molecules/Map';
import PageLayout from '../../component/organisms/PageLayout';
import { ROUTES } from '../../core/const/routes';
import { useTranslation } from 'react-i18next';
import styles from './ShareApartmentView.module.scss';
import useWindowSize from '../../core/hook/windowsize.hook';
import ApartmentRatingButtons from '../../component/atoms/ApartmentRatingButtons';
import UserContext from '../../core/context/user.context';
import { IApplication, IOrganization, MATCHING_MODE, TApplicationCreate, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from '../../core/enum/local-storage.enum';
import { MODAL_IDENTIFIER } from '../../core/enum/modals.enum';
import { saveRatingInLocaleStorage } from '../../component/organisms/ApartmentStackWrapper';
import ModalContext from '../../core/context/modal.context';
import useToast from '../../core/hook/toast.hook';
import ApplicationServiceContext from '../../core/context/application-service.context';
import { useIonRouter } from '@ionic/react';
import NotificationBadgesContext from '../../core/context/notifications-badges-context';
import CTAButton from '../../component/atoms/Buttons/CTAButton';
import { motion } from 'framer-motion';
import MixpanelTrackingServiceContext from '../../core/context/mixpanel-tracking-service.context';
import { getAnimation } from '../../component/organisms/ApartmentStack';

export type TAnimationDirection = 'left' | 'right' | 'initial';

const ShareApartmentView: FC<{ isPortfolio?: boolean }> = ({ isPortfolio = false }) => {
  const { mixpanelTrackingService } = useContext(MixpanelTrackingServiceContext);
  const { sendSuccessToast } = useToast();
  const { openModal } = useContext(ModalContext);
  const { applicationService } = useContext(ApplicationServiceContext);
  const { apartment, isLoading } = useApartment();
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ApartmentAdvertisementView' });
  const { isGreaterMd } = useWindowSize();
  const router = useIonRouter();
  const { setBookmarkNotifications } = useContext(NotificationBadgesContext);
  const { organizationService, user, tenantProfile } = useContext(UserContext);
  const [organization, setOrganization] = useState<IOrganization>(null);
  const [application, setApplication] = useState<IApplication>(null);
  const [animationDirection, setAnimationDirection] = useState<TAnimationDirection>('initial');
  const REDIRECT_ROUTE = isPortfolio
    ? t(ROUTES.landlord.organization.portfolio.path).replace(':slug', organization?.slug)
    : t(ROUTES.tenant.matching.list.path);

  const fetchData = async (): Promise<void> => {
    if (apartment?.organizationId) {
      if (apartment?.creatorId && apartment?.id && user?.uid) {
        const application: IApplication = await applicationService.getApplicationRating(
          apartment?.creatorId,
          apartment?.id,
          user?.uid
        );
        setApplication(application ?? null);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [apartment]);

  const submitRatingHandler = async (rating: MATCHING_MODE) => {
    mixpanelTrackingService.trackRatingClick(rating, apartment.id);
    setAnimationDirection(rating === MATCHING_MODE.NOPE ? 'left' : 'right');

    if (user) {
      await createApplication(rating);
    } else {
      // IF USER TRIES TO APPLY - FORCE REGISTRATION / LOGIN
      if (rating === MATCHING_MODE.LIKE) {
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({
            id: MODAL_IDENTIFIER.REGISTER_USER,
            data: {
              userType: USER_TYPE.TENANT,
              redirectUrl: REDIRECT_ROUTE,
            },
          });
        }, 300);
      } else {
        console.log('REDIRECT_ROUTE', REDIRECT_ROUTE);
        saveRatingInLocaleStorage(rating, apartment.id, setBookmarkNotifications);
        redirectAfterRating();
      }
    }
  };

  const createApplication = async (rating: MATCHING_MODE) => {
    const hasCompletedSelfDisclosure =
      tenantProfile?.aboutMe && tenantProfile?.household && tenantProfile?.personalInformation;

    localStorage.setItem(
      LOCAL_STORAGE_KEYS.REDIRECT_URL,
      `${t(ROUTES.landlord.organization.portfolio.apartment.path)
        .replace(':slug', organization?.slug)
        .replace(':apartmentId', apartment.id)}`
    );

    if (rating === MATCHING_MODE.LIKE) {
      if (!user.isEmailVerified) {
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.CONFIRM_EMAIL });
        }, 300);
        return;
      }

      if (!hasCompletedSelfDisclosure) {
        setAnimationDirection('right');
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
        }, 300);
        return;
      }
    }

    try {
      const application: TApplicationCreate = {
        apartmentId: apartment.id,
        address: apartment.mainInformation.address,
        media: apartment.media[0] || null,
        isLandlordTyping: false,
        isTenantTyping: false,
        landlordId: apartment.creatorId,
        editorList: apartment.editorList,
        landlordRating: MATCHING_MODE.NONE,
        rating,
        lastMessageSenderId: tenantProfile.uid,
        lastMessage: {
          editorState: '',
          html: '',
        },
        rooms: apartment?.mainInformation?.rooms ?? 0,
        warmRent: apartment?.cost?.warmRent ?? 0,
        size: apartment?.mainInformation?.size ?? 0,
        seenByLandlord: false,
        tenantProfile,
      };
      await applicationService.addApplicationList([application]);

      if (hasCompletedSelfDisclosure && rating === MATCHING_MODE.LIKE) {
        await sendSuccessToast('Perfekt! Deine Bewerbung ist abgeschickt.');
      }

      redirectAfterRating();
    } catch (error) {
      console.error(error);
    }
  };

  const redirectAfterRating = () => {
    // TIMEOUT NEEDED TO SEE THE ANIMATION
    setTimeout(() => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, REDIRECT_ROUTE);
      router.push(t(ROUTES.general.redirect.path));
    }, 350);
  };

  return (
    <PageLayout showBackButton pageTitle={t(ROUTES.landlord.apartment.preview.title)} fullWidthPage>
      <div className={styles.wrapper}>
        <div className={styles.apartmentInformation}>
          {apartment ? (
            <>
              <motion.div
                key={apartment?.id}
                className={styles.apartment}
                style={{
                  zIndex: 10,
                }}
                animate={getAnimation(true, animationDirection)}
              >
                <ApartmentInformation apartment={apartment} />
              </motion.div>
              <div className={styles.ratings}>
                {apartment?.applicationRefList.includes(user?.uid) ? (
                  <CTAButton
                    buttonText={'Zur Bewerbung'}
                    expand={'block'}
                    onClick={() => router.push(t(ROUTES.tenant.chat.path))}
                  />
                ) : (
                  <ApartmentRatingButtons
                    hasApplication={!!application}
                    onButtonClick={(matchingMode) => submitRatingHandler(matchingMode)}
                  />
                )}
              </div>
            </>
          ) : null}
        </div>
        <div className={styles.map}>
          {!isLoading && !!apartment && isGreaterMd && <MapboxMap isInteractive={true} apartments={[apartment]} />}
        </div>
      </div>
    </PageLayout>
  );
};

export default ShareApartmentView;
