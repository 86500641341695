import { FC, useContext, useEffect, useState } from 'react';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import CTAButton, { BUTTON_STYLE } from '../../../../atoms/Buttons/CTAButton';
import { TEXT_COLOR } from '../../../../atoms/typographie/Text';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import ModalContext from '../../../../../core/context/modal.context';
import { useTranslation } from 'react-i18next';
import LOCAL_STORAGE_KEYS from '../../../../../core/enum/local-storage.enum';
import { PopupModal } from 'react-calendly';
import UserContext from '../../../../../core/context/user.context';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import FormHeader from '../../../../atoms/FormHeader';

const ApartmentVirtualTourForm: FC<{ apartment: IApartment }> = ({ apartment }) => {
  const { openModal } = useContext(ModalContext);
  const { landlordProfile } = useContext(UserContext);
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApartmentOverviewView' });
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [showVirtualBookingTourModal, setShowVirtualBookingTourModal] = useState<boolean>(false);

  useEffect(() => {
    if (showVirtualBookingTourModal) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, window.location.href);
      const address = `${apartment.mainInformation.address.street}%20${apartment.mainInformation.address.houseNumber}%20${apartment.mainInformation.address.city}%20${apartment.mainInformation.address.postalCode}`;

      let baseUrl = `https://calendly.com/christian-philippi/3d-tour?location=${address}`;

      if (landlordProfile?.personalInformation) {
        let phoneNumber = landlordProfile.personalInformation.phoneNumber;

        if (phoneNumber.startsWith('+')) {
          phoneNumber = phoneNumber.slice(3);
        }
        if (phoneNumber.startsWith('00')) {
          phoneNumber = phoneNumber.slice(4);
        }
        if (phoneNumber.startsWith('01')) {
          phoneNumber = phoneNumber.slice(1);
        }

        baseUrl += `&email=${landlordProfile?.email}`;
        baseUrl += `&name=${
          landlordProfile?.personalInformation?.firstName + ' ' + landlordProfile?.personalInformation?.lastName
        }`;
        baseUrl += `&a1=+49${phoneNumber}`;
      }
      setBaseUrl(baseUrl);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);
    }
  }, [showVirtualBookingTourModal, landlordProfile]);

  return (
    <div>
      {showVirtualBookingTourModal && baseUrl && (
        <PopupModal
          url={baseUrl}
          rootElement={document.getElementsByTagName('body')[0]}
          open={showVirtualBookingTourModal}
          onModalClose={() => setShowVirtualBookingTourModal(false)}
        />
      )}
      <FormHeader title={a('virtualTour')} />

      <FormSectionTitle
        title={'Buch eine 3D-Tour'}
        subtitle={'Unser Team kommt bei dir vorbei und erstellt eine 3D Visualisierung deiner Wohnung.'}
      />
      {apartment.matterportId ? (
        <CTAButton
          expand={'full'}
          buttonStyle={BUTTON_STYLE.SECONDARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={a('showVirtualTour')}
          icon={faEye}
          onClick={() => openModal({ id: MODAL_IDENTIFIER.VIRTUAL_TOUR })}
        />
      ) : (
        <CTAButton
          expand={'full'}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={a('bookVirtualTour')}
          onClick={() => setShowVirtualBookingTourModal(true)}
        />
      )}
    </div>
  );
};

export default ApartmentVirtualTourForm;
