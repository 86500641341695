import MediaUploadDropZone from '../../molecules/MediaUploadDropZone';
import { FC, useContext, useEffect, useState } from 'react';
import StorageServiceContext from '../../../core/context/storage-service.context';
import UserContext from '../../../core/context/user.context';
import ApartmentServiceContext from '../../../core/context/apartment-service.context';
import useToast from '../../../core/hook/toast.hook';
import { IApartment, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import { getDownloadURL } from 'firebase/storage';
import { UPLOAD_TYPE } from '../../molecules/DocumentUploadDropZone';
import { IonCol, IonRow } from '@ionic/react';
import { generateUUID } from '@ionic/cli/lib/utils/uuid';
import styles from './ApartmentMediaUpload.module.scss';
import Text from '../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';

const UploadApartmentMedia: FC<{ apartment: IApartment }> = ({ apartment }) => {
  const { storageService } = useContext(StorageServiceContext);
  const { user } = useContext(UserContext);
  const { apartmentService } = useContext(ApartmentServiceContext);
  const { sendErrorToast } = useToast();
  const [fileUploadList, setFileUploadList] = useState<File[]>([]);
  const [uploadCount, setUploadCount] = useState<number>(0);
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentMediaList' });

  const uploadFileAndSaveToApartment = async () => {
    const id = generateUUID();
    const upload = await storageService.createFileUpload(fileUploadList[0], {
      id: id,
      creatorId: user.uid,
      mediaType: MEDIA_TYPE.IMAGE,
      uploadType: UPLOAD_TYPE.APARTMENT_MEDIA,
      custom: { apartmentId: apartment.id },
    });

    upload.uploadTask.on(
      'state_changed',
      null,
      (err) => {
        console.error('Error state_change uploadTask', err.message);
        return sendErrorToast('Error state_change uploadTask');
      },
      async () => {
        const downloadURL = await getDownloadURL(storageService.getRef(upload.uploadPath));
        const file = {
          creatorId: user.uid,
          alt: upload.alt,
          id: id,
          url: downloadURL ? downloadURL : null,
          mediaType: MEDIA_TYPE.IMAGE,
          updatedAt: new Date(),
        };
        await apartmentService.updateApartmentMediaList(apartment, [...apartment.media, file]);
        const newUploadList = fileUploadList;
        newUploadList.shift();
        setFileUploadList([...newUploadList]);
      }
    );
  };

  useEffect(() => {
    if (fileUploadList.length) {
      uploadFileAndSaveToApartment();
    }
  }, [fileUploadList]);

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (acceptedFiles.length > 0) {
      await apartmentService.updateApartmentImagesToResize(apartment, acceptedFiles.length);
      setFileUploadList(acceptedFiles);
      setUploadCount(acceptedFiles.length);
    }
  };

  return (
    <IonRow>
      <IonCol>
        {apartment.imagesToResizeCount > 0 ? (
          <div className={styles.progressBar}>
            <div
              className={styles.progressBarProgress}
              style={{ width: `${(100 - (100 / uploadCount) * apartment.imagesToResizeCount).toFixed(0)}%` }}
            ></div>
            <div className={styles.progressBarContent}>
              <Text>
                {t('uploadText')}
                <span>{(100 - (100 / uploadCount) * apartment.imagesToResizeCount).toFixed(0)}%</span>
              </Text>
            </div>
          </div>
        ) : (
          <MediaUploadDropZone multiple={true} onDrop={onDrop} />
        )}
      </IonCol>
    </IonRow>
  );
};

export default UploadApartmentMedia;
