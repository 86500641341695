import styles from './ProfileAvatar.module.scss';
import { FormEvent, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUser, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FIRESTORE_COLLECTION_PATH, ILandlordProfile, ITenantProfile, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { ICropImageModalProps } from '../modals/CropImageModal';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import useToast from '../../../core/hook/toast.hook';
import ModalContext from '../../../core/context/modal.context';
import UserContext from '../../../core/context/user.context';
import { useTranslation } from 'react-i18next';
import StorageServiceContext from '../../../core/context/storage-service.context';

export interface ITenantProfileInformationProps {
  profile?: ILandlordProfile | ITenantProfile | null;
}

const ProfileAvatar = ({ profile }: ITenantProfileInformationProps) => {
  const { t } = useTranslation('common');
  const inputRef = useRef<HTMLInputElement>(null);
  const { sendSuccessToast, sendInfoToast, sendErrorToast } = useToast();
  const { openModal } = useContext(ModalContext);
  const { userService, user, activeUserType } = useContext(UserContext);
  const { storageService } = useContext(StorageServiceContext);

  const clickInputField = () => {
    inputRef?.current?.click();
  };

  const deleteInput = () => {
    inputRef.current.value = '';
  };

  const handleProfileImageUpload = async (blobPng: Blob) => {
    let path: string;

    if (activeUserType === USER_TYPE.TENANT) {
      path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', user.uid)}/${
        user.uid
      }/profileImage.png`;
    } else {
      path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', user.uid)}/${
        user.uid
      }/profileImage.png`;
    }

    try {
      const downloadUrl = await storageService.uploadBlob(blobPng, path, user.uid, 'image/png');

      if (activeUserType === USER_TYPE.TENANT) {
        await userService.updateTenantProfilePicture(user.uid, downloadUrl);
      } else {
        await userService.updateLandlordProfilePicture(user.uid, downloadUrl);
      }

      await sendInfoToast('Profilbild erfolgreich hochgeladen.');
    } catch (e) {
      console.error(e);
    }
  };

  const deleteProfileImage = async () => {
    if (activeUserType === USER_TYPE.TENANT) {
      userService
        .updateTenantProfilePicture(user.uid, null)
        .catch(console.error)
        .then(() => {
          sendSuccessToast('Profilbild erfolgreich gelöscht.');
        });
    } else {
      userService
        .updateLandlordProfilePicture(user.uid, null)
        .catch(console.error)
        .then(() => {
          sendSuccessToast('Profilbild erfolgreich gelöscht.');
        });
    }
  };

  const onImageChange = (fileList: File[], deleteInput?: any) => {
    openModal<ICropImageModalProps>({
      id: MODAL_IDENTIFIER.CROP_IMAGE,
      data: { image: fileList[0], onCropFinish: handleProfileImageUpload },
    });
    deleteInput && deleteInput();
  };

  const onChangeCapture = (e: FormEvent<HTMLInputElement>) => {
    const fileList: FileList | null = (e?.target as HTMLInputElement)?.files;
    if (fileList?.length) {
      return onImageChange(Array.from(fileList), deleteInput());
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <button type={'button'} onClick={clickInputField} className={styles.tenantInformationWrapper}>
          <div className={styles.imageWrapper}>
            <input hidden type="file" accept={'image/*'} ref={inputRef} onChangeCapture={onChangeCapture} />
            {profile?.photoUrl ? (
              <img
                src={profile?.photoUrl}
                alt={`${profile?.personalInformation?.firstName} ${profile?.personalInformation?.lastName}`}
              />
            ) : (
              <div className={styles.silhouette}>
                <FontAwesomeIcon icon={faUser} />
              </div>
            )}

            <div className={styles.camera}>
              <FontAwesomeIcon icon={faCamera} />
            </div>
          </div>
        </button>
        {profile?.photoUrl ? (
          <div className={styles.delete}>
            <button onClick={deleteProfileImage}>
              <span className="sr-only">{t('deleteProfileImage')}</span>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileAvatar;
