import { createContext } from 'react';
import StorageService from '../service/storage.service';

export interface IStorageServiceContext {
  storageService: StorageService;
}

const StorageServiceContext = createContext<IStorageServiceContext>({
  storageService: null,
});

export default StorageServiceContext;
