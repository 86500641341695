import { useTranslation } from 'react-i18next';
import ModalWrapper from '../ModalWrapper';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text from '../../../atoms/typographie/Text';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import ModalContext from '../../../../core/context/modal.context';
import { useContext } from 'react';
import { useIonRouter } from '@ionic/react';
import { ROUTES } from '../../../../core/const/routes';

const CompleteApplicationFolderModal = () => {
  const { closeModal } = useContext(ModalContext);
  const router = useIonRouter();
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.modals.applicationFolder.CompleteApplicationFolderRequest',
  });
  const { t: c } = useTranslation('common');

  const handleNextStep = () => {
    closeModal();
    router.push(c(ROUTES.tenant.account.applicationFolder.profile));
  };

  return (
    <ModalWrapper>
      <img style={{ margin: '0 auto' }} src={t('image.src')} alt={t('image.alt')} />
      <Spacer size={SPACER_SIZE.SM} />
      <Headline size={HEADLINE_SIZE.H3} align={'center'}>
        {t('title')}
      </Headline>
      <Text align={'center'} tag={'p'}>
        {t('text')}
      </Text>

      <CTAButton
        onClick={handleNextStep}
        expand={'block'}
        buttonText={c('registerButtonText')}
        buttonStyle={BUTTON_STYLE.PRIMARY}
      />
      <Spacer size={SPACER_SIZE.SM} />
      <CTAButton
        onClick={() => closeModal()}
        expand={'block'}
        buttonText={c('skip')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
      />
    </ModalWrapper>
  );
};

export default CompleteApplicationFolderModal;
