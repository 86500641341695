import CTAButton, { getSubmitIcon } from '../Buttons/CTAButton';
import styles from './FormFooter.module.scss';
import { FC } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import { useTranslation } from 'react-i18next';

const FormFooter: FC<{ buttonSubmitMode: SUBMIT_BUTTON_MODE }> = ({ buttonSubmitMode }) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.formFooter}>
      <CTAButton
        spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
        icon={getSubmitIcon(buttonSubmitMode)}
        size={'small'}
        buttonText={t('save')}
      />
    </div>
  );
};

export default FormFooter;
