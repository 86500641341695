import { Dispatch, FC, SetStateAction } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import ApplicantModalWrapper from '../ApplicantModalWrapper';
import Chat from '../../Chat';
import styles from './ApplicantChatModal.module.scss';
import RoundIconButton from '../../../atoms/Buttons/RoundIconButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Avatar, { AVATAR_SIZE } from '../../../atoms/Avatar';

const ApplicantChatModal: FC<{
  showApplicantChat: boolean;
  setShowApplicantChat: Dispatch<SetStateAction<boolean>>;
  application: IApplication;
}> = ({ showApplicantChat, setShowApplicantChat, application }) => {
  const { t } = useTranslation('common');
  const ChatHeader = () => (
    <div className={styles.header}>
      <div className={styles.profile}>
        <Avatar size={AVATAR_SIZE.md} avatarUrl={application.tenantProfile.photoUrl} />
        <span>
          {application.tenantProfile?.personalInformation?.firstName &&
          application.tenantProfile?.personalInformation?.lastName ? (
            <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
              {application.tenantProfile?.personalInformation?.firstName}{' '}
              {application.tenantProfile?.personalInformation?.lastName}
            </Headline>
          ) : null}
        </span>
      </div>
      <RoundIconButton onClick={() => setShowApplicantChat(false)} buttonText={t('close')} icon={faTimes} />
    </div>
  );

  return (
    <ApplicantModalWrapper smallSize setShowModal={setShowApplicantChat} showModal={showApplicantChat}>
      {application ? <Chat key={application.apartmentId} header={<ChatHeader />} application={application} /> : null}
    </ApplicantModalWrapper>
  );
};

export default ApplicantChatModal;
