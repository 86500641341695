import BlogPreviewGrid from '../../../../../component/molecules/BlogPreviewGrid';
import styles from './LandlordLandingMediaSection.module.scss';
import HeadlineText from '../../../../../component/molecules/HeadlineText';
import { useTranslation } from 'react-i18next';
const LandlordLandingMediaSection = () => {
  const { t } = useTranslation('common');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.LandlordLanding.media' });
  return (
    <div className={styles.wrapper}>
      <HeadlineText headline={l('headline')} text={l('subtitle')} />
      <div className={styles.blogWrapper}>
        <BlogPreviewGrid
          blogs={[
            {
              logo: t('logos.deutsche_startups'),
              tag: 'Deutsche Startups',
              headline: '5 frische Kölner Startups',
              text: 'In Köln tummeln sich zahlreiche spannende Startups. Hier wieder einige - insbesondere sehr jung...',
              link: 'https://www.deutsche-startups.de/2022/12/21/neue-koelner-startups-aiconver/',
            },
            {
              logo: t('logos.apti'),
              tag: 'Apti Award',
              headline: 'Apti Award 2022',
              text: 'Am Donnerstagabend wurden von der Austrian PropTech-Initiative apti die besten Immobilien Start-ups gekürt.',
              link: 'https://www.diepresse.com/6196631/apti-award-2022-die-gewinner-stehen-fest',
            },
            {
              logo: t('logos.immo_zeitung'),
              tag: 'Immobilienzeitung',
              headline: 'PropTech Germany',
              text: 'Gewinner Kategorie Vermarktung, Vermietung und Verkauf: Wohnsinn. Wohnsinn.com ist ein...',
              link: 'https://www.iz.de/digitales/news/proptech-awards-kategorie-vermarktung-vermietung-und-verkauf-wohnsinn-2000008945',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default LandlordLandingMediaSection;
