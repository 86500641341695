import { IMedia } from '@wohnsinn/ws-ts-lib';
import { Dispatch, FC, SetStateAction } from 'react';
import styles from './ImageGallery.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IonRouterLink, useIonRouter } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { Navigation } from 'swiper';

export interface IImageGalleryProps {
  media: IMedia[];
  handleSlideTap?: any;
  slideShowIndex: number;
  setSlideShowIndex: Dispatch<SetStateAction<number>>;
  noLightBox?: boolean;
  routerLink?: string;
  id: string;
}

const ImageGallery: FC<IImageGalleryProps> = ({
  media,
  handleSlideTap = null,
  slideShowIndex,
  setSlideShowIndex,
  noLightBox = false,
  routerLink,
  id,
}) => {
  const router = useIonRouter();

  return (
    <>
      <div className={styles.swiperWrapper}>
        <IonRouterLink className={routerLink ? styles.link : ''} routerLink={routerLink ? routerLink : undefined} />
        {media.length > 1 ? (
          <>
            <button className={`M-${id}-swiper-prev ${styles.buttons} ${styles.prev}`}>
              <span className={'sr-only'}>prev</span>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button className={`M-${id}-swiper-next ${styles.buttons} ${styles.next}`}>
              <span className={'sr-only'}>next</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </>
        ) : null}
        <Swiper
          modules={[Navigation]}
          navigation={{
            nextEl: `.M-${id}-swiper-next`,
            prevEl: `.M-${id}-swiper-prev`,
          }}
          grabCursor
          allowTouchMove
          tabIndex={0}
          slidesPerView={1}
          spaceBetween={0}
          onSlideChange={(swiper) => setSlideShowIndex(swiper.activeIndex)}
          className={styles.slides}
          onClick={routerLink ? () => router.push(routerLink) : undefined}
        >
          {media.map((media, i) => (
            <SwiperSlide
              onClick={noLightBox ? undefined : () => handleSlideTap(i)}
              key={`${media.id}-${i}`}
              style={{ backgroundImage: `url(${media.thumbnailUrl ? media.thumbnailUrl : media.url})` }}
              className={styles.slide}
            />
          ))}
        </Swiper>

        {media.length > 1 ? (
          <div className={styles.dotsWrapper}>
            {media.map((dot, index) => (
              <div
                className={`${styles.dot} ${index === slideShowIndex ? styles.active : ''}`}
                key={`${dot.id}-${index}`}
              >
                <div className="sr-only">slide-${slideShowIndex}</div>
              </div>
            ))}
          </div>
        ) : null}

        {media.length > 1 ? (
          <div className={styles.indexIndicator}>{`${(
            slideShowIndex + 1
          ).toString()} / ${media.length.toString()}`}</div>
        ) : null}
      </div>
    </>
  );
};

export default ImageGallery;
