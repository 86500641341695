import styles from './LandlordLandingAboutWohnsinnSection.module.scss';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../../../../component/atoms/typographie/Headline';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_WEIGHT } from '../../../../../component/atoms/typographie/Text';

const LandlordLandingAboutWohnsinnSection = () => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div>
          <Headline align={'left'}>{'Über Wohnsinn'}</Headline>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <div>
              <Headline
                align={'right'}
                noMargin
                size={HEADLINE_SIZE.DISPLAY}
                color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}
              >
                2023
              </Headline>
              <Text align={'right'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                Offizieller Marktstart
              </Text>
            </div>
            <div>
              <Headline
                align={'right'}
                noMargin
                size={HEADLINE_SIZE.DISPLAY}
                color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}
              >
                +50
              </Headline>
              <Text align={'right'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                Makler & Verwalter
              </Text>
            </div>
          </div>
          <div className={styles.column}>
            <div>
              <Headline
                align={'right'}
                noMargin
                size={HEADLINE_SIZE.DISPLAY}
                color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}
              >
                +10.000
              </Headline>
              <Text align={'right'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                Registrierte Suchende
              </Text>
            </div>
            <div>
              <Headline
                align={'right'}
                noMargin
                size={HEADLINE_SIZE.DISPLAY}
                color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}
              >
                +120.000
              </Headline>
              <Text align={'right'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                Verwaltete Wohnungen
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.heatMap}>
        <img src={t('icons.wohnsinn_heat_map')} alt="Wohnsinn Heat Map" />
      </div>
    </div>
  );
};

export default LandlordLandingAboutWohnsinnSection;
