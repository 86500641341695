import { IonApp, setupIonicReact, useIonViewDidEnter } from '@ionic/react';
import { HelmetProvider } from 'react-helmet-async';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/variables.scss';
/* Wohnsinn global styles */
import './theme/globals.scss';
/* Swiper.js */
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css';
import { FC, useEffect, useState } from 'react';
import UserContext from './core/context/user.context';
import useAuthUser from './core/hook/auth-user.hook';
import { SplashScreen } from '@capacitor/splash-screen';
import LoadingScreen from './component/atoms/LoadingScreen';
import TenantFilterParamsService from './core/service/tenant-filter-params.service';
import useTenantFilterParams from './core/hook/tenant-filter-params.hook';
import TenantFilterParamsContext from './core/context/tenant-filter-params.context';
import UserService from './core/service/user.service';
import AuthService from './core/service/auth.service';
import DatabaseService from './core/service/database.service';
import StorageService from './core/service/storage.service';
import { FirebaseFunctionsService } from './core/service/firebase-functions-service';
import { FirebaseFunctionsContext } from './core/context/firebase-functions.context';
import { ApartmentService } from './core/service/apartment.service';
import ApartmentServiceContext from './core/context/apartment-service.context';
import StorageServiceContext from './core/context/storage-service.context';
import ApplicationService from './core/service/application.service';
import ApplicationServiceContext from './core/context/application-service.context';
import LinearRouter from './router';
import OrganizationService from './core/service/organisation.service';
import ModalContext from './core/context/modal.context';
import useModal from './core/hook/modal.hook';
import AppDataProvider from './component/organisms/AppDataProvider';
import MixpanelTrackingService from './core/service/mixpanel-tracking.service';
import MixpanelTrackingServiceContext from './core/context/mixpanel-tracking-service.context';
import { ChatService } from './core/service/chat.service';
import ChatServiceContext from './core/context/chat-service.context';
import { RemoteConfigService } from './core/service/remote-config.service';
import ScrollPositionProviderContext from './core/context/scroll-position-provider.context';

const remoteConfigService: RemoteConfigService = new RemoteConfigService();
const databaseService: DatabaseService = new DatabaseService();
const chatService = new ChatService(databaseService);
const firebaseFunctionsService: FirebaseFunctionsService = new FirebaseFunctionsService();
const mixpanelTrackingService = new MixpanelTrackingService();
const apartmentService: ApartmentService = new ApartmentService(databaseService, firebaseFunctionsService);
const applicationService: ApplicationService = new ApplicationService();
const userService: UserService = new UserService(
  databaseService,
  mixpanelTrackingService,
  firebaseFunctionsService,
  applicationService
);
const organizationService: OrganizationService = new OrganizationService(databaseService, userService);
const authService: AuthService = new AuthService(userService);
const tenantFilterParamsService: TenantFilterParamsService = new TenantFilterParamsService(
  databaseService,
  userService
);
const storageService = new StorageService();

setupIonicReact();

const App: FC = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const { user, landlordProfile, tenantProfile, activeUserType, loadingUser } = useAuthUser(userService, authService);
  const { tenantFilterParams, updateTenantFilterParams, matchData, loadingMatchData, fetchMatchList } =
    useTenantFilterParams(tenantFilterParamsService, apartmentService, user);
  const useModalValues = useModal();

  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useIonViewDidEnter(async () => {
    await SplashScreen.hide();
  });

  const checkMaintenanceMode = async () => {
    const isInMaintainanceMode: boolean = await remoteConfigService.getRemoteConfigBoolean('isInMaintainanceMode');
    setIsMaintenanceMode(isInMaintainanceMode);
  };

  useEffect(() => {
    checkMaintenanceMode();
  }, [isMaintenanceMode]);

  if (loadingUser) {
    return <LoadingScreen />;
  }

  return (
    <ModalContext.Provider value={useModalValues}>
      <TenantFilterParamsContext.Provider
        value={{
          tenantFilterParams,
          updateTenantFilterParams,
          matchData,
          loadingMatchData,
          fetchMatchList,
        }}
      >
        <UserContext.Provider
          value={{
            organizationService,
            authService,
            userService,
            user,
            tenantProfile,
            landlordProfile,
            activeUserType,
          }}
        >
          <FirebaseFunctionsContext.Provider value={{ firebaseFunctionsService }}>
            <StorageServiceContext.Provider value={{ storageService }}>
              <ApplicationServiceContext.Provider value={{ applicationService }}>
                <ApartmentServiceContext.Provider value={{ apartmentService }}>
                  <ChatServiceContext.Provider value={{ chatService }}>
                    <ScrollPositionProviderContext.Provider value={{ scrollPos, setScrollPos }}>
                      <MixpanelTrackingServiceContext.Provider value={{ mixpanelTrackingService }}>
                        <HelmetProvider>
                          <IonApp>
                            <AppDataProvider>
                              <LinearRouter isMaintenanceMode={isMaintenanceMode} />
                            </AppDataProvider>
                          </IonApp>
                        </HelmetProvider>
                      </MixpanelTrackingServiceContext.Provider>
                    </ScrollPositionProviderContext.Provider>
                  </ChatServiceContext.Provider>
                </ApartmentServiceContext.Provider>
              </ApplicationServiceContext.Provider>
            </StorageServiceContext.Provider>
          </FirebaseFunctionsContext.Provider>
        </UserContext.Provider>
      </TenantFilterParamsContext.Provider>
    </ModalContext.Provider>
  );
};

export default App;
