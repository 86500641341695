import { MATCHLIST_TYPES, TAnimationDirection } from '../ApartmentStackWrapper';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import styles from './ApartmentStack.module.scss';
import ApartmentInformation from '../ApartmentInformation';
import { motion } from 'framer-motion';
import { animateToLeft, animateToRight, initial } from '../../../core/const/matching-animations';

const ApartmentStack: FC<{
  matchList: IApartment[];
  currentApartment: IApartment;
  animationDirection: TAnimationDirection;
  matchingCategory: MATCHLIST_TYPES;
}> = ({ matchList, currentApartment, animationDirection, matchingCategory }) => {
  return (
    <>
      {matchList.slice(0, 3).map((apartment: IApartment, index: number) => {
        const canAnimate = index === 0;
        return (
          <motion.div
            key={apartment.id}
            className={styles.apartment}
            style={{
              zIndex: currentApartment.id === apartment?.id ? 10 : (index - 1) * -1,
            }}
            animate={getAnimation(canAnimate, animationDirection)}
          >
            {/*<ApartmentPreviewHeader title={'Zurück zur Übersicht'} backRoute={r(ROUTES.tenant.matching.list.path)} />*/}
            <ApartmentInformation apartment={apartment} />
          </motion.div>
        );
      })}
    </>
  );
};

/**
 * Get styles for rating animations
 * @param {boolean} canAnimate
 * @param  {TAnimationDirection} animationDirection
 */
export const getAnimation = (canAnimate: boolean, animationDirection: TAnimationDirection) => {
  switch (canAnimate && animationDirection) {
    case 'initial':
      return initial;
    case 'left':
      return animateToLeft;
    case 'right':
      return animateToRight;
  }
};

export default ApartmentStack;
