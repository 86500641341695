import styles from './ApartmentCard.module.scss';
import { IApartment, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { FC, useContext, useState } from 'react';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import VirtualTourStatusIndicator from '../../../atoms/VirtualTourStatusIndicator';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../../core/context/user.context';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../atoms/typographie/Text';
import Badge, { BADGE_COLORS } from '../../../atoms/Badge';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import { IApartmentWithRating } from '../../../../view/landlord/organization/OrganizationPortfolioView';
import TagList from '../../TagList';
import { TAG_COLOR } from '../../../atoms/Tag';
import ImageGallery from '../../ImageGallery';
import { Timestamp } from 'firebase/firestore';
import LOCAL_STORAGE_KEYS from '../../../../core/enum/local-storage.enum';
import ScrollPositionProviderContext from '../../../../core/context/scroll-position-provider.context';

export enum APARTMENT_CARD_TYPE {
  APPLICATION = 'application',
  APARTMENT = 'apartment',
}

export interface IApartmentCardProps {
  apartment: IApartmentWithRating | IApartment;
  route: string;
  notifications?: number;
  showLandlordInformations?: boolean;
  rating?: MATCHING_MODE;
}

const ApartmentCard: FC<IApartmentCardProps> = ({
  apartment,
  route,
  notifications,
  rating,
  showLandlordInformations,
}) => {
  // hooks
  const { t } = useTranslation('common');
  // contexts
  const { user } = useContext(UserContext);
  const { scrollPos } = useContext(ScrollPositionProviderContext);
  // states
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  // objects
  const { address } = apartment.mainInformation;
  const basics = [
    { key: 'warmRent', label: `💰 ${apartment.cost?.warmRent ? `${apartment.cost.warmRent} €` : 'keine angabe'}` },
    { key: 'rooms', label: `🛋️ ${apartment?.mainInformation?.rooms} ${t('apartment.mainInformation.rooms.label')}` },
    { key: 'size', label: `📐 ${apartment?.mainInformation?.size} m²` },
  ];

  const checkIfApartmentIsNew = (): boolean => {
    if (apartment.publishedAt) {
      const publishedDate = new Timestamp(
        // @ts-ignore
        apartment.publishedAt['_seconds'],
        // @ts-ignore
        apartment.publishedAt['_nanoseconds']
      ).toDate();
      const diff = (new Date().getTime() - publishedDate.getTime()) / 1000;
      const dayDiff = Math.floor(diff / 86400);
      if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) return false;

      return dayDiff < 2;
    }
    return false;
  };

  return (
    <div className={styles.apartmentCard}>
      <IonRouterLink
        routerLink={route}
        className={styles.link}
        onClick={() => {
          localStorage.setItem(LOCAL_STORAGE_KEYS.SCROLL_POSITION, String(scrollPos));
        }}
      />

      {checkIfApartmentIsNew() ? (
        <div className={styles.newBadge}>
          <Badge
            textWeight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
            text={`${t('new').toUpperCase()}!`}
            color={BADGE_COLORS.PRIMARY}
          />
        </div>
      ) : null}
      {notifications ? (
        <div className={styles.notificationsWrapper}>
          <div className={styles.notificationsBadge}>
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_LIGHT} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
              {notifications.toString()}
            </Text>
          </div>
        </div>
      ) : null}

      {showLandlordInformations && apartment.editorList.includes(user?.uid) && apartment?.applicationRefList?.length ? (
        <div className={styles.applicationsCountWrapper}>
          <Badge
            color={BADGE_COLORS.PRIMARY}
            text={`${apartment?.applicationRefList?.length} ${
              apartment?.applicationRefList?.length === 1 ? t('application') : t('applications')
            }`}
          />
        </div>
      ) : null}

      {rating === 'NOPE' && <img alt={'nope'} src={t('icons.heart_nope')} className={styles.icon} />}
      {rating === 'MAYBE' && <img alt={'maybe'} src={t('icons.heart_like')} className={styles.icon} />}

      {apartment.applicationRefList.includes(user?.uid) && apartment?.applicationRefList?.length ? (
        <div className={styles.applicationsCountWrapper}>
          <Badge color={BADGE_COLORS.PRIMARY} text={`Bereits Beworben`} />
        </div>
      ) : null}

      <div className={styles.image}>
        {apartment.media && apartment.media.length ? (
          <ImageGallery
            id={apartment.id}
            routerLink={route}
            setSlideShowIndex={setSlideShowIndex}
            noLightBox={true}
            slideShowIndex={slideShowIndex}
            media={apartment.media}
          />
        ) : (
          <img
            loading="lazy"
            className={styles.placeholder}
            alt={'placeholder-apartment-image'}
            src={t('apartmentPlaceholder')}
          />
        )}
      </div>

      {showLandlordInformations ? (
        <div className={styles.statusBar}>
          <div className={`${styles.publishedState} ${apartment.isPublished ? styles.isPublished : ''}`}>
            <IonIcon icon={apartment.isPublished ? checkmarkCircleOutline : alertCircleOutline} />
            <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
              {apartment.isPublished
                ? `${t('advertisement')} ${t('published')}`
                : `${t('advertisement')} ${t('unpublished')}`}
            </Text>
          </div>
          <VirtualTourStatusIndicator matterportId={apartment.matterportId} />
        </div>
      ) : null}

      <div className={styles.content}>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_DARK} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
          {address.hasPublishedAddress || showLandlordInformations
            ? `${address.street} ${address.houseNumber}`
            : 'Adresse auf Anfrage'}
        </Text>
        <br />
        <Headline tag={'span'} size={HEADLINE_SIZE.H3}>
          {address.postalCode} {address.city}
        </Headline>
        <div className={styles.bottom}>
          <TagList color={TAG_COLOR.BLUE} size={'small'} testId={'apartmentBasics'} tags={basics} />
        </div>
      </div>
    </div>
  );
};

export default ApartmentCard;
