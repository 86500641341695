import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState } from 'lexical';
import { FC, useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { $generateHtmlFromNodes } from '@lexical/html';

const FormControlPlugin: FC<{ field: ControllerRenderProps }> = ({ field }) => {
  const [initialValueIsLoaded, setIsInitialValueIsLoaded] = useState<boolean>(false);
  const [editor] = useLexicalComposerContext();
  const initialEditorConfigString = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;

  useEffect(() => {
    if (editor) {
      editor.update(() => {
        if (field.value === null) {
          const defaultEditorState: EditorState = editor.parseEditorState(initialEditorConfigString);
          editor.setEditorState(defaultEditorState);
          setTimeout(() => editor.focus(), 0);
          setIsInitialValueIsLoaded(true);
        }
        if (
          field.value?.editorState &&
          !initialValueIsLoaded &&
          initialEditorConfigString !== field.value?.editorState
        ) {
          const fieldEditorState: EditorState = editor.parseEditorState(field.value?.editorState);
          editor.setEditorState(fieldEditorState);
          setTimeout(() => editor.focus(), 0);
          setIsInitialValueIsLoaded(true);
        }
      });

      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          field.onChange({ editorState: JSON.stringify(editorState), html: $generateHtmlFromNodes(editor, null) });
        });
      });
    }
  }, [field?.value?.editorState, editor]);

  return null;
};

export default FormControlPlugin;
