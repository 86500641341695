import styles from './DropDownMenu.module.scss';
import { FC, useContext } from 'react';
import Avatar, { AVATAR_SIZE } from '../../atoms/Avatar';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../core/context/user.context';
import { IonRouterLink, useIonRouter } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOut, faSignIn } from '@fortawesome/pro-solid-svg-icons';
import Text, { TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import { IMenuItemProps } from '../../../core/const/menu-items';
import { ROUTES } from '../../../core/const/routes';

const DropDownMenu: FC<{ menu: IMenuItemProps[] }> = ({ menu }) => {
  const { t } = useTranslation('common');
  const { user, activeUserType, landlordProfile, authService, tenantProfile } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const router = useIonRouter();

  const logoutHandler = (): Promise<void> => {
    return authService.signOut(router, t);
  };

  return (
    <div className={styles.dropdownMenu}>
      {menu?.some((link) => link.notifications > 0) ? <div className={styles.notification} /> : null}
      <div className={styles.avatar}>
        <Avatar
          size={AVATAR_SIZE.sm}
          avatarUrl={activeUserType === USER_TYPE.LANDLORD ? landlordProfile?.photoUrl : tenantProfile?.photoUrl}
        />
        <FontAwesomeIcon icon={faBars} />
      </div>

      <div className={styles.listWrapper}>
        <ul className={styles.list}>
          {user ? (
            <div className={styles.userName}>
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} tag={'span'}>
                {activeUserType === USER_TYPE.LANDLORD ? (
                  <span>
                    {landlordProfile?.personalInformation?.firstName} {landlordProfile?.personalInformation?.lastName}
                  </span>
                ) : (
                  <span>
                    {tenantProfile?.personalInformation?.firstName} {tenantProfile?.personalInformation?.lastName}
                  </span>
                )}
              </Text>
              <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
                {activeUserType === USER_TYPE.LANDLORD ? landlordProfile?.email : tenantProfile?.email}
              </Text>
            </div>
          ) : (
            <li className={styles.listItem}>
              <button className={styles.logoutButton} onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}>
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'}>
                  {t('signIn')} <FontAwesomeIcon icon={faSignIn} />
                </Text>
              </button>
            </li>
          )}
          {menu?.length
            ? menu.map((menuItem, index) => (
                <li key={`${menuItem.label}-${index}-${menuItem.icon.iconName}`} className={styles.listItem}>
                  <IonRouterLink
                    className={`${styles.listItemLink} ${menuItem.notifications > 0 ? styles.withNotification : ''}`}
                    routerLink={menuItem.shouldOpenModal && !user ? undefined : t(menuItem.link)}
                    onClick={
                      !user && menuItem.shouldOpenModal ? () => openModal({ id: MODAL_IDENTIFIER.SIGN_IN }) : undefined
                    }
                  >
                    <Text
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      tag={'span'}
                    >
                      {t(menuItem.label)}
                    </Text>
                  </IonRouterLink>
                </li>
              ))
            : null}

          <li className={styles.listItem}>
            <IonRouterLink className={styles.listItemLink} routerLink={t(ROUTES.general.agb.path)}>
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'}>
                {t(ROUTES.general.agb.title)}
              </Text>
            </IonRouterLink>
          </li>

          <li className={styles.listItem}>
            <IonRouterLink className={styles.listItemLink} routerLink={t(ROUTES.general.privacy.path)}>
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'}>
                {t(ROUTES.general.privacy.title)}
              </Text>
            </IonRouterLink>
          </li>

          <li className={styles.listItem}>
            <IonRouterLink className={styles.listItemLink} routerLink={t(ROUTES.general.imprint.path)}>
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'}>
                {t(ROUTES.general.imprint.title)}
              </Text>
            </IonRouterLink>
          </li>

          {user ? (
            <li className={styles.listItem}>
              <button className={styles.logoutButton} onClick={logoutHandler}>
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'}>
                  {t('signOut')} <FontAwesomeIcon icon={faSignOut} />
                </Text>
              </button>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default DropDownMenu;
