import { IonCol, IonRow } from '@ionic/react';
import ApartmentCard from '../../molecules/Cards/ApartmentCard';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import { IApartmentWithRating } from '../../../view/landlord/organization/OrganizationPortfolioView';
import Headline, { HEADLINE_SIZE } from '../../../component/atoms/typographie/Headline';

const ApartmentCardGrid: FC<{
  apartmentList: IApartment[] | IApartmentWithRating[];
  route: string;
  noApartmentsMessage: string;
}> = ({ apartmentList, route, noApartmentsMessage }) => {
  if (!apartmentList?.length) {
    return <Headline size={HEADLINE_SIZE.H3}>{noApartmentsMessage}</Headline>;
  }

  return (
    <IonRow>
      {apartmentList.map((apartment) => {
        return (
          <IonCol key={apartment.id} size="12" size-sm="6" size-lg="4">
            <ApartmentCard
              apartment={apartment}
              route={route.replace(':apartmentId', apartment.id)}
              rating={'rating' in apartment && apartment?.rating ? apartment.rating : null}
            />
          </IonCol>
        );
      })}
    </IonRow>
  );
};
export default ApartmentCardGrid;
