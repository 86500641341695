import { IApplication } from '@wohnsinn/ws-ts-lib';

export const sortApplicationsByIncome = (applications: IApplication[], asc = true): IApplication[] => {
  let sortedApplications = applications;

  sortedApplications = applications.sort((a, b) => {
    return asc
      ? a.tenantProfile.household.monthlyIncome - b.tenantProfile.household.monthlyIncome
      : b.tenantProfile.household.monthlyIncome - a.tenantProfile.household.monthlyIncome;
  });

  return sortedApplications;
};
