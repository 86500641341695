import { FC, useContext } from 'react';
import { Controller } from 'react-hook-form';
import styles from './text-editor.module.scss';
import FormErrorMessage from '../FormErrorMessage';
import { FormContext } from '../../../../core/context/form.context';
import LexicalEditor from '../LexicalEditor';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../typographie/Text';

export interface IEditorProps {
  disabled?: boolean;
  helperText?: string;
  label?: string;
  maxLength?: number;
  mobileSmall?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  onKeyDown?: (e: any) => void;
  hideErrorMessage?: boolean;
  onSubmit?: any;
  unit?: string;
  showErrors?: boolean;
  size?: '1x' | '2x';
  isAutofocused?: boolean;
  showLabel?: boolean;
}

const TextEditor: FC<IEditorProps> = (props) => {
  const {
    hideErrorMessage = false,
    helperText,
    label,
    name,
    placeholder,
    disabled = false,
    required,
    onSubmit,
    showErrors = true,
    size = '1x',
    isAutofocused = false,
    showLabel = true,
  } = props;
  const { control } = useContext(FormContext);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className={styles.formField}>
          <div>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} tag={'span'}>
              {showLabel && label} {label && required && <span> *</span>}
            </Text>
            <LexicalEditor
              isAutofocused={isAutofocused}
              field={field}
              onSubmit={onSubmit}
              disabled={disabled}
              placeholder={placeholder}
              size={size}
            />
          </div>
          {helperText && (
            <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {helperText}
            </Text>
          )}
          {showErrors && (
            <span className={styles.errorMessageWrapper}>
              {!hideErrorMessage && <FormErrorMessage error={error} />}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default TextEditor;
