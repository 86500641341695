import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from '../../../../core/context/modal.context';
import MapboxMap from '../../Map';
import { useTranslation } from 'react-i18next';

const MapModal: FC = () => {
  const { modalData: apartment } = useContext(ModalContext);
  const { t } = useTranslation('common');

  return (
    <ModalWrapper
      noContentPadding
      title={
        apartment
          ? apartment.mainInformation?.address?.hasPublishedAddress
            ? `${apartment.mainInformation.address.street} ${
                apartment.mainInformation.address.houseNumber ? apartment.mainInformation.address.houseNumber : ''
              }`
            : `${apartment.mainInformation.address.postalCode} ${apartment.mainInformation.address.city}`
          : t('address.noApartmentAvailable')
      }
    >
      {apartment ? <MapboxMap isInteractive apartments={[apartment]} /> : null}
    </ModalWrapper>
  );
};

export default MapModal;
