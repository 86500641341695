import { FC } from 'react';
import styles from './Spacer.module.scss';

export enum SPACER_SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const Spacer: FC<{ size?: SPACER_SIZE }> = ({ size }) => {
  return <div className={`${styles.spacer} ${styles[size]}`} />;
};

export default Spacer;
