import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import { IonCol, IonGrid, useIonRouter } from '@ionic/react';
import ModalContext from '../../../../../core/context/modal.context';
import UserContext from '../../../../../core/context/user.context';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormLayout from '../../../FormLayout';
import { ITenantFormProps } from '../TenantProfileForm';
import PageTitle from '../../../../molecules/PageTitle';
import { ROUTES } from '../../../../../core/const/routes';
import Section from '../../../../atoms/Section';
import LightShow from '../../../../molecules/LightShow';
import DocumentUploadListItem from '../../../../atoms/DocumentUploadListItem';
import IntroductionVideoUpload from '../../../IntroductionVideoUpload';
import styles from './TenantIntroductionVideoForm.module.scss';
import useToast from '../../../../../core/hook/toast.hook';
import CheckList from '../../../../atoms/CheckList';
import { MEDIA_TYPE } from '@wohnsinn/ws-ts-lib/dist/types/media';
import { IMedia } from '@wohnsinn/ws-ts-lib';
import CTAButton, { BUTTON_STYLE } from '../../../../atoms/Buttons/CTAButton';
import Text, { TEXT_WEIGHT } from '../../../../atoms/typographie/Text';

const TenantIntroductionVideoForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantProfile, userService } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const router = useIonRouter();
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [showExampleVideo, setShowExampleVideo] = useState<boolean>(false);
  const { sendErrorToast } = useToast();

  const { t } = useTranslation('common', { keyPrefix: 'view.AccountView.IntroductionVideoView' });
  const { t: r } = useTranslation('common');

  const SKIP_BUTTON = {
    text: r('skip'),
    action: () => router.push(r(ROUTES.tenant.account.applicationFolder.incomeProof), 'forward'),
  };

  const CANCEL_BUTTON = {
    text: r('cancel'),
    action: () => router.push(r(ROUTES.tenant.account.overview.path), 'back'),
  };

  const deleteVideoHandler = async (): Promise<void> => {
    await userService
      .updateTenantIntroductionVideo(tenantProfile.uid, null)
      .then(() => {
        closeModal();
      })
      .catch(async (e) => {
        console.error('error on deleteVideoHandler', e);
        await sendErrorToast(t('toast.error'));
      });
  };

  const EXAMPLE_VIDEO: IMedia = {
    creatorId: 'Wohnsinn',
    alt: 'Beispiel Vorstellungsvideo',
    id: 'xxxx1',
    url: 'https://firebasestorage.googleapis.com/v0/b/wohnsinn-prod.appspot.com/o/static%2Fvideos%2FBeispielvideo.mp4?alt=media&token=1b5dd053-4f22-43c3-9f85-854397c167fc&_gl=1*19p0cg*_ga*MTMzMjU1MTc5OS4xNjQ5MjMxODY1*_ga_CW55HF8NVT*MTY4NTY4OTMyNi40OC4xLjE2ODU2ODk1NDkuMC4wLjA.',
    mediaType: MEDIA_TYPE.VIDEO,
    updatedAt: new Date(),
  };

  return (
    <>
      {!isApplicationFolderTunnel && <PageTitle pageTitle={r(ROUTES.tenant.account.introductionVideo.title)} />}
      <FormLayout notSubmitButton={isApplicationFolderTunnel ? SKIP_BUTTON : CANCEL_BUTTON} showSubmitButton={false}>
        <IonGrid className={'wohnsinn-forms-grid'}>
          <LightShow open={showExampleVideo} onClose={() => setShowExampleVideo(false)} media={[EXAMPLE_VIDEO]} />
          <Section>
            <IonCol size={'12'} sizeMd={'6'}>
              <div className={styles.videoInfoWrapper}>
                <CheckList items={['5x bessere Chancen', 'authentisch & persönlich', 'Max. 45 Sekunden']} />
              </div>
            </IonCol>
            <IonCol size={'12'} sizeMd={'6'}>
              <CTAButton
                expand={'block'}
                buttonText={'Beispielvideo anzeigen'}
                onClick={() => setShowExampleVideo(true)}
                buttonStyle={BUTTON_STYLE.SECONDARY_INVERTED}
              />
            </IonCol>
          </Section>
          <Section>
            <IonCol size={'12'}>
              {tenantProfile.aboutMe?.introductionVideo ? (
                <>
                  <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>{r('introductionVideo')}</Text>
                  <LightShow
                    open={isLightShowOpen}
                    onClose={() => setIsLightShowOpen(false)}
                    media={[tenantProfile.aboutMe.introductionVideo]}
                  />
                  <DocumentUploadListItem
                    upload={tenantProfile.aboutMe.introductionVideo}
                    deleteHandler={() =>
                      openModal({
                        id: MODAL_IDENTIFIER.DELETE_MODAL,
                        data: {
                          deleteHandler: () => deleteVideoHandler(),
                          title: t('delete.title'),
                          text: t('delete.text'),
                        },
                      })
                    }
                    previewHandler={() => setIsLightShowOpen(true)}
                  />
                </>
              ) : null}
              <div className={styles.mediaUploadWrapper}>
                <IntroductionVideoUpload />
              </div>
            </IonCol>
          </Section>
        </IonGrid>
      </FormLayout>
    </>
  );
};

export default TenantIntroductionVideoForm;
