import { FC, PropsWithChildren } from 'react';

interface ITextPararaph {
  title: string;
  description: string;
  count: number;
  children?: any;
}

const TextParagraph: FC<PropsWithChildren<ITextPararaph>> = ({ title, description, children, count }) => {
  return (
    <div>
      <h4>
        {count} {title}
      </h4>
      <p>{description}</p>
      {children}
    </div>
  );
};
export default TextParagraph;
