import { FC } from 'react';
import { APARTMENT_TYPE, IApartment } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentInformationLocation.module.scss';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../typographie/Headline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faMapMarkerAlt, faStairs, faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../typographie/Text';
import getFormattedApartmentType from '../../../core/helper/get-formatted-apartment-type';

export interface IApartmentInformationLocationProps {
  apartment: IApartment;
}

const ApartmentInformationLocation: FC<IApartmentInformationLocationProps> = ({ apartment }) => {
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.address' });
  const { t } = useTranslation('common');
  const { mainInformation, cost } = apartment;

  return (
    <div className={styles.wrapper}>
      <div className={styles.address}>
        <div className={styles.warmRent}>
          <Headline tag={'span'} size={HEADLINE_SIZE.H1} color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}>
            {`${cost?.warmRent ? `${cost?.warmRent} €` : 'keine Angabe'}`}
          </Headline>{' '}
          <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
            Warmmiete
          </Text>
        </div>

        <div className={styles.street}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            <FontAwesomeIcon className={styles.icon} icon={faMapMarkerAlt} size={'sm'} />
            {mainInformation?.address?.hasPublishedAddress
              ? `${mainInformation?.address.street} ${mainInformation?.address?.houseNumber}`
              : ''}
            {mainInformation?.address?.hasPublishedAddress ? ' • ' : null}
            {mainInformation?.address?.postalCode} {mainInformation?.address?.city}
          </Headline>
        </div>

        <div className={styles.infos}>
          <Text
            className={styles.iconText}
            tag={'span'}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            <FontAwesomeIcon className={styles.icon} icon={faUpRightAndDownLeftFromCenter} size={'sm'} />
            {`${mainInformation?.rooms} ${t('apartment.mainInformation.rooms.label')} `}
          </Text>

          <Text
            className={styles.iconText}
            tag={'span'}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            <FontAwesomeIcon className={styles.icon} icon={faCube} size={'sm'} />{' '}
            {`${mainInformation?.size} ${t('qm')}   `}{' '}
          </Text>

          <Text
            className={styles.iconText}
            tag={'span'}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            <FontAwesomeIcon className={styles.icon} icon={faStairs} size={'sm'} />{' '}
            {mainInformation?.address?.apartmentType === APARTMENT_TYPE.LEVEL
              ? !mainInformation.address.floor
                ? t('levelApartment')
                : mainInformation?.address?.floor > 0
                ? `${mainInformation.address.floor}. ${t('level')}`
                : ''
              : getFormattedApartmentType(mainInformation?.address?.apartmentType, a)}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ApartmentInformationLocation;
