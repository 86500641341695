import { FC, useContext } from 'react';
import { IMedia } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentMediaItem.module.scss';
import Text, { TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/pro-light-svg-icons';
import { useIonAlert } from '@ionic/react';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useApartment from '../../../core/hook/apartment.hook';

export interface IApartmentMediaUploadItem {
  media: IMedia;
  changeDescriptionHandler: any;
  deleteImageHandler: any;
  imageIndex: number;
  previewHandler: (index: number) => void;
}

const ApartmentMediaItem: FC<IApartmentMediaUploadItem> = ({
  media,
  changeDescriptionHandler,
  deleteImageHandler,
  imageIndex,
  previewHandler,
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const { apartment } = useApartment();
  const [presentAlert] = useIonAlert();
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentMediaItem' });
  const sortable = useSortable({ id: media.id });
  const { active, attributes, listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const changeDescriptionAlert = () => {
    return presentAlert({
      header: m('mediaDescription'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t('ok'),
          role: 'confirm',
          handler: (e) => changeDescriptionHandler(imageIndex, e['0']),
        },
      ],
      inputs: [
        {
          value: `${media.alt ? media.alt : ''}`,
          attributes: {
            minlength: 3,
          },
        },
      ],
    });
  };

  return (
    <div
      className={`${styles.apartmentMediaItem} ${active?.id === media.id ? styles.active : ''}`}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
    >
      <div className={styles.actionButtonWrapper}>
        <button className={styles.actionButton} onPointerDown={() => previewHandler(imageIndex)}>
          <span className="sr-only">{t('delete')}</span>
          <FontAwesomeIcon icon={faEye} />
        </button>
        <button
          className={`${styles.actionButton} ${styles.actionDelete}`}
          onPointerDown={() =>
            openModal({
              id: MODAL_IDENTIFIER.DELETE_MODAL,
              data: {
                deleteHandler: () => deleteImageHandler(imageIndex, media, apartment.id).then(() => closeModal()),
                title: m('delete.title'),
                text: m('delete.text'),
              },
            })
          }
        >
          <span className="sr-only">{t('delete')}</span>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      <div className={styles.image} style={{ backgroundImage: `url(${media?.thumbnailUrl ?? media.url})` }} />

      <div className={styles.description}>
        <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} overFlowEllipsis>
          {media.alt}
        </Text>
        <button className={styles.editButton} onPointerDown={changeDescriptionAlert}>
          <span className="sr-only">{t('description')}</span>
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </div>
    </div>
  );
};

export default ApartmentMediaItem;
