import { FC, useContext, useEffect, useRef } from 'react';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import ApartmentCard from '../../../../component/molecules/Cards/ApartmentCard';
import styles from './ApartmentListView.module.scss';
import FilterBar from '../../../../component/molecules/FilterBar';
import Text from '../../../../component/atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from '../../../../component/atoms/typographie/Headline';
import UserContext from '../../../../core/context/user.context';
import Pagination from '../../../../component/molecules/Pagination';
import FilterList from '../../../../component/molecules/FilterList';
import scrollTo from '../../../../core/helper/scroll-to';
import ScrollPositionProviderContext from '../../../../core/context/scroll-position-provider.context';
import LOCAL_STORAGE_KEYS from '../../../../core/enum/local-storage.enum';
import CTAButton from '../../../../component/atoms/Buttons/CTAButton';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import ModalContext from '../../../../core/context/modal.context';
import usePaginatedApartmentsHook from '../../../../core/hook/paginated-apartments.hook';
import { useParams } from 'react-router';
import TenantFilterParamsContext from '../../../../core/context/tenant-filter-params.context';
import ApartmentListLoadingView from '../../../../component/molecules/LoadingElements/ApartmentListLoadingView';

const ApartmentListView: FC = () => {
  // custom hooks
  const { page } = useParams<{ page: string }>();
  const { t } = useTranslation('common');
  // contexts
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const { user } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const { scrollPos } = useContext(ScrollPositionProviderContext);
  const { apartments, otherApartments, pagesCount, currentPage, setCurrentPage, isLoading } =
    usePaginatedApartmentsHook();
  // refs
  const { loadingMatchData } = useContext(TenantFilterParamsContext);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  /**
   * Scroll to apartment card I came from - when click on back button from apartment preview
   * localeStorage - NOT_COMING_FROM_STACK shows that users is coming actually from stack and not refreshing the page
   */
  useEffect(() => {
    const scrollPosition = localStorage.getItem(LOCAL_STORAGE_KEYS.SCROLL_POSITION);
    const notComingFromStack = localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_COMMING_FROM_STACK);
    if (scrollPosition && !notComingFromStack) {
      setTimeout(() => {
        scrollTo(contentRef, parseInt(scrollPosition));
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SCROLL_POSITION);
      }, 200);
    }
  }, [apartments]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tenantFilterParams]);

  useEffect(() => {
    setCurrentPage(page ? parseInt(page) : 1);
  }, [page]);

  useEffect(() => {
    handlePagination(currentPage);
  }, [currentPage]);

  const handlePagination = (page: number) => {
    // Add URL params without refresh
    window.history.pushState(null, null, `${t(ROUTES.tenant.matching.list.path)}/${page}`);
  };

  return (
    <PageLayout
      noHorizontalPadding
      showPlaceholderToolbar={false}
      contentRef={contentRef}
      hideTabBar={false}
      pageTitle={t(ROUTES.tenant.matching.list.title)}
      appHeaderContent={<FilterBar />}
    >
      {isLoading && loadingMatchData ? (
        <ApartmentListLoadingView />
      ) : (
        <div className={styles.wrapper}>
          {!user ? (
            <div className={styles.safeSearch}>
              <CTAButton
                shadow
                buttonText={'Suche speichern'}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.REGISTER_USER })}
              />
            </div>
          ) : null}

          <div className={styles.content}>
            <div className={`${styles.filterWrapper} ${scrollPos > 3 ? styles.scrolled : ''}`}>
              <FilterBar />
            </div>

            <div className={styles.moreFilters}>
              <FilterList />
            </div>

            {apartments.length ? (
              <div className={styles.cardWrapper}>
                {apartments && apartments.length
                  ? apartments.map((apartment) => (
                      <div className={`${styles.card}`} key={apartment.id}>
                        <ApartmentCard
                          showLandlordInformations={false}
                          apartment={apartment}
                          route={t(ROUTES.tenant.matching.stack.selectedApartment.path)
                            .replace(':matchingCategory', 'top')
                            // prevent IDs with # Hashes in URL to be split
                            .replace(':selectedApartmentId', apartment.id.replace('#', '::'))}
                        />
                      </div>
                    ))
                  : null}
              </div>
            ) : (
              <div className={styles.emptyApartments}>
                <Text>Wir haben aktuell keine passenden Wohnungen zu deinen Suchkriterien.</Text>
              </div>
            )}
            {currentPage === pagesCount.length && otherApartments.length ? (
              <div className={`${styles.cardWrapper} ${styles.moreApartments}`}>
                <div className={styles.moreApartmentHeadline}>
                  <Headline tag={'span'} size={HEADLINE_SIZE.H2}>
                    Weitere Vorschläge
                  </Headline>
                  <div>
                    <Text tag={'span'}>Hier noch ein paar Wohnungen die nicht ganz deinen Kriterien entsprechen</Text>
                  </div>
                </div>
                {otherApartments && otherApartments.length
                  ? otherApartments.map((apartment) => (
                      <div className={`${styles.card}`} key={apartment.id}>
                        <ApartmentCard
                          showLandlordInformations={false}
                          apartment={apartment}
                          route={t(ROUTES.tenant.matching.stack.selectedApartment.path)
                            .replace(':matchingCategory', 'other')
                            // prevent IDs with # Hashes in URL to be split
                            .replace(':selectedApartmentId', apartment.id.replace('#', '::'))}
                        />
                      </div>
                    ))
                  : null}
              </div>
            ) : null}
            {pagesCount.length > 1 ? (
              <div className={styles.pagination}>
                <Pagination setCurrentPage={setCurrentPage} currentPage={currentPage} pages={pagesCount} />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default ApartmentListView;
