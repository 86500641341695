import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../core/const/routes';
import LandlordLandingView from '../view/landlord/landing/LandlordLandingView';
import AGBView from '../view/general/AGB';
import PrivacyView from '../view/general/PrivacyView';
import ImprintView from '../view/general/ImprintView';
import MaintenanceModeView from '../view/general/MaintenanceModeView';
import { useTranslation } from 'react-i18next';

const MaintenanceRouter = () => {
  const { t } = useTranslation('common');

  return (
    <Switch>
      <Route exact path={t(ROUTES.landlord.landing.path)}>
        <LandlordLandingView />
      </Route>
      {/* MAINTENANCE ROUTER */}

      <Route exact path={t(ROUTES.general.agb.path)}>
        <AGBView />
      </Route>
      <Route exact path={t(ROUTES.general.privacy.path)}>
        <PrivacyView />
      </Route>
      <Route exact path={t(ROUTES.general.imprint.path)}>
        <ImprintView />
      </Route>

      <Route path={t(ROUTES.general.maintenance.path)}>
        <MaintenanceModeView />
      </Route>
      <Redirect to={t(ROUTES.general.maintenance.path)} />
    </Switch>
  );
};

export default MaintenanceRouter;
