import { FC, useEffect, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import styles from './ApplicantInformationSection.module.scss';
import {
  getJobStatusItems,
  getLegalItems,
  getPersonalInformationItems,
  getSelfDisclosureItems,
} from '../../../../core/helper/get-applicant-informations-helper';
import { ITenantProfile, IUpload } from '@wohnsinn/ws-ts-lib';
import useTenantDocuments from '../../../../core/hook/tenant-document.hook';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import SanitizedHTML from '../../../atoms/SanitzedHtml';
import Text, { TEXT_TYPE, TEXT_WEIGHT } from '../../../atoms/typographie/Text';
import ItemList from '../../../molecules/ItemList';
import DownloadItemsList from '../../../molecules/DownloadItemsList';

const ApplicantInformationSection: FC<{ tenantProfile: ITenantProfile; isSideBar?: boolean }> = ({
  tenantProfile,
  isSideBar = false,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.ApplicantInformation' });
  const { t: c } = useTranslation('common');
  const [schufaCheckDocumentUploadList, setSchufaCheckUploadList] = useState<IUpload[]>([]);
  const [incomeProofDocumentUploadList, setIncomeProofDocumentUploadList] = useState<IUpload[]>([]);
  const { schufaCheckDocumentList, incomeProofDocumentList } = useTenantDocuments(tenantProfile?.uid);

  useEffect(() => {
    setSchufaCheckUploadList(schufaCheckDocumentList);
    setIncomeProofDocumentUploadList(incomeProofDocumentList);
  }, [schufaCheckDocumentList, incomeProofDocumentList]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol className={styles.hidePrint} size={'12'} sizeMd={isSideBar ? '12' : '6'}>
          {/* ABOUT */}
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3}>{c('aboutMe')}</Headline>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
              <SanitizedHTML
                className={styles.aboutMeText}
                options={{ allowedTags: ['br', 'p'] }}
                dirty={tenantProfile.aboutMe.description.html}
              />
            </Text>
          </div>
        </IonCol>
        <IonCol className={styles.showPrint} size={'12'} sizeMd={'12'}>
          {/* ABOUT */}
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3}>{c('aboutMe')}</Headline>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
              <SanitizedHTML
                className={styles.aboutMeText}
                options={{ allowedTags: [] }}
                dirty={tenantProfile.aboutMe.description.html}
              />
            </Text>
          </div>
        </IonCol>

        {tenantProfile && tenantProfile.aboutMe?.introductionVideo ? (
          <IonCol className={styles.hidePrint} size={'12'} sizeMd={isSideBar ? '12' : '6'}>
            {/* INTRODUCTION VIDEO */}
            <video width={'100%'} src={tenantProfile.aboutMe.introductionVideo.url} className={styles.video} controls>
              <source src={tenantProfile.aboutMe.introductionVideo.url} />
            </video>
          </IonCol>
        ) : null}
      </IonRow>
      <IonRow>
        <IonCol size={'12'} sizeMd={isSideBar ? '12' : '6'}>
          {/* PERSONAL INFORMATION */}
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3}>{c('personalInformation')}</Headline>
            <ItemList items={getPersonalInformationItems(tenantProfile, c)} />
          </div>
        </IonCol>
        <IonCol size={'12'} sizeMd={isSideBar ? '12' : '6'}>
          {/* SELF DISCLOSURE */}
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3}>{t('selfDisclosure')}</Headline>
            <ItemList items={getSelfDisclosureItems(tenantProfile, t)} />
          </div>
        </IonCol>
        <IonCol size={'12'} sizeMd={isSideBar ? '12' : '6'}>
          {/* JOB STATUS */}
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3}>{t('jobStatus')}</Headline>
            <ItemList items={getJobStatusItems(tenantProfile, t)} />
          </div>
        </IonCol>
        <IonCol size={'12'} sizeMd={isSideBar ? '12' : '6'}>
          {/* LEGAL ITEMS */}
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3}>{t('legalItems')}</Headline>
            <ItemList items={getLegalItems(tenantProfile, t, c)} />
          </div>
        </IonCol>
        <IonCol size={'12'} sizeMd={isSideBar ? '12' : '6'}>
          {/* DOCUMENTS */}
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3}>{t('documents')}</Headline>
            {schufaCheckDocumentUploadList && schufaCheckDocumentUploadList.length ? (
              <DownloadItemsList label={c('schufa')} list={schufaCheckDocumentUploadList} />
            ) : (
              <Text>Der Bewerber hat keine Bonitätsnachweise hochgeladen</Text>
            )}
            {incomeProofDocumentUploadList && incomeProofDocumentUploadList.length ? (
              <DownloadItemsList label={c('incomeProof')} list={incomeProofDocumentUploadList} />
            ) : (
              <Text>Der Bewerber hat keine Einkommensnachweise hochgeladen</Text>
            )}
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ApplicantInformationSection;
