import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import TenantFilterParamsContext from '../../../core/context/tenant-filter-params.context';
import { IApartment, MATCHING_MODE, TApplicationCreate, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from '../../../core/const/routes';
import styles from './ApartmentStackWrapper.module.scss';
import ApartmentRatingButtons from '../../atoms/ApartmentRatingButtons';
import ApartmentInformationLoading from '../../molecules/LoadingElements/ApartmentInformationLoading';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../core/hook/windowsize.hook';
import MapboxMap from '../../molecules/Map';
import { useIonRouter } from '@ionic/react';
import LOCAL_STORAGE_KEYS from '../../../core/enum/local-storage.enum';
import UserContext from '../../../core/context/user.context';
import ModalContext from '../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import NotificationBadgesContext from '../../../core/context/notifications-badges-context';
import MixpanelTrackingServiceContext from '../../../core/context/mixpanel-tracking-service.context';
import ApplicationServiceContext from '../../../core/context/application-service.context';
import useToast from '../../../core/hook/toast.hook';
import ApartmentStack from '../ApartmentStack';
import CTAButton from '../../atoms/Buttons/CTAButton';
import Text from '../../atoms/typographie/Text';

export type TAnimationDirection = 'left' | 'right' | 'initial';

export enum MATCHLIST_TYPES {
  TOP = 'top',
  OTHER = 'other',
}

export interface INotLoggedInUserRatings {
  MAYBE: string[];
  NOPE: string[];
}

const ApartmentStackWrapper: FC<{ matchingCategory: MATCHLIST_TYPES; selectedApartmentId: string }> = ({
  matchingCategory,
  selectedApartmentId,
}) => {
  const router = useIonRouter();
  const { isGreaterMd } = useWindowSize();
  const { sendSuccessToast } = useToast();
  // contexts
  const { openModal } = useContext(ModalContext);
  const { user, tenantProfile } = useContext(UserContext);
  const { applicationService } = useContext(ApplicationServiceContext);
  const { mixpanelTrackingService } = useContext(MixpanelTrackingServiceContext);
  const { matchData, loadingMatchData } = useContext(TenantFilterParamsContext);
  const { setBookmarkNotifications } = useContext(NotificationBadgesContext);
  // Translations
  const { t: r } = useTranslation('common');
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.ApartmentStackWrapper' });
  // States
  const [matchList, setMatchList] = useState<IApartment[]>([]);
  const [currentApartment, setCurrentApartment] = useState<IApartment>(null);
  // while isAnimation the RatingButtons are disabled
  const [isAnimating, setIsAnimating] = useState(false);
  // while matchlist array is build, isLoading is set to true to display a loading state
  const [isLoading, setIsLoading] = useState(true);
  const [animationDirection, setAnimationDirection] = useState<TAnimationDirection>('initial');
  const [showBookmarkInfo, setShowBookmarkInfo] = useState<boolean>(false);

  /**
   * Check which matchData category is selected and set to matchList
   */
  useEffect(() => {
    let apartmentList: IApartment[] = [...matchData.perfectMatches];
    if (matchingCategory === MATCHLIST_TYPES.TOP) {
      if (selectedApartmentId) {
        apartmentList = putSelectedApartmentAsFirstInStack(matchData.perfectMatches, selectedApartmentId);
      } else {
        apartmentList = [...matchData.perfectMatches];
      }
    }

    if (matchingCategory === MATCHLIST_TYPES.OTHER) {
      if (selectedApartmentId) {
        apartmentList = putSelectedApartmentAsFirstInStack(matchData.otherMatches, selectedApartmentId);
      } else {
        apartmentList = [...matchData.otherMatches];
      }
    }

    setMatchList(apartmentList);
    setCurrentApartment(apartmentList[0]);
  }, [isLoading, loadingMatchData]);

  useEffect(() => {
    // Set loading state to false when matchList was set
    setIsLoading(false);
  }, [matchList]);

  /**
   * Check if user is logged in to either create application or save rating in localeStorage
   * @param {MATCHING_MODE} rating
   */
  const submitRatingHandler = async (rating: MATCHING_MODE) => {
    setIsAnimating(true);
    mixpanelTrackingService.trackRatingClick(rating, currentApartment.id);
    setAnimationDirection(rating === MATCHING_MODE.NOPE ? 'left' : 'right');
    if (rating === MATCHING_MODE.NOPE || rating === MATCHING_MODE.MAYBE) {
      setShowBookmarkInfo(localStorage.getItem(LOCAL_STORAGE_KEYS.HAS_SEEN_BOOKMARK_INFO) !== 'true');
    }
    if (user) {
      await createApplication(rating);
    } else {
      // IF USER TRIES TO APPLY - FORCE REGISTRATION / LOGIN
      if (rating === MATCHING_MODE.LIKE) {
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({
            id: MODAL_IDENTIFIER.REGISTER_USER,
            data: {
              userType: USER_TYPE.TENANT,
              redirectUrl: r(ROUTES.tenant.matching.stack.selectedApartment.path)
                .replace(':matchingCategory', matchingCategory)
                .replace(':selectedApartmentId', currentApartment.id),
            },
          });
        }, 300);
      } else {
        saveRatingInLocaleStorage(rating, currentApartment.id, setBookmarkNotifications);
        updateCurrentMatchList();
      }
      setIsAnimating(false);
    }
  };

  const createApplication = async (rating: MATCHING_MODE) => {
    const hasCompletedSelfDisclosure =
      tenantProfile?.aboutMe && tenantProfile?.household && tenantProfile?.personalInformation;

    localStorage.setItem(
      LOCAL_STORAGE_KEYS.REDIRECT_URL,
      `${r(ROUTES.tenant.matching.stack.selectedApartment.path)
        .replace(':matchingCategory', matchingCategory)
        .replace(':selectedApartmentId', currentApartment.id)}`
    );

    if (rating === MATCHING_MODE.LIKE) {
      if (!user.isEmailVerified) {
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.CONFIRM_EMAIL });
        }, 300);
        setIsAnimating(false);
        return;
      }

      if (!hasCompletedSelfDisclosure) {
        setAnimationDirection('right');
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
        }, 300);
        setIsAnimating(false);
        return;
      }
    }

    try {
      const application: TApplicationCreate = {
        apartmentId: currentApartment.id,
        address: currentApartment.mainInformation.address,
        media: currentApartment.media[0] || null,
        isLandlordTyping: false,
        isTenantTyping: false,
        landlordId: currentApartment.creatorId,
        editorList: currentApartment.editorList,
        landlordRating: MATCHING_MODE.NONE,
        rating,
        lastMessageSenderId: tenantProfile.uid,
        lastMessage: {
          editorState: '',
          html: '',
        },
        rooms: currentApartment?.mainInformation?.rooms ?? 0,
        warmRent: currentApartment?.cost?.warmRent ?? 0,
        size: currentApartment?.mainInformation?.size ?? 0,
        seenByLandlord: false,
        tenantProfile,
      };

      if (rating === MATCHING_MODE.LIKE) {
        application.lastMessage = {
          editorState: tenantProfile.aboutMe?.description?.editorState,
          html: tenantProfile.aboutMe?.description?.html,
        };
      }

      await applicationService.addApplicationList([application]);
      if (hasCompletedSelfDisclosure && rating === MATCHING_MODE.LIKE) {
        sendSuccessToast('Perfekt! Deine Bewerbung ist abgeschickt.').then(() => setAnimationDirection('right'));
      }

      updateCurrentMatchList();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Update the matches Array after a users rating
   */
  const updateCurrentMatchList = (): void => {
    const array = [...matchList];
    setTimeout(async () => {
      array.shift();
      if (!array.length) {
        setRedirectDestination();
      }

      setCurrentApartment(array[0]);
      setMatchList(array);
      setAnimationDirection('initial');
      setIsAnimating(false);
    }, 300);
  };

  /**
   * When current matchList is empty, redirect user to next matchList or back to overview
   */
  const setRedirectDestination = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, r(ROUTES.tenant.matching.list.path));
    return router.push(`${r(ROUTES.general.redirect.path)}/${t('redirect.allRated.param')}`);
  };

  const handleBookmarkInfo = () => {
    setShowBookmarkInfo(false);
    localStorage.setItem(LOCAL_STORAGE_KEYS.HAS_SEEN_BOOKMARK_INFO, 'true');
  };

  return (
    <div className={styles.wrapper}>
      {showBookmarkInfo ? (
        <div className={styles.bookmarkInfo}>
          <div className={styles.backdrop}></div>
          <div className={styles.info}>
            <Text>In der Merkliste findest du deine bewerteten Wohungen</Text>
            <CTAButton onClick={() => handleBookmarkInfo()} expand={'block'} buttonText={'Alles klar!'} />
          </div>
          <div className={styles.arrow} />
        </div>
      ) : null}
      {loadingMatchData || isLoading ? (
        <ApartmentInformationLoading />
      ) : (
        <div className={styles.info}>
          {matchList && matchList.length ? (
            <ApartmentStack
              matchList={matchList}
              matchingCategory={matchingCategory}
              currentApartment={currentApartment}
              animationDirection={animationDirection}
            />
          ) : null}

          <div className={styles.ratings}>
            <ApartmentRatingButtons
              disabled={isAnimating}
              onButtonClick={(matchingMode) => submitRatingHandler(matchingMode)}
            />
          </div>
        </div>
      )}

      {matchList && matchList.length ? (
        <div className={styles.map}>
          {isGreaterMd && matchList[0]?.mainInformation.address?.coordinates ? (
            <MapboxMap isInteractive={true} apartments={[matchList[0]]} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

/**
 * Put selected apartment at the beginning of the matchList
 * @return IApartment[]
 * @param {IApartment[]} matchData
 * @param {string} selectedApartmentId
 */
const putSelectedApartmentAsFirstInStack = (matchData: IApartment[], selectedApartmentId: string): IApartment[] => {
  // Spread array instead of putting equal to not modify original matchData
  const unsortedMatches = [...matchData];
  const apartmentIndex = unsortedMatches.findIndex(
    // @info: revert HASH to COLON // See ApartmentListView Link to ApartmentStackView
    (a: IApartment) => a.id === selectedApartmentId.replace('::', '#')
  );
  let newMatchList: IApartment[] = [];
  if (apartmentIndex >= 0) {
    const elements = unsortedMatches.splice(apartmentIndex, 1);
    newMatchList = [...elements, ...unsortedMatches];
  }

  return newMatchList;
};

// /**
//  * Remove all apartments which are rated in localeStorage by user
//  * @param {IApartment} arrayToFilter
//  */
// export function filterNotLoggedInUserRatings(arrayToFilter: IApartment[]): IApartment[] {
//   const notLoggedInUserRating = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS));
//
//   if (notLoggedInUserRating) {
//     return [...arrayToFilter]
//       .filter((apartment) => !notLoggedInUserRating['MAYBE'].includes(apartment.id))
//       .filter((apartment) => !notLoggedInUserRating['NOPE'].includes(apartment.id));
//   } else {
//     return arrayToFilter;
//   }
// }

/**
 * Check if a string exists in an string-array, if not add it to this array
 * @param array
 * @param elementToAdd
 */
function addElementToArrayAndAvoidDuplicates(array: string[], elementToAdd: string): string[] {
  if (array.indexOf(elementToAdd) < 0) {
    array.push(elementToAdd);
  }

  return array;
}

/**
 * Remove a string from string-array if it exsits in it
 * @param arrayToCheck
 * @param elementToCheck
 */
function removeElementFromArrayIfExists(arrayToCheck: string[], elementToCheck: string): string[] {
  const otherListApartmentIndex = arrayToCheck.indexOf(elementToCheck);
  if (otherListApartmentIndex >= 0) {
    arrayToCheck.splice(otherListApartmentIndex, 1);
  }

  return arrayToCheck;
}

/**
 * Save users rating in localeStorage
 * @param rating
 * @param currentApartmentId
 * @param setBookmarkNotifications
 */
export const saveRatingInLocaleStorage = (
  rating: MATCHING_MODE,
  currentApartmentId: string,
  setBookmarkNotifications: Dispatch<SetStateAction<number>>
) => {
  let ratings: INotLoggedInUserRatings = {
    MAYBE: [],
    NOPE: [],
  };

  // check if user already has ratings in localestorage
  if (localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
    ratings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS));
  }

  if (rating === MATCHING_MODE.MAYBE) {
    addElementToArrayAndAvoidDuplicates(ratings[MATCHING_MODE.MAYBE], currentApartmentId);
    removeElementFromArrayIfExists(ratings[MATCHING_MODE.NOPE], currentApartmentId);
  }

  if (rating === MATCHING_MODE.NOPE) {
    addElementToArrayAndAvoidDuplicates(ratings[MATCHING_MODE.NOPE], currentApartmentId);
    removeElementFromArrayIfExists(ratings[MATCHING_MODE.MAYBE], currentApartmentId);
  }

  setBookmarkNotifications(ratings[MATCHING_MODE.MAYBE].length);
  localStorage.setItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS, JSON.stringify(ratings));
};

export default ApartmentStackWrapper;
