import { createContext } from 'react';
import ApplicationService from '../service/application.service';

export interface IApplicationServiceContextProps {
  applicationService: ApplicationService;
}

const ApplicationServiceContext = createContext<IApplicationServiceContextProps>({
  applicationService: null,
});

export default ApplicationServiceContext;
