import { FC, useState } from 'react';
import Text, { TEXT_COLOR } from '../../typographie/Text';
import styles from './CopyButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCopy } from '@fortawesome/pro-light-svg-icons';
import useToast from '../../../../core/hook/toast.hook';

const CopyButton: FC<{ textToCopy: string; label: string }> = ({ textToCopy, label }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { sendSuccessToast } = useToast();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    sendSuccessToast('Link Kopiert');

    // Reset the "isCopied" state after 2 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className={'floating-wrapper'}>
      <div className={'floating-field'}>
        <input
          disabled={true}
          value={textToCopy}
          type={'text'}
          onClick={handleCopyClick}
          className={'floating-input'}
        />
        <label className={`floating-label ${textToCopy ? 'isInputFilled' : ''}`}>
          <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
            {label}
          </Text>
        </label>
        <button type={'button'} className={styles.icon} onClick={handleCopyClick}>
          {isCopied ? (
            <FontAwesomeIcon
              width={'23px'}
              height={'23px'}
              icon={faCheckCircle}
              size={'1x'}
              fill={'#18CCDE'}
              color={'#18CCDE'}
            />
          ) : (
            <FontAwesomeIcon width={'23px'} height={'23px'} icon={faCopy} size={'1x'} />
          )}
        </button>
      </div>
    </div>
  );
};

export default CopyButton;
