import { FC, useContext, useEffect, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import InputText from '../../../../atoms/formElement/InputText';
import Dropdown from '../../../../atoms/formElement/Dropdown';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from '../../../../../core/const/select-options';
import {
  APARTMENT_CONDITION_SELECT_OPTION_LIST,
  APARTMENT_ENERGY_EFFICIENCY_CLASS_LIST,
  APARTMENT_HEATING_SYSTEM_SELECT_OPTION_LIST,
  BOOLEAN_SELECT_OPTION_ENERGY_PASS_AGE_LIST,
  BOOLEAN_SELECT_OPTION_ENERGY_PASS_AVAILABLE_LIST,
  ENERGY_PASS_TYPE_SELECT_OPTION_LIST,
} from './energy-supply-form-select-options';
import {
  APARTMENT_CONDITION,
  APARTMENT_ENERGY_SUPPLY_SCHEMA,
  ENERGY_PASS_TYPE,
  IApartment,
  IApartmentEnergySupply,
} from '@wohnsinn/ws-ts-lib';
import TextEditor from '../../../../atoms/formElement/TextEditor';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../../../atoms/formElement/InputOptionList';
import { FormContext } from '../../../../../core/context/form.context';
import { FirebaseFunctionsContext } from '../../../../../core/context/firebase-functions.context';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../../core/hook/toast.hook';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import FB_FUNCTION_URLS from '../../../../../core/const/fb-function-names';
import FormHeader from '../../../../atoms/FormHeader';
import FormFooter from '../../../../atoms/FormFooter';
import SplitLine from '../../../../atoms/SplitLine';

const ApartmentEnergySupplyForm: FC<{ scrollToTop: () => any; apartment: IApartment }> = ({
  apartment,
  scrollToTop,
}) => {
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const { t } = useTranslation('common');
  const { t: f } = useTranslation('common', { keyPrefix: 'view.ApartmentOverviewView.EnergySupplyFormView' });
  const { sendInfoToast, sendErrorToast } = useToast();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { control, handleSubmit, watch, reset } = useForm<IApartmentEnergySupply>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(APARTMENT_ENERGY_SUPPLY_SCHEMA)),
    defaultValues: apartment?.energySupply ? apartment.energySupply : null,
  });

  useEffect(() => {
    if (!!apartment?.energySupply) {
      reset(apartment.energySupply);
    }
  }, [apartment]);

  const condition = watch('condition');
  const isEnergyPassAvailable = watch('isEnergyPassAvailable');
  const energyPassType = watch('energyPassType');
  const isEnergyPassCreationDate = watch('isEnergyPassCreationDateBeforeConsumptionUsageRegulation');

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    scrollToTop();
    await sendInfoToast('toast.success');
  };

  const onError = (err: Partial<FieldErrorsImpl<IApartmentEnergySupply>>): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    if (apartment.energySupply) {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentEnergySupply; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.energySupply.update,
          {
            params: { apartmentId: apartment.id },
            body: watch(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          sendErrorToast(t('toast.error'));
          console.error(err);
        });
    } else {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentEnergySupply; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.energySupply.add,
          {
            params: { apartmentId: apartment.id },
            body: watch(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(saveDataToDB, onError)} noValidate autoComplete="off">
        <FormHeader title={'Energieversorgung'} buttonSubmitMode={buttonSubmitMode} />

        <IonGrid className={'wohnsinn-forms-grid'}>
          <IonRow>
            <IonCol size="12">
              <FormSectionTitle title={f('buildingInformation')} />
              <InputText type={'text'} label={f('field.buildYear.label')} name={'buildYear'} required />
              <Dropdown
                optionList={getTranslatedOptions(APARTMENT_HEATING_SYSTEM_SELECT_OPTION_LIST, t)}
                name={'heatingSystem'}
                label={t('apartment.energySupply.heatingSystem.label')}
                required
              />
              <Dropdown
                name={'condition'}
                label={t('apartment.energySupply.condition.label')}
                placeholder={t('apartment.energySupply.condition.label')}
                optionList={getTranslatedOptions(APARTMENT_CONDITION_SELECT_OPTION_LIST, t)}
                required
              />
              {condition && condition !== APARTMENT_CONDITION.NOT_SPECIFIED && (
                <>
                  <InputText
                    type={'number'}
                    pattern="[0-9]*"
                    name={'yearOfLastActivity'}
                    label={t('apartment.energySupply.yearOfLastActivity')}
                    required
                  />
                  <TextEditor
                    label={t('Beschreibung')}
                    placeholder={t('Beschreiben Sie den aktuellen Zustand der Wohnung')}
                    name={'description'}
                    required
                  />
                </>
              )}

              <SplitLine />

              <FormSectionTitle title={t('apartment.energySupply.energyPass')} />

              <InputOptionList
                mode={'radio'}
                name={'isEnergyPassAvailable'}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTION_ENERGY_PASS_AVAILABLE_LIST, t)}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                label={t('Energieausweis vorhanden?')}
                required
              />
              {isEnergyPassAvailable && (
                <>
                  <InputOptionList
                    mode={'radio'}
                    name={'energyPassType'}
                    options={getTranslatedOptions(ENERGY_PASS_TYPE_SELECT_OPTION_LIST, t)}
                    columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                    label={'Ausweisart?'}
                  />
                  <InputOptionList
                    mode={'radio'}
                    name={'isEnergyPassCreationDateBeforeConsumptionUsageRegulation'}
                    options={getTranslatedOptions(BOOLEAN_SELECT_OPTION_ENERGY_PASS_AGE_LIST, t)}
                    columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                    label={'Erstellungsdatum?'}
                  />
                  {energyPassType === ENERGY_PASS_TYPE.CONSUMPTION_PASS && isEnergyPassCreationDate && (
                    <InputOptionList
                      mode={'radio'}
                      name={'isEnergyConsumptionForHotWaterIncluded'}
                      options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                      columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                      label={'Ist der Energieverbrauch für Warmwasser enthalten?'}
                    />
                  )}
                  <InputText
                    name={'energyConsumption'}
                    type={'number'}
                    pattern="[0-9]*"
                    label={`Energie${
                      energyPassType === ENERGY_PASS_TYPE.CONSUMPTION_PASS ? 'verbrauch' : 'bedarf'
                    }skennwert`}
                  />
                </>
              )}
              {energyPassType === ENERGY_PASS_TYPE.CONSUMPTION_PASS && (
                <Dropdown
                  label="Energieeffizienzklasse"
                  placeholder="Energieeffizienzklasse"
                  name={'energyEfficiency'}
                  optionList={APARTMENT_ENERGY_EFFICIENCY_CLASS_LIST}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentEnergySupplyForm;
