import styles from './LandlordBusinessCard.module.scss';
import { ILandlordProfile } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import { IOrganization } from '@wohnsinn/ws-ts-lib';
import Avatar, { AVATAR_SIZE } from '../../atoms/Avatar';
import OrganizationInformation from '../../atoms/OrganizationInformation';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import SplitLine from '../../atoms/SplitLine';
import Badge, { BADGE_COLORS } from '../../atoms/Badge';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';

export interface ILandlordBusinessCardProps {
  landlordProfile: ILandlordProfile;
  organization?: IOrganization;
}

const LandlordBusinessCard: FC<ILandlordBusinessCardProps> = ({ landlordProfile, organization }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.wrapper} data-testid={'landlordCard'}>
      {organization ? (
        <>
          <OrganizationInformation organization={organization} />
          <SplitLine />
        </>
      ) : (
        <Badge color={BADGE_COLORS.PRIMARY} text={t('privateLandlord')} />
      )}

      <Headline size={HEADLINE_SIZE.H3}>{t('view.ApartmentOverviewView.contactPerson')}</Headline>
      <div className={styles.landlord}>
        <Avatar size={AVATAR_SIZE.md} avatarUrl={landlordProfile?.photoUrl} />
        <p>
          <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
            {landlordProfile?.personalInformation?.firstName} {landlordProfile?.personalInformation?.lastName}
          </Text>
          {landlordProfile?.personalInformation?.phoneNumber && (
            <a href={`tel:${landlordProfile?.personalInformation?.phoneNumber}`}>
              <br />
              <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}>
                {landlordProfile?.personalInformation?.phoneNumber}
              </Text>
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

export default LandlordBusinessCard;
