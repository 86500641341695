import { FC, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TabBarItem.module.scss';
import { IonRouterLink, useIonRouter, UseIonRouterResult } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../../core/context/modal.context';
import UserContext from '../../../core/context/user.context';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import { IMenuItemProps } from '../../../core/const/menu-items';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../NotificationBadge';

const TabBarItem: FC<IMenuItemProps> = ({ label, icon, iconActive, link, notifications, shouldOpenModal = false }) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation('common');
  const router: UseIonRouterResult = useIonRouter();
  const isActive =
    (label !== 'core.const.tabBarMenuItems.search' && (active || router?.routeInfo?.pathname.includes(t(link)))) ||
    (label == 'core.const.tabBarMenuItems.search' && router?.routeInfo?.pathname === '/');
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  return (
    <IonRouterLink
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      className={`${styles.wrapper} ${isActive ? styles.active : ''} `}
      routerLink={shouldOpenModal && !user ? undefined : t(link)}
      onClick={!user && shouldOpenModal ? () => openModal({ id: MODAL_IDENTIFIER.SIGN_IN }) : undefined}
    >
      <div className={styles.tabBarItem}>
        {notifications ? (
          <div className={styles.badgeWrapper}>
            <NotificationBadge
              size={NOTIFICATION_BADGE_SIZE.SMALL}
              count={typeof notifications === 'number' ? notifications : '!'}
            />
          </div>
        ) : null}
        <FontAwesomeIcon icon={isActive ? iconActive : icon} />
        <span className={styles.label}>{t(label)}</span>
      </div>
    </IonRouterLink>
  );
};

export default TabBarItem;
