import styles from './ApplicantInformationHeader.module.scss';
import { faPrint, faTimes } from '@fortawesome/pro-solid-svg-icons';
import RoundIconButton from '../../../atoms/Buttons/RoundIconButton';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';

const ApplicantInformationHeader: FC<{ closeAction?: () => any }> = ({ closeAction }) => {
  const { t } = useTranslation('common');

  const handlePrint = async () => {
    try {
      document.execCommand('print', false, null);
    } catch {
      window.print();
    }
  };

  return (
    <div className={styles.header}>
      <CTAButton
        onClick={handlePrint}
        buttonText={t('print')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        size={'small'}
        icon={faPrint}
      />
      {closeAction ? <RoundIconButton onClick={closeAction} buttonText={t('close')} icon={faTimes} /> : null}
    </div>
  );
};

export default ApplicantInformationHeader;
