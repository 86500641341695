import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const useToast = () => {
  const [presentToast, dismiss] = useIonToast();
  const { t } = useTranslation('common');

  const sendErrorToast = async (messageKey: string) => {
    return presentToast({
      buttons: [{ text: t('toast.close'), handler: () => dismiss() }],
      position: 'top',
      duration: 2500,
      message: t(messageKey),
      color: 'danger',
    });
  };

  const sendSuccessToast = async (messageKey: string) => {
    return presentToast({
      buttons: [{ text: t('toast.close'), handler: () => dismiss() }],
      position: 'top',
      duration: 2500,
      message: t(messageKey),
      color: 'primary',
    });
  };

  const sendWarningToast = async (messageKey: string) => {
    return presentToast({
      buttons: [{ text: t('toast.close'), handler: () => dismiss() }],
      position: 'top',
      duration: 3000,
      message: t(messageKey),
      color: 'warning',
    });
  };

  const sendInfoToast = async (messageKey: string) => {
    return presentToast({
      buttons: [{ text: t('toast.close'), handler: () => dismiss() }],
      position: 'top',
      duration: 3000,
      message: t(messageKey),
      color: 'tertiary',
    });
  };

  return { sendInfoToast, sendSuccessToast, sendWarningToast, sendErrorToast };
};

export default useToast;
