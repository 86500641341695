import BackButton from '../../atoms/Buttons/BackButton';
import { IonBreadcrumbs } from '@ionic/react';
import styles from './Breadcrumbs.module.scss';
import { FC, PropsWithChildren } from 'react';
import Section from '../../atoms/Section';

export interface IFormsPageHeaderProps {
  backRoute?: string;
}

const Breadcrumbs: FC<PropsWithChildren<IFormsPageHeaderProps>> = ({ children, backRoute }) => {
  return (
    <Section>
      <div className={styles.wrapper}>
        {backRoute ? <BackButton /> : null}
        <IonBreadcrumbs>{children}</IonBreadcrumbs>
      </div>
    </Section>
  );
};

export default Breadcrumbs;
