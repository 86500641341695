import { createContext, Dispatch, SetStateAction } from 'react';

export interface INotificationBadgesContext {
  bookmarkNotifications: number;
  setBookmarkNotifications: Dispatch<SetStateAction<number>>;
}

const NotificationBadgesContext = createContext<INotificationBadgesContext>({
  bookmarkNotifications: null,
  setBookmarkNotifications: null,
});

export default NotificationBadgesContext;
