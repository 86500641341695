import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';
import { useForm } from 'react-hook-form';
import { ILandlordPersonalInformation, LANDLORD_PERSONAL_INFORMATION_SCHEMA } from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import styles from './StepAddUserProfile.module.scss';
import { SUBMIT_BUTTON_MODE } from '../../../../../../core/enum/submit-button-mode.enum';
import UserContext from '../../../../../../core/context/user.context';
import LOCAL_STORAGE_KEYS from '../../../../../../core/enum/local-storage.enum';
import { FormContext } from '../../../../../../core/context/form.context';
import InputText from '../../../../../atoms/formElement/InputText';
import CTAButton, { getSubmitIcon } from '../../../../../atoms/Buttons/CTAButton';
import SwiperTunnelStepsWrapper from '../../../Layout/SwiperTunnelStepsWrapper';
import SwiperTunnelTitle from '../../../Layout/SwiperTunnelTitle';
import { useIonRouter } from '@ionic/react';
import { ROUTES } from '../../../../../../core/const/routes';

const StepAddLandlordProfile: FC<{ isOrganization: boolean; setAllowRedirect: Dispatch<SetStateAction<boolean>> }> = ({
  isOrganization,
  setAllowRedirect,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.RegisterLandlordSteps.StepAddUserProfile',
  });
  const router = useIonRouter();
  const swiper = useSwiper();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { userService, landlordProfile } = useContext(UserContext);
  const { t: c } = useTranslation('common');

  const { control, watch, handleSubmit } = useForm<ILandlordPersonalInformation>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(LANDLORD_PERSONAL_INFORMATION_SCHEMA)),
  });

  const handleFormSubmit = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    await userService.updateLandlordPersonalInformation(landlordProfile.uid, watch());
    swiper.allowSlideNext = true;
    if (isOrganization) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_COMMERCIAL_LANDLORD, 'true');

      swiper.slideNext(300);
    } else {
      setAllowRedirect(true);
      router.push(`${c(ROUTES.general.redirect.path)}/register`);
    }
  };

  const handleFormError = (errors: FieldErrors) => {
    console.error(errors);
  };

  return (
    <SwiperTunnelStepsWrapper>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
          <div className={styles.wrapper}>
            <SwiperTunnelTitle image={c('pictogram.object')} title={t('formTitle')} subtitle={t('formSubTitle')} />
          </div>
          <InputText tabIndex={1} label={c('firstName.label')} name={'firstName'} required />
          <InputText tabIndex={2} label={c('lastName.label')} name={'lastName'} required />
          <InputText tabIndex={3} type={'tel'} label={c('phoneNumber.label')} name={'phoneNumber'} required />

          <button className={'sr-only'}>submit</button>
          <CTAButton
            tabIndex={4}
            disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            icon={getSubmitIcon(buttonSubmitMode)}
            spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            expand={'block'}
            buttonText={isOrganization ? c('next') : c('finish')}
          />
        </form>
      </FormContext.Provider>
    </SwiperTunnelStepsWrapper>
  );
};

export default StepAddLandlordProfile;
