import { FC, useContext, useEffect, useState } from 'react';
import PageLayout from '../../../../component/organisms/PageLayout';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../core/const/routes';
import ApartmentCard from '../../../../component/molecules/Cards/ApartmentCard';
import InfoBox, { INFO_BOX_ICONS } from '../../../../component/atoms/InfoBox';
import { IonCol, IonRow } from '@ionic/react';
import Section from '../../../../component/atoms/Section';
import CTACard from '../../../../component/molecules/Cards/CTACard';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import PageTitle from '../../../../component/molecules/PageTitle';
import UserContext from '../../../../core/context/user.context';
import ApartmentLoadingCard from '../../../../component/molecules/LoadingElements/ApartmentLoadingCard';
import ApartmentServiceContext from '../../../../core/context/apartment-service.context';
import { Unsubscribe } from '@firebase/firestore';
import { onSnapshot } from 'firebase/firestore';

const ApplicationListView: FC = () => {
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationListView' });
  const { apartmentService } = useContext(ApartmentServiceContext);
  const { landlordProfile } = useContext(UserContext);
  const [apartmentList, setApartmentList] = useState<IApartment[]>([]);
  const [isLoadingApartments, setIsLoadingApartments] = useState<boolean>(true);

  useEffect(() => {
    setIsLoadingApartments(true);

    let unsubscribe: Unsubscribe;

    (async () => {
      const allApartmentsWithApplicationsQuery = await apartmentService.getAllApartmentsWithApplicationsQuery(
        landlordProfile.uid
      );

      unsubscribe = onSnapshot(
        allApartmentsWithApplicationsQuery,
        async (snap) => {
          const apartmentsWithApplicationsList = snap.docs.map((doc) => doc.data());
          if (apartmentsWithApplicationsList.length) {
            setApartmentList(apartmentsWithApplicationsList);
          }
        },
        (error) => console.error(error)
      );
    })();
    setIsLoadingApartments(false);

    return unsubscribe;
  }, [landlordProfile]);

  if (!apartmentList?.length && !isLoadingApartments) {
    return (
      <PageLayout hideTabBar={false} pageTitle={t(ROUTES.tenant.bookmark.title)}>
        <Section center>
          <IonCol size={'12'} sizeMd={'6'}>
            <CTACard
              title={a('noApplications.title')}
              text={a('noApplications.text')}
              imgSrc={t('pictogram.chatMessage')}
              imgAltText={a('bookmarkIcon')}
            />
          </IonCol>
        </Section>
      </PageLayout>
    );
  }

  // const [paginatedResponse, setPaginatedResponse] = useState<IPaginatedResponse<IApartment>>({
  //   skip: 0,
  //   take: 10,
  //   hasMore: false,
  //   status: 'success',
  //   items: [],
  // });

  // const { status } = paginatedResponse;

  return (
    <PageLayout hideTabBar={false} pageTitle={t(ROUTES.landlord.application.list.title)}>
      <Section>
        <IonCol>
          <PageTitle showSubmitButton={false} pageTitle={a('pageTitle')} />
        </IonCol>
      </Section>
      <IonRow>
        {isLoadingApartments ? (
          [1, 2, 3, 4, 5, 6].map((card, index) => (
            <IonCol key={index} size="12" size-sm="6" size-lg="4">
              <ApartmentLoadingCard />
            </IonCol>
          ))
        ) : apartmentList?.length ? (
          apartmentList.map((apartment) => (
            <IonCol key={apartment.id} size="12" size-sm="6" size-lg="4">
              <ApartmentCard
                key={apartment.id}
                apartment={apartment}
                route={`${t(ROUTES.landlord.application.overview.path)}`.replace(':apartmentId', apartment.id)}
                notifications={apartment?.unreadTenantChatsRef?.length}
              />
            </IonCol>
          ))
        ) : (
          <InfoBox text={'noApplications.text'} icon={INFO_BOX_ICONS.LIGHT_BULB} />
        )}
      </IonRow>
    </PageLayout>
  );
};

export default ApplicationListView;
