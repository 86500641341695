import { IonImg, IonRouterLink } from '@ionic/react';
import PageLayout from '../../../component/organisms/PageLayout';
import styles from './NotFoundView.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../core/const/routes';

const NotFoundView = () => {
  const { t } = useTranslation('common');

  return (
    <PageLayout pageTitle={t(ROUTES.general.notFound.title)}>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <h1>{t(ROUTES.general.notFound.title)}</h1>
          <p>
            Bring mich nach <IonRouterLink routerLink="/">Hause</IonRouterLink>
          </p>
        </div>
        <IonImg src={'/assets/images/redirect/throwing-balls.gif'} />
      </div>
    </PageLayout>
  );
};

export default NotFoundView;
