import { IonContent, IonFooter, IonGrid, IonPage, IonToolbar } from '@ionic/react';
import { FC, PropsWithChildren, useContext } from 'react';
import AppHeader from '../../molecules/AppHeader';
import { useTranslation } from 'react-i18next';
import styles from './PageLayout.module.scss';
import { Helmet } from 'react-helmet-async';
import TabBarMenu from '../../molecules/TabBarMenu/TabBarMenu';
import {
  getGuestMenuList,
  getLandlordMenuList,
  getMobileGuestMenuList,
  getTenantMenuList,
  IMenuNotifications,
} from '../../../core/const/menu-items';
import UserContext from '../../../core/context/user.context';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import useWindowSize from '../../../core/hook/windowsize.hook';
import ApplicationListContext from '../../../core/context/application-list.context';
import NotificationBadgesContext from '../../../core/context/notifications-badges-context';
import useLandlordApartmentListHook from '../../../core/hook/landlord-apartment-list.hook';
import ScrollPositionProviderContext from '../../../core/context/scroll-position-provider.context';

export interface ILayoutProps {
  pageTitle: string;
  fullWidthPage?: boolean;
  headerStartButton?: any;
  headerEndButton?: any;
  disableScroll?: boolean;
  showBackButton?: boolean;
  hideTabBar?: boolean;
  showLogo?: boolean;
  showPlaceholderToolbar?: boolean;
  appHeaderBottomBorder?: boolean;
  noHorizontalPadding?: boolean;
  showMobilePageTitle?: boolean;
  hideFooter?: boolean;
  hideMenu?: boolean;
  contentRef?: any;
  appHeaderContent?: any;
}

const PageLayout: FC<PropsWithChildren<ILayoutProps>> = ({
  pageTitle,
  children,
  headerStartButton,
  headerEndButton,
  fullWidthPage = false,
  disableScroll = false,
  showBackButton = false,
  hideTabBar = true,
  showPlaceholderToolbar = true,
  appHeaderBottomBorder = false,
  showMobilePageTitle = false,
  noHorizontalPadding = false,
  hideMenu = false,
  contentRef,
  appHeaderContent,
}) => {
  // custom hooks
  const { t } = useTranslation('common');
  const { isSmallerLg, isSmallerMd } = useWindowSize();
  const { unreadTenantChatsCount } = useLandlordApartmentListHook();
  // contexts
  const { activeUserType, user } = useContext(UserContext);
  const { unreadNotifications: unreadTenantChatNotifications, maybeList } = useContext(ApplicationListContext);
  const { bookmarkNotifications } = useContext(NotificationBadgesContext);
  const { setScrollPos } = useContext(ScrollPositionProviderContext);

  const createMenu = (notifications: IMenuNotifications) => {
    return user
      ? activeUserType === USER_TYPE.LANDLORD
        ? getLandlordMenuList(notifications)
        : getTenantMenuList(notifications)
      : isSmallerMd
      ? getMobileGuestMenuList(notifications)
      : getGuestMenuList(notifications);
  };

  const notifications = {
    account: !user?.isEmailVerified,
    landlordChat: unreadTenantChatsCount,
    tenantChat: unreadTenantChatNotifications,
    bookmark: maybeList?.length ? maybeList.length : bookmarkNotifications ?? 0,
  };

  const MENU = createMenu(notifications);

  return (
    <>
      <div id="lightshow-portal" />

      <IonPage>
        <AppHeader
          hideMenu={hideMenu}
          pageTitle={pageTitle}
          showPageTitle={showMobilePageTitle}
          appHeaderBottomBorder={appHeaderBottomBorder}
          showBackButton={showBackButton}
          headerStartButton={headerStartButton}
          headerEndButton={headerEndButton}
          menu={MENU}
          fullWidth={true}
          customContent={appHeaderContent}
        />
        <Helmet>
          <title>{`${t('appTitle')} - ${t(pageTitle)}`}</title>
        </Helmet>

        <IonContent
          scrollY={!disableScroll}
          scrollEvents={!disableScroll}
          onIonScroll={(e) => {
            setScrollPos(e.detail.scrollTop);
          }}
          ref={contentRef}
        >
          {showPlaceholderToolbar ? (
            headerEndButton || headerStartButton || showBackButton || disableScroll ? null : (
              <div className={`${styles.placeholderToolbar}`} />
            )
          ) : null}

          <IonGrid
            className={`${fullWidthPage ? styles.removePadding : ''} ${
              noHorizontalPadding ? styles.noHorizontalPadding : ''
            } ${styles.grid}`}
            fixed={!fullWidthPage}
          >
            {children}
          </IonGrid>
        </IonContent>

        {isSmallerLg && !hideTabBar && (
          <IonFooter>
            <IonToolbar>
              <TabBarMenu tabBarMenuItemList={MENU} />
            </IonToolbar>
          </IonFooter>
        )}
      </IonPage>
    </>
  );
};

export default PageLayout;
