import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../../../../component/atoms/typographie/Headline';
import Text from '../../../../../component/atoms/typographie/Text';
import CTAButton, { BUTTON_STYLE } from '../../../../../component/atoms/Buttons/CTAButton';
import styles from './LandlordLandingHero.module.scss';
import { IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../../core/const/routes';
import { PopupModal } from 'react-calendly';
import { useState } from 'react';

const LandlordLandingHero = () => {
  const { t } = useTranslation('common');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.LandlordLanding.hero' });
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  return (
    <div className={`${styles.wrapper} ion-padding`}>
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_CATCH_UP_LINK}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
      <IonRow>
        <div className={styles.content}>
          <div className={styles.text}>
            <Headline mobileAlign={'center'} color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY} size={HEADLINE_SIZE.H3}>
              {l('tag')}
            </Headline>
            <Headline mobileAlign={'center'} size={HEADLINE_SIZE.DISPLAY}>
              {l('headline_one')}
              <br />
              {l('headline_two')}
            </Headline>
            <Text mobileAlign={'center'}>{l('subtitle')}</Text>
            <div className={styles.buttonWrapper}>
              <CTAButton buttonText={'Kostenlos inserieren'} link={t(ROUTES.landlord.register.path)} />
              <CTAButton
                onClick={() => setIsCalendlyOpen(true)}
                buttonStyle={BUTTON_STYLE.SECONDARY}
                buttonText={'Kostenlose Demo anfragen'}
              />
            </div>
          </div>
          <div className={styles.image}>
            <img src={t('icons.landlord_landing_hero')} alt="" />
            <img className={styles.logo} src={t('icons.wohnsinn_logo')} alt="Wohnsinn Logo" />
          </div>
        </div>
      </IonRow>
    </div>
  );
};

export default LandlordLandingHero;
