import { FC, useContext, useState } from 'react';
import { IonLabel, IonSelect, IonSelectOption, isPlatform } from '@ionic/react';
import { FormContext } from '../../../../core/context/form.context';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import differenceInYears from 'date-fns/differenceInYears';
import { addYears, getDate, getDaysInMonth, getMonth, getYear, isLeapYear, lastDayOfMonth, subYears } from 'date-fns';
import FormErrorMessage from '../FormErrorMessage';
import { useTranslation } from 'react-i18next';
import MobileDatepicker from '../MobileDatepicker';
import isIsoDate from '../../../../core/helper/is-iso-date';
import styles from './DateInput.module.scss';
import Text from '../../typographie/Text';

export interface IDateInputProps {
  startDate?: Date;
  endDate?: Date;
  label: string;
  name: string;
  required?: boolean;
  hideErrorMessage?: boolean;
}

const generateYears = (startDate: Date, endDate: Date): number[] => {
  const years = [];
  while (differenceInYears(startDate, endDate) < 0) {
    years.push(getYear(startDate));
    startDate = addYears(startDate, 1);
  }
  years.push(getYear(startDate));
  return years;
};

const months: string[] = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const DateInput: FC<IDateInputProps> = (props) => {
  const {
    startDate = subYears(new Date(), 100),
    endDate = new Date(),
    name,
    hideErrorMessage = false,
    label,
    required,
  } = props;
  const { t } = useTranslation('common');
  const years: number[] = generateYears(startDate, endDate);
  const { control } = useContext(FormContext);
  const [days, setDays] = useState<number>(getDaysInMonth(startDate));

  const transformDate = (date: Date): Date => {
    switch (typeof date) {
      case 'string':
        if (isIsoDate(date)) {
          return new Date(date);
        } else {
          return new Date();
        }
      case 'object':
        return date;
      default:
        return new Date();
    }
  };

  const getDayFromDate = (date: Date): number => {
    return getDate(transformDate(date));
  };

  const getMonthFromDate = (date: Date): number => {
    return getMonth(transformDate(date));
  };

  const getYearFromDate = (date: Date): number => {
    const transformedDate = transformDate(date);
    let year: number;
    switch (typeof transformedDate) {
      case 'object':
        if (transformedDate) {
          if (endDate && getYear(transformedDate) > getYear(endDate)) {
            year = getYear(endDate);
            break;
          } else if (startDate && getYear(transformedDate) < getYear(startDate)) {
            year = getYear(startDate);
            break;
          }
          year = getYear(transformedDate);
          break;
        } else {
          year = getYear(endDate);
          break;
        }
    }
    return year;
  };

  const handleDayFieldChange = (field: ControllerRenderProps, value: number) => {
    if (value && !isNaN(value)) {
      const selectedDay: number = value;
      const selectedMonth: number = getMonthFromDate(field.value);
      const selectedYear: number = getYearFromDate(field.value);
      const date: Date | string = checkMonthDays(selectedDay, selectedMonth, selectedYear);
      updateValue(field, date);
    }
  };

  const handleMonthFieldChange = (field: ControllerRenderProps, value: number) => {
    if (value && !isNaN(value)) {
      const selectedDay: number = getDayFromDate(field.value);
      const selectedMonth = value;
      const selectedYear: number = getYearFromDate(field.value);
      const date: Date | string = checkMonthDays(selectedDay, selectedMonth, selectedYear);

      updateValue(field, date);
    }
  };

  const handleYearFieldChange = (field: ControllerRenderProps, value: number) => {
    if (value && !isNaN(value)) {
      const selectedDay: number = getDayFromDate(field.value);
      const selectedMonth: number = getMonthFromDate(field.value);
      const selectedYear: number = value;
      const date: Date | string = checkMonthDays(selectedDay, selectedMonth, selectedYear);

      updateValue(field, date);
    }
  };

  const checkMonthDays = (selectedDay: number, selectedMonth: number, selectedYear: number): Date => {
    let outputDate: Date;
    const isSelectedDateInvalid: boolean =
      (selectedMonth === 1 && isLeapYear(selectedYear) && selectedDay > 29) ||
      (selectedMonth === 1 && !isLeapYear(selectedYear) && selectedDay > 28) ||
      ([1, 3, 5, 8, 10].includes(selectedMonth) && selectedDay > 30);

    if (isSelectedDateInvalid) {
      const lastDayDate = lastDayOfMonth(new Date(selectedYear, selectedMonth, selectedDay)).getDate();
      outputDate = new Date(selectedYear, selectedMonth, lastDayDate);
    } else {
      outputDate = new Date(selectedYear, selectedMonth, selectedDay);
    }
    return outputDate;
  };

  const updateValue = (field: ControllerRenderProps, date: Date): void => {
    setDays(getDaysInMonth(date));
    field.onChange(date);
  };

  return isPlatform('mobile') || isPlatform('mobileweb') ? (
    <MobileDatepicker label={label} name={name} startDate={startDate} endDate={endDate} required={required} />
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={'form-field'}>
          <IonLabel position="stacked">
            {label && (
              <Text>
                {label}
                {required && <span> *</span>}
              </Text>
            )}
          </IonLabel>
          <div className={styles.dateInputWrapper}>
            <IonSelect
              className={`${styles.dateInput} ${styles.day}`}
              interface="popover"
              multiple={false}
              okText={t('ok')}
              cancelText={t('cancel')}
              placeholder={t('day')}
              value={getDayFromDate(field.value)}
              onIonChange={(e) => handleDayFieldChange(field, e.target.value)}
            >
              {[...Array(days)].map((value, i) => (
                <IonSelectOption key={`day-${value}-${i}`} value={i + 1}>
                  {i + 1}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonSelect
              className={`${styles.dateInput} ${styles.month}`}
              interface="popover"
              multiple={false}
              okText={t('ok')}
              cancelText={t('cancel')}
              placeholder={t('month')}
              value={getMonthFromDate(field.value)}
              onIonChange={(e) => handleMonthFieldChange(field, e.target.value)}
            >
              {months.map((month, i) => (
                <IonSelectOption key={`month-${month}-${i}`} value={i}>
                  {t(`months.${month}`)}
                </IonSelectOption>
              ))}
            </IonSelect>

            <IonSelect
              className={`${styles.dateInput} ${styles.year}`}
              interface="popover"
              okText={t('ok')}
              multiple={false}
              cancelText={t('cancel')}
              placeholder={t('year')}
              value={getYearFromDate(field.value)}
              onIonChange={(e) => handleYearFieldChange(field, e.target.value)}
            >
              {years.map((value, i) => (
                <IonSelectOption key={`year-${value}-${i}`} value={value}>
                  {value}
                </IonSelectOption>
              ))}
            </IonSelect>
          </div>
          {!hideErrorMessage && <FormErrorMessage error={error} />}
        </div>
      )}
    />
  );
};

export default DateInput;
