import { FC } from 'react';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export interface ISanitizeHTMLProps {
  dirty: string;
  className?: string;
  options?: IOptions;
}

export const defaultOptions: IOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'span', 'h1', 'p', 'br'],
  allowedAttributes: {
    a: ['href'],
  },
};

const SanitizedHTML: FC<ISanitizeHTMLProps> = ({ className, dirty, options = defaultOptions }) => (
  <span className={className ? className : ''} dangerouslySetInnerHTML={{ __html: sanitizeHtml(dirty, options) }} />
);

export default SanitizedHTML;
