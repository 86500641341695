import { IonContent, IonPage } from '@ionic/react';
import { FC, PropsWithChildren } from 'react';
import styles from './TunnelLayout.module.scss';
import { Swiper } from 'swiper/react';

const TunnelLayout: FC<PropsWithChildren<{ allowNextSlide: boolean; onSlideChangeTransitionEnd?: any }>> = ({
  children,
  allowNextSlide,
  onSlideChangeTransitionEnd,
}) => {
  return (
    <IonPage>
      <IonContent scrollY={false}>
        <div className={styles.tunnelBackground}>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            watchSlidesProgress={true}
            allowSlideNext={allowNextSlide}
            onSlideChangeTransitionEnd={
              onSlideChangeTransitionEnd
                ? ({ activeIndex }) => onSlideChangeTransitionEnd(activeIndex)
                : function () {
                    return;
                  }
            }
          >
            {children}
          </Swiper>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TunnelLayout;
