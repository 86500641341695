import { IonCol, IonRow } from '@ionic/react';
import ApartmentMediaItem from '../ApartmentMediaItem';
import { FC, useContext, useState } from 'react';
import { FIRESTORE_COLLECTION_PATH, IApartment, IMedia } from '@wohnsinn/ws-ts-lib';
import ApartmentServiceContext from '../../../core/context/apartment-service.context';
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types/events';
import InfoBox from '../../atoms/InfoBox';
import { TEXT_TYPE } from '../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import LightShow from '../LightShow';
import SplitLine from '../../atoms/SplitLine';
import StorageServiceContext from '../../../core/context/storage-service.context';

export interface IApartmentMediaList {
  apartment: IApartment;
}

const ApartmentMediaList: FC<IApartmentMediaList> = ({ apartment }) => {
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [lightShowIndex, setLightShowIndex] = useState<number>(0);
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentMediaList' });
  const { media } = apartment;
  const { apartmentService } = useContext(ApartmentServiceContext);
  const { storageService } = useContext(StorageServiceContext);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 80,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const changeApartmentMediaDescriptionHandler = (index: number, newImageDescription: string) => {
    const newApartmentMedia: IMedia[] = [...media];
    newApartmentMedia[index] = { ...newApartmentMedia[index], alt: newImageDescription };
    return apartmentService.updateApartmentMediaList(apartment, newApartmentMedia);
  };

  /**
   * Deletes given Apartment Media Image and Thumbnail from storage
   * @param index
   * @param mediaToDelete
   * @param apartmentId
   */
  const deleteApartmentImageHandler = async (index: number, mediaToDelete: IMedia, apartmentId: string) => {
    const newApartmentMedia: IMedia[] = [...media];
    newApartmentMedia.splice(index, 1);

    // create path to apartment media storage
    const deletePathPrefix = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', mediaToDelete.creatorId)
      .replace('{landlordId}', mediaToDelete.creatorId)}/${apartmentId}/`;

    const deleteOriginalPath = `${deletePathPrefix}${mediaToDelete.id}`;
    const deleteThumbnailPath = `${deletePathPrefix}thumbnails/${mediaToDelete.id}${process.env.REACT_APP_RESIZED_IMAGE_SUFFIX}`;
    // delete files from storage
    await storageService.deleteFiles([deleteOriginalPath, deleteThumbnailPath]);

    return apartmentService.updateApartmentMediaList(apartment, newApartmentMedia);
  };

  const handleDragEnd = async (event: DragEndEvent): Promise<void> => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = media.findIndex((f) => f.id === active.id);
      const newIndex = media.findIndex((f) => f.id === over?.id);
      return apartmentService.updateApartmentMediaList(apartment, arrayMove(media, oldIndex, newIndex));
    }
  };

  const lightShowHandler = (index: number) => {
    setLightShowIndex(index);
    setIsLightShowOpen(true);
  };

  return (
    !!apartment.media?.length && (
      <>
        <LightShow
          open={isLightShowOpen}
          onClose={() => setIsLightShowOpen(false)}
          media={apartment.media}
          openAtIndex={lightShowIndex}
        />
        <IonRow>
          {media && media.length ? (
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={media} strategy={rectSortingStrategy}>
                {media.map((mediaItem, index) => (
                  <IonCol style={{ padding: '5px' }} size={'6'} sizeMd={'3'} key={mediaItem.id}>
                    <ApartmentMediaItem
                      media={mediaItem}
                      imageIndex={index}
                      changeDescriptionHandler={changeApartmentMediaDescriptionHandler}
                      deleteImageHandler={deleteApartmentImageHandler}
                      previewHandler={lightShowHandler}
                    />
                  </IonCol>
                ))}
              </SortableContext>
            </DndContext>
          ) : (
            'keine Bilder'
          )}
        </IonRow>

        <SplitLine />

        {media && media.length && (
          <IonRow>
            <IonCol>
              <InfoBox textType={TEXT_TYPE.TEXT_TYPE_CAPTION} text={t('infoText')} />
            </IonCol>
          </IonRow>
        )}
      </>
    )
  );
};

export default ApartmentMediaList;
