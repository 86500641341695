import { useContext } from 'react';
import ModalWrapper from '../ModalWrapper';
import ModalContext from '../../../../core/context/modal.context';
import Text from '../../../atoms/typographie/Text';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import styles from './DeleteModal.module.scss';

const DeleteModal = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { t } = useTranslation('common');

  return (
    <ModalWrapper noHeadingMargin title={''}>
      <div className={styles.iconWrapper}>
        <img className={styles.image} data-testid={'ctaCard.image'} src={t('pictogram.delete')} alt={t('delete')} />
      </div>
      <Headline tag={'span'} size={HEADLINE_SIZE.H2}>
        {modalData?.title}
      </Headline>
      <Text tag={'p'}>{modalData?.text}</Text>

      <div className={styles.buttonWrapper}>
        <CTAButton
          buttonStyle={BUTTON_STYLE.SECONDARY}
          buttonText={t('cancel')}
          expand={'block'}
          onClick={() => closeModal()}
        />
        <CTAButton
          expand={'block'}
          buttonStyle={BUTTON_STYLE.DANGER}
          buttonText={t('delete')}
          onClick={() => modalData.deleteHandler()}
        />
      </div>
    </ModalWrapper>
  );
};

export default DeleteModal;
