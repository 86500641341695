import { useTranslation } from 'react-i18next';
import { faBuildings } from '@fortawesome/pro-light-svg-icons';
import { useContext, useEffect, useState } from 'react';
import styles from '../signIn/RegisterUserModal/RegisterUserModal.module.scss';
import { useForm } from 'react-hook-form';
import { TOrganizationCreate } from '../../../../core/service/organisation.service';
import ModalContext from '../../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import UserContext from '../../../../core/context/user.context';
import ModalWrapper from '../ModalWrapper';
import { FormContext } from '../../../../core/context/form.context';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import { slugify } from '../../../../core/helper/slugify-text';
import InputWithDebounce from '../../../atoms/formElement/InputWithDebounce';

const AddNewOrganizationModal = () => {
  const { organizationService, landlordProfile } = useContext(UserContext);
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', { keyPrefix: 'component.molecules.modals.AddNewOrganizationModal' });
  const { openModal } = useContext(ModalContext);
  const [slug, setSlug] = useState<string>('');

  const { control, handleSubmit, watch, setValue } = useForm<TOrganizationCreate>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { domain: landlordProfile.email.split('@')[1] },
  });
  const setSlugAndValue = (newSlugName: string) => {
    setSlug(newSlugName);
    setValue('slug', newSlugName);
  };
  const organizationTitle = watch('title');

  const formValue = watch();

  const validateSlugName = async (slugName: string, index = 0) => {
    const newSlugName: string = slugify(slugName) + (index > 0 ? `-${index + 1}` : '');

    const organization = await organizationService.getOrganizationBySlug(newSlugName);
    if (organization) {
      if (organization.id === landlordProfile.organizationId) {
        setSlugAndValue(newSlugName);
      } else {
        await validateSlugName(slugName, index + 1);
      }
    } else {
      setSlugAndValue(newSlugName);
    }
  };

  const onSubmit = (): void => {
    organizationService.addOrganization(
      {
        ...formValue,
        website: null,
        photoUrl: null,
        imprintText: null,
        imprintLink: null,
        dataProtectionText: null,
        dataProtectionLink: null,
      },
      landlordProfile.uid
    );

    return openModal({ id: MODAL_IDENTIFIER.EMAIL_VERIFICATION });
  };

  useEffect(() => {
    if (organizationTitle) {
      validateSlugName(organizationTitle);
    }
  }, [organizationTitle]);

  return (
    <ModalWrapper title={v('title')} subtitle={v('subTitle')} icon={faBuildings}>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(onSubmit, console.error)}>
          <InputWithDebounce
            debounceTimeout={300}
            label={t('organization.title.label')}
            name={'title'}
            required={true}
          ></InputWithDebounce>{' '}
          <input type="hidden" name={'domain'} />
          <input type={'hidden'} name={'slug'} />
          <div className={styles.buttonWrapper}>
            <CTAButton expand={'block'} buttonText={t('registerButtonText')} />
          </div>
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default AddNewOrganizationModal;
