import { useContext, useEffect, useState } from 'react';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import { ChatService } from '../service/chat.service';
import { IChatMessage } from '@wohnsinn/ws-ts-lib';
import ChatServiceContext from '../context/chat-service.context';

const useChat = (
  tenantId: string,
  landlordId: string,
  uid: string,
  apartmentId: string,
  editorList?: string[]
): {
  chatService: ChatService;
  chatMessageList: IChatMessage[];
  isLoadingMessages: boolean;
  isTyping: boolean;
  setIsTyping: (isTyping: boolean) => void;
} => {
  const [chatMessageList, setMessageList] = useState<IChatMessage[]>([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const { chatService } = useContext(ChatServiceContext);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (apartmentId && tenantId) {
      setIsLoadingMessages(true);
      const chatListRef = chatService.getChatMessageListRef(tenantId, apartmentId as string, landlordId as string);

      (async () => {
        unsubscribe = onSnapshot(
          chatListRef,
          (chatListSnap) => {
            setMessageList(chatListSnap.docs.map((doc) => doc.data() as IChatMessage));

            setIsLoadingMessages(false);
          },
          (err) => {
            setIsLoadingMessages(false);
            console.error('Error on useChatMessageListHook ', err);
          }
        );
      })();
    }
    return unsubscribe;
  }, [apartmentId, tenantId, landlordId]);

  return { chatService, chatMessageList, isLoadingMessages, isTyping, setIsTyping };
};

export default useChat;
