import TunnelSlide from '../../../SlideLayouts/TunnelSlideLayout';
import LOCAL_STORAGE_KEYS from '../../../../../../core/enum/local-storage.enum';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../../../core/context/user.context';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import { useForm } from 'react-hook-form';
import useToast from '../../../../../../core/hook/toast.hook';
import { FormContext } from '../../../../../../core/context/form.context';
import InputText from '../../../../../atoms/formElement/InputText';
import styles from '../EnterPhoneNumber/EnterPhoneNumber.module.scss';
import TwilioServiceContext from '../../../../../../core/context/twilio-service.context';
import LoadingAnimation from '../../../../../atoms/LoadingAnimation';
import { useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../../../core/const/routes';
import { useParams } from 'react-router';

const VerifyPhoneNumber = () => {
  const { user } = useContext(UserContext);
  const { twilioService } = useContext(TwilioServiceContext);
  const { t } = useTranslation('common');
  const [isLoadingVerifiedState, setIsLoadingVerifiedState] = useState(true);
  const { sendSuccessToast, sendErrorToast, sendInfoToast } = useToast();
  const [isVerifyingSMS, setIsVerifyingSMS] = useState<boolean>(false);
  const phoneNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.PHONE_NUMBER);
  const swiper = useSwiper();
  const { isActive } = useSwiperSlide();
  const router = useIonRouter();
  const { apartmentId } = useParams<{ apartmentId: string }>();

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({ mode: 'onSubmit', reValidateMode: 'onBlur' });

  const inputCode = watch('inputCode');

  useEffect(() => {
    if (isActive) {
      setIsLoadingVerifiedState(true);
      if (user?.isPhoneNumberVerified) {
        swiper.slideTo(3);
      } else {
        swiper.allowSlideNext = false;
      }
      setIsLoadingVerifiedState(false);
    }
  }, [isActive, swiper, setIsLoadingVerifiedState, user]);

  const sendVerificationCode = async (): Promise<void> => {
    setIsVerifyingSMS(true);
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.PHONE_NUMBER)) {
      return twilioService
        .requestVerificationCode(phoneNumber)
        .then(async () => {
          await sendSuccessToast('Es wurde ein Verifizierungscode an ihre Mobilrufnummer gesendet.');
          setIsVerifyingSMS(false);
        })
        .catch(async (err) => {
          console.error(err);
          await sendErrorToast('Das hat leider nicht geklappt');
          setIsVerifyingSMS(false);
        });
    } else {
      await sendInfoToast('Bitte Mobilrufnummer eingeben');
      setIsVerifyingSMS(false);
    }
  };

  const verifyCode = async (): Promise<void> => {
    setIsVerifyingSMS(true);
    if (!Object.keys(errors).length && phoneNumber) {
      await twilioService
        .verifyVerificationCode(phoneNumber, inputCode)
        .then(async () => {
          await sendSuccessToast('Sie haben sich erfolgreich verifiziert!');
          setIsVerifyingSMS(false);
          router.push(t(ROUTES.landlord.apartment.overview.path).replace(':apartmentId', apartmentId));
        })
        .catch(async (err) => {
          console.error(err);
          await sendErrorToast('Das hat leider nicht geklappt');
          setIsVerifyingSMS(false);
        });
    }
  };

  return (
    <FormContext.Provider
      value={{
        control,
      }}
    >
      <form onSubmit={handleSubmit(verifyCode)}>
        <TunnelSlide
          title={'Verifizierungscode eingeben'}
          allowSlideNext={inputCode?.length > 0 && !isVerifyingSMS}
          isFormSubmit
          customCTALabel={'Verifizieren'}
        >
          {isVerifyingSMS ? (
            <LoadingAnimation />
          ) : isLoadingVerifiedState ? (
            'loading'
          ) : (
            <>
              <InputText label={'Verifizierungscode'} name={'inputCode'} />
              <div className={styles.resendVerification}>
                <button type={'button'} onClick={sendVerificationCode}>
                  Code erneut senden
                </button>
              </div>
            </>
          )}
        </TunnelSlide>
      </form>
    </FormContext.Provider>
  );
};

export default VerifyPhoneNumber;
