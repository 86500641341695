import { FC } from 'react';
import LinkElement from '../../../atoms/LinkElement';
import { useTranslation } from 'react-i18next';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const DocumentUploadButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('common');
  return (
    <LinkElement text={t('component.molecules.DocumentUploadDropZone.addDocument')} onClick={onClick} icon={faPlus} />
  );
};

export default DocumentUploadButton;
