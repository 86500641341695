import { FC, useContext } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import styles from './DownloadItemsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import StorageServiceContext from '../../../core/context/storage-service.context';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../atoms/typographie/Text';

const DownloadItemsList: FC<{ list: IUpload[]; label: string }> = ({ list, label }) => {
  const { storageService } = useContext(StorageServiceContext);
  const downloadDocument = (upload: IUpload) => {
    const windowReference = window.open();
    storageService.getDownloadUrl(`${upload.uploadPath}`).then((href) => {
      windowReference.location = href;
    });
  };

  return (
    <div className={styles.wrapper}>
      {list.map((upload, i) => (
        <IonItem key={`incomeProof-${i}`} className={styles.item} onClick={() => downloadDocument(upload)}>
          <IonLabel className={styles.label}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {`${label} Nr. ${i + 1}`}
            </Text>
          </IonLabel>
          <span className={styles.download} slot={'end'}>
            <FontAwesomeIcon icon={faDownload} />
          </span>
        </IonItem>
      ))}
    </div>
  );
};

export default DownloadItemsList;
