import { createContext } from 'react';
import TwilioService from '../service/twilio.service';

export interface ITwilioServiceContextProps {
  twilioService: TwilioService;
}

const TwilioServiceContext = createContext<ITwilioServiceContextProps>({
  twilioService: null,
});

export default TwilioServiceContext;
