import { useContext, useEffect, useState } from 'react';
import ApplicationServiceContext from '../context/application-service.context';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import { IApplication } from '@wohnsinn/ws-ts-lib';

const useApplication = (
  apartmentId: string,
  tenantId: string,
  landlordId: string
): { applicationList: IApplication[]; loadingApplication: boolean } => {
  const { applicationService } = useContext(ApplicationServiceContext);
  const [loadingApplication, setLoadingApplication] = useState(true);
  const [applicationList, setApplicationList] = useState<IApplication[]>([]);

  useEffect(() => {
    const applicationRef = applicationService.getLandlordApplicationListRef({ apartmentId, tenantId, landlordId });
    let unsubscribe: Unsubscribe;
    (async () => {
      unsubscribe = onSnapshot(
        applicationRef,
        (applicationSnap) => {
          setApplicationList(applicationSnap.docs.map((doc) => doc.data() as IApplication));
          setLoadingApplication(false);
        },
        (err) => {
          setLoadingApplication(false);
          console.error('Error on useChatMessageListHook ', err);
        }
      );
    })();

    return unsubscribe;
  }, [apartmentId, tenantId, landlordId]);

  return { applicationList, loadingApplication };
};

export default useApplication;
