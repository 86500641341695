import PageLayout from '../../../component/organisms/PageLayout';
import { ROUTES } from '../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import CTACard from '../../../component/molecules/Cards/CTACard';
import { IonCol, IonRow } from '@ionic/react';

const MaintenanceModeView = () => {
  const { t } = useTranslation('common');

  return (
    <PageLayout hideMenu pageTitle={t(ROUTES.general.maintenance.title)}>
      <IonRow>
        <IonCol style={{ marginTop: 100 }}>
          <CTACard
            title={'Wartungsmodus'}
            imgAltText={'wartungsmodus'}
            imgSrc={'/assets/images/redirect/basketball.gif'}
            text={'Wir befinden uns gerade im Wartungsmodus, sind aber bald wieder für euch da!'}
          />
        </IonCol>
      </IonRow>
    </PageLayout>
  );
};

export default MaintenanceModeView;
