import { FC, useContext, useEffect, useState } from 'react';
import { IApartment, ILandlordProfile, IOrganization } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentInformation.module.scss';
import ApartmentInformationLocation from '../../atoms/ApartmentInformationLocation/';
import { useTranslation } from 'react-i18next';
import ApartmentBasics from '../../molecules/ApartmentBasics';
import ApartmentEquipment from '../../molecules/ApartmentEquipment';
import ApartmentCosts, { IApartmentCostsProps } from '../../molecules/ApartmentCosts';
import VirtualTour from '../../atoms/VirtualTour';
import Tag from '../../atoms/Tag';
import CTAButton from '../../atoms/Buttons/CTAButton';
import UserContext from '../../../core/context/user.context';
import LandlordBusinessCard from '../../molecules/LandlordBusinessCard';
import ApartmentEnergySupply from '../../molecules/ApartmentEnergySupply';
import useWindowSize from '../../../core/hook/windowsize.hook';
import ModalContext from '../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import LightShow from '../../molecules/LightShow';
import SanitizedHTML from '../../atoms/SanitzedHtml';
import Text, { TEXT_COLOR, TEXT_VARIANT, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import Badge, { BADGE_COLORS } from '../../atoms/Badge';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import ApartmentRent from '../../molecules/ApartmentRent';
import sanitize from 'sanitize-html';
import ImageGallery from '../../molecules/ImageGallery';

export interface IApartmentInformationProps {
  apartment: IApartment;
  backRoute?: string;
}

const ApartmentInformation: FC<IApartmentInformationProps> = ({ apartment }) => {
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'component.organisms.ApartmentInformation' });
  const { user } = useContext(UserContext);
  const [mapIsOpen, setMapIsOpen] = useState<boolean>(false);
  const [landlordCardInformation, setLandlordCardInformation] =
    useState<{ landlordProfile: ILandlordProfile; organization: IOrganization }>(null);
  const { userService, organizationService } = useContext(UserContext);
  const { isGreaterMd } = useWindowSize();
  const { openModal } = useContext(ModalContext);
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [slideShowIndex, setSlideShowIndex] = useState<number>(0);
  const customEquipmentDescription = apartment?.equipment?.description?.html;
  const customEquipmentDescriptionLength = sanitize(apartment?.equipment?.description?.html, {
    allowedTags: [],
  })?.length;
  const customEnergyDescription = apartment?.energySupply?.description?.html;
  const apartmentIsUnpublished = !apartment?.isPublished && !apartment?.editorList?.includes(user?.uid);

  // Add additional costs to apartment costs
  let costs: IApartmentCostsProps = {};

  if (apartment?.cost && Object.keys(apartment.cost)?.length) {
    costs = { ...costs, apartmentCosts: apartment.cost };
  }

  if (apartment.equipment?.kitchenPrice) {
    costs.kitchenPrice = apartment.equipment.kitchenPrice;
  }

  if (apartment.equipment?.parkingSpotRent) {
    costs.parkingSpotRent = apartment.equipment.parkingSpotRent;
  }

  const fetchLandlordProfile = async (): Promise<void> => {
    const landlordProfile: ILandlordProfile | void = await userService.getLandlordProfile(
      apartment?.contactPersons?.length ? apartment?.contactPersons[0] : apartment.creatorId
    );
    // TODO: add if ready
    // Note: Tenants will not get the landlordProfile
    if (landlordProfile) {
      const organization = await organizationService.getOrganizationById(landlordProfile.organizationId);
      setLandlordCardInformation({ landlordProfile, organization });
    }
  };

  useEffect(() => {
    if (mapIsOpen && isGreaterMd) {
      setMapIsOpen(false);
    }
  }, [isGreaterMd]);

  useEffect(() => {
    fetchLandlordProfile();
  }, [apartment]);

  const handleGalleryClick = (index: number) => {
    setSlideShowIndex(index);
    setIsLightShowOpen(true);
  };

  return (
    <div
      key={apartment.id}
      className={`${styles.informationWrapper} ${apartmentIsUnpublished ? styles.isUnpublished : ''}`}
    >
      <div className={styles.apartmentCard}>
        {apartmentIsUnpublished && (
          <div className={styles.disabledTagWrapper}>
            <Badge text={'Deaktiviert'} color={BADGE_COLORS.DISABLED} />
          </div>
        )}
        {apartment.media && apartment.media.length ? (
          <LightShow
            media={apartment.media}
            openAtIndex={slideShowIndex}
            open={isLightShowOpen}
            onClose={() => setIsLightShowOpen(false)}
          />
        ) : null}
        <div className={styles.apartmentImage}>
          {apartment.media && apartment.media.length ? (
            <ImageGallery
              id={apartment.id}
              slideShowIndex={slideShowIndex}
              setSlideShowIndex={setSlideShowIndex}
              handleSlideTap={handleGalleryClick}
              media={apartment.media}
            />
          ) : (
            <div style={{ backgroundImage: `url(${t('apartmentPlaceholder')})` }} className={styles.placeHolder} />
          )}
        </div>
        <ApartmentInformationLocation apartment={apartment} />
      </div>
      <div className={styles.informationSection} style={{ marginTop: 16 }}>
        <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
          {t('basics')}
        </Headline>
        <ApartmentBasics apartment={apartment} />
      </div>
      {customEquipmentDescription || apartment.equipment?.equipmentList?.length ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('equipment')}
          </Headline>
          {apartment.equipment?.equipmentList?.length ? (
            <ApartmentEquipment equipmentList={apartment.equipment.equipmentList} />
          ) : null}
          {customEquipmentDescriptionLength ? (
            <button
              onClick={() =>
                openModal({
                  id: MODAL_IDENTIFIER.INFO_TEXT,
                  data: {
                    title: t('apartment.equipment.description.label'),
                    sanitizeText: customEquipmentDescription,
                  },
                })
              }
            >
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                {`${t('showMore')} >`}
              </Text>
            </button>
          ) : null}
        </div>
      ) : null}
      {apartment.matterportId ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('3dTour')}
          </Headline>
          <button onClick={() => openModal({ id: MODAL_IDENTIFIER.VIRTUAL_TOUR, data: apartment.matterportId })}>
            <div style={{ pointerEvents: 'none', borderRadius: '10px', overflow: 'hidden' }}>
              <VirtualTour matterportId={apartment.matterportId} />
            </div>
          </button>
        </div>
      ) : null}
      {costs && Object.keys(costs)?.length ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('apartment.cost.title')}
          </Headline>
          <ApartmentCosts {...costs} />
        </div>
      ) : null}

      <div className={styles.informationSection}>
        <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
          {t('rent')}
        </Headline>
        <ApartmentRent mainInfo={apartment.mainInformation} aptRentType={apartment.cost?.rentType} />
      </div>

      <div className={styles.informationSection}>
        <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
          {t('location')}
        </Headline>
        <div>
          <Tag
            tag={{
              key: 'location',
              label: `📍${
                apartment.mainInformation?.address?.hasPublishedAddress
                  ? apartment.mainInformation?.address?.street
                  : ''
              } ${
                apartment.mainInformation?.address?.hasPublishedAddress
                  ? apartment.mainInformation?.address?.houseNumber
                  : ''
              } ${apartment.mainInformation?.address?.hasPublishedAddress ? ', ' : ''}${
                apartment.mainInformation?.address?.postalCode
              } ${apartment.mainInformation?.address?.city}`,
            }}
          />
        </div>
        <div className={styles.mapPreview}>
          <button
            className={styles.openMapButton}
            onClick={() => openModal({ id: MODAL_IDENTIFIER.MAP_MODAL, data: apartment })}
          >
            <CTAButton buttonText={a('openCard')} />
          </button>

          <div className={styles.mapPlaceHolder} />
        </div>
      </div>
      {apartment.energySupply && Object.keys(apartment.energySupply)?.length ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('buildingAndLocation')}
          </Headline>
          <ApartmentEnergySupply energySupply={apartment.energySupply} />
          {customEnergyDescription && (
            <>
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
                {t('info')}
              </Text>
              <SanitizedHTML dirty={customEnergyDescription} />
            </>
          )}
        </div>
      ) : null}
      {landlordCardInformation ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('provider')} <Tag tag={{ key: 'verified', label: `✅ ${t('verified')}` }} />
          </Headline>
          <LandlordBusinessCard
            organization={landlordCardInformation.organization}
            landlordProfile={landlordCardInformation.landlordProfile}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ApartmentInformation;
