import { useTranslation } from 'react-i18next';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import Breadcrumbs from '../../../../component/molecules/Breadcrumbs';
import { IonBreadcrumb } from '@ionic/react';
import PageTitle from '../../../../component/molecules/PageTitle';

const NotificationSettings = () => {
  const { t } = useTranslation('common');
  return (
    <PageLayout pageTitle={t(ROUTES.tenant.account.notifications.title)} showBackButton>
      <Breadcrumbs backRoute={t(ROUTES.tenant.account.overview.path)}>
        <IonBreadcrumb routerLink={t(ROUTES.tenant.account.router)}>{t(ROUTES.tenant.account.title)}</IonBreadcrumb>
        <IonBreadcrumb routerLink={t(ROUTES.tenant.account.notifications.path)}>
          {t(ROUTES.tenant.account.notifications.title)}
        </IonBreadcrumb>
      </Breadcrumbs>
      <PageTitle pageTitle={t(ROUTES.tenant.account.notifications.title)} showSubmitButton={false} />
      Benachrichtigungseinstellungen
    </PageLayout>
  );
};

export default NotificationSettings;
