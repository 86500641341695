import { IonRouterLink, useIonRouter } from '@ionic/react';
import styles from './BackButtonStyles.module.scss';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

const BackButton: FC<{ backRoute?: string }> = ({ backRoute }) => {
  const router = useIonRouter();
  return (
    <IonRouterLink
      onClick={backRoute ? () => router.push(backRoute) : () => history.back()}
      className={styles.backButton}
    >
      <FontAwesomeIcon icon={faChevronLeft} size={'1x'} />
    </IonRouterLink>
  );
};
export default BackButton;
