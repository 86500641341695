import PageLayout from '../../../../component/organisms/PageLayout';
import Section from '../../../../component/atoms/Section';
import { IonCol } from '@ionic/react';
import CTACard from '../../../../component/molecules/Cards/CTACard';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../core/const/routes';
import UserContext from '../../../../core/context/user.context';
import { useContext } from 'react';
import { FormContext } from '../../../../core/context/form.context';
import InputText from '../../../../component/atoms/formElement/InputText';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { PHONE_SCHEMA } from '@wohnsinn/ws-ts-lib';
import Joi from 'joi';
import CTAButton from '../../../../component/atoms/Buttons/CTAButton';
import ContactCTACard from '../../../../component/molecules/Cards/ContactCTACard';

const ActivateAccountView = () => {
  const { t } = useTranslation('common', { keyPrefix: 'view.ActivateAccountView' });
  const { t: c } = useTranslation('common');
  const { landlordProfile, authService, userService } = useContext(UserContext);

  const { control, handleSubmit, watch } = useForm<{
    phone: string;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object({ phone: PHONE_SCHEMA })),
  });

  const submitSuccess = async () => {
    await userService.updateLandlordPersonalInformation(landlordProfile.uid, {
      ...landlordProfile.personalInformation,
      phoneNumber: watch('phone'),
    });

    await authService.triggerLandlordActivationRequestMail(landlordProfile.email);
  };

  return (
    <PageLayout hideTabBar={false} pageTitle={c(ROUTES.landlord.account.activateAccount.title)}>
      <Section center>
        <IonCol size={'12'} sizeMd={'6'}>
          {landlordProfile?.personalInformation?.phoneNumber ? (
            <CTACard
              title={t('activationRequest.title')}
              text={t('activationRequest.text')}
              imgSrc={c('pictogram.object')}
              imgAltText={'activation-request'}
            />
          ) : (
            <FormContext.Provider value={{ control }}>
              <form onSubmit={handleSubmit(submitSuccess, console.error)}>
                <CTACard
                  title={t('missingPhone.title')}
                  text={t('missingPhone.text')}
                  imgSrc={c('pictogram.mail')}
                  imgAltText={'activation-request-missing-phone-number'}
                />
                <InputText label={c('phoneNumber.label')} name={'phone'} type={'tel'} />
                <CTAButton expand={'block'} buttonText={'Speichern'} />
              </form>
            </FormContext.Provider>
          )}
          <br />
          <ContactCTACard />
        </IonCol>
      </Section>
    </PageLayout>
  );
};

export default ActivateAccountView;
