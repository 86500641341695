import { FC } from 'react';
import styles from './CheckMark.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const CheckMark: FC<{ isChecked: boolean; onClick: () => any }> = ({ isChecked, onClick }) => {
  return (
    <button className={`${styles.wrapper} ${isChecked ? styles.isChecked : ''}`} onClick={onClick}>
      <FontAwesomeIcon icon={faCheck} />
      <span className="sr-only">{isChecked ? 'selected' : 'not selected'}</span>
    </button>
  );
};

export default CheckMark;
