import { useContext, useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { FILE_UPLOAD_STATUS, IUpload } from '@wohnsinn/ws-ts-lib';
import UserContext from '../context/user.context';

const useTenantDocuments = (
  tenantProfileUid?: string
): {
  incomeProofDocumentList: IUpload[];
  schufaCheckDocumentList: IUpload[];
  hasIncomeProofDocument: boolean;
  hasSchufaCheckDocument: boolean;
} => {
  const [schufaCheckDocumentList, setSchufaCheckList] = useState<IUpload[]>([]);
  const [incomeProofDocumentList, setIncomeProofDocumentList] = useState<IUpload[]>([]);
  const [hasIncomeProofDocument, setHasIncomeProofDocument] = useState<boolean>(false);
  const [hasSchufaCheckDocument, setHasSchufaCheckDocument] = useState<boolean>(false);
  const { userService } = useContext(UserContext);

  useEffect(() => {
    if (tenantProfileUid) {
      onSnapshot(userService.getSchufaCheckDocumentListRef(tenantProfileUid), (colSnap) => {
        setHasSchufaCheckDocument(colSnap.docs.length > 0);
        setSchufaCheckList(
          colSnap.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              status: FILE_UPLOAD_STATUS.DONE,
              errors: [],
              uploadTask: null,
              storageRef: null,
            };
          })
        );
      });

      onSnapshot(userService.getIncomeProofDocumentListRef(tenantProfileUid), (colSnap) => {
        setHasIncomeProofDocument(colSnap.docs.length > 0);
        setIncomeProofDocumentList(
          colSnap.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              status: FILE_UPLOAD_STATUS.DONE,
              errors: [],
              url: '',
              uploadTask: null,
              storageRef: null,
            };
          })
        );
      });
    }
  }, [tenantProfileUid]);
  return {
    incomeProofDocumentList,
    schufaCheckDocumentList,
    hasIncomeProofDocument,
    hasSchufaCheckDocument,
  };
};
export default useTenantDocuments;
