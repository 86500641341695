import {
  Auth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  UserCredential,
} from 'firebase/auth';
import UserService from './user.service';
import { getApps, initializeApp } from 'firebase/app';
import DatabaseService from './database.service';
import { isPlatform, UseIonRouterResult } from '@ionic/react';
import FIREBASE_OPTIONS from '../../firebase.config';
import { TFunction } from 'i18next';
import { ROUTES } from '../const/routes';
import { FirebaseFunctionsService } from './firebase-functions-service';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import { HttpsCallableResult } from 'firebase/functions';
import OrganizationService from './organisation.service';
import { IOrganization, SIGN_IN_METHOD, USER_TYPE } from '@wohnsinn/ws-ts-lib';

class AuthService {
  public readonly auth: Auth;
  public readonly MICROSOFT_OAUTH_PROVIDER: OAuthProvider;
  public readonly APPLE_OAUTH_PROVIDER: OAuthProvider;
  public readonly GOOGLE_OAUTH_PROVIDER: GoogleAuthProvider;
  private databaseService: DatabaseService;
  private firebaseFunctionsService: FirebaseFunctionsService;
  private organisationService: OrganizationService;

  constructor(private userService: UserService) {
    this.databaseService = new DatabaseService();
    this.organisationService = new OrganizationService(this.databaseService, this.userService);
    this.firebaseFunctionsService = new FirebaseFunctionsService();
    this.MICROSOFT_OAUTH_PROVIDER = new OAuthProvider('microsoft.com');
    this.APPLE_OAUTH_PROVIDER = new OAuthProvider('apple.com');
    this.GOOGLE_OAUTH_PROVIDER = new GoogleAuthProvider();

    if (getApps().length === 0 && !isPlatform('mobile')) {
      this.auth = getAuth(initializeApp(FIREBASE_OPTIONS));
    } else {
      this.auth = getAuth(getApps()[0]);
    }

    if (process.env.REACT_APP_USE_EMULATOR === 'true' && process.env.REACT_APP_ENVIRONMENT === 'qas') {
      connectAuthEmulator(this.auth, `http://${process.env.REACT_APP_EMULATOR_HOST}:9099`);
    }
  }

  public async triggerSendVerificationLink(email: string, userType: USER_TYPE): Promise<HttpsCallableResult | void> {
    try {
      await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.sendEmailVerificationMail, {
        body: { email, userType },
      });
    } catch (e) {
      console.error('Error on triggerSendVerificationLink', e);
    }
  }

  public authProviderLogin(signInMethod: SIGN_IN_METHOD): Promise<UserCredential> {
    switch (signInMethod) {
      case SIGN_IN_METHOD.MICROSOFT:
        return signInWithPopup(this.auth, this.MICROSOFT_OAUTH_PROVIDER);
      case SIGN_IN_METHOD.GOOGLE:
        return signInWithPopup(this.auth, this.GOOGLE_OAUTH_PROVIDER);
      case SIGN_IN_METHOD.APPLE:
        return signInWithPopup(this.auth, this.APPLE_OAUTH_PROVIDER);
      default:
        return;
    }
  }

  public emailAndPasswordLogin(email: string, password: string): Promise<UserCredential | void> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public triggerPasswordResetMail = async (email: string) => {
    return this.firebaseFunctionsService
      .callFbFunction(FB_FUNCTION_URLS.user.sendPasswordResetMail, {
        email,
      })
      .catch(console.error);
  };

  public async signOut(router: UseIonRouterResult, translate: TFunction): Promise<void> {
    return signOut(getAuth(getApps()[0])).then(() => {
      router.push(translate(ROUTES.tenant.matching.list.path));
      localStorage.clear();
      return;
    });
  }

  public async deleteUser(router: UseIonRouterResult, translate: TFunction): Promise<void> {
    await this.auth.currentUser
      .delete()
      .then(() => {
        this.signOut(router, translate);
      })
      .catch(console.error);
  }

  /**
   * Fetches the sign in method for an email
   * @param email
   * @return {null} - If this email is not registered
   * @return {string} - Returns the name of the sign-in-method for this email
   */
  public async fetchSignInMethodsForEmail(email: string): Promise<string | null> {
    const signInMethod = await fetchSignInMethodsForEmail(this.auth, email);

    if (!signInMethod.length) {
      return null;
    }

    return signInMethod[0];
  }

  /**
   * Create a user with a tenant/landlord/organization profile for users registered with email and password
   * @param email
   * @param password
   * @param userType
   * @param organization
   */
  public async handleUserRegistrationWithEmailAndPassword(
    email: string,
    password: string,
    userType: USER_TYPE,
    organization?: IOrganization
  ): Promise<void> {
    try {
      // CREATE A USER WITH EMAIL AND PASSWORD IN OUT FIREBASE
      const userCredentials = await createUserWithEmailAndPassword(this.auth, email, password);

      // CREATE USER DOCUMENT WITH LANDLORD/TENANT/ORGANIZATION PROFILE AND ROLES
      await this.userService.createUserWithProfileAndRoles(userCredentials.user, userType, organization);

      if (userType === USER_TYPE.LANDLORD) {
        await this.triggerLandlordActivationRequestMail(userCredentials.user.email);
      }

      // SEND EMAIL-VERFICATION LINK
      await this.triggerSendVerificationLink(email, userType);
    } catch (error) {
      console.error('Error at handleUserRegistration: ', error);
    }
  }

  /**
   * Create a user with a tenant/landlord/organization profile for users registered with an auth provider
   * @param userCredentials
   * @param userType
   * @param organization
   */
  public async handleUserRegistrationWithAuthProvider(
    userCredentials: UserCredential,
    userType: USER_TYPE,
    organization?: IOrganization
  ): Promise<any> {
    try {
      // CREATE USER DOCUMENT WITH LANDLORD/TENANT/ORGANIZATION PROFILE AND ROLES
      const user = await this.userService.createUserWithProfileAndRoles(userCredentials.user, userType, organization);

      if (userType === USER_TYPE.LANDLORD) {
        await this.triggerLandlordActivationRequestMail(userCredentials.user.email);
      }
      // SEND EMAIL-VERFICATION LINK
      await this.triggerSendVerificationLink(user.email, userType);
    } catch (error) {
      console.error('Error at handleUserRegistration: ', error);
    }
  }

  public async triggerLandlordActivationRequestMail(email: string): Promise<HttpsCallableResult | void> {
    try {
      await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.sendLandlordActivationRequestMail, {
        body: { email },
      });
    } catch (e) {
      console.error('Error on triggerLandlordActivationRequestMail', e);
    }
  }
}

export default AuthService;
