import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import styles from './StepAddOrganization.module.scss';
import { useSwiper } from 'swiper/react';
import { useIonRouter } from '@ionic/react';
import UserContext from '../../../../../../core/context/user.context';
import { SUBMIT_BUTTON_MODE } from '../../../../../../core/enum/submit-button-mode.enum';
import { TOrganizationCreate } from '../../../../../../core/service/organisation.service';
import { FormContext } from '../../../../../../core/context/form.context';
import TextInput from '../../../../../atoms/formElement/InputText';
import CTAButton, { getSubmitIcon } from '../../../../../atoms/Buttons/CTAButton';
import { slugify } from '../../../../../../core/helper/slugify-text';
import InputWithDebounce from '../../../../../atoms/formElement/InputWithDebounce';
import SwiperTunnelStepsWrapper from '../../../Layout/SwiperTunnelStepsWrapper';
import SwiperTunnelTitle from '../../../Layout/SwiperTunnelTitle';
import { ROUTES } from '../../../../../../core/const/routes';

const StepAddOrganiztion: FC<{ setAllowRedirect: Dispatch<SetStateAction<boolean>> }> = ({ setAllowRedirect }) => {
  const swiper = useSwiper();
  const { organizationService, landlordProfile } = useContext(UserContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const [slug, setSlug] = useState<string>('');
  const { control, handleSubmit, watch, setValue } = useForm<TOrganizationCreate>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const router = useIonRouter();
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.RegisterLandlordSteps.StepAddOrganization',
  });
  const { t: o } = useTranslation('common', {
    keyPrefix: 'organization',
  });

  const formValue = watch();

  const organizationTitle = watch('title');

  useEffect(() => {
    if (organizationTitle) {
      validateSlugName(organizationTitle);
    }
  }, [organizationTitle]);

  const validateSlugName = async (slugName: string, index = 0) => {
    const newSlugName: string = slugify(slugName) + (index > 0 ? `-${index + 1}` : '');

    const organization = await organizationService.getOrganizationBySlug(newSlugName);
    if (organization) {
      if (organization.id === landlordProfile.organizationId) {
        setSlugAndValue(newSlugName);
      } else {
        await validateSlugName(slugName, index + 1);
      }
    } else {
      setSlugAndValue(newSlugName);
    }
  };

  const setSlugAndValue = (newSlugName: string) => {
    setSlug(newSlugName);
    setValue('slug', newSlugName);
  };

  const handleFormSubmit = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    await organizationService.addOrganization(
      {
        ...formValue,
        website: null,
        photoUrl: null,
        imprintText: null,
        imprintLink: null,
        dataProtectionText: null,
        dataProtectionLink: null,
      },
      landlordProfile.uid
    );

    setAllowRedirect(true);
    router.push(t(ROUTES.landlord.account.activateAccount.path));
  };

  const handleFormError = (errors: FieldErrors) => {
    console.error(errors);
  };

  return (
    <SwiperTunnelStepsWrapper>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
          <div className={styles.wrapper}>
            <SwiperTunnelTitle image={c('pictogram.object')} title={t('formTitle')} subtitle={t('formSubTitle')} />
          </div>
          <InputWithDebounce
            tabIndex={1}
            debounceTimeout={300}
            label={c('organization.title.label')}
            name={'title'}
            required={true}
          />
          <input type="hidden" name={'domain'} />
          <input type="hidden" name={'slug'} />
          <TextInput label={o('website.label')} name={'website'} type={'text'} />

          <button className={'sr-only'}>submit</button>
          <CTAButton
            disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            icon={getSubmitIcon(buttonSubmitMode)}
            spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            expand={'block'}
            buttonText={c('finish')}
          />
        </form>
      </FormContext.Provider>
    </SwiperTunnelStepsWrapper>
  );
};

export default StepAddOrganiztion;
