import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, KEY_ENTER_COMMAND, KEY_TAB_COMMAND } from 'lexical';
import { FC, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

const SubmitOnEnterKlickPlugin: FC<{ onSubmit: () => void }> = ({ onSubmit }): null => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (isPlatform('desktop')) {
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (event: KeyboardEvent | null) => {
          // skipping if shift is pressed (defaulting to line-break)
          if (event !== null && !event.shiftKey) {
            event.preventDefault();
            onSubmit();
            return true;
          }
        },
        COMMAND_PRIORITY_LOW
      );

      editor.registerCommand(
        KEY_TAB_COMMAND,
        (event: KeyboardEvent | null) => {
          if (event !== null) {
            return true;
          }
        },
        COMMAND_PRIORITY_LOW
      );
    }
  }, [editor]);

  return null;
};

export default SubmitOnEnterKlickPlugin;
