import { FC } from 'react';
export interface IVirtualTourProps {
  matterportId: string;
  playInFrame?: boolean;
}

const VirtualTour: FC<IVirtualTourProps> = ({ matterportId, playInFrame = false }) => {
  return (
    <iframe
      data-testid={'virtualTour'}
      id="showcase"
      width="100%"
      height="100%"
      src={`https://my.matterport.com/show/?m=${matterportId}${playInFrame ? '&play=1' : ''}`}
      allowFullScreen
      allow="vr"
      title="Space"
      className="space_space__mhBYE"
    />
  );
};

export default VirtualTour;
