import { FC, PropsWithChildren } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import AppHeader from '../../../molecules/AppHeader';
import styles from './SwiperLayoutView.module.scss';
const SwiperViewLayout: FC<PropsWithChildren<{ centerVertical?: boolean }>> = ({ children, centerVertical = true }) => {
  return (
    <IonPage>
      <IonContent>
        <div className={styles.wrapper}>
          <AppHeader menu={null} />
          <div className={`${styles.swiperWrapper} ${centerVertical ? styles.centerVertical : ''}`}>{children}</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SwiperViewLayout;
