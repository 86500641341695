import { FC, PropsWithChildren } from 'react';
import { IonRow } from '@ionic/react';
import styles from './Section.module.scss';

export interface ISectionProps {
  backgroundColor?: 'grey' | 'white';
  center?: boolean;
  reducePadding?: boolean;
}

const Section: FC<PropsWithChildren<ISectionProps>> = ({
  children,
  backgroundColor = 'grey',
  center = false,
  reducePadding = false,
}) => {
  return (
    <IonRow
      className={`${styles.section} ${reducePadding ? styles.reducePadding : ''} ${styles[backgroundColor]} ${
        center ? 'ion-justify-content-center' : ''
      }`}
    >
      {children}
    </IonRow>
  );
};

export default Section;
