import AddNewOrganizationModal from './AddNewOrganizationModal';
import CompleteApplicationFolderModal from './CompleteApplicationFolderModal';
import ConfirmApplicationModal from './ConfirmApplicationModal';
import ConfirmEmailModal from './signIn/ConfirmEmailModal';
import ContactModal from './ContactModal';
import CreateOrUploadSchufaModal from './CreateOrUploadSchufaModal';
import CreateUserInvitationModal from './CreateUserInvitationModal';
import CropImageModal from './CropImageModal';
import DeleteModal from './DeleteModal';
import EmailVerificationModal from './signIn/EmailVerificationModal';
import FaqModal from './FaqModal';
import FilterLocationModal from './filter/FilterLocationModal';
import FilterModal from './filter/FilterModal';
import FilterRoomsModal from './filter/FilterRoomsModal';
import HowToSlidesModal from './HowToSlidesModal';
import InfoTextModal from './InfoTextModal';
import MapModal from './MapModal';
import MissingApplicationFolderModal from './MissingApplicationFolderModal';
import ModalContext from '../../../core/context/modal.context';
import NotificationsModal from './NotificationsModal';
import PublishApartmentModal from './PublishApartmentModal';
import RegisterUserModal from './signIn/RegisterUserModal';
import SettingsModal from './SettingsModal';
import SignInModal from './signIn/SignInModal';
import VirtualTourModal from './VirtualTourModal';
import YouveBeenInvitedModal from './YouveBeenInvitedModal';
import styles from './ModalLoader.module.scss';
import { IonModal } from '@ionic/react';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import { useContext } from 'react';
import FilterPersonsModal from './filter/FilterPersonsModal';
import FilterRentModal from './filter/FilterRentModal';
import FilterResetModal from './filter/FilterResetModal';

const ModalLoader = () => {
  const { currentModalId, closeModal, onDismiss, allowBackdropDismiss } = useContext(ModalContext);

  const renderModal = () => {
    switch (currentModalId) {
      case MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER:
        return <CompleteApplicationFolderModal />;
      case MODAL_IDENTIFIER.ADD_NEW_ORGANIZATION:
        return <AddNewOrganizationModal />;
      case MODAL_IDENTIFIER.MISSING_APPLICATION_FOLDER:
        return <MissingApplicationFolderModal />;
      case MODAL_IDENTIFIER.CONFIRM_APPLICATION:
        return <ConfirmApplicationModal />;
      case MODAL_IDENTIFIER.CONFIRM_EMAIL:
        return <ConfirmEmailModal />;
      case MODAL_IDENTIFIER.EMAIL_VERIFICATION:
        return <EmailVerificationModal />;
      case MODAL_IDENTIFIER.CONTACT_MODAL:
        return <ContactModal />;
      case MODAL_IDENTIFIER.CREATE_OR_UPLOAD_SCHUFA:
        return <CreateOrUploadSchufaModal />;
      case MODAL_IDENTIFIER.CROP_IMAGE:
        return <CropImageModal />;
      case MODAL_IDENTIFIER.DELETE_MODAL:
        return <DeleteModal />;
      case MODAL_IDENTIFIER.FAQ:
        return <FaqModal />;
      case MODAL_IDENTIFIER.FILTER_LOCATION:
        return <FilterLocationModal />;
      case MODAL_IDENTIFIER.FILTER_MODAL:
        return <FilterModal />;
      case MODAL_IDENTIFIER.FILTER_PERSONS:
        return <FilterPersonsModal />;
      case MODAL_IDENTIFIER.FILTER_RESET:
        return <FilterResetModal />;
      case MODAL_IDENTIFIER.FILTER_ROOMS:
        return <FilterRoomsModal />;
      case MODAL_IDENTIFIER.FILTER_RENT:
        return <FilterRentModal />;
      case MODAL_IDENTIFIER.HOW_TO_SLIDES:
        return <HowToSlidesModal />;
      case MODAL_IDENTIFIER.INFO_TEXT:
        return <InfoTextModal />;
      case MODAL_IDENTIFIER.MAP_MODAL:
        return <MapModal />;
      case MODAL_IDENTIFIER.NOTIFICATIONS:
        return <NotificationsModal />;
      case MODAL_IDENTIFIER.PUBLISH_APARTMENT:
        return <PublishApartmentModal />;
      case MODAL_IDENTIFIER.REGISTER_USER:
        return <RegisterUserModal />;
      case MODAL_IDENTIFIER.SETTINGS:
        return <SettingsModal />;
      case MODAL_IDENTIFIER.SIGN_IN:
        return <SignInModal />;
      case MODAL_IDENTIFIER.VIRTUAL_TOUR:
        return <VirtualTourModal />;
      case MODAL_IDENTIFIER.YOUVE_BEEN_INVITED_MODAL:
        return <YouveBeenInvitedModal />;
      case MODAL_IDENTIFIER.CREATE_USER_INVITATION:
        return <CreateUserInvitationModal />;
    }
  };

  const onDismissHandler = () => {
    if (onDismiss) {
      onDismiss();
    }
    closeModal();
  };

  return (
    <IonModal
      className={styles.modal}
      isOpen={!!currentModalId}
      onDidDismiss={() => onDismissHandler()}
      backdropDismiss={allowBackdropDismiss}
    >
      {renderModal()}
    </IonModal>
  );
};

export default ModalLoader;
