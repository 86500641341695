import { FormContext } from '../../../../../core/context/form.context';
import InputWithDebounce from '../../../../atoms/formElement/InputWithDebounce';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import UserContext from '../../../../../core/context/user.context';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TOrganizationCreate } from '../../../../../core/service/organisation.service';
import { slugify } from '../../../../../core/helper/slugify-text';
import FormHeader from '../../../../atoms/FormHeader';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import { LANDLORD_FORMS } from '../../../../../view/landlord/account/AccountView';

const LandlordCreateOrganizationForm: FC<{ setOrganizationFormActive: Dispatch<SetStateAction<LANDLORD_FORMS>> }> = ({
  setOrganizationFormActive,
}) => {
  const { organizationService, landlordProfile } = useContext(UserContext);
  const { t } = useTranslation('common');
  const { t: f } = useTranslation('common', { keyPrefix: 'component.organisms.forms.landlord.form.organization' });
  const [slug, setSlug] = useState<string>('');

  const { control, handleSubmit, watch, setValue } = useForm<TOrganizationCreate>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { domain: landlordProfile.email.split('@')[1] },
  });
  const setSlugAndValue = (newSlugName: string) => {
    setSlug(newSlugName);
    setValue('slug', newSlugName);
  };
  const organizationTitle = watch('title');

  const formValue = watch();

  const validateSlugName = async (slugName: string, index = 0) => {
    const newSlugName: string = slugify(slugName) + (index > 0 ? `-${index + 1}` : '');

    const organization = await organizationService.getOrganizationBySlug(newSlugName);
    if (organization) {
      if (organization.id === landlordProfile.organizationId) {
        setSlugAndValue(newSlugName);
      } else {
        await validateSlugName(slugName, index + 1);
      }
    } else {
      setSlugAndValue(newSlugName);
    }
  };

  const onSubmit = (): void => {
    organizationService.addOrganization(
      {
        ...formValue,
        website: null,
        photoUrl: null,
        imprintText: null,
        imprintLink: null,
        dataProtectionText: null,
        dataProtectionLink: null,
      },
      landlordProfile.uid
    );

    setOrganizationFormActive(LANDLORD_FORMS.ORGANIZATION);
  };

  useEffect(() => {
    if (organizationTitle) {
      validateSlugName(organizationTitle);
    }
  }, [organizationTitle]);

  return (
    <FormContext.Provider value={{ control }}>
      <FormHeader title={f('title')} subtitle={'Starte mit einem kostenlosen Konto.'} />
      <FormSectionTitle title={f('sections.organization.title')} subtitle={f('sections.organization.subtitle')} />
      <form onSubmit={handleSubmit(onSubmit, console.error)}>
        <InputWithDebounce debounceTimeout={300} label={t('organization.title.label')} name={'title'} required={true} />
        <input type="hidden" name={'domain'} />
        <input type={'hidden'} name={'slug'} />
        <CTAButton buttonText={t('registerButtonText')} />
      </form>
    </FormContext.Provider>
  );
};

export default LandlordCreateOrganizationForm;
