import {
  APARTMENT_CONDITION,
  ENERGY_EFFICIENCY,
  ENERGY_PASS_TYPE,
  HEATING_SYSTEM,
  ISelectOption,
} from '@wohnsinn/ws-ts-lib';

export const APARTMENT_CONDITION_SELECT_OPTION_LIST: ISelectOption<APARTMENT_CONDITION>[] = [
  { value: APARTMENT_CONDITION.NOT_SPECIFIED, label: 'Keine Angabe' },
  { value: APARTMENT_CONDITION.REFURBISHED, label: 'Saniert' },
  { value: APARTMENT_CONDITION.RENOVATED, label: 'Renoviert' },
  { value: APARTMENT_CONDITION.MODERNISED, label: 'Modernisiert' },
  { value: APARTMENT_CONDITION.FIRST_TIME_USE, label: 'Erstbezug' },
];

export const APARTMENT_ENERGY_EFFICIENCY_CLASS_LIST: ISelectOption<ENERGY_EFFICIENCY>[] = [
  { value: ENERGY_EFFICIENCY.A_PLUS, label: 'A+' },
  { value: ENERGY_EFFICIENCY.A, label: 'A' },
  { value: ENERGY_EFFICIENCY.B, label: 'B' },
  { value: ENERGY_EFFICIENCY.C, label: 'C' },
  { value: ENERGY_EFFICIENCY.D, label: 'D' },
  { value: ENERGY_EFFICIENCY.E, label: 'E' },
  { value: ENERGY_EFFICIENCY.F, label: 'F' },
  { value: ENERGY_EFFICIENCY.G, label: 'G' },
  { value: ENERGY_EFFICIENCY.H, label: 'H' },
];

export const APARTMENT_HEATING_SYSTEM_SELECT_OPTION_LIST: ISelectOption<HEATING_SYSTEM>[] = [
  { value: HEATING_SYSTEM.NATURAL_GAS_CENTRAL_HEATING, label: 'Erdgas-Zentralheizung' },
  { value: HEATING_SYSTEM.NATURAL_GAS_FLOOR_HEATING, label: 'Erdgas-Etagenheizung' },
  { value: HEATING_SYSTEM.OIL_CENTRAL_HEATING, label: 'Öl-Zentralheizung' },
  { value: HEATING_SYSTEM.DISTRICT_HEATING, label: 'Fernwärme' },
  { value: HEATING_SYSTEM.PELLET_CENTRAL_HEATING, label: 'Holz- / Pellets-Zentralheizung' },
  { value: HEATING_SYSTEM.ELECTRIC_HEAT_PUMP, label: 'Elektro-Wärmepumpe' },
  { value: HEATING_SYSTEM.NIGHT_POWER_STORAGE, label: 'Nachtstromspeicherheizung' },
  { value: HEATING_SYSTEM.CUSTOM_HEATING, label: 'Eigene Angabe' },
  {
    value: HEATING_SYSTEM.NONE,
    label: 'Keine Angabe',
  },
];

export const BOOLEAN_SELECT_OPTION_ENERGY_PASS_AGE_LIST: ISelectOption<boolean>[] = [
  { label: 'Bis 30.04.2014', value: true },
  { label: 'Nach 01.05.2014', value: false },
];

export const BOOLEAN_SELECT_OPTION_ENERGY_PASS_AVAILABLE_LIST: ISelectOption<boolean>[] = [
  { label: 'Vorhanden', value: true },
  { label: 'Nicht notwendig', value: false },
];

export const ENERGY_PASS_TYPE_SELECT_OPTION_LIST: ISelectOption<ENERGY_PASS_TYPE>[] = [
  { label: 'Verbrauchsausweis', value: ENERGY_PASS_TYPE.CONSUMPTION_PASS },
  { label: 'Bedarfsausweis', value: ENERGY_PASS_TYPE.USAGE_PASS },
];
