import { FC, useContext } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './OptionList.module.scss';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormContext } from '../../../core/context/form.context';
import { Controller } from 'react-hook-form';
import FormErrorMessage from '../formElement/FormErrorMessage';
import { faBuildings } from '@fortawesome/pro-light-svg-icons';

export interface IOptionListItem {
  heading: string;
  iconName: IconProp;
  text: string;
  value: any;
}

export interface IOptionList {
  optionsList: [IOptionListItem, IOptionListItem];
  name: string;
  disabled?: boolean;
}

const OptionList: FC<IOptionList> = ({ optionsList = [], name, disabled }) => {
  const { control } = useContext(FormContext);

  const handleOnChange = (field: any, e: any) => {
    const value = e.target.value;
    const isTrue: boolean = value === 'true';
    let convertedValue: any;

    if (isTrue || value === 'false') {
      convertedValue = isTrue;
    } else {
      convertedValue = value;
    }

    return field.onChange(convertedValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={styles.optionListWrapper}>
          {optionsList.map((option, i) => (
            <div key={`${name}-${option.value}`}>
              <input
                tabIndex={i + 1}
                onChange={(e) => handleOnChange(field, e)}
                className={styles.input}
                name={name}
                disabled={disabled}
                id={`${name}-${i}`}
                type={'radio'}
                value={option.value}
              />

              <label
                htmlFor={`${name}-${i}`}
                key={`${name}-${i}`}
                className={`${styles.optionListItem} ${disabled ? styles.disabled : ''}`}
              >
                <div className={styles.icon}>
                  <FontAwesomeIcon icon={faBuildings} size={'1x'} color={'var(--ion-color-primary)'} />
                </div>
                <div className={styles.textWrapper}>
                  <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                    {option.heading}
                  </Text>
                  <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                    {option.text}
                  </Text>
                </div>
                <div className={styles.checkMarkWrapper}></div>
              </label>
            </div>
          ))}
          {error ? <FormErrorMessage error={error} /> : null}
        </div>
      )}
    />
  );
};

export default OptionList;
