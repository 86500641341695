import ReactDom from 'react-dom';
import styles from './LightShow.module.scss';
import { FC, useState } from 'react';
import { EffectFade, FreeMode, Keyboard, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IMedia, MEDIA_TYPE, TOpenImmoVideo } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faTimes } from '@fortawesome/pro-solid-svg-icons';

// Import Swiper styles
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Swiper as SwiperClass } from 'swiper/types';

export interface ILightShowProps {
  open: boolean;
  onClose: () => void;
  media: IMedia[] | TOpenImmoVideo[];
  openAtIndex?: number;
}

// eslint-disable-next-line react/prop-types
const LightShow: FC<ILightShowProps> = ({ open, onClose, media, openAtIndex }) => {
  const { t } = useTranslation('common');
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [currentImageTitle, setCurrentImageTitle] = useState<string>(
    media[0]?.alt?.length > 25
      ? media[0]?.alt.substring(0, 25).concat('...')
      : media[0]?.alt
      ? media[0]?.alt
      : 'no-alt-description'
  );
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  if (!open) return null;

  const handleArrowDisableState = (swiper: SwiperClass) => {
    setIsLastSlide(swiper.isEnd);
    setIsFirstSlide(swiper.isBeginning);
  };

  return ReactDom.createPortal(
    <>
      <div onClick={onClose} className={styles.backdrop} />
      <div className={styles.lightShowWrapper}>
        <div className={styles.lightShowHeader}>
          <span className={styles.title}>{currentImageTitle}</span>

          <button className={styles.closeBtn} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size={'2x'} />
            <span className="sr-only">{t('close')}</span>
          </button>
        </div>

        <div data-testid={'imageGallery'} className={styles.swiperWrapper}>
          <button
            className={`${styles.arrow} ${styles.arrowNext} ${isLastSlide ? styles.disabled : ''}`}
            id={'lightshow-arrow-next'}
          >
            <FontAwesomeIcon icon={faAngleRight} />
            <span className="sr-only">{t('next')}</span>
          </button>

          <button
            className={`${styles.arrow} ${styles.arrowPrev} ${isFirstSlide ? styles.disabled : ''}`}
            id={'lightshow-arrow-prev'}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            <span className="sr-only">{t('previous')}</span>
          </button>
          <Swiper
            navigation={{
              nextEl: '#lightshow-arrow-next',
              prevEl: '#lightshow-arrow-prev',
            }}
            onSlideChange={handleArrowDisableState}
            data-testid={'swiper'}
            modules={[Keyboard, EffectFade, Navigation, Thumbs]}
            effect="fade"
            keyboard
            grabCursor
            initialSlide={openAtIndex ? openAtIndex : 0}
            tabIndex={0}
            slidesPerView={1}
            spaceBetween={0}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
            className={styles.slides}
            onSlideChangeTransitionStart={(e) =>
              setCurrentImageTitle(
                media[e.realIndex]?.alt?.length > 25
                  ? media[e.realIndex]?.alt.substring(0, 25).concat('...')
                  : media[e.realIndex]?.alt
                  ? media[e.realIndex]?.alt
                  : 'no-alt-description'
              )
            }
          >
            {media.map((media, i) => (
              <SwiperSlide
                data-testid={`swiper.slide-${i}`}
                key={`${media.alt}-${i}`}
                style={{ backgroundImage: `url(${media.url})` }}
                className={styles.slide}
              >
                {media.mediaType === MEDIA_TYPE.VIDEO && (
                  <video className={styles.video} height={'100%'} src={media.url} controls>
                    <source src={media.url} />
                  </video>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          {media?.length > 1 ? (
            <Swiper
              onSwiper={setThumbsSwiper}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              freeMode
              initialSlide={openAtIndex ? openAtIndex : 0}
              className={`${styles.thumbnails} lightshow-thumbnail-slider`}
              slidesPerView={4}
              spaceBetween={5}
            >
              {media.map((media, i) => (
                <SwiperSlide key={`${media.alt}-${i}`}>
                  {({ isActive }) => (
                    <div
                      style={{ backgroundImage: `url(${media.url})` }}
                      className={`${styles.slide} ${isActive ? styles.isActive : ''}`}
                    >
                      {media.mediaType === MEDIA_TYPE.VIDEO && (
                        <video controls={false} style={{ pointerEvents: 'none' }} width={'100%'} src={media.url}>
                          <source src={media.url} />
                        </video>
                      )}
                    </div>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
        </div>
      </div>
    </>,
    document.getElementById('lightshow-portal')
  );
};

export default LightShow;
