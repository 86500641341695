import ModalWrapper from '../ModalWrapper';
import { IonCol, useIonRouter } from '@ionic/react';
import { useContext } from 'react';
import UserContext from '../../../../core/context/user.context';
import { useTranslation } from 'react-i18next';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from '../../../../core/enum/local-storage.enum';
import LinkElement from '../../../atoms/LinkElement';
import { faChevronRight, faRightLeft, faSignOut, faTrash } from '@fortawesome/pro-solid-svg-icons';
import Section from '../../../atoms/Section';
import styles from './SettingsModal.module.scss';
import ModalContext from '../../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import { ROUTES } from '../../../../core/const/routes';

const SettingsModal = () => {
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.SettingsModal' });
  const { activeUserType, user, authService, userService, tenantProfile, landlordProfile } = useContext(UserContext);
  const router = useIonRouter();
  const { openModal, closeModal } = useContext(ModalContext);

  const handleUserTypeCreation = (userType: USER_TYPE) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_TYPE, userType);
    userService.createProfileFromExistingProfile(userType, user, tenantProfile, landlordProfile);
    handleActiveUserTypeSwitch();
  };

  const handleActiveUserTypeSwitch = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, t(ROUTES.tenant.account.router));
    userService.setActiveUserType(
      user.uid,
      activeUserType === USER_TYPE.LANDLORD ? USER_TYPE.TENANT : USER_TYPE.LANDLORD
    );
    closeModal();
    router.push(t(ROUTES.general.redirect.path));
  };

  const logoutHandler = (): Promise<void> => {
    closeModal();
    return authService.signOut(router, t);
  };

  return (
    <ModalWrapper title={m('settings')}>
      <div className={styles.itemsWrapper}>
        <Section reducePadding>
          <IonCol>
            <LinkElement
              text={m('notifications')}
              onClick={() => openModal({ id: MODAL_IDENTIFIER.NOTIFICATIONS })}
              icon={faChevronRight}
            />
            <LinkElement
              text={m('faq')}
              onClick={() => openModal({ id: MODAL_IDENTIFIER.FAQ })}
              icon={faChevronRight}
            />
            <LinkElement
              text={m('help')}
              onClick={() => openModal({ id: MODAL_IDENTIFIER.CONTACT_MODAL })}
              icon={faChevronRight}
            />
          </IonCol>
        </Section>
        <Section reducePadding>
          <IonCol>
            {user.isLandlord && !user.isTenant && (
              <LinkElement
                text={'Jetzt Mieterprofil erstellen'}
                onClick={() => handleUserTypeCreation(USER_TYPE.TENANT)}
                icon={faRightLeft}
              />
            )}

            {!user.isLandlord && user.isTenant && (
              <LinkElement
                text={m('createLandlord')}
                onClick={() => handleUserTypeCreation(USER_TYPE.LANDLORD)}
                icon={faRightLeft}
              />
            )}

            {user.isLandlord && user.isTenant ? (
              <LinkElement
                text={activeUserType === USER_TYPE.LANDLORD ? m('setTenantMode') : m('setLandlordMode')}
                onClick={user.isLandlord && user.isTenant ? () => handleActiveUserTypeSwitch() : null}
                icon={faRightLeft}
              />
            ) : null}

            <LinkElement text={t(ROUTES.general.agb.title)} routerLink={t(ROUTES.general.agb.path)} />
            <LinkElement text={t(ROUTES.general.privacy.title)} routerLink={t(ROUTES.general.privacy.path)} />
            <LinkElement text={t(ROUTES.general.imprint.title)} routerLink={t(ROUTES.general.imprint.path)} />
          </IonCol>
        </Section>
        <Section reducePadding>
          <IonCol>
            <LinkElement text={m('signOut')} onClick={logoutHandler} icon={faSignOut} />
            <LinkElement
              text={t('view.AccountView.deleteAccount')}
              onClick={() =>
                openModal({
                  id: MODAL_IDENTIFIER.DELETE_MODAL,
                  data: {
                    deleteHandler: () => authService.deleteUser(router, t).then(() => closeModal()),
                    title: m('delete.title'),
                    text: m('delete.text'),
                  },
                })
              }
              icon={faTrash}
            />
          </IonCol>
        </Section>
      </div>
    </ModalWrapper>
  );
};

export default SettingsModal;
