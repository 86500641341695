import ModalWrapper from '../../ModalWrapper';
import ApartmentInLocationFinder from '../../../ApartmentInLocationFinder';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import { useContext } from 'react';
import ModalContext from '../../../../../core/context/modal.context';
import TenantFilterParamsContext from '../../../../../core/context/tenant-filter-params.context';
import styles from './FilterLocationModal.module.scss';
import { ILocationSelection } from '@wohnsinn/ws-ts-lib';

import { FILTER_TABS } from '../../../FilterBar';
import { useTranslation } from 'react-i18next';

const FilterLocationModal = () => {
  const { t } = useTranslation('common');
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { closeModal, openModal, modalData } = useContext(ModalContext);

  const setLocation = async (location: ILocationSelection) => {
    await updateTenantFilterParams({ ...tenantFilterParams, location });
  };

  const handleModalClose = () => {
    modalData?.setActiveTab(FILTER_TABS.NONE);
    closeModal();
  };

  return (
    <ModalWrapper title={'Wo möchtest du wohnen?'}>
      <div className={styles.wrapper}>
        <ApartmentInLocationFinder />
        {/*<div className={styles.suggestionsTitle}>*/}
        {/*  <Headline noMargin size={HEADLINE_SIZE.H3}>*/}
        {/*    Schnellauswahl*/}
        {/*  </Headline>*/}
        {/*  <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>*/}
        {/*    Hier eine Auswahl der beliebtesten Wohnorte.*/}
        {/*  </Text>*/}
        {/*</div>*/}
        {/*<div className={styles.citySuggestions}>*/}
        {/*  <SuggestionButton onClick={() => setLocation(BERLIN_LOCATION)} label={'Berlin'} />*/}
        {/*  <SuggestionButton onClick={() => setLocation(FRANKFURT_AM_MAIN_LOCATION)} label={'Frankfurt am Main'} />*/}
        {/*  <SuggestionButton onClick={() => setLocation(HAMBURG)} label={'Hamburg'} />*/}
        {/*  <SuggestionButton onClick={() => setLocation(KOELN)} label={'Köln'} />*/}
        {/*  <SuggestionButton onClick={() => setLocation(MUENCHEN)} label={'München'} />*/}
        {/*  <SuggestionButton onClick={() => setLocation(STUTTGART)} label={'Stuttgart'} />*/}
        {/*</div>*/}
        <CTAButton rounded={false} expand={'block'} buttonText={t('save')} onClick={() => handleModalClose()} />
      </div>
    </ModalWrapper>
  );
};

export default FilterLocationModal;
