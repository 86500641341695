import { SwiperSlide } from 'swiper/react';
import TunnelLayout from '../../../../component/organisms/Tunnels/TunnelLayout';
import EnterPhoneNumber from '../../../../component/organisms/Tunnels/Slides/PublishTunnelSlides/EnterPhoneNumber';
import VerifyPhoneNumber from '../../../../component/organisms/Tunnels/Slides/PublishTunnelSlides/VerifyPhoneNumber';
import { useContext } from 'react';
import TwilioServiceContext from '../../../../core/context/twilio-service.context';
import { FirebaseFunctionsContext } from '../../../../core/context/firebase-functions.context';
import TwilioService from '../../../../core/service/twilio.service';

const PublishProcessTunnel = () => {
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const twilioService: TwilioService = new TwilioService(firebaseFunctionsService);

  return (
    <TwilioServiceContext.Provider value={{ twilioService }}>
      <TunnelLayout allowNextSlide={true}>
        <>
          <SwiperSlide>
            <EnterPhoneNumber />
          </SwiperSlide>
          <SwiperSlide>
            <VerifyPhoneNumber />
          </SwiperSlide>
        </>
      </TunnelLayout>
    </TwilioServiceContext.Provider>
  );
};

export default PublishProcessTunnel;
