import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../../../component/atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faComment, faMinus, faPlay, faDown, faPlus, faUp } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TENANT_JOB_SELECT_OPTION_LIST } from '../../forms/tenant/TenantProfileForm';
import styles from './ApplicationOverviewTable.module.scss';
import { IonRouterLink } from '@ionic/react';
import Avatar, { AVATAR_SIZE } from '../../../../component/atoms/Avatar';
import printDate from '../../../../core/helper/printDate';
import CTAButton, { BUTTON_STYLE } from '../../../../component/atoms/Buttons/CTAButton';
import LandlordRatingButtons from '../../../../component/molecules/LandlordRatingButtons';
import { useTranslation } from 'react-i18next';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { IApplication, IMedia, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import useWindowSize from '../../../../core/hook/windowsize.hook';
import { IRatedApplicantLists } from '../../../../view/landlord/application/ApplicationOverviewView';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../../../atoms/NotificationBadge';

const ApplicationOverviewTable: FC<{
  sortApplicantByIncome: () => any;
  sortAsc: boolean;
  sortedApplicantList: IRatedApplicantLists;
  selectedRating: MATCHING_MODE | 'ALL';
  setShowApplicantInformation: Dispatch<SetStateAction<boolean>>;
  handleApplicantInformationClick: (application: IApplication) => any;
  handleIntroductionVideoClick: (video: IMedia) => any;
  handleClickOnChat: (application: IApplication) => any;
}> = ({
  handleApplicantInformationClick,
  handleIntroductionVideoClick,
  handleClickOnChat,
  sortAsc,
  sortApplicantByIncome,
  sortedApplicantList,
  selectedRating,
  setShowApplicantInformation,
}) => {
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationOverviewView' });
  const { t } = useTranslation('common');
  const { isSmallerMd } = useWindowSize();
  const [selectedTenantId, setSelectedTenantId] = useState<string>(null);

  if (isSmallerMd) {
    return (
      <div className={styles.mobile}>
        {sortedApplicantList[selectedRating].map((application: IApplication) => {
          const jobStatus = TENANT_JOB_SELECT_OPTION_LIST.filter(
            (list) => list.value === application?.tenantProfile?.personalInformation?.jobStatus
          )[0].label;
          return (
            <div className={styles.element} key={`${application?.apartmentId}-${application?.tenantProfile.uid}`}>
              <div className={styles.head}>
                <button
                  className={styles.infoWrapper}
                  onClick={() =>
                    setSelectedTenantId(
                      selectedTenantId === application.tenantProfile.uid ? null : application.tenantProfile.uid
                    )
                  }
                >
                  <FontAwesomeIcon
                    className={styles.plus}
                    icon={selectedTenantId === application.tenantProfile.uid ? faMinus : faPlus}
                  />
                  <Avatar size={AVATAR_SIZE.xs} avatarUrl={application?.tenantProfile?.photoUrl} />
                  <div className={styles.infoText}>
                    <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
                      {application?.tenantProfile?.personalInformation?.firstName}{' '}
                      {application?.tenantProfile?.personalInformation?.lastName}
                    </Text>
                    <Text
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                      tag={'span'}
                      type={TEXT_TYPE.TEXT_TYPE_FOOTER}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
                    >
                      {a('born')} {printDate(application?.tenantProfile?.personalInformation?.dateOfBirth, t)}
                    </Text>
                  </div>
                </button>
                <div className={styles.ctaWrapper}>
                  <CTAButton
                    buttonStyle={BUTTON_STYLE.SECONDARY}
                    buttonText={t('showApplicantInfo')}
                    hideText
                    icon={faCircleInfo}
                    onClick={() => handleApplicantInformationClick(application)}
                    size={'small'}
                  />
                  <div className={styles.messagesWrapper}>
                    {application.unreadTenantMessagesRef.length ? (
                      <div className={styles.messagesBadge}>
                        <NotificationBadge
                          size={NOTIFICATION_BADGE_SIZE.SMALL}
                          count={application.unreadTenantMessagesRef.length}
                        />
                      </div>
                    ) : null}
                    <CTAButton
                      buttonStyle={BUTTON_STYLE.SECONDARY}
                      buttonText={a('messages')}
                      hideText
                      icon={faComment}
                      onClick={() => handleClickOnChat(application)}
                      size={'small'}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.moreInfo} ${
                  selectedTenantId === application.tenantProfile.uid ? styles.show : ''
                }`}
              >
                <div>
                  <div>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      Beruf:
                    </Text>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                      {jobStatus}
                      {application?.tenantProfile?.personalInformation?.customJobDescription ? ', ' : null}{' '}
                      {application?.tenantProfile?.personalInformation?.customJobDescription
                        ? application?.tenantProfile?.personalInformation?.customJobDescription
                        : ''}
                    </Text>
                  </div>
                  <div>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      Einkommen:
                    </Text>{' '}
                    <Text
                      type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      tag={'span'}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                    >
                      {application?.tenantProfile?.household?.monthlyIncome
                        ? `${application?.tenantProfile.household.monthlyIncome} ${t('currency')}`
                        : `0 ${t('currency')}`}
                    </Text>
                  </div>

                  <div>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      Video:
                    </Text>
                    {application?.tenantProfile?.aboutMe?.introductionVideo?.url ? (
                      <CTAButton
                        buttonStyle={BUTTON_STYLE.SECONDARY}
                        buttonText={a('playVideo')}
                        hideText
                        icon={faPlay}
                        onClick={() =>
                          handleIntroductionVideoClick(application?.tenantProfile.aboutMe.introductionVideo)
                        }
                        size={'small'}
                      />
                    ) : (
                      <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                        <FontAwesomeIcon icon={faTimes} />
                      </Text>
                    )}
                  </div>
                </div>

                <div>
                  <LandlordRatingButtons
                    big={false}
                    landlordRating={application?.landlordRating}
                    tenantProfileId={application?.tenantProfile?.uid}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.tableWrapper}>
      <table className={`ws-table ${styles.table}`}>
        <thead>
          <tr>
            <th>
              <span className={'sr-only'}>Bild</span>
            </th>
            <th align={'left'}>
              <Text
                weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                tag={'span'}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              >
                {a('tableHead.name')}
              </Text>
            </th>
            <th>
              <Text
                weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                tag={'span'}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              >
                {a('tableHead.info')}
              </Text>
            </th>
            <th align={'left'}>
              <Text
                weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                tag={'span'}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              >
                {a('tableHead.job')}
              </Text>
            </th>
            <th align={'right'}>
              <button onClick={sortApplicantByIncome}>
                <Text
                  uppercase
                  weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                  color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                  tag={'span'}
                  type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                >
                  {a('tableHead.income')}
                </Text>
                <span className="sr-only">
                  {a('sortIncome')} {sortAsc ? a('asc') : a('desc')}
                </span>{' '}
                <FontAwesomeIcon icon={sortAsc ? faUp : faDown} size={'xs'} />
              </button>
            </th>
            <th>
              <Text
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                tag={'span'}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
              >
                {a('tableHead.video')}
              </Text>
            </th>
            <th>
              <Text
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                tag={'span'}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
              >
                {a('tableHead.messages')}
              </Text>
            </th>
            <th align={'center'}>
              <Text
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                tag={'span'}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
              >
                {a('tableHead.rating')}
              </Text>
            </th>
          </tr>
        </thead>

        {sortedApplicantList[selectedRating].length ? (
          <tbody>
            {sortedApplicantList[selectedRating].map((application: IApplication) => {
              const jobStatus = TENANT_JOB_SELECT_OPTION_LIST.filter(
                (list) => list.value === application?.tenantProfile?.personalInformation?.jobStatus
              )[0].label;
              return (
                <tr key={`${application?.apartmentId}-${application?.tenantProfile.uid}`}>
                  <td className={`${styles.imageCell}`} align={'center'}>
                    <IonRouterLink className={styles.link} onClick={() => handleApplicantInformationClick(application)}>
                      <Avatar size={AVATAR_SIZE.md} avatarUrl={application?.tenantProfile?.photoUrl} />
                    </IonRouterLink>
                  </td>
                  <td className={`${styles.nameCell}`}>
                    <Text
                      tag={'span'}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                    >
                      {application?.tenantProfile?.personalInformation?.firstName}{' '}
                      {application?.tenantProfile?.personalInformation?.lastName}
                    </Text>
                    <br />
                    <Text
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                      tag={'span'}
                      type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
                    >
                      {a('born')} {printDate(application?.tenantProfile?.personalInformation?.dateOfBirth, t)}
                    </Text>
                  </td>
                  <td align={'center'} className={styles.infoCell}>
                    <CTAButton
                      buttonStyle={BUTTON_STYLE.SECONDARY}
                      buttonText={t('showApplicantInfo')}
                      icon={faCircleInfo}
                      onClick={() => handleApplicantInformationClick(application)}
                      size={'small'}
                    />
                  </td>
                  <td className={styles.jobCell}>
                    <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      {jobStatus}
                    </Text>
                    {application?.tenantProfile?.personalInformation?.customJobDescription ? <br /> : null}
                    <Text
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                      tag={'span'}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                    >
                      {application?.tenantProfile?.personalInformation?.customJobDescription
                        ? application?.tenantProfile?.personalInformation?.customJobDescription
                        : ''}
                    </Text>
                  </td>
                  <td className={styles.incomeCell} align={'right'}>
                    <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      {application?.tenantProfile?.household?.monthlyIncome
                        ? `${application?.tenantProfile.household.monthlyIncome} ${t('currency')}`
                        : `0 ${t('currency')}`}
                    </Text>
                  </td>

                  <td className={styles.videoCell} align={'center'}>
                    {application?.tenantProfile?.aboutMe?.introductionVideo?.url ? (
                      <CTAButton
                        buttonStyle={BUTTON_STYLE.SECONDARY}
                        buttonText={a('playVideo')}
                        hideText
                        icon={faPlay}
                        onClick={() =>
                          handleIntroductionVideoClick(application?.tenantProfile.aboutMe.introductionVideo)
                        }
                        size={'small'}
                      />
                    ) : (
                      <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                        <FontAwesomeIcon icon={faTimes} />
                      </Text>
                    )}
                  </td>
                  <td align={'center'} className={styles.messagesCell}>
                    <div className={styles.messagesWrapper}>
                      {application.unreadTenantMessagesRef.length ? (
                        <div className={styles.messagesBadge}>
                          <NotificationBadge
                            size={NOTIFICATION_BADGE_SIZE.SMALL}
                            count={application.unreadTenantMessagesRef.length}
                          />
                        </div>
                      ) : null}
                      <CTAButton
                        buttonStyle={BUTTON_STYLE.SECONDARY}
                        buttonText={a('messages')}
                        icon={faComment}
                        onClick={() => handleClickOnChat(application)}
                        size={'small'}
                      />
                    </div>
                  </td>
                  <td className={styles.ratingButtonsCell}>
                    <LandlordRatingButtons
                      big={false}
                      landlordRating={application?.landlordRating}
                      tenantProfileId={application?.tenantProfile?.uid}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </table>
    </div>
  );
};

export default ApplicationOverviewTable;
