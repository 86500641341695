import {
  faCommentDots,
  faHouseBlank,
  faStore,
  faUser,
  faMagnifyingGlass,
  IconDefinition,
  faHeart,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHouseBlank as faHouseBlankSolid,
  faStore as faStoreSolid,
  faUser as faUserSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faCommentDots as faCommentDotsSolid,
  faHeart as faHeartSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { ROUTES } from './routes';

export interface IMenuItemProps {
  label: string;
  icon: IconDefinition;
  iconActive: IconDefinition;
  link?: string;
  notifications?: number | boolean;
  shouldOpenModal?: boolean;
}

export const APARTMENTS_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.objects',
  icon: faHouseBlank,
  iconActive: faHouseBlankSolid,
  link: ROUTES.landlord.apartment.list.path,
  shouldOpenModal: true,
};

export const SERVICES_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.services',
  icon: faStore,
  iconActive: faStoreSolid,
  link: ROUTES.services.overview.path,
};

export const TENANT_MESSAGES_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.messages',
  icon: faCommentDots,
  iconActive: faCommentDotsSolid,
  link: ROUTES.tenant.chat.path,
  notifications: 0,
  shouldOpenModal: true,
};

export const getTenantMessagesMenuItem = (notifications?: number): IMenuItemProps => {
  return {
    ...TENANT_MESSAGES_MENU_ITEM,
    notifications,
  };
};

export const LANDLORD_MESSAGES_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.applications',
  icon: faCommentDots,
  iconActive: faCommentDotsSolid,
  link: ROUTES.landlord.application.list.path,
  notifications: 0,
  shouldOpenModal: true,
};

export const getLandlordMessagesMenuItem = (notifications?: number): IMenuItemProps => {
  return {
    ...LANDLORD_MESSAGES_MENU_ITEM,
    notifications,
  };
};

export const SEARCH_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.search',
  icon: faMagnifyingGlass,
  iconActive: faMagnifyingGlassSolid,
  link: ROUTES.tenant.matching.list.path,
  notifications: 0,
};

export const ACCOUNT_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.account',
  icon: faUser,
  iconActive: faUserSolid,
  link: ROUTES.tenant.account.overview.path,
  notifications: 0,
  shouldOpenModal: true,
};
export const LANDLORD_ACCOUNT_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.account',
  icon: faUser,
  iconActive: faUserSolid,
  link: ROUTES.landlord.account.overview.path,
  notifications: 0,
  shouldOpenModal: true,
};

export const getAccountMenuItem = (notifications?: boolean): IMenuItemProps => {
  return {
    ...ACCOUNT_MENU_ITEM,
    notifications: notifications,
  };
};
export const getLandlordAccountMenuItem = (notifications?: boolean): IMenuItemProps => {
  return {
    ...LANDLORD_ACCOUNT_MENU_ITEM,
    notifications: notifications,
  };
};

export const GUEST_ACCOUNT_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.account',
  icon: faUser,
  iconActive: faUserSolid,
  link: ROUTES.tenant.account.overview.path,
  notifications: 0,
  shouldOpenModal: true,
};

export const BOOKMARK_MENU_ITEM: IMenuItemProps = {
  label: 'core.const.tabBarMenuItems.bookmark',
  icon: faHeart,
  iconActive: faHeartSolid,
  link: ROUTES.tenant.bookmark.router,
  notifications: 0,
  shouldOpenModal: false,
};

export const getBookmarkMenuItem = (notifications?: number): IMenuItemProps => {
  return {
    ...BOOKMARK_MENU_ITEM,
    notifications,
  };
};

export interface IMenuNotifications {
  tenantChat: number;
  landlordChat: number;
  bookmark: number;
  account: boolean;
}

export const getTenantMenuList = (notifications?: IMenuNotifications): IMenuItemProps[] => {
  return [
    SEARCH_MENU_ITEM,
    getTenantMessagesMenuItem(notifications.tenantChat),
    getBookmarkMenuItem(notifications.bookmark),
    SERVICES_MENU_ITEM,
    getAccountMenuItem(notifications.account),
  ];
};

export const getLandlordMenuList = (notifications?: IMenuNotifications): IMenuItemProps[] => {
  return [
    APARTMENTS_MENU_ITEM,
    getLandlordMessagesMenuItem(notifications?.landlordChat),
    SERVICES_MENU_ITEM,
    getLandlordAccountMenuItem(notifications?.account),
  ];
};

export const getGuestMenuList = (notifications?: IMenuNotifications): IMenuItemProps[] => {
  return [getBookmarkMenuItem(notifications?.bookmark), SERVICES_MENU_ITEM];
};

export const getMobileGuestMenuList = (notifications?: IMenuNotifications): IMenuItemProps[] => {
  return [SEARCH_MENU_ITEM, getBookmarkMenuItem(notifications?.bookmark), SERVICES_MENU_ITEM, ACCOUNT_MENU_ITEM];
};
