import styles from './ChatMessage.module.scss';
import { FC } from 'react';
import { IChatMessage } from '@wohnsinn/ws-ts-lib';
import Text, { TEXT_TYPE } from '../../atoms/typographie/Text';
import SanitizedHTML from '../../atoms/SanitzedHtml';
import { useTranslation } from 'react-i18next';

export interface IChatMessageProps {
  message: IChatMessage;
  isSender?: boolean;
  showName?: boolean;
}

const ChatMessage: FC<IChatMessageProps> = ({ message, isSender = false, showName = true }) => {
  const { t } = useTranslation('common');

  return (
    <div className={`${styles.chatMessage} ${isSender ? styles.chatMessageRight : ''}`}>
      {showName ? (
        <div className={`${styles.chatHeader} ${isSender ? styles.isSender : ''}`}>
          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
            {isSender ? t('you') : message.senderName}
          </Text>
        </div>
      ) : null}
      <SanitizedHTML dirty={message.text.html} className={styles.text} />
    </div>
  );
};

export default ChatMessage;
