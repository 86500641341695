import { useContext, useEffect, useState } from 'react';
import UserContext from '../context/user.context';
import { IApplication, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import ApplicationServiceContext from '../context/application-service.context';

interface IUseLandlordApplicationListHookParams {
  apartmentId?: string;
  tenantId?: string;
}
const useLandlordApplicationListHook = (params: IUseLandlordApplicationListHookParams) => {
  const { user } = useContext(UserContext);
  const { applicationService } = useContext(ApplicationServiceContext);
  const [applicationList, setApplicationList] = useState<IApplication[]>([]);
  const [unreadChatNotifications, setUnreadChatNotifications] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setIsLoading(true);
    if (user?.uid && user.activeUserType === USER_TYPE.LANDLORD) {
      const applicationQuery = applicationService.getLandlordApplicationListRef({
        landlordId: user.uid,
        apartmentId: params.apartmentId,
        tenantId: params.tenantId,
      });
      (async () => {
        unsubscribe = onSnapshot(
          applicationQuery,
          (applicationSnap) => {
            let unreadChatNotifications = 0;

            const applicationList: IApplication[] = applicationSnap.docs.map((doc) => {
              const application = doc.data();
              unreadChatNotifications += application.unreadTenantMessagesRef.length;
              return application;
            });
            if (applicationList.length > 1 && params.tenantId && params.apartmentId) {
              throw Error(
                `Found more than 1 matching application for: landlordId: ${user.uid} tenantId: ${params.tenantId} apartmentId: ${params.apartmentId} `
              );
            }
            setApplicationList(applicationList);
            if (!params.apartmentId && !params.tenantId) {
              setUnreadChatNotifications(unreadChatNotifications);
            }
          },
          (err) => console.error('Error on useApplicationHook ', err)
        );
      })();
      setIsLoading(false);
    }
    return unsubscribe;
  }, []);

  return {
    applicationList,
    unreadChatNotifications,
    isLoading,
  };
};

export default useLandlordApplicationListHook;
