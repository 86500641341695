export const ROUTES = {
  general: {
    agb: {
      path: 'routes.general.agb.path',
      title: 'routes.general.agb.title',
    },
    privacy: {
      path: 'routes.general.privacy.path',
      title: 'routes.general.privacy.title',
    },
    imprint: {
      path: 'routes.general.imprint.path',
      title: 'routes.general.imprint.title',
    },
    maintenance: {
      path: 'routes.general.maintenance.path',
      title: 'routes.general.maintenance.title',
    },
    notFound: {
      path: 'routes.general.notFound.path',
      title: 'routes.general.notFound.title',
    },
    notAuthorized: {
      path: 'routes.general.notAuthorized.path',
      title: 'routes.general.notAuthorized.title',
    },
    redirect: {
      title: 'routes.general.redirect.title',
      path: 'routes.general.redirect.path',
    },
  },
  landlord: {
    account: {
      overview: {
        path: 'routes.landlord.account.overview.path',
        title: 'routes.landlord.account.overview.title',
      },
      activateAccount: {
        title: 'routes.landlord.account.activateAccount.title',
        path: 'routes.landlord.account.activateAccount.path',
      },
    },
    apartment: {
      new: {
        path: 'routes.landlord.apartment.new.path',
        title: 'routes.landlord.apartment.new.title',
      },
      list: {
        path: 'routes.landlord.apartment.list.path',
        title: 'routes.landlord.apartment.list.title',
      },
      overview: {
        path: 'routes.landlord.apartment.overview.path',
        title: 'routes.landlord.apartment.overview.title',
      },
      preview: {
        path: 'routes.landlord.apartment.preview.path',
        title: 'routes.landlord.apartment.preview.title',
      },
      publish: {
        path: 'routes.landlord.apartment.publish.path',
        title: 'routes.landlord.apartment.publish.title',
      },
    },
    application: {
      list: {
        path: 'routes.landlord.application.list.path',
        title: 'routes.landlord.application.list.title',
      },
      overview: {
        path: 'routes.landlord.application.overview.path',
        title: 'routes.landlord.application.overview.title',
      },
    },
    landing: {
      path: 'routes.landlord.landing.path',
      title: 'routes.landlord.landing.title',
    },
    organization: {
      portfolio: {
        path: 'routes.landlord.organization.portfolio.path',
        title: 'routes.landlord.organization.portfolio.title',
        apartment: {
          path: 'routes.landlord.organization.portfolio.apartment.path',
          title: 'routes.landlord.organization.portfolio.apartment.title',
        },
      },
    },
    register: {
      title: 'routes.landlord.register.title',
      path: 'routes.landlord.register.path',
    },
  },
  services: {
    overview: {
      title: 'routes.services.overview.title',
      path: 'routes.services.overview.path',
    },
    createSchufa: {
      path: 'routes.services.createSchufa.path',
      title: 'routes.services.createSchufa.title',
    },
    compareCheck24: {
      path: 'routes.services.compareCheck24.path',
      title: 'routes.services.compareCheck24.title',
    },
  },
  tenant: {
    account: {
      title: 'routes.tenant.account.title',
      router: 'routes.tenant.account.router',
      applicationFolder: {
        aboutMe: 'routes.tenant.account.applicationFolder.aboutMe',
        profile: 'routes.tenant.account.applicationFolder.profile',
        household: 'routes.tenant.account.applicationFolder.household',
        incomeProof: 'routes.tenant.account.applicationFolder.incomeProof',
        introductionVideo: 'routes.tenant.account.applicationFolder.introductionVideo',
      },
      overview: {
        path: 'routes.tenant.account.overview.path',
        title: 'routes.tenant.account.overview.title',
      },
      aboutMe: {
        path: 'routes.tenant.account.aboutMe.path',
        title: 'routes.tenant.account.aboutMe.title',
      },
      profile: {
        path: 'routes.tenant.account.profile.path',
        title: 'routes.tenant.account.profile.title',
      },
      notifications: {
        path: 'routes.tenant.account.notifications.path',
        title: 'routes.tenant.account.notifications.title',
      },
      household: {
        path: 'routes.tenant.account.household.path',
        title: 'routes.tenant.account.household.title',
      },
      introductionVideo: {
        path: 'routes.tenant.account.introductionVideo.path',
        title: 'routes.tenant.account.introductionVideo.title',
      },
      incomeProof: {
        path: 'routes.tenant.account.incomeProof.path',
        title: 'routes.tenant.account.incomeProof.title',
      },
    },
    bookmark: {
      title: 'routes.tenant.bookmark.title',
      router: 'routes.tenant.bookmark.router',
    },
    matching: {
      list: {
        title: 'routes.tenant.matching.list.title',
        path: 'routes.tenant.matching.list.path',
      },
      stack: {
        title: 'routes.tenant.matching.stack.title',
        selectedApartment: {
          path: 'routes.tenant.matching.stack.selectedApartment.path',
        },
        firstApartment: {
          path: 'routes.tenant.matching.stack.firstApartment.path',
        },
      },
    },
    shareApartment: {
      title: 'routes.tenant.share.title',
      path: 'routes.tenant.share.path',
    },
    chat: {
      path: 'routes.tenant.chat.path',
      title: 'routes.tenant.chat.title',
    },
  },
  links: {
    terms: 'routes.links.terms',
    dataProtection: 'routes.links.dataProtection',
  },
  passwordResetConfirmation: {
    path: 'routes.passwordReset.confirmation.path',
    title: 'routes.passwordReset.confirmation.title',
  },
  register: {
    tenant: {
      title: 'routes.register.tenant.title',
      path: 'routes.register.tenant.path',
    },
  },
  signIn: {
    title: 'routes.signIn.title',
    path: 'routes.signIn.path',
  },
  unsubscribe: {
    path: 'routes.unsubscribe.path',
    title: 'routes.unsubscribe.title',
  },
};
