export const faqTenant = [
  {
    label: 'core.const.faq.tenant.how.question',
    content: 'core.const.faq.tenant.how.label',
  },
  {
    label: 'core.const.faq.tenant.benefits.question',
    content: 'core.const.faq.tenant.benefits.label',
  },
  {
    label: 'core.const.faq.tenant.pricing.question',
    content: 'core.const.faq.tenant.pricing.label',
  },
  {
    label: 'core.const.faq.tenant.gdpr.question',
    content: 'core.const.faq.tenant.gdpr.label',
  },
];

export const faqLandlord = [
  {
    label: 'core.const.faq.landlord.difference.question',
    content: 'core.const.faq.landlord.difference.label',
  },
  {
    label: 'core.const.faq.landlord.benefits.question',
    content: 'core.const.faq.landlord.benefits.label',
  },
  {
    label: 'core.const.faq.landlord.pricing.question',
    content: 'core.const.faq.landlord.pricing.label',
  },
  {
    label: 'core.const.faq.landlord.seekers.question',
    content: 'core.const.faq.landlord.seekers.label',
  },
  {
    label: 'core.const.faq.landlord.interfaces.question',
    content: 'core.const.faq.landlord.interfaces.label',
  },
  {
    label: 'core.const.faq.landlord.gdpr.question',
    content: 'core.const.faq.landlord.gdpr.label',
  },
];
