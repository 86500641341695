import styles from './LandlordLandingFooter.module.scss';
import { IonRouterLink } from '@ionic/react';
import { ROUTES } from '../../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
const LandlordLandingFooter = () => {
  const { t } = useTranslation('common');
  return (
    <footer className={`${styles.footer} dark`}>
      <IonRouterLink className={styles.logo} routerLink={'/'}>
        <div className={`${styles.appLogo} app-logo`} />
      </IonRouterLink>

      <ul className={styles.nav}>
        <li>
          <IonRouterLink routerLink={t(ROUTES.general.agb.path)}>AGB</IonRouterLink>
        </li>
        <li>
          <IonRouterLink routerLink={t(ROUTES.general.imprint.path)}>Impressum</IonRouterLink>
        </li>
        <li>
          <IonRouterLink routerLink={t(ROUTES.general.privacy.path)}>Datenschutz</IonRouterLink>
        </li>
      </ul>
    </footer>
  );
};

export default LandlordLandingFooter;
