import { FC, useContext, useEffect, useState } from 'react';
import { IApartment, ILandlordProfile } from '@wohnsinn/ws-ts-lib';
import { IonCol, IonGrid, IonRow, IonToggle } from '@ionic/react';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import LandlordBusinessCard from '../../../../molecules/LandlordBusinessCard';
import InfoBox from '../../../../atoms/InfoBox';
import useOrganization from '../../../../../core/hook/organization.hook';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../../../core/context/user.context';
import ApartmentServiceContext from '../../../../../core/context/apartment-service.context';
import { Unsubscribe } from '@firebase/firestore';
import { onSnapshot, Query } from 'firebase/firestore';
import CheckMark from '../../../../atoms/CheckMark';
import SplitLine from '../../../../atoms/SplitLine';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import FormHeader from '../../../../atoms/FormHeader';

const ApartmentContactPersonsForm: FC<{ apartment: IApartment }> = ({ apartment }) => {
  const { organization, organizationService } = useOrganization();
  const { t } = useTranslation('common');
  const { t: c } = useTranslation('common', { keyPrefix: 'view.ApartmentContactPersonView' });
  const { landlordProfile } = useContext(UserContext);
  const { apartmentService } = useContext(ApartmentServiceContext);
  const [organizationUserList, setOrganizationUserList] = useState<ILandlordProfile[]>([]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (apartment) {
      (async () => {
        const organisationUsersQuery: Query<ILandlordProfile> = organizationService.getOrganizationUserListRefById(
          landlordProfile.organizationId
        );

        unsubscribe = onSnapshot(
          organisationUsersQuery,
          async (snap) => {
            const userList = snap.docs
              .filter((oU) => oU?.data().isOrganizationMembershipConfirmed)
              .map((doc) => doc.data());
            if (userList.length) {
              setOrganizationUserList(userList);
            }
          },
          (error) => console.error(error)
        );
      })();
    }
    return unsubscribe;
  }, []);

  const handleIfUserShouldGetApartmentUpdates = async (uid: string) => {
    await apartmentService.updateApartmentContactPersons(apartment, uid, apartment.contactPersons?.includes(uid));
  };
  const handleUserAsContactPerson = async (uid: string) => {
    await apartmentService.setUidAsFirstContactPerson(apartment, uid);
  };

  const renderUserList = () => {
    return organizationUserList.map((user) => {
      const isContactPerson = apartment?.contactPersons?.includes(user.uid);

      return (
        <tr key={user.uid}>
          <td>{user.email}</td>
          <td align={'center'}>
            <IonToggle
              enableOnOffLabels
              onClick={() => handleIfUserShouldGetApartmentUpdates(user.uid)}
              checked={isContactPerson}
            />
          </td>
          <td align={'center'}>
            {apartment?.contactPersons ? (
              <CheckMark
                isChecked={apartment?.contactPersons[0] === user.uid}
                onClick={() => handleUserAsContactPerson(user.uid)}
              />
            ) : null}
          </td>
        </tr>
      );
    });
  };

  const getBusinessCardContact = (): ILandlordProfile => {
    const id = apartment?.contactPersons[0];
    return organizationUserList.find((user) => user.uid === id);
  };

  return (
    <>
      <FormHeader title={c('contactPerson')} />
      <IonGrid>
        <IonRow>
          <IonCol size={'12'}>
            <FormSectionTitle title={c('contactPersons')} subtitle={c('contactPersonsIntroduction')} />
            <div style={{ overflowX: 'scroll' }}>
              <table className={'ws-table'}>
                <thead>
                  <tr>
                    <th align={'left'}>
                      <Text
                        weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                        color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                        tag={'span'}
                        type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                      >
                        {t('user')}
                      </Text>
                    </th>
                    <th>
                      <Text
                        weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                        color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                        tag={'span'}
                        type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                      >
                        {c('getsInformations')}
                      </Text>
                    </th>
                    <th>
                      <Text
                        weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                        color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                        tag={'span'}
                        type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                      >
                        {c('contactPerson')}
                      </Text>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderUserList()}</tbody>
              </table>
            </div>

            <SplitLine />

            <FormSectionTitle title={t('preview')} subtitle={c('previewLandlordCard')} />

            {apartment?.contactPersons?.length ? (
              <LandlordBusinessCard organization={organization} landlordProfile={getBusinessCardContact()} />
            ) : (
              <InfoBox text={c('selectToShowPreview')} />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ApartmentContactPersonsForm;
