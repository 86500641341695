import { createContext } from 'react';
import MixpanelTrackingService from '../service/mixpanel-tracking.service';

export interface IMixpanelTrackingServiceProps {
  mixpanelTrackingService: MixpanelTrackingService;
}

const MixpanelTrackingServiceContext = createContext<IMixpanelTrackingServiceProps>({
  mixpanelTrackingService: null,
});

export default MixpanelTrackingServiceContext;
