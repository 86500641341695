import { FC, useContext } from 'react';
import { IonToggle } from '@ionic/react';
import FormErrorMessage from '../FormErrorMessage';
import { Controller } from 'react-hook-form';
import { FormContext } from '../../../../core/context/form.context';
import styles from './Toggle.module.scss';
import Text, { TEXT_WEIGHT } from '../../typographie/Text';

export interface ISwitchProps {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onFocus?: (event: any) => void;
}

const Toggle: FC<ISwitchProps> = ({ name, label, required = false, disabled = false }) => {
  const { control } = useContext(FormContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={'form-field'}>
          <div className={styles.wrapper}>
            <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {label} {label && required && <span> *</span>}
            </Text>
            <IonToggle
              disabled={disabled}
              value={field.value}
              onIonChange={() => field.onChange(!field.value)}
              slot={'end'}
              checked={!!field.value}
            ></IonToggle>

            <FormErrorMessage error={error} />
          </div>
        </div>
      )}
    />
  );
};

export default Toggle;
