import { FC } from 'react';
import styles from './ContactCTACard.module.scss';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import { Trans, useTranslation } from 'react-i18next';

const ContactCTACard: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.Cards.ContactCTACard' });
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img className={styles.image} width={'50'} height={'50'} src={t('image.src')} alt={t('image.alt')} />

        <div className={styles.textWrapper}>
          <Headline tag={'h2'} size={HEADLINE_SIZE.H3} align={'center'}>
            {t('title')}
          </Headline>

          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'} tag={'p'}>
            <Trans
              i18nKey={t('text')}
              components={{
                link1: (
                  <a
                    className={styles.link}
                    href={'tel:+49 221 95490428'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}
                  />
                ),
                link2: (
                  <a
                    className={styles.link}
                    href={'mailto:hallo@wohnsinn.com'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}
                  />
                ),
              }}
            />
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ContactCTACard;
