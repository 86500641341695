import { IonCol, IonGrid, IonRow } from '@ionic/react';
import TextInput from '../../../../atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../../../atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from '../../../../../core/const/select-options';
import { FormContext } from '../../../../../core/context/form.context';
import { FC, useContext, useEffect, useState } from 'react';
import { APARTMENT_DESIRED_TENANT_SCHEMA, IApartment, IApartmentDesiredTenant } from '@wohnsinn/ws-ts-lib';
import { FirebaseFunctionsContext } from '../../../../../core/context/firebase-functions.context';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../../core/hook/toast.hook';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import FB_FUNCTION_URLS from '../../../../../core/const/fb-function-names';
import FormFooter from '../../../../atoms/FormFooter';
import FormHeader from '../../../../atoms/FormHeader';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import SplitLine from '../../../../atoms/SplitLine';

const ApartmentDesiredTenantForm: FC<{ scrollToTop: () => any; apartment: IApartment }> = ({
  apartment,
  scrollToTop,
}) => {
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const { t } = useTranslation('common');
  const { sendInfoToast } = useToast();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { control, handleSubmit, watch, reset } = useForm<IApartmentDesiredTenant>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(APARTMENT_DESIRED_TENANT_SCHEMA)),
    defaultValues: apartment?.desiredTenant ? apartment.desiredTenant : null,
  });

  useEffect(() => {
    if (!!apartment?.desiredTenant) {
      reset(apartment.desiredTenant);
    }
  }, [apartment]);

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    scrollToTop();
    await sendInfoToast('toast.success');
  };

  const onError = (err: Partial<FieldErrorsImpl<IApartmentDesiredTenant>>): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    if (apartment.id && apartment.desiredTenant) {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentDesiredTenant; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.desiredTenant.update,
          {
            params: { apartmentId: apartment.id },
            body: watch(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    } else {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentDesiredTenant; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.desiredTenant.add,
          {
            params: { apartmentId: apartment.id },
            body: watch(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(saveDataToDB, onError)} noValidate autoComplete="off">
        <FormHeader title={'Wunschmieter:innen'} buttonSubmitMode={buttonSubmitMode} />

        <IonGrid className={'wohnsinn-forms-grid'}>
          <IonRow>
            <IonCol size="12">
              <FormSectionTitle title={'Zur Person'} />
              <TextInput
                name={'numberOfPeopleMovingIn'}
                type={'number'}
                pattern="[0-9]*"
                label={t('numberOfPeopleMovingIn.label')}
              />

              <InputOptionList
                mode={'radio'}
                name={'shouldIncomeBeDoubleWarmRent'}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                label={'Soll das Einkommen der Wunschmieter:innen mind. das 2,5-fache der Warmmiete betragen?'}
                helperText={
                  'Bei Aktivierung dieser Option beträgt das Netto-Haushaltseinkommen aller Bewerbungen mindestens das 2,5-fache der Warmmiete. Bürgschaften sind davon ausgenommen und alle anderen Bewerbungen können auch eingesehen werden.\n'
                }
              />

              <SplitLine />

              <FormSectionTitle title={'Zur Wohnung'} />
              <InputOptionList
                mode={'radio'}
                name={'isSharedUsagePossible'}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                label={'Ist die Wohnung WG geeignet?'}
              />

              <InputOptionList
                mode={'radio'}
                name={'arePetsAllowed'}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                label={'Sind Haustiere (Kleintiere) erlaubt ?'}
              />

              <InputOptionList
                mode={'radio'}
                name={'isHousingEntitlementCertificateMandatory'}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                label={'Ist ein Wohnberechtigungsschein notwendig?'}
                helperText={
                  'Ein Wohnberechtigungsschein ist der offizielle Nachweis, dass der Mieter berechtigt ist, in eine Wohnung („Sozialwohnung“) zu ziehen, die mit öffentlichen Mitteln gefördert wird.'
                }
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentDesiredTenantForm;
