import {
  APARTMENT_TYPE,
  IApartmentAddress,
  IApartmentCost,
  IApartmentDesiredTenant,
  IApartmentEnergySupply,
  IApartmentMainInformation,
  APARTMENT_TYPE_OF_USAGE,
} from '@wohnsinn/ws-ts-lib';
import { IApartmentEquipmentFormValue } from '../../../../component/organisms/forms/apartment/ApartmentEquipmentForm';

export const DEFAULT_DESIRED_TENANT_DATA: IApartmentDesiredTenant = {
  arePetsAllowed: null,
  isHousingEntitlementCertificateMandatory: null,
  isSharedUsagePossible: null,
  numberOfPeopleMovingIn: null,
  shouldIncomeBeDoubleWarmRent: null,
};

export const DEFAULT_APARTMENT_ADDRESS: IApartmentAddress = {
  apartmentType: APARTMENT_TYPE.NONE,
  city: null,
  coordinates: null,
  geoHash: null,
  hasPublishedAddress: null,
  floor: null,
  houseNumber: null,
  postalCode: '',
  street: '',
};

export const DEFAULT_APARTMENT_MAIN_INFORMATION: IApartmentMainInformation = {
  address: {
    city: null,
    houseNumber: null,
    street: null,
    floor: null,
    apartmentType: null,
    postalCode: null,
    coordinates: { lat: null, lng: null },
    geoHash: '#AEC43',
    hasPublishedAddress: null,
  },
  typeOfUsage: APARTMENT_TYPE_OF_USAGE.RESIDE,
  hasMinPeriod: null,
  earliestMoveIn: null,
  hasFixedTerm: null,
  minPeriod: null,
  fixedTerm: new Date(),
  rooms: null,
  size: null,
};

export const DEFAULT_APARTMENT_EQUIPMENT_DATA: IApartmentEquipmentFormValue = {
  description: null,
  kitchenPrice: null,
  parkingSpotRent: null,
  hasKitchen: false,
  hasElevator: false,
  hasGarage: false,
  hasGarden: false,
  hasBalconyOrTerrace: false,
  hasParkingSpot: false,
  isAccessible: false,
  hasBasement: false,
  hasGuestToilet: false,
  isFurnished: false,
  hasParkingSpotRent: false,
  hasKitchenPrice: false,
};

export const DEFAULT_APARTMENT_ENERGY_SUPPLY_DATA: IApartmentEnergySupply = {
  buildYear: null,
  condition: null,
  customHeatingSystem: null,
  energyConsumption: null,
  energyPassType: null,
  description: null,
  energyEfficiency: null,
  heatingSystem: null,
  isEnergyPassAvailable: null,
  isEnergyConsumptionForHotWaterIncluded: null,
  isEnergyPassCreationDateBeforeConsumptionUsageRegulation: null,
  yearOfLastActivity: null,
};

export const DEFAULT_APARTMENT_COST_DATA: IApartmentCost = {
  coldRent: null,
  deposit: null,
  heatingCosts: null,
  isHeatingIncluded: true,
  operationalCosts: null,
  rentType: null,
  warmRent: null,
};
