import { FC, useContext, useEffect, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import InputText from '../../../../atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../../../atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from '../../../../../core/const/select-options';
import Dropdown from '../../../../atoms/formElement/Dropdown';
import { FormContext } from '../../../../../core/context/form.context';
import { FirebaseFunctionsContext } from '../../../../../core/context/firebase-functions.context';
import useToast from '../../../../../core/hook/toast.hook';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { APARTMENT_COST_SCHEMA, IApartment, IApartmentCost, RENT_TYPE } from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import Joi from 'joi';
import FB_FUNCTION_URLS from '../../../../../core/const/fb-function-names';
import FormHeader from '../../../../atoms/FormHeader';
import FormFooter from '../../../../atoms/FormFooter';

export const RENT_TYPE_SELECT_OPTION_LIST = [
  {
    label: 'apartment.cost.typicalAreaRent',
    value: RENT_TYPE.TYPICAL_AREA_RENT,
  },
  {
    label: 'apartment.cost.graduatedRent.label',
    value: RENT_TYPE.GRADUATED_RENT,
  },
  { label: 'apartment.cost.indexRent.label', value: RENT_TYPE.INDEX_RENT },
];

const ApartmentCostsForm: FC<{ scrollToTop: () => any; apartment: IApartment }> = ({ apartment, scrollToTop }) => {
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const { sendInfoToast } = useToast();
  const { t } = useTranslation('common');
  const { t: ac } = useTranslation('common', { keyPrefix: 'apartment.cost' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { control, reset, watch, setValue, handleSubmit } = useForm<IApartmentCost>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(APARTMENT_COST_SCHEMA)),
    defaultValues: apartment?.cost ? apartment.cost : null,
  });
  const coldRent = watch('coldRent');
  const operationalCosts = watch('operationalCosts');
  const heatingCosts = watch('heatingCosts');
  const isHeatingIncluded = watch('isHeatingIncluded');

  useEffect(() => {
    const rent = Number(coldRent) + Number(operationalCosts) + Number(heatingCosts);
    const roundedRent = Math.round((rent + 0.001) * 100) / 100;
    setValue('warmRent', roundedRent);
    setValue('heatingCosts', Number(heatingCosts));
  }, [coldRent, operationalCosts, heatingCosts, setValue, isHeatingIncluded]);

  useEffect(() => {
    if (isHeatingIncluded) setValue('heatingCosts', null);
  }, [isHeatingIncluded]);

  useEffect(() => {
    if (!!apartment?.cost) {
      reset(apartment.cost);
    }
  }, [apartment]);

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    scrollToTop();
    await sendInfoToast('toast.success');
  };

  const onError = (err: Partial<FieldErrorsImpl<IApartmentCost>>): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    if (apartment.cost) {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentCost; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.cost.update,
          {
            params: { apartmentId: apartment.id },
            body: watch(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    } else {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentCost; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.cost.add,
          {
            params: { apartmentId: apartment.id },
            body: watch(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    }
  };
  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(saveDataToDB, onError)} noValidate autoComplete={'off'}>
        <FormHeader title={ac('title')} buttonSubmitMode={buttonSubmitMode} />

        <IonGrid className={'wohnsinn-forms-grid'}>
          <IonRow>
            <IonCol size="12">
              <FormSectionTitle title={ac('rent')} />
              <InputText
                pattern="[0-9]*"
                type={'number'}
                name={'coldRent'}
                label={ac('coldRent.label')}
                helperText={ac('coldRent.helperText')}
                unit={t('currency')}
                required
              />
              <InputText
                pattern="[0-9]*"
                type={'number'}
                name={'operationalCosts'}
                helperText={ac('operationalCost.helperText')}
                label={ac('operationalCost.label')}
                unit={t('currency')}
                required
              />
              <InputOptionList
                options={BOOLEAN_SELECT_OPTIONS}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                mode={'radio'}
                label={ac('isHeatingIncluded.long')}
                name={'isHeatingIncluded'}
              />
              {watch('isHeatingIncluded') === false && (
                <InputText label={ac('heatingCosts')} type={'number'} name={'heatingCosts'} unit={t('currency')} />
              )}
              <InputText
                name={'warmRent'}
                disabled={true}
                pattern="[0-9]*"
                type={'number'}
                label={ac('warmRent.label')}
                unit={t('currency')}
                required
              />
              <Dropdown
                name={'rentType'}
                label={ac('rentType.label')}
                placeholder={ac('rentType.placeholder')}
                optionList={getTranslatedOptions(RENT_TYPE_SELECT_OPTION_LIST, t)}
              />
              <InputText
                type={'text'}
                name={'deposit'}
                label={ac('deposit.label')}
                helperText={ac('deposit.helperText')}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentCostsForm;
