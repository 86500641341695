import { FC } from 'react';
import styles from './MediaUploadDropZone.module.scss';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { UPLOAD_TYPE } from '../DocumentUploadDropZone';

const MediaUploadDropZone: FC<{ text?: string; onDrop: any; multiple?: boolean; uploadType?: UPLOAD_TYPE }> = ({
  text,
  onDrop,
  multiple = false,
  uploadType = UPLOAD_TYPE.APARTMENT_MEDIA,
}) => {
  const { t: c } = useTranslation('common', { keyPrefix: 'component.molecules.MediaUploadDropZone' });

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple,
  });

  return (
    <div {...getRootProps()} className={`${styles.wrapper} ${isDragActive ? styles.activeDrag : ''}`}>
      <input
        {...getInputProps()}
        accept={uploadType === UPLOAD_TYPE.INTRODUCTION_VIDEO ? 'video/mp4, video/quicktime' : 'image/jpeg, image/png'}
      />
      <button className={styles.dropZone} onClick={open} type={'button'}>
        <span className="sr-only"> {text ? text : c('title')}</span>

        <div className={styles.uploadIconWrapper}>
          <FontAwesomeIcon icon={faCloudArrowUp} size={'2x'} />
        </div>

        <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
          {text ? text : c('title')}
        </Text>
        <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
          {uploadType === UPLOAD_TYPE.INTRODUCTION_VIDEO ? c('allowedVideoFiles') : c('allowedImageFiles')}
        </Text>
      </button>
    </div>
  );
};

export default MediaUploadDropZone;
