import { FC } from 'react';
import styles from './Editor.module.scss';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import SubmitOnEnterKlickPlugin from './plugins/SubmitOnEnterKlickPlugin';
import FormControlPlugin from './plugins/FormControlPlugin';
import { ControllerRenderProps } from 'react-hook-form';
import { $createParagraphNode, $getRoot } from 'lexical';
import Text, { TEXT_COLOR } from '../../typographie/Text';

export interface IEditorProps {
  placeholder: string;
  field: ControllerRenderProps;
  disabled: boolean;
  onSubmit: () => void;
  size?: '1x' | '2x';
  isAutofocused?: boolean;
}

const theme = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
};

const onError = (error: Error) => {
  console.error('LexicalEditor Error: ', error);
};

const LexicalEditor: FC<IEditorProps> = ({
  placeholder,
  disabled,
  field,
  size = '1x',
  onSubmit,
  isAutofocused = false,
}) => {
  const initialConfig: InitialConfigType = {
    namespace: 'LexicalEditor',
    theme,
    onError,
    editorState: (editor) => {
      // For autoFocus to work, the editor needs to have a node present in the root.
      const root = $getRoot();
      if (root.isEmpty()) {
        const paragraph = $createParagraphNode();
        root.append(paragraph);
      }
    },
  };

  return (
    <div className={`${styles.wrapper}`}>
      <LexicalComposer initialConfig={initialConfig}>
        <FormControlPlugin field={field} />
        <RichTextPlugin
          contentEditable={
            disabled ? (
              <div className={'editor-disabled'}></div>
            ) : (
              <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_DARK}>
                <ContentEditable className={`editor-input ${size === '2x' ? 'large' : ''}`} />
              </Text>
            )
          }
          placeholder={
            <div className={`${styles.placeholder} ${size === '2x' ? styles.large : ''}`}>{placeholder}</div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        {isAutofocused && <AutoFocusPlugin></AutoFocusPlugin>}
        {onSubmit && <SubmitOnEnterKlickPlugin onSubmit={onSubmit} />}
      </LexicalComposer>
    </div>
  );
};

export default LexicalEditor;
