import ModalWrapper from '../../ModalWrapper';
import { FC, useContext, useState } from 'react';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import ModalContext from '../../../../../core/context/modal.context';
import { useTranslation } from 'react-i18next';
import { numberOptions } from '../FilterModal';
import SuggestionButton from '../../../../atoms/SuggestionButton';
import styles from './FilterRoomsModal.module.scss';
import TenantFilterParamsContext from '../../../../../core/context/tenant-filter-params.context';

const FilterRoomsModal: FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const [rooms, setRooms] = useState(null);

  return (
    <ModalWrapper title={'Wie viele Zimmer brauchst du?'}>
      <div className={styles.suggestionsWrapper}>
        {numberOptions.map((number) => (
          <SuggestionButton key={number.label} onClick={() => console.log('click click')} label={number.label} />
        ))}
      </div>
      <div className={'ion-margin-top'}>
        <CTAButton expand={'block'} onClick={() => closeModal()} buttonText={t('close')} />
      </div>
    </ModalWrapper>
  );
};

export default FilterRoomsModal;
