import { IApartment, IMatchListResponse } from '@wohnsinn/ws-ts-lib';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import TenantFilterParamsContext from '../context/tenant-filter-params.context';
import { useTranslation } from 'react-i18next';
import LOCAL_STORAGE_KEYS from '../enum/local-storage.enum';
// import { useLocation } from 'react-router';
// import useSearchQuery from './search-query.hook';

const APARTMENTS_LIMIT =
  process.env.REACT_APP_ENVIRONMENT === 'production' ||
  process.env.REACT_APP_ENVIRONMENT === 'qa' ||
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 20
    : 10;

const usePaginatedApartmentsHook = (): {
  apartments: IApartment[];
  otherApartments: IApartment[];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  pagesCount: number[];
  isLoading: boolean;
} => {
  const { matchData } = useContext(TenantFilterParamsContext);
  const [apartments, setApartments] = useState<IApartment[]>([]);
  const [otherApartments, setOtherApartments] = useState<IApartment[]>([]);
  const [pagesCount, setPagesCount] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation('common');

  const LocalStorageRatedApartmentIds = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS?.NOT_LOGGED_IN_USER_RATINGS)
  );
  let filteredMatchData: IMatchListResponse = matchData;
  if (LocalStorageRatedApartmentIds) {
    const alreadyRatedApartmentIds: string[] = [
      ...LocalStorageRatedApartmentIds.MAYBE,
      ...LocalStorageRatedApartmentIds.NOPE,
    ];
    const filteredOtherMatches = matchData.otherMatches.filter(
      (apartment) => !alreadyRatedApartmentIds.includes(apartment.id)
    );
    const filteredPerfectMatches = matchData.perfectMatches.filter(
      (apartment) => !alreadyRatedApartmentIds.includes(apartment.id)
    );
    filteredMatchData = {
      ...matchData,
      otherMatches: filteredOtherMatches,
      perfectMatches: filteredPerfectMatches,
    };
  }

  useEffect(() => {
    setIsLoading(true);
    setApartments([...filteredMatchData.perfectMatches].splice((currentPage - 1) * APARTMENTS_LIMIT, APARTMENTS_LIMIT));
    setOtherApartments([...filteredMatchData.otherMatches].splice(0, 10));

    const pages = [];
    for (let i = 1; i <= Math.ceil(filteredMatchData.perfectMatches.length / APARTMENTS_LIMIT); i++) {
      pages.push(i);
    }
    setPagesCount(pages);
    setIsLoading(false);
  }, [matchData]);
  const handleApartmentAndPages = (): void => {
    // WHEN PAGINATION CHANGES, UPDATE APARTMENT LIST
    setApartments([...filteredMatchData.perfectMatches].splice((currentPage - 1) * APARTMENTS_LIMIT, APARTMENTS_LIMIT));
    setOtherApartments([...filteredMatchData.otherMatches].splice(0, 10));
  };

  useEffect(() => {
    handleApartmentAndPages();
  }, [currentPage]);

  return { apartments, otherApartments, currentPage, setCurrentPage, pagesCount, isLoading };
};

export default usePaginatedApartmentsHook;
