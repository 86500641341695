import { IonButtons, IonHeader, IonRouterLink, IonToolbar, useIonRouter } from '@ionic/react';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../core/hook/windowsize.hook';
import styles from './AppHeader.module.scss';
import UserContext from '../../../core/context/user.context';
import { ROUTES } from '../../../core/const/routes';
import BackButton from '../../atoms/Buttons/BackButton';
import DropDownMenu from '../DropDownMenu';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from '../../../core/enum/local-storage.enum';
import DesktopMenu from './DesktopMenu';
import ScrollPositionProviderContext from '../../../core/context/scroll-position-provider.context';

export interface IAppHeader {
  headerStartButton?: any;
  headerEndButton?: any;
  showBackButton?: boolean;
  appHeaderBottomBorder?: boolean;
  menu: any;
  pageTitle?: string;
  showPageTitle?: boolean;
  hideMenu?: boolean;
  fullWidth?: boolean;
  customContent?: boolean;
}

const AppHeader: FC<IAppHeader> = ({
  headerStartButton = null,
  headerEndButton,
  showBackButton,
  menu,
  appHeaderBottomBorder = false,
  pageTitle,
  showPageTitle = false,
  hideMenu = false,
  fullWidth = false,
  customContent,
}) => {
  const { isSmallerLg } = useWindowSize();
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.SettingsModal' });
  const router = useIonRouter();
  const { user, activeUserType, userService, tenantProfile, landlordProfile } = useContext(UserContext);
  const { scrollPos } = useContext(ScrollPositionProviderContext);

  const handleActiveUserTypeSwitch = async () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, '/');

    await userService.setActiveUserType(
      user.uid,
      activeUserType === USER_TYPE.LANDLORD ? USER_TYPE.TENANT : USER_TYPE.LANDLORD
    );

    router.push(t(ROUTES.general.redirect.path));
  };

  const handleUserTypeCreation = async () => {
    const userType = user.isLandlord ? USER_TYPE.TENANT : USER_TYPE.LANDLORD;
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_TYPE, userType);
    await userService.createProfileFromExistingProfile(userType, user, tenantProfile, landlordProfile);
    await handleActiveUserTypeSwitch();
  };

  if (isSmallerLg) {
    if (headerEndButton || headerStartButton || showBackButton || showPageTitle) {
      return (
        <IonHeader
          data-testid="app-header"
          className={`${styles.mobileHeader} ${scrollPos > 5 ? styles.scrolled : ''} ion-no-border ${
            appHeaderBottomBorder ? styles.borderBottom : ''
          }`}
        >
          <IonToolbar className={styles.toolBar}>
            {isSmallerLg && (
              <IonButtons slot="start">
                {headerStartButton ? headerStartButton : showBackButton ? <BackButton /> : null}
              </IonButtons>
            )}
            {showPageTitle ? pageTitle : null}
            {isSmallerLg && <IonButtons slot="end">{headerEndButton ? headerEndButton : null}</IonButtons>}
          </IonToolbar>
        </IonHeader>
      );
    } else {
      return null;
    }
  } else {
    return (
      <div className={styles.wrapper}>
        <div className={`${styles.content} ${fullWidth ? styles.fullWidth : ''}`}>
          <IonRouterLink routerLink={'/'}>
            <div className={`${styles.appLogo} app-logo`} />
          </IonRouterLink>

          {hideMenu ? null : (
            <>
              {customContent ? customContent : null}
              {menu && menu.length && activeUserType === USER_TYPE.LANDLORD ? (
                <DesktopMenu menuItemList={menu} />
              ) : null}
              <div className={styles.accountSection}>
                {!user ? (
                  <CTAButton
                    buttonStyle={BUTTON_STYLE.SECONDARY}
                    link={t(ROUTES.landlord.landing.path)}
                    buttonText={t('advertiseNow')}
                  />
                ) : null}

                {user ? (
                  <CTAButton
                    buttonStyle={BUTTON_STYLE.SECONDARY}
                    onClick={user.isLandlord && user.isTenant ? handleActiveUserTypeSwitch : handleUserTypeCreation}
                    buttonText={activeUserType === USER_TYPE.LANDLORD ? m('setTenantMode') : t('advertiseNow')}
                  />
                ) : null}

                <DropDownMenu menu={menu} />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default AppHeader;
