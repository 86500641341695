import { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import UserContext from '../../../../core/context/user.context';
import { useTranslation } from 'react-i18next';
import { useIonRouter } from '@ionic/react';
import { ROUTES } from '../../../../core/const/routes';
import StepRegisterLandlord from './Steps/RegisterLandlord';
import StepSelectLandlordType from './Steps/SelectLandlordType';
import StepAddOrganization from './Steps/AddOrganization';
import StepConfirmEmail from './Steps/ConfirmEmail';
import StepAddLandlordProfile from './Steps/AddUserProfile';

const LandlordRegistrationTunnel = () => {
  const [isOrganization, setIsOrganization] = useState(false);
  const [allowRedirect, setAllowRedirect] = useState<boolean>(false);
  const { landlordProfile, user } = useContext(UserContext);
  const { t } = useTranslation('common');
  const router = useIonRouter();

  useEffect(() => {
    // REDIRECT IF USER ALREADY LOGGED IN
    if (user && landlordProfile) {
      router.push(t(ROUTES.landlord.apartment.list.path));
    }
  }, []);

  return (
    <Swiper
      onSlideChange={(swiper) => (swiper.allowSlideNext = false)}
      allowSlideNext={false}
      allowSlidePrev={false}
      noSwiping={true}
      draggable={false}
    >
      <SwiperSlide>
        <StepRegisterLandlord />
      </SwiperSlide>
      <SwiperSlide>
        <StepSelectLandlordType setIsOrganization={setIsOrganization} />
      </SwiperSlide>
      <SwiperSlide>
        <StepAddLandlordProfile setAllowRedirect={setAllowRedirect} isOrganization={isOrganization} />
      </SwiperSlide>
      <SwiperSlide>
        <StepAddOrganization setAllowRedirect={setAllowRedirect} />
      </SwiperSlide>
      <SwiperSlide>
        <StepConfirmEmail allowRedirect={allowRedirect} />
      </SwiperSlide>
    </Swiper>
  );
};

export default LandlordRegistrationTunnel;
