import ModalWrapper from '../ModalWrapper';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import { useTranslation } from 'react-i18next';
import styles from './CreateUserInvitationModal.module.scss';
import Text from '../../../atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import UserInviteForm from '../../../organisms/forms/landlord/UserInviteForm';

const CreateUserInvitationModal = () => {
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.CreateUserInvitationModal' });

  return (
    <ModalWrapper noHeadingMargin>
      <div className={styles.modalWrapper}>
        <div>
          <FontAwesomeIcon className={styles.userPlusIcon} icon={faUserPlus} />
        </div>
        <div>
          <Headline tag={'h3'} size={HEADLINE_SIZE.H3}>
            {m('title')}
          </Headline>
          <Text>{m('text')}</Text>
        </div>
        <UserInviteForm />
      </div>
    </ModalWrapper>
  );
};

export default CreateUserInvitationModal;
