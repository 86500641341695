import { IonButton } from '@ionic/react';
import { FC } from 'react';
import styles from './CTAButton.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../typographie/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';

export interface ICTAButtonProps {
  buttonStyle?: BUTTON_STYLE;
  buttonText: string;
  color?: TEXT_COLOR;
  customStyling?: any;
  disabled?: boolean;
  expand?: 'block' | 'full';
  expandMobile?: boolean;
  hideText?: boolean;
  icon?: IconProp;
  img?: string;
  link?: string;
  onClick?: any;
  routerDirection?: 'none' | 'forward' | 'back' | 'root';
  shadow?: boolean;
  size?: 'tiny' | 'small' | 'normal' | 'big';
  spinIcon?: boolean;
  tabIndex?: number;
  type?: 'submit' | 'button' | 'reset';
  rounded?: boolean;
}

export enum BUTTON_STYLE {
  DANGER = 'danger',
  DANGER_INVERTED = 'danger-inverted',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_INVERTED = 'secondary-inverted',
  TERTIARY = 'tertiary',
  TERTIARY_LIGHT = 'tertiary-light',
}

const CTAButton: FC<ICTAButtonProps> = ({
  buttonStyle = BUTTON_STYLE.PRIMARY,
  buttonText,
  color,
  customStyling,
  disabled,
  expand,
  expandMobile = false,
  hideText,
  icon,
  img,
  link,
  onClick,
  routerDirection,
  shadow = false,
  size = 'normal',
  spinIcon = false,
  tabIndex,
  type = 'submit',
  rounded = true,
}) => {
  return (
    <IonButton
      tabIndex={tabIndex}
      className={`
        ${styles.ctaButton} 
        ${styles[size]} 
        ${styles[buttonStyle]} 
        ${customStyling ? customStyling : ''} 
        ${hideText ? styles.hideText : ''}
        ${shadow ? styles.shadow : ''}
        ${expandMobile ? styles.expandMobile : ''}
        ${rounded ? styles.rounded : ''}
      `}
      data-testid={'ctaButton'}
      routerLink={link}
      onClick={onClick}
      expand={expand}
      disabled={disabled}
      routerDirection={routerDirection}
      title={buttonText}
      type={type}
    >
      {img ? (
        <img
          alt={buttonText}
          className={`${styles.img} ${hideText ? styles.hideText : ''} ${size === 'big' ? styles.big : ''}`}
          src={img}
        />
      ) : null}

      {icon ? (
        <div className={`${styles.icon} ${hideText ? styles.hideText : ''} ${size === 'big' ? styles.big : ''}`}>
          <FontAwesomeIcon
            spin={spinIcon}
            width={size === 'tiny' ? '12px' : '23px'}
            height={size === 'tiny' ? '12px' : '23px'}
            icon={icon}
            size={'1x'}
          />
        </div>
      ) : null}
      {hideText ? (
        <span className={'sr-only'}>{buttonText}</span>
      ) : (
        <Text
          color={
            color
              ? color
              : buttonStyle === BUTTON_STYLE.TERTIARY_LIGHT
              ? TEXT_COLOR.TEXT_COLOR_LIGHT
              : BUTTON_STYLE.PRIMARY || buttonStyle === BUTTON_STYLE.DANGER
              ? TEXT_COLOR.TEXT_COLOR_LIGHT
              : TEXT_COLOR.TEXT_COLOR_DARK
          }
          type={size === 'tiny' ? TEXT_TYPE.TEXT_TYPE_CAPTION : TEXT_TYPE.TEXT_TYPE_BODY}
          variant={size === 'small' ? TEXT_VARIANT.TEXT_VARIANT_SMALL : TEXT_VARIANT.TEXT_VARIANT_LARGE}
          weight={size === 'small' ? TEXT_WEIGHT.TEXT_WEIGHT_REGULAR : TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
        >
          {buttonText}
        </Text>
      )}
    </IonButton>
  );
};

export const getSubmitIcon = (submitMode: SUBMIT_BUTTON_MODE): IconProp => {
  switch (submitMode) {
    case SUBMIT_BUTTON_MODE.ERROR:
      return faTimesCircle;
    case SUBMIT_BUTTON_MODE.NONE:
      return null;
    case SUBMIT_BUTTON_MODE.SUBMITTING:
      return faSpinner;
    case SUBMIT_BUTTON_MODE.SUCCESS:
      return faCheckCircle;
    default:
      return null;
  }
};

export default CTAButton;
