import ModalWrapper from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import styles from './FilterModal.module.scss';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import TextInput from '../../../../atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../../../atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from '../../../../../core/const/select-options';
import { FormContext } from '../../../../../core/context/form.context';
import { useContext } from 'react';
import TenantFilterParamsContext from '../../../../../core/context/tenant-filter-params.context';
import { useForm } from 'react-hook-form';
import { EQUIPMENT, ISelectOption, ITenantFilterParams } from '@wohnsinn/ws-ts-lib';
import ModalContext from '../../../../../core/context/modal.context';
import { DEFAULT_TENANT_FILTER_PARAMS } from '../../../../../core/service/tenant-filter-params.service';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import SplitLine from '../../../../atoms/SplitLine';
import ApartmentInLocationFinder from '../../../ApartmentInLocationFinder';

export const numberOptions: ISelectOption[] = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '+6' },
];

export const EQUIPMENT_LIST_SELECT_OPTIONS: ISelectOption<EQUIPMENT>[] = [
  {
    label: 'apartment.equipment.equipmentList.HAS_BASEMENT',
    value: EQUIPMENT.HAS_BASEMENT,
  },
  {
    label: 'apartment.equipment.equipmentList.HAS_PARKING_SPOT',
    value: EQUIPMENT.HAS_PARKING_SPOT,
  },
  {
    label: 'apartment.equipment.equipmentList.HAS_GARDEN',
    value: EQUIPMENT.HAS_GARDEN,
  },
  {
    label: 'apartment.equipment.equipmentList.HAS_GUEST_TOILET',
    value: EQUIPMENT.HAS_GUEST_TOILET,
  },
  {
    label: 'apartment.equipment.equipmentList.HAS_ELEVATOR',
    value: EQUIPMENT.HAS_ELEVATOR,
  },
  {
    label: 'apartment.equipment.equipmentList.HAS_KITCHEN',
    value: EQUIPMENT.HAS_KITCHEN,
  },
  {
    label: 'apartment.equipment.equipmentList.HAS_BALCONY_OR_TERRACE',
    value: EQUIPMENT.HAS_BALCONY_OR_TERRACE,
  },
];

const FilterModal = () => {
  const { t } = useTranslation('common');
  const { t: f } = useTranslation('common', { keyPrefix: 'component.molecules.modals.MatchesFilterModal' });
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { control, watch, handleSubmit } = useForm<ITenantFilterParams>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: tenantFilterParams ? tenantFilterParams : DEFAULT_TENANT_FILTER_PARAMS,
  });
  const { closeModal, openModal } = useContext(ModalContext);
  const value = watch();
  const updateTenantFilterParamsHandler = async () => {
    await updateTenantFilterParams({
      ...value,
      id: tenantFilterParams.id,
    });
    closeModal();
  };

  return (
    <ModalWrapper title={f('title')}>
      <FormContext.Provider value={{ control }}>
        <form className={styles.form} onSubmit={handleSubmit(updateTenantFilterParamsHandler)}>
          <ApartmentInLocationFinder />
          <div className={styles.filterSection}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {t('component.organisms.searchTunnelSlides.WarmRent.title')}
            </Text>
            <TextInput
              name={'warmRent'}
              unit={t('currency')}
              pattern="[0-9]*"
              minNumber={10}
              type={'number'}
              label={t('component.organisms.searchTunnelSlides.WarmRent.placeholder')}
            />
          </div>
          <div className={styles.filterSection}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {t('component.organisms.searchTunnelSlides.NumberOfPeopleMovingIn.title')}
            </Text>
            <InputOptionList
              mode={'radio'}
              name={'numberOfPeopleMovingIn'}
              options={numberOptions}
              columns={RADIO_INPUT_COLUMNS_COUNT.THREE}
            />
          </div>

          <div className={styles.filterSection}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {t('component.organisms.searchTunnelSlides.ArePetsAllowed.title')}
            </Text>
            <InputOptionList
              mode={'radio'}
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
              options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
              name={'arePetsAllowed'}
            />
          </div>

          <div className={styles.filterSection}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {t('component.organisms.searchTunnelSlides.IsSharedUsagePossible.title')}
            </Text>
            <InputOptionList
              mode={'radio'}
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
              options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
              name={'isSharedUsagePossible'}
            />
          </div>

          <SplitLine size={'small'} />

          <div className={styles.filterSection}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {t('component.organisms.searchTunnelSlides.Rooms.title')}
            </Text>
            <InputOptionList
              mode={'radio'}
              name={'rooms'}
              options={numberOptions}
              columns={RADIO_INPUT_COLUMNS_COUNT.THREE}
            />
          </div>
          <div className={styles.filterSection}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {t('component.organisms.searchTunnelSlides.Size.title')}
            </Text>
            <TextInput
              name={'size'}
              pattern="[0-9]*"
              type={'number'}
              unit={`m²`}
              minNumber={1}
              label={t('component.organisms.searchTunnelSlides.Size.placeholder')}
            />
          </div>
          <div className={styles.filterSection}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {t('component.organisms.searchTunnelSlides.Equipment.title')}
            </Text>
            <div>
              <InputOptionList
                mode={'checkbox'}
                name={'equipmentList'}
                options={getTranslatedOptions(EQUIPMENT_LIST_SELECT_OPTIONS, t)}
              />
            </div>
          </div>

          <button
            style={{ display: 'block', width: '100%' }}
            type={'button'}
            onClick={() => openModal({ id: MODAL_IDENTIFIER.FILTER_RESET })}
          >
            <Text align={'center'} tag={'p'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_DANGER}>
              Filter zurücksetzen
            </Text>
          </button>

          <div className={`${styles.handleButton} ${styles.top}`}>
            <CTAButton rounded={false} type={'submit'} expand={'block'} buttonText={f('showResults')} />
          </div>
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default FilterModal;
