import { useTranslation } from 'react-i18next';
import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { Helmet } from 'react-helmet-async';
import LandlordLandingNavigation from './LandlordLandingNavigation';
import LandlordLandingHero from './LandlordLandingHero';
import LandlordLandingCrmSection from './LandlordLandingCrmSection';
import LandlordLandingAboutWohnsinnSection from './LandlordLandingAboutWohnsinnSection';
import LandlordLandingWohnsinnProsSection from './LandlordLandingWohnsinnProsSection';
import LandlordLandingWohnsinnExplainedSection from './LandlordLandingWohnsinnExplainedSection';
import LandlordLandingCustomersSection from './LandlordLandingCustomersSection';
import LandlordLandingTestimonialSection from './LandlordLandingTestimonialSection';
import LandlordLandingMediaSection from './LandlordLandingMediaSection';
import LandlordLandingFooter from './LandlordLandingFooter';

const LandlordLandingView = () => {
  const { t } = useTranslation('common');
  return (
    <IonPage>
      <Helmet>
        <title>{`${t('appTitle')} - ${t('Inserieren mit Wohnsinn')}`}</title>
      </Helmet>
      <IonContent>
        <IonGrid fixed className={'ion-no-padding'}>
          <LandlordLandingNavigation />

          <LandlordLandingHero />

          <LandlordLandingCrmSection />

          <LandlordLandingWohnsinnExplainedSection />

          <LandlordLandingWohnsinnProsSection />

          <LandlordLandingCustomersSection />

          <LandlordLandingAboutWohnsinnSection />

          <LandlordLandingTestimonialSection />

          <LandlordLandingMediaSection />

          <LandlordLandingFooter />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LandlordLandingView;
