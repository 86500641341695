import Headline, { HEADLINE_SIZE } from '../typographie/Headline';
import CTAButton, { getSubmitIcon } from '../Buttons/CTAButton';
import styles from './FormHeader.module.scss';
import { FC } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import Text, { TEXT_COLOR } from '../typographie/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

const FormHeader: FC<{
  customCtaIcon?: IconProp;
  customOnClick?: () => any;
  customCtaButtonTitle?: string;
  buttonSubmitMode?: SUBMIT_BUTTON_MODE;
  title: string;
  subtitle?: string;
}> = ({ buttonSubmitMode, title, subtitle, customCtaButtonTitle, customOnClick, customCtaIcon }) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.formHeader}>
      <div>
        <Headline tag={'span'} size={HEADLINE_SIZE.H3}>
          {title}
        </Headline>{' '}
        <br />
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {subtitle}
        </Text>
      </div>
      {customOnClick && customCtaButtonTitle ? (
        <CTAButton
          onClick={customOnClick}
          type={'button'}
          icon={customCtaIcon ? customCtaIcon : null}
          size={'small'}
          buttonText={customCtaButtonTitle}
        />
      ) : null}

      {buttonSubmitMode ? (
        <CTAButton
          spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
          icon={getSubmitIcon(buttonSubmitMode)}
          size={'small'}
          buttonText={t('save')}
        />
      ) : null}
    </div>
  );
};

export default FormHeader;
