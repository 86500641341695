import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from '../../../../core/context/modal.context';
import VirtualTour from '../../../atoms/VirtualTour';
import styles from './VirtualTourModal.module.scss';

const VirtualTourModal: FC = () => {
  const { modalData: matterPortId } = useContext(ModalContext);

  return (
    <ModalWrapper noContentPadding title={'Virtuelle 3D Tour'}>
      <div className={styles.virtualTourWrapper}>
        <VirtualTour matterportId={matterPortId} playInFrame={true} />
      </div>
    </ModalWrapper>
  );
};

export default VirtualTourModal;
