import { useContext } from 'react';
import ModalWrapper from '../ModalWrapper';
import ModalContext from '../../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Toggle from '../../../atoms/formElement/Toggle';
import UserContext from '../../../../core/context/user.context';
import { useTranslation } from 'react-i18next';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { IonCol, IonGrid } from '@ionic/react';
import { INotificationSettings } from '@wohnsinn/ws-ts-lib';
import { FormContext } from '../../../../core/context/form.context';
import useToast from '../../../../core/hook/toast.hook';
import CTAButton from '../../../atoms/Buttons/CTAButton';

const NotificationsModal = () => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.modals.NotificationsModal' });
  const { t: c } = useTranslation('common');
  const { user, userService } = useContext(UserContext);
  const { sendSuccessToast } = useToast();

  const { handleSubmit, control } = useForm<{ isMailEnabled: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: { isMailEnabled: user.notificationSettings.isMailEnabled },
  });

  const onSuccess = async (formValue: Partial<INotificationSettings>): Promise<void> => {
    await userService.updateUserNotificationSettings(user.uid, { isMailEnabled: formValue.isMailEnabled });
    await sendSuccessToast('toast.notificationSettingsUpdated');
  };

  const onError = (err: Partial<FieldErrorsImpl<INotificationSettings>>): void => {
    console.error(err);
  };

  return (
    <ModalWrapper backFunction={() => openModal({ id: MODAL_IDENTIFIER.SETTINGS })} title={'Benachrichtigungen'}>
      <Headline size={HEADLINE_SIZE.H3}>Werde über neue Matches benachrichtigt</Headline>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(onSuccess, onError)} noValidate>
          <IonGrid className={'wohnsinn-forms-grid'}>
            <IonCol size="12" size-md="6">
              <Toggle label={t('emailNotifications')} name={'isMailEnabled'} />
            </IonCol>
          </IonGrid>
          <CTAButton type={'submit'} expand={'block'} buttonText={c('save')} />
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default NotificationsModal;
