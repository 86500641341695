import { FC } from 'react';
import PageLayout from '../../../component/organisms/PageLayout';
import styles from './CreateSchufaView.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../core/const/routes';

const CreateSchufaView: FC = () => {
  const { t } = useTranslation('common');

  return (
    <PageLayout showBackButton pageTitle={t(ROUTES.services.createSchufa.title)}>
      <iframe
        className={styles.responsiveIframe}
        src="https://bonitaetscheck.meineschufa.de/econ/process/14/bonitaetscheck-ms"
      />
    </PageLayout>
  );
};

export default CreateSchufaView;
