import styles from './LandlordLandingWohnsinnProsSection.module.scss';
import Text, { TEXT_VARIANT } from '../../../../../component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import Headline from '../../../../../component/atoms/typographie/Headline';

const LandlordLandingWohnsinnProsSection = () => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.label}>
            <Headline align={'left'}>Das innovativste Portal auf dem Markt</Headline>
          </div>
          <div className={styles.compare}>
            <div className={styles.logo}>
              <img src={t('logos.wohnsinn_app_logo')} alt="wohnsinn logo" />
            </div>
            <div className={styles.logo}>
              <img src={t('logos.immoscout_app_logo')} alt="immoscout logo" />
            </div>
            <div className={`${styles.hideMobile} ${styles.logo}`}>
              <img src={t('logos.immowelt_app_logo')} alt="immowelt logo" />
            </div>
            <div className={`${styles.hideMobile} ${styles.logo}`}>
              <img src={t('logos.kleinanzeigen_app_logo')} alt="kleinanzeigen logo" />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <Text>Smarte Wunschmieter-Filterung für passgenaue Anfragen</Text>
          </div>
          <div className={styles.compare}>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>
            <div>
              <Text align={'center'} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
                teilweise
              </Text>
            </div>
            <div className={styles.hideMobile}>-</div>
            <div className={styles.hideMobile}>-</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <Text>OpenImmo Schnittstelle für einfach Datenübertragung</Text>
          </div>
          <div className={styles.compare}>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>

            <div className={`${styles.hideMobile} ${styles.check}`}>
              <img src={t('icons.check')} alt="" />
            </div>
            <div className={`${styles.hideMobile} ${styles.check}`}>
              <img src={t('icons.check')} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <Text>Verifizierte Interessenten bei jeder Anfragen</Text>
          </div>
          <div className={styles.compare}>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>
            <div>-</div>
            <div className={styles.hideMobile}>-</div>
            <div className={styles.hideMobile}>-</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <Text>Userverwaltung für dein Unternehmen</Text>
          </div>
          <div className={styles.compare}>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>

            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>
            <div className={styles.hideMobile}>-</div>
            <div className={styles.hideMobile}>-</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <Text>Vorstellungsvideos der Interessenten für einen persönlichen ersten Eindruck</Text>
          </div>
          <div className={styles.compare}>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>
            <div>-</div>
            <div className={styles.hideMobile}>-</div>
            <div className={styles.hideMobile}>-</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <Text>Transparente Preisstruktur</Text>
          </div>
          <div className={styles.compare}>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>

            <div>-</div>

            <div className={styles.hideMobile}>-</div>

            <div className={`${styles.check} ${styles.hideMobile}`}>
              <img src={t('icons.check')} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <Text>Digitale Mietverträge mit eigenen Vorlagen und rechtssicherer Signatur.</Text>
          </div>
          <div className={styles.compare}>
            <div className={styles.check}>
              <img src={t('icons.check')} alt="" />
            </div>
            <div>-</div>
            <div className={styles.hideMobile}>-</div>
            <div className={styles.hideMobile}>-</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandlordLandingWohnsinnProsSection;
