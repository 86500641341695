import { useIonRouter } from '@ionic/react';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import InputText from '../../../../atoms/formElement/InputText';
import ProfileAvatar from '../../../../molecules/ProfileAvatar';
import { FormContext } from '../../../../../core/context/form.context';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { ILandlordPersonalInformation, LANDLORD_PERSONAL_INFORMATION_SCHEMA } from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect, useState } from 'react';
import UserContext from '../../../../../core/context/user.context';
import ModalContext from '../../../../../core/context/modal.context';
import useToast from '../../../../../core/hook/toast.hook';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import CTAButton, { BUTTON_STYLE } from '../../../../atoms/Buttons/CTAButton';
import FormFooter from '../../../../atoms/FormFooter';
import FormHeader from '../../../../atoms/FormHeader';
import SplitLine from '../../../../atoms/SplitLine';
import { ILandlordAccountFormProps } from '../../../../../view/landlord/account/AccountView';

const LandlordProfileForm: FC<ILandlordAccountFormProps> = ({ scrollToTop }) => {
  const { landlordProfile, userService, authService } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { sendInfoToast } = useToast();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.SettingsModal' });
  const router = useIonRouter();

  useEffect(() => {
    if (landlordProfile?.personalInformation) {
      reset(landlordProfile.personalInformation);
    } else {
      reset();
    }
  }, [landlordProfile]);

  const { control, watch, handleSubmit, reset } = useForm<ILandlordPersonalInformation>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(LANDLORD_PERSONAL_INFORMATION_SCHEMA)),
  });

  const onSuccess = async (): Promise<void> => {
    scrollToTop();
    await setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    await sendInfoToast('toast.profileUpdated');
  };

  const updateLandlordProfile = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    return userService.updateLandlordPersonalInformation(landlordProfile.uid, watch()).then(onSuccess);
  };

  const handleError = (err: any) => {
    console.error('Error on LandlordProfileForm: ', err);
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(updateLandlordProfile, (err) => handleError(err))}>
        <FormHeader
          subtitle={'Verwalte dein persönliches Profil und hinterlege ein Profilbild.'}
          title={'Kundendaten'}
        />
        <FormSectionTitle title={t('Profilbild')} />
        <ProfileAvatar profile={landlordProfile} />

        <SplitLine />

        <FormSectionTitle title={t('generalInformation')} />
        <InputText label={t('firstName.label')} name={'firstName'} required />
        <InputText label={t('lastName.label')} name={'lastName'} required />
        <InputText
          type={'tel'}
          label={t('phoneNumber.label')}
          name={'phoneNumber'}
          required
          tooltipText={'Nur angeben, wenn ein telefonischer Kontakt von Interessenten:innen gewünscht ist.'}
        />

        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
      <SplitLine />

      <FormSectionTitle title={t('Kontoverwaltung')} />
      <CTAButton
        buttonText={t('view.AccountView.deleteAccount')}
        onClick={() =>
          openModal({
            id: MODAL_IDENTIFIER.DELETE_MODAL,
            data: {
              deleteHandler: () => authService.deleteUser(router, t).then(() => closeModal()),
              title: m('delete.title'),
              text: m('delete.text'),
            },
          })
        }
        size={'small'}
        type={'button'}
        buttonStyle={BUTTON_STYLE.DANGER}
      />
    </FormContext.Provider>
  );
};

export default LandlordProfileForm;
