import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import ModalContext from '../../../../core/context/modal.context';
import Text from '../../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import SanitizedHTML from '../../../atoms/SanitzedHtml';

const InfoTextModal: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { t } = useTranslation('common');

  return (
    <ModalWrapper title={modalData?.title ? modalData.title : 'Info'}>
      {modalData?.text ? <Text>{modalData.text}</Text> : null}
      {modalData?.sanitizeText ? (
        <Text>
          <SanitizedHTML dirty={modalData.sanitizeText} />
        </Text>
      ) : null}
      <div className={'ion-margin-top'}>
        <CTAButton expand={'block'} onClick={() => closeModal()} buttonText={t('close')} />
      </div>
    </ModalWrapper>
  );
};

export default InfoTextModal;
