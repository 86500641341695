import { FC, PropsWithChildren } from 'react';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import styles from './PageTitle.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../atoms/typographie/Text';
import CTAButton, { BUTTON_STYLE, getSubmitIcon } from '../../atoms/Buttons/CTAButton';
import { IonCol, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import { INotSubmitButton } from '../../organisms/FormLayout';
import Badge, { BADGE_COLORS } from '../../atoms/Badge';

export interface IPageTitleProps {
  pageTitle: string;
  secondPageTitle?: string;
  showSubmitButton?: boolean;
  showSecondActionButton?: boolean;
  notSubmitButton?: INotSubmitButton;
  submitMode?: SUBMIT_BUTTON_MODE;
  center?: boolean;
  badgeText?: string;
  badgeColor?: BADGE_COLORS;
}

const PageTitle: FC<PropsWithChildren<IPageTitleProps>> = ({
  children,
  pageTitle,
  secondPageTitle,
  showSubmitButton = false,
  submitMode,
  notSubmitButton,
  center = false,
  badgeText,
  badgeColor = BADGE_COLORS.PRIMARY,
}) => {
  const { t } = useTranslation('common');

  return (
    <IonRow>
      <IonCol size={'12'} className={'ion-no-padding'}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <div className={styles.badgeWrapper}>
              <Headline size={HEADLINE_SIZE.H2} tag={'span'} align={center ? 'center' : 'left'}>
                {pageTitle}
              </Headline>
              {badgeText ? (
                <div>
                  <Badge text={badgeText} color={badgeColor} />
                </div>
              ) : null}
            </div>
            {secondPageTitle && (
              <Text
                align={center ? 'center' : 'left'}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                type={TEXT_TYPE.TEXT_TYPE_BODY}
                tag={'p'}
              >
                {secondPageTitle}
              </Text>
            )}
          </div>

          {children}

          <div className={styles.submitButton}>
            {notSubmitButton ? (
              <div className={styles.submitButton}>
                <CTAButton
                  type={'button'}
                  onClick={notSubmitButton.action}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={notSubmitButton.text}
                />
              </div>
            ) : null}
            {showSubmitButton && (
              <CTAButton
                icon={getSubmitIcon(submitMode)}
                spinIcon={submitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                buttonText={t('save')}
              />
            )}
          </div>
        </div>
      </IonCol>
    </IonRow>
  );
};

export default PageTitle;
