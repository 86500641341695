import { FC, useContext, useState } from 'react';
import TextInput from '../../../../atoms/formElement/InputText';
import CTAButton, { getSubmitIcon } from '../../../../atoms/Buttons/CTAButton';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { FormContext } from '../../../../../core/context/form.context';
import { useFieldArray, useForm } from 'react-hook-form';
import FB_FUNCTION_URLS from '../../../../../core/const/fb-function-names';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../../../../core/context/modal.context';
import { FirebaseFunctionsContext } from '../../../../../core/context/firebase-functions.context';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import UserContext from '../../../../../core/context/user.context';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import useToast from '../../../../../core/hook/toast.hook';

const UserInviteForm: FC<{ onSuccess?: () => any }> = ({ onSuccess }) => {
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.CreateUserInvitationModal' });
  const { activeUserType, landlordProfile, tenantProfile } = useContext(UserContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState(SUBMIT_BUTTON_MODE.NONE);
  const { sendSuccessToast } = useToast();
  const { t } = useTranslation('common');
  const { closeModal } = useContext(ModalContext);
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const landlordName: string =
    landlordProfile?.personalInformation?.firstName && landlordProfile?.personalInformation?.lastName
      ? `${landlordProfile?.personalInformation.firstName} ${landlordProfile?.personalInformation.lastName}`
      : '';
  const tenantName: string =
    tenantProfile?.personalInformation?.firstName && tenantProfile?.personalInformation?.lastName
      ? `${tenantProfile?.personalInformation.firstName} ${tenantProfile?.personalInformation.lastName}`
      : '';
  const senderName: string = activeUserType === USER_TYPE.LANDLORD ? landlordName : tenantName;
  const organizationId = landlordProfile.organizationId;

  const { control, handleSubmit, watch } = useForm<{ invitedUsers: { email: string }[] }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      invitedUsers: [{ email: '' }],
    },
  });

  const { fields } = useFieldArray({
    name: 'invitedUsers',
    control,
  });

  const submitForm = async (formData: any) => {
    const newData = formData;

    newData.invitedUsers = formData.invitedUsers.map((user: { email: string }) => {
      return {
        email: user.email.toLowerCase(),
      };
    });

    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.sendUserInvitationMail, {
      ...newData,
      senderName,
      organizationId,
    });

    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    if (onSuccess) {
      await sendSuccessToast('Nutzer:in erfolgreich eingeladen.');
      onSuccess();
    }
    closeModal();
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form autoComplete={'off'} onSubmit={handleSubmit(submitForm, (error) => console.error(error))}>
        <FormSectionTitle
          title={'Neuen Benutzer einladen'}
          subtitle={'Lade weitere Benutzer ein, diesem Unternehmen beizutreten.\n' + '\n'}
        />
        {fields.map((field, index) => (
          <TextInput key={field.id} label={t('email.label')} name={`invitedUsers[${index}].email`} type={'email'} />
        ))}

        <CTAButton
          icon={getSubmitIcon(buttonSubmitMode)}
          spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
          disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.ERROR || buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
          expand="block"
          buttonText={m('buttonText')}
        />
      </form>
    </FormContext.Provider>
  );
};

export default UserInviteForm;
