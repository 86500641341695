import { FC } from 'react';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import AccountBreadcrumbs from '../../../../component/molecules/Breadcrumbs/AccountBreadcrumbs';
import { useTranslation } from 'react-i18next';
import TenantAboutMeForm from '../../../../component/organisms/forms/tenant/TenantAboutMeForm';
import TunnelStepper, {
  ITunnelStepperStepProps,
  TUNNEL_STEP_STATE,
} from '../../../../component/organisms/Tunnels/TunnelStepper';

export interface IAccountStepperViewProps {
  isApplicationFolderTunnel?: boolean;
}

const APPLICATION_FOLDER_STEPS_ABOUT_ME: ITunnelStepperStepProps[] = [
  { label: 'Profil', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenant.account.applicationFolder.profile },
  { label: 'Haushalt', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenant.account.applicationFolder.household },
  { label: 'Über mich', state: TUNNEL_STEP_STATE.CURRENT, link: ROUTES.tenant.account.applicationFolder.aboutMe },
  {
    label: 'Vorstellungsvideo',
    state: TUNNEL_STEP_STATE.INACTIVE,
    link: ROUTES.tenant.account.applicationFolder.introductionVideo,
  },
  { label: 'Dokumente', state: TUNNEL_STEP_STATE.INACTIVE, link: ROUTES.tenant.account.applicationFolder.incomeProof },
];

const AboutMeView: FC<IAccountStepperViewProps> = ({ isApplicationFolderTunnel = false }) => {
  const { t } = useTranslation('common');

  return (
    <PageLayout pageTitle={t(ROUTES.tenant.account.aboutMe.title)} showBackButton>
      {isApplicationFolderTunnel ? (
        <TunnelStepper steps={APPLICATION_FOLDER_STEPS_ABOUT_ME} />
      ) : (
        <AccountBreadcrumbs page={ROUTES.tenant.account.aboutMe} />
      )}

      <TenantAboutMeForm isApplicationFolderTunnel={isApplicationFolderTunnel} />
    </PageLayout>
  );
};

export default AboutMeView;
