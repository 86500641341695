import { createContext } from 'react';
import { ApartmentService } from '../service/apartment.service';

export interface IApartmentServiceContextProps {
  apartmentService: ApartmentService;
}

const ApartmentServiceContext = createContext<IApartmentServiceContextProps>({
  apartmentService: null,
});

export default ApartmentServiceContext;
