import ModalWrapper from '../ModalWrapper';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { useIonRouter } from '@ionic/react';
import { ROUTES } from '../../../../core/const/routes';
import { FC, useContext } from 'react';
import ModalContext from '../../../../core/context/modal.context';

const MissingApplicationFolderModal: FC = () => {
  const { t } = useTranslation('common');
  const router = useIonRouter();
  const { closeModal } = useContext(ModalContext);

  const handleCompleteHouseholdClick = () => {
    closeModal();
    router.push(t(ROUTES.tenant.account.overview.path));
  };

  return (
    <ModalWrapper title={t('Der letzte Schritt vor der Bewerbung')}>
      Um dich auf die Wohnung zu bewerben benötigst du eine vollständige Bewerbermappe
      <CTAButton onClick={handleCompleteHouseholdClick} buttonText={'Bewerbermappe vervollständigen'} />
    </ModalWrapper>
  );
};

export default MissingApplicationFolderModal;
