import { createContext, Dispatch, SetStateAction } from 'react';
import { IApartment } from '@wohnsinn/ws-ts-lib';

export interface IPaginatedApartmentsContextProps {
  apartments: IApartment[];
  otherApartments: IApartment[];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  pagesCount: number[];
}

const ApartmentServiceContext = createContext<IPaginatedApartmentsContextProps>({
  apartments: null,
  otherApartments: null,
  currentPage: null,
  setCurrentPage: null,
  pagesCount: null,
});

export default ApartmentServiceContext;
