import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import TextInput from '../../../../atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../../../atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from '../../../../../core/const/select-options';
import { FormContext } from '../../../../../core/context/form.context';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import UserContext from '../../../../../core/context/user.context';
import useToast from '../../../../../core/hook/toast.hook';
import { DEFAULT_HOUSEHOLD_DATA } from '../../../../../view/tenant/account/HouseholdView';
import { useTranslation } from 'react-i18next';
import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { IHousehold, HOUSEHOLD_SCHEMA } from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { FC, useContext, useEffect, useState } from 'react';
import { ITenantFormProps } from '../TenantProfileForm';
import FormLayout from '../../../FormLayout';
import PageTitle from '../../../../molecules/PageTitle';
import { ROUTES } from '../../../../../core/const/routes';
import { TENANT_PETS_SELECT_OPTION_LIST } from '../../apartment/ApartmentDesiredTenantForm/desired-tenant-form-select-options';

const TenantHouseHoldForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantProfile, userService } = useContext(UserContext);
  const { sendSuccessToast } = useToast();
  const { t } = useTranslation('common');
  const { t: h } = useTranslation('common', { keyPrefix: 'view.AccountView.HouseholdView' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const router = useIonRouter();

  const { control, watch, handleSubmit, setValue } = useForm<IHousehold>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(HOUSEHOLD_SCHEMA)),
    defaultValues: tenantProfile?.household ? tenantProfile?.household : DEFAULT_HOUSEHOLD_DATA,
  });
  const numberOfPeopleMovingIn = watch('numberOfPeopleMovingIn');
  const numberOfChildrenMovingIn = watch('numberOfChildrenMovingIn');

  useEffect(() => {
    if (numberOfPeopleMovingIn === 1) {
      setValue('numberOfChildrenMovingIn', null);
    }
  }, [numberOfPeopleMovingIn]);

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    try {
      await sendSuccessToast('toast.profileUpdated');

      if (isApplicationFolderTunnel) {
        router.push(t(ROUTES.tenant.account.applicationFolder.aboutMe), 'forward');
      } else {
        router.push(t(ROUTES.tenant.account.overview.path), 'back');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateTenantHousehold = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    return userService.updateTenantHousehold(tenantProfile.uid, watch()).then(onSuccess);
  };

  const CANCEL_BUTTON = {
    text: t('cancel'),
    action: () => router.push(t(ROUTES.tenant.account.overview.path), 'back'),
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(updateTenantHousehold, (err) => console.error(err))}>
        {!isApplicationFolderTunnel && (
          <PageTitle
            notSubmitButton={CANCEL_BUTTON}
            pageTitle={t(ROUTES.tenant.account.household.title)}
            showSubmitButton
            submitMode={buttonSubmitMode}
          />
        )}
        <FormLayout submitMode={buttonSubmitMode} notSubmitButton={isApplicationFolderTunnel ? null : CANCEL_BUTTON}>
          <IonGrid className={'wohnsinn-forms-grid'}>
            <IonRow className={'wohnsinn-form-section'}>
              <IonCol size={'12'} sizeMd={'6'}>
                <FormSectionTitle title={h('personalInformation.personCount')} />
                <TextInput
                  required
                  pattern="[0-9]*"
                  unit={
                    numberOfPeopleMovingIn > 1
                      ? t('numberOfPeopleMovingIn.unit.plural')
                      : t('numberOfPeopleMovingIn.unit.singular')
                  }
                  name={'numberOfPeopleMovingIn'}
                  type={'number'}
                  label={t('numberOfPeopleMovingIn.label')}
                />

                {numberOfPeopleMovingIn > 1 && (
                  <TextInput
                    unit={
                      numberOfChildrenMovingIn > 1
                        ? t('numberOfChildrenMovingIn.unit.plural')
                        : t('numberOfChildrenMovingIn.unit.singular')
                    }
                    pattern="[0-9]*"
                    name={'numberOfChildrenMovingIn'}
                    type={'number'}
                    label={t('numberOfChildrenMovingIn.label')}
                  />
                )}

                <FormSectionTitle title={h('personalInformation.monthlyIncomeTitle')} />
                <TextInput
                  name={'monthlyIncome'}
                  type={'number'}
                  pattern="[0-9]*"
                  label={t('view.AccountView.HouseholdView.personalInformation.monthlyIncome')}
                  unit={t('currency')}
                />

                <FormSectionTitle title={h('personalInformation.sharedUsageTitle')} />
                <InputOptionList
                  mode={'radio'}
                  name={'isSharedUsagePossible'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                />
              </IonCol>
              <IonCol size="12" size-md="6">
                <FormSectionTitle title={t('arePetsAllowed.label')} />
                <InputOptionList
                  mode={'radio'}
                  name={'arePetsAllowed'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                />
                {watch('arePetsAllowed') && (
                  <InputOptionList
                    mode={'radio'}
                    name={'pets'}
                    options={getTranslatedOptions(TENANT_PETS_SELECT_OPTION_LIST, t)}
                    columns={RADIO_INPUT_COLUMNS_COUNT.THREE}
                    label={t('arePetsAllowed.label')}
                  />
                )}

                <FormSectionTitle title={t('hasPledge.label')} />
                <InputOptionList
                  mode={'radio'}
                  name={'hasPledge'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                />
                <FormSectionTitle title={t('canBeSubsidized')} />
                <InputOptionList
                  mode={'radio'}
                  name={'canBeSubsidized'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </FormLayout>
      </form>
    </FormContext.Provider>
  );
};

export default TenantHouseHoldForm;
