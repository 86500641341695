import { useContext } from 'react';
import ModalWrapper from '../ModalWrapper';
import ModalContext from '../../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import CTACard from '../../Cards/CTACard';
import { useTranslation } from 'react-i18next';

const ContactModal = () => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');

  return (
    <ModalWrapper backFunction={() => openModal({ id: MODAL_IDENTIFIER.SETTINGS })} title={'Kontakt & Hilfe'}>
      <CTACard
        title={t('Hast du noch fragen?')}
        text={t('Schreibe uns gerne eine E-Mail oder ruf an.')}
        imgAltText={t('todo')}
        imgSrc={t('/assets/images/tenant-dashboard-cta.png')}
        link={'mailto:kontakt@wohnsinn.com'}
        ctaText={t('Direkt einen Termin buchen')}
      />
    </ModalWrapper>
  );
};

export default ContactModal;
