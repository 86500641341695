import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router';
import PageLayout from '../../component/organisms/PageLayout';
import { ROUTES } from '../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { IonCol, IonGrid, useIonRouter } from '@ionic/react';
import Section from '../../component/atoms/Section';
import FormSectionTitle from '../../component/atoms/FormSectionTitle';
import InputText from '../../component/atoms/formElement/InputText';
import { FormContext } from '../../core/context/form.context';
import FormLayout from '../../component/organisms/FormLayout';
import { useForm } from 'react-hook-form';
import { SUBMIT_BUTTON_MODE } from '../../core/enum/submit-button-mode.enum';
import useToast from '../../core/hook/toast.hook';
import FB_FUNCTION_URLS from '../../core/const/fb-function-names';
import { FirebaseFunctionsContext } from '../../core/context/firebase-functions.context';

const UnsubscribeView: FC = () => {
  const params = useParams<{ id: string }>();
  const { sendSuccessToast, sendErrorToast } = useToast();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t } = useTranslation('common');
  const router = useIonRouter();
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);

  const { control, watch, handleSubmit } = useForm<{ email: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });

  const onSuccess = (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    return sendSuccessToast('toast.notificationSettingsUpdated').then(() => router.push(t('/')));
  };

  const updateNotificationSettings = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    return firebaseFunctionsService
      .callFbFunction(FB_FUNCTION_URLS.user.updateNotificationSettings, {
        body: { email: watch('email'), uid: params.id },
      })
      .then(onSuccess)
      .catch((e) => {
        sendErrorToast('toast.error').then(() => setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE));
        return console.error('Error updating notification Settings', e);
      });
  };

  const handleError = (err: any) => {
    sendErrorToast('toast.error').then(() => setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE));
    console.error('Error on UnsubscribeView: ', err);
  };

  return (
    <PageLayout pageTitle={t(ROUTES.tenant.account.profile.title)} showBackButton>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(updateNotificationSettings, (err) => handleError(err))}>
          <FormLayout submitMode={buttonSubmitMode} showSubmitButton>
            <IonGrid className={'wohnsinn-forms-grid'}>
              <Section>
                <IonCol size={'12'}>
                  <FormSectionTitle title={'Vom Newsletter Abmelden'} />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <InputText label={'email'} name={'email'} required />
                </IonCol>
              </Section>
            </IonGrid>
          </FormLayout>
        </form>
      </FormContext.Provider>
    </PageLayout>
  );
};

export default UnsubscribeView;
