import ModalWrapper from '../ModalWrapper';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { MATCHING_MODE, TApplicationCreate } from '@wohnsinn/ws-ts-lib';
import { FC, useContext } from 'react';
import ApplicationServiceContext from '../../../../core/context/application-service.context';
import UserContext from '../../../../core/context/user.context';
import ModalContext from '../../../../core/context/modal.context';
import useToast from '../../../../core/hook/toast.hook';
import Text from '../../../atoms/typographie/Text';
import Spacer from '../../../atoms/Spacer';

const ConfirmApplicationModal: FC = () => {
  const { applicationService } = useContext(ApplicationServiceContext);
  const { tenantProfile } = useContext(UserContext);
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.signIn.ConfirmApplicationModal' });
  const { closeModal, modalData: application } = useContext(ModalContext);
  const { sendSuccessToast } = useToast();

  const addApplicationHandler = async (): Promise<void> => {
    const applicationList: TApplicationCreate[] = [
      {
        address: application.address,
        apartmentId: application.apartmentId,
        editorList: application.editorList,
        isLandlordTyping: false,
        isTenantTyping: false,
        landlordId: application.landlordId,
        landlordRating: MATCHING_MODE.NONE,
        lastMessage: {
          editorState: tenantProfile.aboutMe.description.editorState,
          html: tenantProfile.aboutMe.description.html,
        },
        lastMessageSenderId: tenantProfile.uid,
        media: application.media,
        rating: MATCHING_MODE.LIKE,
        rooms: application?.rooms ? application.rooms : 0,
        seenByLandlord: false,
        size: application?.size ? application.size : 0,
        tenantProfile,
        warmRent: application?.warmRent ? application.warmRent : 0,
      },
    ];

    await applicationService.addApplicationList(applicationList);
    await sendSuccessToast('Erfolgreich beworben.');
    closeModal();
  };

  return (
    <ModalWrapper title={m('title')} icon={t('pictogram.mail')}>
      <Text align={'center'}>{m('text')}</Text>
      <Spacer />
      <CTAButton expand={'block'} buttonText={t('send')} onClick={addApplicationHandler} />
      <br />
      <CTAButton expand={'block'} buttonStyle={BUTTON_STYLE.SECONDARY} buttonText={t('cancel')} onClick={closeModal} />
    </ModalWrapper>
  );
};

export default ConfirmApplicationModal;
