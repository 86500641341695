import { FC } from 'react';
import Text from '../../atoms/typographie/Text';
import Headline from '../../atoms/typographie/Headline';
import styles from './HeadlineText.module.scss';

const HeadlineText: FC<{ headline: string; text?: string }> = ({ headline, text }) => {
  return (
    <div className={styles.wrapper}>
      <Headline align={'center'}>{headline}</Headline>
      {text ? (
        <div className={styles.subtitle}>
          <Text align={'center'}>{text}</Text>
        </div>
      ) : null}
    </div>
  );
};

export default HeadlineText;
