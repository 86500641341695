import { FC, ReactElement } from 'react';
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react';
import styles from './Accordion.module.scss';
import Text, { TEXT_WEIGHT } from '../typographie/Text';
import { useTranslation } from 'react-i18next';

export interface IAccordionItem {
  label: string | ReactElement;
  content: string | ReactElement;
}

export interface IAccordionProps {
  items?: IAccordionItem[];
  selectedTab?: string;
  onChange?: any;
}

const Accordion: FC<IAccordionProps> = ({ items, onChange, selectedTab }) => {
  const { t } = useTranslation('common');

  const renderItems = (items: IAccordionItem[]) =>
    items.map((item, index) => (
      <IonAccordion key={`accordion-${index}`} value={`accordion-${index}`}>
        <IonItem slot="header" className={selectedTab === `accordion-${index}` ? styles.activeTab : ''}>
          <IonLabel>
            {typeof item.label === 'string' ? (
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} tag={'span'}>
                {t(item.label)}
              </Text>
            ) : (
              item.label
            )}
          </IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          {typeof item.content === 'string' ? <Text>{t(item.content)}</Text> : item.content}
        </div>
      </IonAccordion>
    ));

  return (
    <IonAccordionGroup
      value={selectedTab ? selectedTab : undefined}
      onIonChange={onChange}
      className={styles.accordion}
    >
      {renderItems(items)}
    </IonAccordionGroup>
  );
};

export default Accordion;
