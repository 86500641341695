import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSwiper } from 'swiper/react';
import { faHouse, faSearch } from '@fortawesome/pro-light-svg-icons';
import Joi from 'joi';
import { Dispatch, FC, SetStateAction } from 'react';
import styles from './StepSelectLandlordType.module.scss';
import OptionList, { IOptionListItem } from '../../../../../atoms/OptionList';
import LOCAL_STORAGE_KEYS from '../../../../../../core/enum/local-storage.enum';
import { FormContext } from '../../../../../../core/context/form.context';
import CTAButton from '../../../../../atoms/Buttons/CTAButton';
import SwiperTunnelStepsWrapper from '../../../Layout/SwiperTunnelStepsWrapper';
import SwiperTunnelTitle from '../../../Layout/SwiperTunnelTitle';

const StepSelectLandlordType: FC<{ setIsOrganization: Dispatch<SetStateAction<boolean>> }> = ({
  setIsOrganization,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.RegisterLandlordSteps.StepSelectLandlordType',
  });

  const { t: c } = useTranslation('common');
  const swiper = useSwiper();

  const landLordTypeOptions: [IOptionListItem, IOptionListItem] = [
    {
      heading: t('options.private.heading'),
      iconName: faSearch,
      text: t('options.private.text'),
      value: false,
    },
    {
      heading: t('options.commercial.heading'),
      iconName: faHouse,
      text: t('options.commercial.text'),
      value: true,
    },
  ];

  const { control, handleSubmit, watch } = useForm<{
    landlordType: boolean;
  }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object({ landlordType: Joi.boolean().required() })),
  });

  const handleFormSubmit = async () => {
    const isCommercialLandlord = watch('landlordType');
    if (isCommercialLandlord) {
      setIsOrganization(true);
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_COMMERCIAL_LANDLORD, 'true');
    }

    swiper.allowSlideNext = true;
    swiper.slideNext(300);
  };

  const handleFormError = (errors: FieldErrors) => {
    console.error(errors);
  };

  return (
    <SwiperTunnelStepsWrapper>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
          <div className={styles.wrapper}>
            <SwiperTunnelTitle image={c('pictogram.object')} title={t('formTitle')} subtitle={t('formSubTitle')} />
            <OptionList optionsList={landLordTypeOptions} name={'landlordType'} />
            <button className={'sr-only'}>submit</button>
            <CTAButton expand={'block'} buttonText={c('next')} />
          </div>
        </form>
      </FormContext.Provider>
    </SwiperTunnelStepsWrapper>
  );
};

export default StepSelectLandlordType;
