import { FC, useContext, useEffect } from 'react';
import PageLayout from '../../../component/organisms/PageLayout';
import UserContext from '../../../core/context/user.context';
import { ROUTES } from '../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import LandlordServices from '../../../component/organisms/LandlordServices';
import TenantServices from '../../../component/organisms/TenantServices';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import { IonCol, useIonRouter } from '@ionic/react';
import PageTitle from '../../../component/molecules/PageTitle';
import Section from '../../../component/atoms/Section';

const ServicesView: FC = () => {
  const { t } = useTranslation('common');
  const { activeUserType, user } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const router = useIonRouter();

  useEffect(() => {
    if (router.routeInfo.pathname === t('routes.signIn.path') && !!!user) {
      openModal({ id: MODAL_IDENTIFIER.SIGN_IN, allowBackdropDismiss: false });
    }

    if (router.routeInfo.pathname === t('routes.register.path') && !!!user) {
      openModal({ id: MODAL_IDENTIFIER.REGISTER_USER, allowBackdropDismiss: false });
    }
  }, [router]);

  return (
    <PageLayout hideTabBar={false} pageTitle={t(ROUTES.services.overview.title)}>
      <Section>
        <IonCol>
          <PageTitle showSubmitButton={false} pageTitle={t(ROUTES.services.overview.title)} />
          {activeUserType === USER_TYPE.LANDLORD && user.isLandlord ? <LandlordServices /> : <TenantServices />}
        </IonCol>
      </Section>
    </PageLayout>
  );
};

export default ServicesView;
