import { FC, useContext, useEffect, useState } from 'react';
import PageLayout from '../../../../component/organisms/PageLayout';
import UserContext from '../../../../core/context/user.context';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { IonCol, IonInfiniteScroll, IonInfiniteScrollContent, IonRow } from '@ionic/react';
import ApartmentCard from '../../../../component/molecules/Cards/ApartmentCard';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import ApartmentServiceContext from '../../../../core/context/apartment-service.context';
import CTACard from '../../../../component/molecules/Cards/CTACard';
import Section from '../../../../component/atoms/Section';
import PageTitle from '../../../../component/molecules/PageTitle';
import CTAButton from '../../../../component/atoms/Buttons/CTAButton';
import styles from './ApartmentListView.module.scss';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import SearchInput from '../../../../component/atoms/formElement/SearchInput';
import ApartmentLoadingCard from '../../../../component/molecules/LoadingElements/ApartmentLoadingCard';

const ApartmentListView: FC = () => {
  const { landlordProfile } = useContext(UserContext);
  const { apartmentService } = useContext(ApartmentServiceContext);
  const [apartmentList, setApartmentList] = useState<IApartment[]>([]);
  const [filteredApartmentList, setFilteredApartmentList] = useState<IApartment[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [lastVisibleApartment, setLastVisibleApartment] = useState<QueryDocumentSnapshot<IApartment>>(null);
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ApartmentListView' });

  const fetchData = async (fetchWithLoadingAnimation = false) => {
    if (fetchWithLoadingAnimation) {
      setIsLoadingContent(true);
    }

    const apartmentDocSnapShots = await apartmentService.getLandlordApartmentDocSnapShots(
      landlordProfile.uid,
      lastVisibleApartment
    );

    if (apartmentDocSnapShots) {
      setLastVisibleApartment(apartmentDocSnapShots.docs[apartmentDocSnapShots.docs.length - 1]);

      const paginatedApartmentList: IApartment[] = apartmentDocSnapShots.docs.map((apartment) => ({
        ...apartment.data(),
        id: apartment.id,
      }));
      setApartmentList([...apartmentList, ...paginatedApartmentList]);
      setFilteredApartmentList([...apartmentList, ...paginatedApartmentList]);
    }
    if (apartmentList?.find((a) => a.id === lastVisibleApartment?.id)) {
      setInfiniteDisabled(true);
    }

    setIsLoadingContent(false);
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  const handleSearch = (searchKey: string) => {
    if (!searchKey) {
      setFilteredApartmentList([...apartmentList]);
    } else {
      const newList = apartmentList.filter((apartment) => {
        return (
          apartment.mainInformation.address.street.toLowerCase().includes(searchKey.toLowerCase()) ||
          apartment.mainInformation.address.city.toLowerCase().includes(searchKey.toLowerCase())
        );
      });
      setFilteredApartmentList([...newList]);
    }
  };

  return (
    <PageLayout hideTabBar={false} pageTitle={t(ROUTES.landlord.apartment.list.title)}>
      {apartmentList && apartmentList.length ? (
        <Section>
          <IonCol>
            <PageTitle pageTitle={t(ROUTES.landlord.apartment.list.title)}>
              {apartmentList && apartmentList?.length ? (
                <div className={styles.ctaBar}>
                  {/* TODO: This is just a simple frontend implementation - REFACTOR */}
                  <SearchInput name={t('search')} placeholder={t('search')} onChange={handleSearch} />

                  <CTAButton
                    customStyling={styles.fullWidthButton}
                    icon={faPlus}
                    buttonText={v('createNew')}
                    link={t(ROUTES.landlord.apartment.new.path)}
                  />
                </div>
              ) : null}
            </PageTitle>
          </IonCol>
        </Section>
      ) : null}

      {isLoadingContent ? (
        <IonRow>
          {[1, 2, 3, 4, 5, 6].map((card, index) => (
            <IonCol key={index} size="12" size-sm="6" size-lg="4">
              <ApartmentLoadingCard />
            </IonCol>
          ))}
        </IonRow>
      ) : apartmentList && apartmentList.length ? (
        <IonRow>
          {filteredApartmentList.map((apartment) => {
            return (
              <IonCol key={apartment.id} size="12" sizeSm="6" sizeMd="4" sizeLg="3">
                <ApartmentCard
                  showLandlordInformations
                  apartment={apartment}
                  route={t(ROUTES.landlord.apartment.overview.path).replace(':apartmentId', apartment.id)}
                />
              </IonCol>
            );
          })}
        </IonRow>
      ) : (
        <Section center>
          <IonCol size={'12'} sizeMd={'6'}>
            <CTACard
              title={v('noObjects')}
              text={v('noApartmentsText')}
              imgSrc={t('pictogram.object')}
              imgAltText={'keine Objekte vorhanden'}
              ctaText={v('createNew')}
              link={t(ROUTES.landlord.apartment.new.path)}
            />
          </IonCol>
        </Section>
      )}

      <IonInfiniteScroll onIonInfinite={() => fetchData()} threshold="30px" disabled={isInfiniteDisabled}>
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText={v('loadingMoreText')}
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </PageLayout>
  );
};

export default ApartmentListView;
