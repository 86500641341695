import ModalWrapper from '../../ModalWrapper';
import { FC, useContext } from 'react';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import ModalContext from '../../../../../core/context/modal.context';
import { useTranslation } from 'react-i18next';
import TenantFilterParamsContext from '../../../../../core/context/tenant-filter-params.context';
import { useForm } from 'react-hook-form';
import styles from './FilterRentModal.module.scss';
import { FormContext } from '../../../../../core/context/form.context';
import Headline, { HEADLINE_SIZE } from '../../../../atoms/typographie/Headline';
import TextInput from '../../../../atoms/formElement/InputText';
import SuggestionButton from '../../../../atoms/SuggestionButton';
import Text, { TEXT_TYPE, TEXT_VARIANT } from '../../../../atoms/typographie/Text';
import { FILTER_TABS } from '../../../FilterBar';

const FilterRentModal: FC = () => {
  const { closeModal, modalData, openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { control, watch, setValue, handleSubmit } = useForm<{ warmRent: number }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { warmRent: tenantFilterParams?.warmRent ? tenantFilterParams.warmRent : null },
  });

  const value = watch().warmRent;

  const updateTenantFilterParamsHandler = async () => {
    await updateTenantFilterParams({ ...tenantFilterParams, warmRent: value });
    handleModalClose();
  };

  const handleModalClose = () => {
    modalData?.setActiveTab(FILTER_TABS.NONE);
    closeModal();
  };

  return (
    <ModalWrapper title={'Wie viel möchtest du ausgeben?'}>
      <FormContext.Provider value={{ control }}>
        <form className={styles.form} onSubmit={handleSubmit(updateTenantFilterParamsHandler)}>
          <div className={styles.suggestionsTitle}>
            <Headline noMargin size={HEADLINE_SIZE.H3}>
              Deine Warmmiete
            </Headline>
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              Wähle wie viel du maximal ausgeben möchtest.
            </Text>
          </div>

          <TextInput
            name={'warmRent'}
            unit={t('currency')}
            pattern="[0-9]*"
            minNumber={10}
            type={'number'}
            label={t('component.organisms.searchTunnelSlides.WarmRent.placeholder')}
          />

          <br />

          <div className={styles.suggestionsTitle}>
            <Headline noMargin size={HEADLINE_SIZE.H3}>
              Schnellauswahl
            </Headline>
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              Wähle einen Betrag aus unserer Schnellauswahl.
            </Text>
          </div>
          <div className={styles.suggestionsWrapper}>
            <SuggestionButton onClick={() => setValue('warmRent', 800)} label={'800 €'} />
            <SuggestionButton onClick={() => setValue('warmRent', 1200)} label={'1200 €'} />
            <SuggestionButton onClick={() => setValue('warmRent', 1600)} label={'1600 €'} />
            <SuggestionButton onClick={() => setValue('warmRent', 2000)} label={'2000 €'} />
            <SuggestionButton onClick={() => setValue('warmRent', 2400)} label={'2400 €'} />
            <SuggestionButton onClick={() => setValue('warmRent', 3000)} label={'3000 €'} />
          </div>

          <div className={'ion-margin-top'}>
            <CTAButton type={'submit'} rounded={false} expand={'block'} buttonText={t('save')} />
          </div>
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default FilterRentModal;
