import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageLayout from './../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { IonCol, IonInfiniteScroll, IonInfiniteScrollContent, IonRow } from '@ionic/react';
import Section from './../../../../component/atoms/Section';
import ApartmentServiceContext from './../../../../core/context/apartment-service.context';
import { IApartment, IOrganization, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import PageTitle from './../../../../component/molecules/PageTitle';
import styles from './OrganizationPortfolioView.module.scss';
import ApartmentLoadingCard from './../../../../component/molecules/LoadingElements/ApartmentLoadingCard';
import UserContext from './../../../../core/context/user.context';
import ApartmentCardGrid from './../../../../component/atoms/ApartmentCardGrid';
import ApplicationListContext from './../../../../core/context/application-list.context';
import LOCAL_STORAGE_KEYS from './../../../../core/enum/local-storage.enum';

export interface IApartmentWithRating extends IApartment {
  rating: MATCHING_MODE;
}

const OrganizationPortfolioView: FC = () => {
  const params = useParams<{ slug: string }>();
  const { t } = useTranslation('common');
  const { organizationService, user } = useContext(UserContext);
  const { apartmentService } = useContext(ApartmentServiceContext);
  const { nopeList, maybeList } = useContext(ApplicationListContext);

  const [organization, setOrganization] = useState<IOrganization>(null);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [lastVisibleApartment, setLastVisibleApartment] = useState<QueryDocumentSnapshot<IApartment>>(null);
  const [apartmentList, setApartmentList] = useState<IApartment[]>([]);
  const [maybeApartmentIdList, setMaybeApartmentIdList] = useState<string[]>([]);
  const [nopeApartmentIdList, setNopeApartmentIdList] = useState<string[]>([]);
  const [apartmentListWithRating, setApartmentListWithRatings] = useState<IApartmentWithRating[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ApartmentListView' });

  const fetchData = async (fetchWithLoadingAnimation = false) => {
    const organization: IOrganization = await organizationService.getOrganizationBySlug(params?.slug);
    if (organization) {
      setOrganization(organization);
    }

    if (fetchWithLoadingAnimation) {
      setIsLoadingContent(true);
    }
    const apartmentDocSnapShots = await apartmentService.getOrganizationApartmentDocSnapShots(
      organization?.id ?? null,
      lastVisibleApartment
    );

    if (apartmentDocSnapShots) {
      setLastVisibleApartment(apartmentDocSnapShots.docs[apartmentDocSnapShots.docs.length - 1]);

      const paginatedApartmentList: IApartment[] = apartmentDocSnapShots.docs.map((apartment) => ({
        ...apartment.data(),
        id: apartment.id,
      }));
      if (maybeList?.length) {
        setMaybeApartmentIdList(maybeList.map((apt) => apt.apartmentId));
      }
      if (nopeList?.length) {
        setNopeApartmentIdList(nopeList.map((apt) => apt.apartmentId));
      }
      setApartmentList([...apartmentList, ...paginatedApartmentList]);
    }
    if (apartmentList?.find((a) => a.id === lastVisibleApartment?.id)) {
      setInfiniteDisabled(true);
    }
    setIsLoadingContent(false);
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  useEffect(() => {
    apartmentList.map((apt) => {
      if (user) {
        if (apt.applicationRefList.includes(user?.uid)) {
          const apartmentWithRating = { ...apt, rating: MATCHING_MODE.LIKE };
          return setApartmentListWithRatings((oldValue) => [apartmentWithRating, ...oldValue]);
        }
        if (maybeApartmentIdList.includes(apt.id)) {
          const apartmentWithRating = { ...apt, rating: MATCHING_MODE.MAYBE };
          return setApartmentListWithRatings((oldValue) => [apartmentWithRating, ...oldValue]);
        }
        if (nopeApartmentIdList.includes(apt.id)) {
          const apartmentWithRating = { ...apt, rating: MATCHING_MODE.NOPE };
          return setApartmentListWithRatings((oldValue) => [apartmentWithRating, ...oldValue]);
        }
        //default
        const apartmentWithRating = { ...apt, rating: MATCHING_MODE.NONE };
        return setApartmentListWithRatings((oldValue) => [apartmentWithRating, ...oldValue]);
      } else {
        const parsedRatings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS));
        if (parsedRatings?.MAYBE?.includes(apt.id)) {
          const apartmentWithRating = { ...apt, rating: MATCHING_MODE.MAYBE };
          return setApartmentListWithRatings((oldValue) => [apartmentWithRating, ...oldValue]);
        }
        if (parsedRatings?.NOPE?.includes(apt.id)) {
          const apartmentWithRating = { ...apt, rating: MATCHING_MODE.NOPE };
          return setApartmentListWithRatings((oldValue) => [apartmentWithRating, ...oldValue]);
        }
        //default
        const apartmentWithRating = { ...apt, rating: MATCHING_MODE.NONE };
        return setApartmentListWithRatings((oldValue) => [apartmentWithRating, ...oldValue]);
      }
    });
  }, [isLoadingContent]);

  return (
    <PageLayout pageTitle={t(ROUTES.landlord.organization.portfolio.title)} hideTabBar={false}>
      {apartmentList && apartmentList.length ? (
        <Section>
          <IonCol>
            <div className={styles.logoWrapper}>
              {organization?.photoUrl && (
                <img
                  className={styles.organizationImage}
                  src={organization?.photoUrl}
                  alt={`${organization?.title} Logo`}
                />
              )}
            </div>
            <PageTitle pageTitle={organization?.title} />
          </IonCol>
        </Section>
      ) : null}

      {isLoadingContent ? (
        <IonRow>
          {[1, 2, 3, 4, 5, 6].map((card, index) => (
            <IonCol key={index} size="12" size-sm="6" size-lg="4">
              <ApartmentLoadingCard />
            </IonCol>
          ))}
        </IonRow>
      ) : (
        <>
          <ApartmentCardGrid
            apartmentList={apartmentListWithRating}
            route={t(ROUTES.landlord.organization.portfolio.apartment.path).replace(':slug', organization?.slug)}
            noApartmentsMessage={v('noApartments')}
          />
        </>
      )}

      <IonInfiniteScroll onIonInfinite={() => fetchData()} threshold="100px" disabled={isInfiniteDisabled}>
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText={v('loadingMoreText')}
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </PageLayout>
  );
};

export default OrganizationPortfolioView;
