import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, { MapRef, Marker, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

// Needs to be implemented like this to work on production!
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import styles from './Map.module.scss';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';

import { IApartment, IGeoPoint } from '@wohnsinn/ws-ts-lib';
import { IViewPort, POI_TYPE } from '@wohnsinn/ws-ts-lib';
import ApartmentOnMapMarker from './ApartmentOnMapMarker';
import PoiLayer from './PoiLayer';
import CTAButton from '../../atoms/Buttons/CTAButton';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import PoiWrapper from './PoiWrapper';

export interface IMapProps {
  apartments: IApartment[];
  isInteractive: boolean;
  parentElementId?: string;
  withApartmentPreview?: boolean;
  setHoveredApartmentId?: Dispatch<SetStateAction<string>>;
  hoveredApartmentId?: string;
}

const OFFSET_LAT = 0.0003 * (Math.random() < 0.5 ? -1 : 1);
const OFFSET_LNG = 0.0003 * (Math.random() < 0.5 ? -1 : 1);

const Map: FC<IMapProps> = ({
  isInteractive,
  parentElementId,
  apartments,
  withApartmentPreview,
  hoveredApartmentId,
  setHoveredApartmentId,
}) => {
  // Const
  const INITIAL_ZOOM_LEVEL = 10;
  // Refs
  const mapRef = useRef<MapRef>();
  const oldHoveredApartment = useRef(null);
  // States
  const [showPoiWrapper, setShowPoiWrapper] = useState<boolean>(false);
  const [activePoiList, setActivePoiList] = useState<POI_TYPE[]>([]);
  const [zoomLevel, setZoomLevel] = useState<number>(INITIAL_ZOOM_LEVEL);
  const [radiusMarkerSize, setRadiusMarkerSize] = useState<number>(INITIAL_ZOOM_LEVEL * 3);
  const [showApartmentPreview, setShowApartmentPreview] = useState<boolean>(false);
  const [selectedApartmentId, setSelectedApartmentId] = useState<string>(null);
  // Observer
  const resizeObserverBody = new ResizeObserver(() => resizeObserverCallback());
  const resizeObserverParent = new ResizeObserver(() => resizeObserverCallback());

  const resizeObserverCallback = () => {
    function throttle(observeFunc: () => any, delay: number) {
      setTimeout(() => observeFunc(), delay);
    }

    throttle(handleMap, 150);
  };

  const handleMap = () => {
    mapRef?.current?.resize();
  };

  // SET BOUNDARIES TO SEE ALL MARKERS ON MAP
  useEffect(() => {
    // Set new boundaries only if it's not cause by hovering an apartment
    if (mapRef?.current && apartments.length > 0 && oldHoveredApartment.current === hoveredApartmentId) {
      if (apartments.length === 1) {
        mapRef?.current.setCenter(apartments[0].mainInformation.address.coordinates);
      } else {
        const bounds = new mapboxgl.LngLatBounds([
          apartments[0].mainInformation.address.coordinates,
          apartments[0].mainInformation.address.coordinates,
        ]);

        apartments.forEach(function (apartment) {
          if (
            apartment?.mainInformation?.address?.coordinates?.lat &&
            apartment?.mainInformation?.address?.coordinates?.lng
          ) {
            bounds.extend([
              parseFloat(String(apartment.mainInformation.address.coordinates.lng)),
              parseFloat(String(apartment.mainInformation.address.coordinates.lat)),
            ]);
          }
        });

        mapRef.current.fitBounds(bounds, { padding: 100, linear: false });
      }
    }
    oldHoveredApartment.current = hoveredApartmentId;
  }, [apartments]);

  useEffect(() => {
    mapRef.current?.flyTo({
      center: [
        apartments[0].mainInformation.address.coordinates.lng,
        apartments[0].mainInformation.address.coordinates.lat,
      ],
      duration: 2000,
    });
  }, [apartments]);

  const [viewPort, setViewPort] = useState<IViewPort>({
    longitude: apartments[0].mainInformation.address.coordinates.lng,
    latitude: apartments[0].mainInformation.address.coordinates.lat,
    zoom: 12,
    pitch: 50,
  });

  useEffect(() => {
    if (parentElementId) {
      resizeObserverBody.observe(document.getElementById(parentElementId));
      resizeObserverParent.observe(document.body);

      return () => {
        resizeObserverBody.disconnect();
        resizeObserverParent.disconnect();
      };
    }
  }, []);

  const setMarker = () => {
    if (!mapRef.current?.hasImage('poiSymbol')) {
      mapRef.current?.loadImage('/assets/icon/poi-marker.png', (error, image) => {
        if (error) throw error;
        if (!mapRef.current?.hasImage('poiSymbol')) mapRef.current?.addImage('poiSymbol', image, { sdf: true });
      });
    }
  };

  const zoomToPoi = (poi: POI_TYPE) => {
    const zoom = getPoiLevel(poi);
    if (zoom > zoomLevel) {
      setZoomLevel(zoom);
      mapRef.current?.flyTo({ center: [viewPort.longitude, viewPort.latitude], duration: 2000, zoom });
    }
  };

  const togglePoi = (poi: POI_TYPE) => {
    if (!activePoiList.includes(poi)) {
      zoomToPoi(poi);
    }

    const pl = activePoiList;
    const index = pl.indexOf(poi);
    index >= 0 ? pl.splice(index, 1) : pl.push(poi);
    setActivePoiList([...pl]);
  };

  const handleApartmentPreview = (apartmentId: string, coordinates?: IGeoPoint) => {
    if (coordinates) {
      setSelectedApartmentId(apartmentId);
      setShowApartmentPreview(true);
    } else {
      setShowApartmentPreview(false);
    }
  };

  const renderMarker = () =>
    apartments.map((apartment, index) => {
      const {
        mainInformation: {
          address: { hasPublishedAddress, coordinates },
        },
      } = apartment;
      return (
        <Marker
          style={{ zIndex: selectedApartmentId === apartment.id ? '2' : '1' }}
          key={index}
          latitude={hasPublishedAddress ? coordinates.lat : coordinates.lat + OFFSET_LAT}
          longitude={hasPublishedAddress ? coordinates.lng : coordinates.lng + OFFSET_LNG}
        >
          {withApartmentPreview ? (
            <ApartmentOnMapMarker
              apartment={apartment}
              hoveredApartmentId={hoveredApartmentId}
              setHoveredApartmentId={setHoveredApartmentId}
              selectedApartmentId={selectedApartmentId}
              showApartmentPreview={showApartmentPreview}
              handleApartmentPreview={handleApartmentPreview}
            />
          ) : hasPublishedAddress ? (
            <img
              width="40"
              style={{ transform: 'translateY(-50%)' }}
              src={'/assets/icon/location-marker.svg'}
              alt="location-marker"
            />
          ) : (
            <div
              style={{
                width: radiusMarkerSize < 50 ? 50 : radiusMarkerSize,
                height: radiusMarkerSize < 50 ? 50 : radiusMarkerSize,
                backgroundColor: 'rgb(24, 204, 222, 0.4)',
                border: '2px solid white',
                borderRadius: '50%',
              }}
            />
          )}
        </Marker>
      );
    });

  // const setTenantFilterParamsLocationOnMove = async (evt: ViewStateChangeEvent) => {
  //   const fetchSuggestions = async (): Promise<void> => {
  //     try {
  //       const mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${evt?.viewState?.longitude},${evt?.viewState?.latitude}.json`;
  //       const suggestionListData = await (
  //         await fetch(
  //           `${mapBoxApiUrl}?country=DE&language=DE&&types=place,locality,postcode,district,neighborhood&&&access_token=${process.env.REACT_APP_MAP_BOX_TOKEN}`
  //         )
  //       ).json();
  //
  //       const location = suggestionListData?.features[0];
  //       console.log('lo', location.radius);
  //       if (location) {
  //         const selectedParams: ILocationSelection = {
  //           center: location.center,
  //           geoPoint: { lat: location.center[1], lng: location.center[0] },
  //           radius: location?.radius ? location.radius : 25,
  //           place_name: location.place_name,
  //           id: location.id,
  //         };
  //
  //         // await updateTenantFilterParams({ ...tenantFilterParams, location: selectedParams });
  //       }
  //     } catch (e) {
  //       console.error('error on fetchSuggestions', e);
  //     }
  //   };
  //   await fetchSuggestions();
  // };

  return (
    <ReactMapGL
      id={'WOHNSINN-MAP'}
      {...viewPort}
      initialViewState={{
        longitude: -100,
        latitude: 40,
        zoom: 3.5,
      }}
      onLoad={() => {
        // @ts-ignore
        mapRef?.current?.setLanguage('de');
        handleMap();
        resizeBy(viewPort.latitude, viewPort.longitude);
        setMarker();
      }}
      // onZoomEnd={(evt) => setTenantFilterParamsLocationOnMove(evt)}
      // onDragEnd={(evt) => setTenantFilterParamsLocationOnMove(evt)}
      onMove={(evt) => {
        setRadiusMarkerSize((zoomLevel - 10) * (zoomLevel - 10) * (2 * 5));
        setViewPort(evt.viewState);
        setZoomLevel(evt.viewState.zoom);
      }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken={process.env.REACT_APP_MAP_BOX_TOKEN}
      maxZoom={18}
      minZoom={5}
      ref={mapRef}
      style={{ width: '100%', height: '100%' }}
      attributionControl={false}
    >
      {renderMarker()}

      {isInteractive && (
        <>
          <NavigationControl />
          <PoiLayer activePoiList={activePoiList} />
          <CTAButton
            size={'small'}
            customStyling={styles.togglePoiButton}
            onClick={() => setShowPoiWrapper(!showPoiWrapper)}
            icon={faMapMarkerAlt}
            buttonText={'In der Nähe'}
          />

          {showPoiWrapper && <PoiWrapper activePoiList={activePoiList} togglePoi={togglePoi} />}
        </>
      )}
    </ReactMapGL>
  );
};

function getPoiLevel(poi: POI_TYPE): number {
  switch (poi) {
    case POI_TYPE.RESTAURANTS:
      return 13;
    case POI_TYPE.HOSPITALS:
      return 13;
    case POI_TYPE.TRAINS:
      return 13;
    case POI_TYPE.SCHOOLS:
      return 14;
    case POI_TYPE.PHARMACY:
      return 15;
    case POI_TYPE.KITA:
      return 15;
    case POI_TYPE.SUPERMARKET:
      return 14;
    case POI_TYPE.FAST_FOOD:
      return 15;
    default:
      return 12;
  }
}

export default Map;
