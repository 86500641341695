import { FC } from 'react';
import styles from './ApplicantInformationUserInfo.module.scss';
import Avatar, { AVATAR_SIZE } from '../../../atoms/Avatar';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';

const ApplicantInformationUserInfo: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  return (
    <div className={styles.userInfo}>
      <div>
        <Avatar bordered size={AVATAR_SIZE.xxl} avatarUrl={tenantProfile?.photoUrl} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {tenantProfile?.personalInformation?.firstName && tenantProfile?.personalInformation?.lastName ? (
          <Headline size={HEADLINE_SIZE.H1} tag={'span'}>
            {tenantProfile?.personalInformation?.firstName} {tenantProfile?.personalInformation?.lastName}
          </Headline>
        ) : null}
        <Text tag={'span'} className={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {tenantProfile.email}
        </Text>
      </div>
    </div>
  );
};

export default ApplicantInformationUserInfo;
