import { Dispatch, FC, SetStateAction, useRef } from 'react';
import ApartmentCard from '../../Cards/ApartmentCard';
import { IApartment, IGeoPoint } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import styles from './ApartmentOnMapMarker.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import MobileApartmentPreviewCard from '../../Cards/MobileApartmentPreviewCard';
import useWindowSize from '../../../../core/hook/windowsize.hook';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../atoms/typographie/Text';

const ApartmentOnMapMarker: FC<{
  apartment: IApartment;
  showApartmentPreview: boolean;
  selectedApartmentId: string;
  setHoveredApartmentId?: Dispatch<SetStateAction<string>>;
  hoveredApartmentId?: string;
  handleApartmentPreview: (apartmentId: string, coordinates?: IGeoPoint) => any;
}> = ({
  showApartmentPreview,
  selectedApartmentId,
  apartment,
  handleApartmentPreview,
  hoveredApartmentId,
  setHoveredApartmentId,
}) => {
  const { t } = useTranslation('common');
  const { isSmallerMd } = useWindowSize();
  const ref = useRef(null);

  const shouldPositionBelow = (): boolean => {
    return ref?.current?.getBoundingClientRect().y <= 400;
  };

  return (
    <div
      ref={ref}
      className={`${styles.wrapper} ${
        showApartmentPreview && selectedApartmentId === apartment.id ? styles.hovered : ''
      }`}
    >
      {showApartmentPreview && selectedApartmentId === apartment.id ? (
        <div className={`${styles.apartmentPreview} ${shouldPositionBelow() ? styles.positionBelow : ''}`}>
          <button className={styles.close} onClick={() => handleApartmentPreview(apartment.id)}>
            <span className="sr-only">close</span>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {isSmallerMd ? (
            <MobileApartmentPreviewCard
              apartment={apartment}
              route={t(ROUTES.tenant.matching.stack.selectedApartment.path)
                .replace(':matchingCategory', 'top')
                // prevent IDs with # Hashes in URL to be split
                .replace(':selectedApartmentId', apartment.id.replace('#', '::'))}
            />
          ) : (
            <ApartmentCard
              apartment={apartment}
              route={t(ROUTES.tenant.matching.stack.selectedApartment.path)
                .replace(':matchingCategory', 'top')
                // prevent IDs with # Hashes in URL to be split
                .replace(':selectedApartmentId', apartment.id.replace('#', '::'))}
              showLandlordInformations={false}
            />
          )}
        </div>
      ) : null}

      <button
        className={`${styles.marker} ${
          apartment.mainInformation?.address?.hasPublishedAddress ? '' : styles.unpublished
        } ${hoveredApartmentId === apartment.id ? styles.hovered : ''}`}
        onClick={() => handleApartmentPreview(apartment.id, apartment.mainInformation.address.coordinates)}
        onMouseEnter={() => setHoveredApartmentId(apartment.id)}
        onMouseLeave={() => setHoveredApartmentId(null)}
      >
        <Text
          type={TEXT_TYPE.TEXT_TYPE_CAPTION}
          color={TEXT_COLOR.TEXT_COLOR_LIGHT}
          weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
        >
          {apartment?.cost?.warmRent ? `${apartment.cost.warmRent} €` : 'Auf Anfrage'}
        </Text>
      </button>
    </div>
  );
};

export default ApartmentOnMapMarker;
