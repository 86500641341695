import ModalWrapper from '../../ModalWrapper';
import { FC, useContext } from 'react';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import ModalContext from '../../../../../core/context/modal.context';
import TenantFilterParamsContext from '../../../../../core/context/tenant-filter-params.context';
import styles from './FilterResetModal.module.scss';
import Headline, { HEADLINE_SIZE } from '../../../../atoms/typographie/Headline';
import Text, { TEXT_TYPE, TEXT_VARIANT } from '../../../../atoms/typographie/Text';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { DEFAULT_TENANT_FILTER_PARAMS } from '../../../../../core/service/tenant-filter-params.service';

const FilterResetModal: FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);

  const updateTenantFilterParamsHandler = async () => {
    await updateTenantFilterParams({ ...tenantFilterParams, ...DEFAULT_TENANT_FILTER_PARAMS });
    closeModal();
  };

  return (
    <ModalWrapper title={'Filter zurücksetzen'}>
      <div className={styles.suggestionsTitle}>
        <Headline noMargin size={HEADLINE_SIZE.H3}>
          Filter zurücksetzen
        </Headline>
        <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
          Möchtest du wirklich alle Filter zurücksetzen?
        </Text>
      </div>

      <CTAButton
        onClick={() => updateTenantFilterParamsHandler()}
        type={'button'}
        buttonText={'Filter zurücksetzen'}
        icon={faTrash}
        size={'small'}
      />
    </ModalWrapper>
  );
};

export default FilterResetModal;
