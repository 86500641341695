import { FC, PropsWithChildren } from 'react';
import styles from '../SlideLayout.module.scss';
import { IonProgressBar } from '@ionic/react';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import { useSwiper } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../../../core/hook/windowsize.hook';

export interface ITunnelSlideLayoutProps {
  title: string;
  allowSlideNext: boolean;
  isFormSubmit?: boolean;
  customCTALabel?: string;
  sliderEndAction?: () => any;
}

const TunnelSlideLayout: FC<PropsWithChildren<ITunnelSlideLayoutProps>> = ({
  children,
  title,
  allowSlideNext,
  isFormSubmit = false,
  customCTALabel,
  sliderEndAction,
}) => {
  const swiper = useSwiper();
  const { t: c } = useTranslation('common');
  const { isSmallerLg, windowHeight } = useWindowSize();

  const handleNextStepEmit = () => {
    if (swiper.isEnd) {
      sliderEndAction();
    } else {
      swiper.slideNext(100);
    }
  };

  return (
    <div style={{ height: isSmallerLg ? `calc(${windowHeight} - 65px)` : 'auto' }} className={styles.tunnelSlide}>
      <IonProgressBar value={swiper.progress} />
      <div className={'swiper-no-swiping'}>
        <div className={styles.contentWrapper}>
          <h4 className={styles.headline}>{title}</h4>
          {children}
          <CTAButton
            buttonText={customCTALabel ? customCTALabel : c('next')}
            onClick={isFormSubmit ? null : handleNextStepEmit}
            expand={'block'}
            type={isFormSubmit ? 'submit' : 'button'}
            disabled={!allowSlideNext}
            routerDirection={'forward'}
            customStyling={styles.nextButton}
          />
        </div>
      </div>
    </div>
  );
};

export default TunnelSlideLayout;
