import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect, useState } from 'react';
import { IonRouterLink } from '@ionic/react';
import styles from './ApartmentStackView.module.scss';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../../../../component/atoms/NotificationBadge';
import ApplicationListContext from '../../../../core/context/application-list.context';
import NotificationsBadgesContext from '../../../../core/context/notifications-badges-context';
import ApartmentStackWrapper, { MATCHLIST_TYPES } from '../../../../component/organisms/ApartmentStackWrapper';
import ModalContext from '../../../../core/context/modal.context';
import LOCAL_STORAGE_KEYS from '../../../../core/enum/local-storage.enum';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import { useParams } from 'react-router';

const ApartmentStackView: FC = () => {
  const { t: r } = useTranslation('common');
  const [animateBookmarkIcon, setAnimateBookmarkIcon] = useState<boolean>(false);
  const { maybeList } = useContext(ApplicationListContext);
  const { bookmarkNotifications } = useContext(NotificationsBadgesContext);
  const { openModal } = useContext(ModalContext);
  const { matchingCategory, selectedApartmentId } =
    useParams<{ matchingCategory: MATCHLIST_TYPES; selectedApartmentId: string }>();

  /**
   * Scroll to apartment card I came from - when click on back button from apartment preview
   * localeStorage - NOT_COMMING_FROM_STACK shows that users is coming actually from stack and not refreshing the page
   */
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.NOT_COMMING_FROM_STACK, 'true');
    return () => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.NOT_COMMING_FROM_STACK);
    };
  }, []);

  // SHOW HOW-TO SLIDES
  useEffect(() => {
    if (
      !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_HAS_SEEN_HOW_TO_SLIDES) &&
      (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'qa')
    ) {
      openModal({ id: MODAL_IDENTIFIER.HOW_TO_SLIDES, allowBackdropDismiss: false });
    }
  }, []);
  // SHOW HOW-TO END

  // BOOKMAKR ICON - START
  useEffect(() => {
    if ((maybeList?.length && maybeList?.length > 0) || bookmarkNotifications) {
      setAnimateBookmarkIcon(true);
      setTimeout(() => {
        setAnimateBookmarkIcon(false);
      }, 1000);
    }
  }, [maybeList?.length, bookmarkNotifications]);

  const BookmarkIcon = () => (
    <IonRouterLink
      routerLink={r(ROUTES.tenant.bookmark.router)}
      className={`${styles.bookmarkIcon} ${animateBookmarkIcon ? styles.animate : ''}`}
    >
      {maybeList.length > 0 || bookmarkNotifications > 0 ? (
        <div className={styles.badgeWrapper}>
          <NotificationBadge
            count={maybeList.length > 0 ? maybeList.length : bookmarkNotifications}
            size={NOTIFICATION_BADGE_SIZE.MEDIUM}
          />
        </div>
      ) : null}
      <img className={styles.icon} src={r('icons.heart_like')} alt="like" />
    </IonRouterLink>
  );

  return (
    <PageLayout
      appHeaderBottomBorder
      showBackButton={true}
      disableScroll
      fullWidthPage={true}
      headerEndButton={<BookmarkIcon />}
      hideTabBar={true}
      pageTitle={r(ROUTES.tenant.matching.stack.title)}
    >
      <ApartmentStackWrapper selectedApartmentId={selectedApartmentId} matchingCategory={matchingCategory} />
    </PageLayout>
  );
};

export default ApartmentStackView;
