import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import styles from './ApplicantModalWrapper.module.scss';

const ApplicantModalWrapper: FC<
  PropsWithChildren<{ showModal: boolean; smallSize?: boolean; setShowModal: Dispatch<SetStateAction<boolean>> }>
> = ({ showModal, setShowModal, children, smallSize = false }) => {
  return (
    <>
      <div className={`${styles.backDrop} ${showModal ? styles.show : ''}`} onClick={() => setShowModal(false)} />
      <div className={`${styles.wrapper} ${showModal ? styles.show : ''} ${smallSize ? styles.small : ''}`}>
        {children}
      </div>
    </>
  );
};

export default ApplicantModalWrapper;
