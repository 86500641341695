import styles from './ApartmentRatingButtons.module.scss';
import { FC } from 'react';
import { MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import CTAButton, { BUTTON_STYLE } from '../Buttons/CTAButton';
import { useTranslation } from 'react-i18next';

export interface IApartmentRatingButtonsProps {
  onButtonClick: (matchingMode: any) => void;
  hasApplication?: boolean;
  disabled?: boolean;
}

const ApartmentRatingButtons: FC<IApartmentRatingButtonsProps> = ({
  onButtonClick,
  hasApplication = false,
  disabled = false,
}) => {
  const { t } = useTranslation('common');

  if (hasApplication) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          disabled={disabled}
          buttonText={t('apply')}
          expand={'block'}
          customStyling={styles.singleButton}
          onClick={() => onButtonClick(MATCHING_MODE.LIKE)}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <CTAButton
        disabled={disabled}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        buttonText={t('nope')}
        customStyling={styles.buttonAnimation}
        hideText
        img={t('icons.heart_nope')}
        onClick={() => onButtonClick(MATCHING_MODE.NOPE)}
        size={'big'}
      />

      <CTAButton
        disabled={disabled}
        buttonText={t('apply')}
        customStyling={styles.buttonAnimation}
        onClick={() => onButtonClick(MATCHING_MODE.LIKE)}
        size={'big'}
      />

      <CTAButton
        disabled={disabled}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        buttonText={t('like')}
        customStyling={styles.buttonAnimation}
        hideText
        img={t('icons.heart_like')}
        onClick={() => onButtonClick(MATCHING_MODE.MAYBE)}
        size={'big'}
      />
    </div>
  );
};

export default ApartmentRatingButtons;
