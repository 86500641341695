import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import { FC, PropsWithChildren, useContext } from 'react';
import ModalContext from '../../../../../core/context/modal.context';
import CTAButton, { getSubmitIcon } from '../../../../atoms/Buttons/CTAButton';
import styles from './RegistrationFormWrapper.module.scss';
import SplitLine from '../../../../atoms/SplitLine';
import AuthProviderLoginButtons from '../../../../molecules/AuthProviderLoginButtons';
import AcceptPrivacyInfoText from '../../../../atoms/AcceptPrivacyInfoText';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import SwiperTunnelTitle from '../../../SwiperTunnel/Layout/SwiperTunnelTitle';

const RegistrationFormWrapper: FC<
  PropsWithChildren<{
    handleUserRegistrationWithAuthProvider: any;
    hideAuthProviderLogin?: boolean;
    buttonSubmitMode: SUBMIT_BUTTON_MODE;
  }>
> = ({ children, handleUserRegistrationWithAuthProvider, hideAuthProviderLogin = false, buttonSubmitMode }) => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', { keyPrefix: 'component.organisms.forms.signIn.RegistrationFormWrapper' });

  return (
    <div>
      <div className={styles.titleWrapper}>
        <SwiperTunnelTitle title={v('title')} subtitle={v('subtitle')} />
      </div>

      {children}

      <button type={'submit'} className={'hidden'}>
        submit
      </button>
      <CTAButton
        type={'submit'}
        disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
        icon={getSubmitIcon(buttonSubmitMode)}
        spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
        expand={'block'}
        buttonText={t('registerButtonText')}
      />

      <div className={styles.splitLineWrapper}>
        <SplitLine text={t('or')}></SplitLine>
      </div>

      {hideAuthProviderLogin ? null : (
        <AuthProviderLoginButtons handleUserRegistrationWithAuthProvider={handleUserRegistrationWithAuthProvider} />
      )}

      <AcceptPrivacyInfoText />

      <Text align={'center'}>
        <Text tag={'span'} align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {v('alreadyHaveAnAccount')}
        </Text>
        <br />
        <button type={'button'} onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}>
          <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
            {v('loginNow')}
          </Text>
        </button>
      </Text>
    </div>
  );
};

export default RegistrationFormWrapper;
