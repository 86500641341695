import TextEditor from '../../../../atoms/formElement/TextEditor';
import { FormContext } from '../../../../../core/context/form.context';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import UserContext from '../../../../../core/context/user.context';
import useToast from '../../../../../core/hook/toast.hook';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FC, useContext, useState } from 'react';
import FormLayout from '../../../FormLayout';
import { FirebaseFunctionsContext } from '../../../../../core/context/firebase-functions.context';
import FB_FUNCTION_URLS from '../../../../../core/const/fb-function-names';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import Joi from 'joi';
import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import { IAboutMe, TENANT_ABOUT_ME_SCHEMA } from '@wohnsinn/ws-ts-lib';
import PageTitle from '../../../../molecules/PageTitle';
import { ROUTES } from '../../../../../core/const/routes';
import { ITenantFormProps } from '../TenantProfileForm';

const DEFAULT_ABOUT_ME_DATA: IAboutMe = {
  description: null,
  introductionVideo: null,
};

const TenantAboutMeForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantProfile } = useContext(UserContext);
  const { sendSuccessToast } = useToast();
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.forms.tenant.TenantAboutMeForm' });
  const [submitMode, setSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const router = useIonRouter();

  const { control, handleSubmit } = useForm<IAboutMe>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(TENANT_ABOUT_ME_SCHEMA)),
    defaultValues: tenantProfile?.aboutMe ? tenantProfile?.aboutMe : DEFAULT_ABOUT_ME_DATA,
  });

  const onSuccess = async (): Promise<void> => {
    setSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    try {
      if (isApplicationFolderTunnel) {
        router.push(c(ROUTES.tenant.account.applicationFolder.introductionVideo), 'forward');
      } else {
        router.push(c(ROUTES.tenant.account.overview.path), 'back');
      }

      await sendSuccessToast('toast.profileUpdated');
    } catch (e) {
      console.error(e);
    }
  };

  const updateTenantAboutMe = (formData: IAboutMe): Promise<any> => {
    setSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    return firebaseFunctionsService
      .callFbFunction(FB_FUNCTION_URLS.user.aboutMe.update, {
        body: {
          ...formData,
          introductionVideo: tenantProfile?.aboutMe?.introductionVideo
            ? tenantProfile?.aboutMe?.introductionVideo
            : null,
        },
        params: { tenantProfileId: tenantProfile.uid },
      })
      .then(onSuccess)
      .catch((e) => console.error('error updating tenantprofile.aboutMe', e));
  };

  const CANCEL_BUTTON = {
    text: c('cancel'),
    action: () => router.push(c(ROUTES.tenant.account.overview.path), 'back'),
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form
        onSubmit={handleSubmit(
          (formData) => updateTenantAboutMe(formData),
          (err) => console.error(err)
        )}
      >
        {!isApplicationFolderTunnel && (
          <PageTitle
            pageTitle={c(ROUTES.tenant.account.aboutMe.title)}
            showSubmitButton
            submitMode={submitMode}
            notSubmitButton={CANCEL_BUTTON}
          />
        )}
        <FormLayout submitMode={submitMode} notSubmitButton={isApplicationFolderTunnel ? null : CANCEL_BUTTON}>
          <IonGrid className={'wohnsinn-forms-grid'}>
            <IonRow className={'wohnsinn-form-section'}>
              <IonCol size={'12'}>
                <FormSectionTitle title={t('description.label')} />

                <TextEditor
                  showLabel={false}
                  size={'2x'}
                  name={'description'}
                  label={t('description.label')}
                  placeholder={t('description.placeholder')}
                  helperText={t('description.helperText')}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </FormLayout>
      </form>
    </FormContext.Provider>
  );
};

export default TenantAboutMeForm;
