import { FC } from 'react';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import DocumentUploadItem from '../DoumentUploadItem';
import { UPLOAD_TYPE } from '../index';

const DocumentUploadFileList: FC<{
  documentUploadList: IUpload[];
  uploadType: UPLOAD_TYPE;
  onDocumentDelete: (onDocumentDelete: IUpload, uploadType: UPLOAD_TYPE) => void;
}> = ({ documentUploadList, uploadType, onDocumentDelete }) => {
  return (
    <div>
      {documentUploadList.map((documentUpload, i) => (
        <DocumentUploadItem
          key={`${documentUpload?.id}_${new Date().getTime()}`}
          uploadItemData={documentUpload}
          uploadType={uploadType}
          onDocumentDelete={onDocumentDelete}
          documentName={`Dokument-${i + 1}`}
        />
      ))}
    </div>
  );
};

export default DocumentUploadFileList;
