import { FC, PropsWithChildren, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose } from '@fortawesome/pro-light-svg-icons';
import styles from './ModalWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../../../core/context/modal.context';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IModalWrapperProps {
  title?: string;
  subtitle?: string;
  noContentPadding?: boolean;
  noHeadingMargin?: boolean;
  backFunction?: any;
  icon?: string | IconProp;
  canClose?: boolean;
}

const ModalWrapper: FC<PropsWithChildren<IModalWrapperProps>> = ({
  children,
  title,
  subtitle,
  noContentPadding = false,
  noHeadingMargin = false,
  backFunction,
  icon,
  canClose = true,
}) => {
  const { t: m } = useTranslation('modals');
  const { closeModal } = useContext(ModalContext);

  return (
    <div className={styles.modalWrapper}>
      <div className={`${styles.modalHeader} ${noHeadingMargin ? styles.noHeadingMargin : ''}`}>
        {icon ? (
          typeof icon === 'string' ? (
            <img className={styles.icon} src={icon} />
          ) : (
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon className={styles.icon} icon={icon} />
            </div>
          )
        ) : null}

        {backFunction ? (
          <button className={`${styles.button} ${styles.back}`} onClick={backFunction}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className={'sr-only'}>{m('back')}</span>
          </button>
        ) : null}

        <div className={styles.titleWrapper}>
          {title ? (
            <Headline tag={'span'} size={HEADLINE_SIZE.H3} align={'center'}>
              {title}
            </Headline>
          ) : null}
          {subtitle ? (
            <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'} align={'center'}>
              {subtitle}
            </Text>
          ) : null}
        </div>

        {canClose ? (
          <button className={`${styles.button} ${styles.close}`} onClick={closeModal}>
            <FontAwesomeIcon icon={faClose} className={styles.closeIcon} />
            <span className={'sr-only'}>{m('close')}</span>
          </button>
        ) : null}
      </div>
      <div className={`${styles.childrenWrapper} ${noContentPadding ? styles.noContentPadding : ''}`}>{children}</div>
    </div>
  );
};

export default ModalWrapper;
