import { FC } from 'react';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { IonGrid } from '@ionic/react';
import ApartmentMediaUpload from '../../../ApartmentMediaUpload';
import ApartmentMediaList from '../../../../molecules/ApartmentMediaList';
import SplitLine from '../../../../atoms/SplitLine';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import FormHeader from '../../../../atoms/FormHeader';

const ApartmentMediaForm: FC<{ apartment: IApartment }> = ({ apartment }) => {
  return (
    <>
      <FormHeader title={'Medien'} />
      {apartment && (
        <IonGrid className={'wohnsinn-forms-grid'}>
          <FormSectionTitle title={'Upload'} />

          <ApartmentMediaUpload apartment={apartment} />

          <SplitLine />

          {apartment.imagesToResizeCount > 0 ? null : (
            <>
              <FormSectionTitle title={'Wohnungsbilder'} />
              <ApartmentMediaList apartment={apartment} />
            </>
          )}
        </IonGrid>
      )}
    </>
  );
};

export default ApartmentMediaForm;
