import {
  ITenantFilterParams,
  IUserWithRoles,
  FIRESTORE_COLLECTION_PATH,
  TTenantFilterParamsCreate,
} from '@wohnsinn/ws-ts-lib';
import DatabaseService from './database.service';
import UserService from './user.service';
import LOCAL_STORAGE_KEYS from '../enum/local-storage.enum';

export const DEFAULT_TENANT_FILTER_PARAMS: TTenantFilterParamsCreate = {
  arePetsAllowed: null,
  createdAt: new Date(),
  equipmentList: [],
  importantPlaces: [],
  isSharedUsagePossible: null,
  location: null,
  numberOfPeopleMovingIn: null,
  rooms: null,
  size: null,
  warmRent: null,
};

class TenantFilterParamsService {
  constructor(private databaseService: DatabaseService, private userService: UserService) {}

  public async getOrCreateTenantFilterParamsId(user: IUserWithRoles = null): Promise<string | void> {
    const localTenantFilterParamsId: string = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_FILTER_PARAMS_ID);

    let tenantFilterParamsId: string | void;
    if (user) {
      tenantFilterParamsId = await this.getTenantFilterParamsIdFromProfile(user.uid);
    }

    if (!tenantFilterParamsId) {
      if (localTenantFilterParamsId) {
        tenantFilterParamsId = localTenantFilterParamsId;
      } else {
        const tenantFilterParamsDocRef: string | void = await this.createTenantFilterParams();

        if (tenantFilterParamsDocRef) {
          tenantFilterParamsId = tenantFilterParamsDocRef;
        }
      }
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.TENANT_FILTER_PARAMS_ID, tenantFilterParamsId as string);
    return tenantFilterParamsId;
  }

  public async getFilterParams(user?: IUserWithRoles): Promise<ITenantFilterParams | void> {
    const tenantFilterParamsId: string | void = await this.getOrCreateTenantFilterParamsId(user);

    return this.databaseService
      .getDbDoc<ITenantFilterParams>(`${FIRESTORE_COLLECTION_PATH.tenantFilterParams.root}/${tenantFilterParamsId}`, {
        fetchWithId: true,
      })
      .then((filterParams) => {
        return filterParams;
      })
      .catch((err) => {
        console.error(err);
        this.setFilterParams(DEFAULT_TENANT_FILTER_PARAMS);
        this.getFilterParams(user);
      });
  }

  public async setFilterParams<T>(data: T, user: IUserWithRoles = null): Promise<void> {
    const tenantFilterParamsId: string | void = await this.getOrCreateTenantFilterParamsId(user);

    return this.databaseService
      .setDbDoc<T>(data, `${FIRESTORE_COLLECTION_PATH.tenantFilterParams.root}/${tenantFilterParamsId}`, true, {
        setCreatedAt: false,
      })
      .catch((err) => console.error(err));
  }

  private async getTenantFilterParamsIdFromProfile(uid: string): Promise<string | void> {
    const startTime = new Date().getTime();

    return this.userService
      .getTenantProfile(uid)
      .then((tenantProfile) => {
        if (tenantProfile) {
          return tenantProfile.tenantFilterParamsId;
        }
      })
      .catch((err) => console.error(err));
  }

  private async createTenantFilterParams(uid: string = null): Promise<string | void> {
    const startTime = new Date().getTime();
    return this.databaseService
      .addDbDoc<TTenantFilterParamsCreate>(
        { ...DEFAULT_TENANT_FILTER_PARAMS },
        FIRESTORE_COLLECTION_PATH.tenantFilterParams.root
      )
      .then(async (docRef) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.TENANT_FILTER_PARAMS_ID, docRef.id);
        if (uid) {
          return this.userService.updateTenantFilterParamsId(uid, docRef.id).catch((err) => {
            console.error('error on setTenantFilterParamsIdOnTenantProfile', err);
          });
        }

        return docRef.id;
      })
      .catch((err) => console.error(err));
  }
}

export default TenantFilterParamsService;
