import ModalWrapper from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import Spacer from '../../../../atoms/Spacer';
import ModalContext from '../../../../../core/context/modal.context';
import { useContext } from 'react';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import UserContext from '../../../../../core/context/user.context';
import useToast from '../../../../../core/hook/toast.hook';

const EmailVerificationModal = () => {
  const { closeModal } = useContext(ModalContext);
  const { authService, activeUserType, landlordProfile, tenantProfile } = useContext(UserContext);
  const { sendInfoToast } = useToast();
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.signIn.ConfirmEmailModal' });
  const email = localStorage.getItem('userC');

  const resendVerificationMail = async (): Promise<void> => {
    const email: string = activeUserType === USER_TYPE.LANDLORD ? landlordProfile.email : tenantProfile.email;
    try {
      await authService.triggerSendVerificationLink(email, activeUserType);
      await sendInfoToast('toast.resendEmailVerificationMail');
    } catch (e) {
      console.error('Error on resendVerificationMail', e);
    }
  };

  return (
    <ModalWrapper title={m('title')} icon={t('pictogram.mail')}>
      <Text align={'center'}>{m('text', { replace: { email } })}</Text>
      <Text
        color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
        type={TEXT_TYPE.TEXT_TYPE_CAPTION}
        weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
        align={'center'}
      >
        <button onClick={resendVerificationMail}>
          {m('subText')}
          {m('sendNew')}
        </button>
      </Text>
      <Spacer />
      <CTAButton expand={'block'} buttonText={t('ok')} onClick={closeModal} />
    </ModalWrapper>
  );
};

export default EmailVerificationModal;
