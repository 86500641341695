import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import AuthService from '../service/auth.service';
import { ILandlordProfile, ITenantProfile, IUserWithRoles, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import UserService from '../service/user.service';

const useAuthUser = (
  userService: UserService,
  authService: AuthService
): {
  user: IUserWithRoles;
  landlordProfile: ILandlordProfile;
  tenantProfile: ITenantProfile;
  activeUserType: USER_TYPE;
  loadingUser: boolean;
} => {
  const [user] = useAuthState(authService.auth);
  const [userData, setUserData] = useState<IUserWithRoles>(null);
  const [landlordProfile, setLandlordProfile] = useState<ILandlordProfile>(null);
  const [tenantProfile, setTenantProfile] = useState<ITenantProfile>(null);
  const [isLoadingUser, setIsLoadingProfile] = useState<boolean>(true);

  const handleUserLogout = (): void => {
    setUserData(null);
    setTenantProfile(null);
    setLandlordProfile(null);
    setIsLoadingProfile(false);
  };

  useEffect(() => {
    onAuthStateChanged(authService.auth, (user) => {
      if (user) {
        const userDataRef = userService.getUserDocRefByUid(user.uid);

        onSnapshot(userDataRef, (doc) => {
          setUserData(doc.data() as IUserWithRoles);
        });
      } else {
        handleUserLogout();
      }
    });
  }, [user]);

  useEffect(() => {
    if (userData?.isTenant && userData?.uid) {
      const tenantProfileRef = userService.getTenantProfileRefByUid(userData.uid);
      onSnapshot(tenantProfileRef, (doc) => {
        if (doc.exists()) {
          const tenantProfile: ITenantProfile = { ...doc.data() } as ITenantProfile;
          setTenantProfile(tenantProfile);
          setIsLoadingProfile(false);
        }
      });
    }

    if (userData?.isLandlord && userData?.uid) {
      const landlordProfileRef = userService.getLandlordProfileRefByUid(userData.uid);

      onSnapshot(landlordProfileRef, (doc) => {
        if (doc.exists()) {
          const landlordProfile: ILandlordProfile = { ...doc.data() } as ILandlordProfile;
          setLandlordProfile(landlordProfile);
          setIsLoadingProfile(false);
        }
      });
    }
  }, [userData]);

  return {
    user: userData ? userData : null,
    tenantProfile,
    landlordProfile,
    activeUserType: userData ? userData.activeUserType : USER_TYPE.TENANT,
    loadingUser: isLoadingUser,
  };
};

export default useAuthUser;
