import { useTranslation } from 'react-i18next';
import Section from '../../../component/atoms/Section';
import TextBlockSection from '../../../component/molecules/TextBlockSection';
import TextParagraph from '../../../component/molecules/TextParagraph';
import PageLayout from '../../../component/organisms/PageLayout';
import { ROUTES } from '../../../core/const/routes';

const PrivacyView = () => {
  const { t } = useTranslation('common');
  return (
    <PageLayout pageTitle={t(ROUTES.general.privacy.title)}>
      <Section>
        <div title="Datenschutzerklärung Website">
          In dieser Datenschutzerklärung informieren wir Sie gem. Art. 12 – 14 DS‑GVO über unsere Verarbeitung auf Sie
          beziehbarer Daten im Rahmen Ihrer Benutzung unserer Website und Ihrer Kontaktaufnahme mit uns.
        </div>
      </Section>
      <Section>
        <TextBlockSection title="1. Verantwortlicher">
          Verantwortlicher für die Verarbeitung gem. Art. 24 DS‑GVO ist die
          <br />
          <br />
          Wohnsinn GmbH
          <br />
          Martinsfeld 12
          <br />
          50676 Köln
          <br />
          kontakt@wohnsinn.com
          <br /> <br />
          <br />
        </TextBlockSection>
        <TextBlockSection title="2. Kontaktdaten unseres Datenschutzbeauftragten">
          Per E-Mail an <a href="mailto:datenschutz@wohnsinn.com">datenschutz@wohnsinn.com</a> können Sie Kontakt zum
          Datenschutzbeauftragten des Verantwortlichen aufnehmen.
        </TextBlockSection>
        <TextBlockSection title="3. Ihre Rechte">
          <TextParagraph
            title={'Rechte'}
            count={3.1}
            description={
              'Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:'
            }
          >
            <li>Recht auf Auskunft sowie auf Erhalt einer Kopie Ihrer Daten,</li>
            <li>Recht auf Berichtigung,</li>
            <li>Recht auf Löschung und auf Vergessenwerden,</li>
            <li>Recht auf Einschränkung der Verarbeitung,</li>
            <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
            <li>Recht auf Datenübertragbarkeit.</li>
            <br />
            Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
            personenbezogenen Daten durch uns zu beschweren.
          </TextParagraph>
        </TextBlockSection>
        <TextBlockSection title="4. Bereitstellung dieser Website">
          <TextParagraph
            title={'Zweck'}
            description={
              ' Diese Verarbeitung dient dem Verbindungsaufbau unseres Webservers' +
              ' mit Ihrem Browser für Ihre Nutzung unserer' +
              'Website.'
            }
            count={4.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten:'}
            count={4.2}
          >
            <li>IP-Adresse,</li>
            <li>Datum und Uhrzeit der Anfrage;</li>
            <li>Die Zeitzonendifferenz zur Greenwich Mean Time (GMT) des anfragenden Endgeräts,</li>
            <li>Der Inhalt der Anforderung, das heißt, die konkreten Seiten die angesteuert werden;</li>
            <li>der Zugriffsstatus/HTTP-Statuscode;</li>
            <li>die jeweils übertragene Datenmenge;</li>
            <li>die Webseite, von der aus die Anforderung kommt;</li>
            <li>der vom anfragenden Endgerät verwendete Browser;</li>
            <li>die Sprache und die Version der vom anfragenden Endgerät verwendeten Browsersoftware;</li>
            <li>das Betriebssystem und die Oberfläche des anfragenden Endgeräts.</li>
          </TextParagraph>
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              ' Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist die Erbringung\n' +
              '        unserer Dienstleistungen, deren Empfänger Websitebesucher sind sowie die Durchführung vorvertraglicher\n' +
              '        Maßnahmen, die auf Anfrage der Websitebesucher erfolgen, gem. Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={4.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              ' Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist für den Verbindungsaufbau unseres Webservers\n' +
              ' mit Ihrem Browser für Ihre Nutzung unserer Website erforderlich. Falls Sie uns die oben genannten auf Sie\n' +
              ' bezogenen Daten nicht zur Verfügung stellen, können wir Ihnen unsere Website nicht darstellen.'
            }
            count={4.4}
          ></TextParagraph>
          <TextParagraph
            title={'Speicherdauer'}
            count={4.5}
            description={'Die Daten werden nach Ablauf Ihrer Browser-Session gelöscht.'}
          />
          <TextParagraph
            title={'Empfänger'}
            count={4.6}
            description={
              'Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister mit Standort in Deutschland'
            }
          />
        </TextBlockSection>
        <TextBlockSection title="5. Sicherheit unserer Website">
          <TextParagraph
            title={'Zweck'}
            count={5.1}
            description={
              'Diese Verarbeitung dient der Auswertung der Systemsicherheit und Systemstabilität sowie Sicherheit der ' +
              'Verarbeitung für die Gewährleistung des störungsfreien Betriebs der Website.'
            }
          />
          <TextParagraph
            title={'Datenarten'}
            count={5.2}
            description={'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten:'}
          >
            <li>IP-Adresse Ihres Endgeräts,</li>
            <li>Datum und Uhrzeit Ihres Zugriffs,</li>
            <li>der Inhalt Ihrer Anforderung, das heißt, die konkreten Webseiten, die von Ihnen angesteuert werden,</li>
            <li>Name und URL der abgerufenen Dateien,</li>
            <li>Website, von der aus Ihr Zugriff erfolgt (Referrer-URL),</li>
            <li>der von Ihnen verwendeten Browser,</li>
            <li>das Betriebssystem Ihres Endgerätes,</li>
            <li>der Name Ihres Access-Providers,</li>
            <li>die Spracheinstellungen der von Ihrem Endgerät verwendeten Browsersoftware,</li>
            <li>die Version der von Ihrem Endgerät verwendeten Browsersoftware,</li>
            <li>die Zeitzonendifferenz zur Greenwich Mean Time (GMT) Ihres Endgeräts,</li>
            <li>der Zugriffsstatus/HTTP-Statuscode.</li>
          </TextParagraph>
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist der' +
              ' Grundsatz der Richtigkeit sowie Integrität und Vertraulichkeit gem. Art. 5 Abs. 1 lit. d und f ' +
              'DS‑GVO i. V. m. Art. 6 Abs. 1 UAbs. 1 lit. c DS‑GVO, da diese' +
              ' Verarbeitungstätigkeit eine technische und organisatorische Maßnahme gem. Art. 24, 28 und 32 DS‑GVO zur ' +
              'Sicherung der Integrität darstellt, in Verbindung mit der Nachweisbarkeit der Einhaltung dieser Pflichten ' +
              'gem. Art. 5 Abs. 1 lit. d und f DS‑GVO i. V. m. Art. 5 Abs. 2 DS‑GVO.'
            }
            count={5.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist 7 Tage.'
            }
            count={5.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister mit Standort in Deutschland.'
            }
            count={5.5}
          />
        </TextBlockSection>
        <TextBlockSection title="6. Einwilligungsverwaltung">
          <TextParagraph
            title={'Zweck'}
            description={
              'Diese Verarbeitung dient der Verwaltung Ihrer Einwilligungen ' +
              'und der Dokumentation Ihrer Einwilligungen.'
            }
            count={6.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten dabei Einwilligungsdaten als auf Sie beziehbare Daten.'}
            count={6.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist unsere Nachweispflicht gem. Art. 5 Abs. 2 DS‑GVO i. V. m. Art. 6 Abs. 1 UAbs. 1 lit. c DS‑GVO i. V. m. Art. 7 Abs. 1 DS-GVO.\n' +
              'Die Speicherung und das Auslesen von für die Verwaltung Ihrer Einwilligungen und die Dokumentation Ihrer Einwilligungen technisch notwendigen Daten auf Ihrem Endgerät ist Art. 5 Abs. 2 DS‑GVO i. V. m. Art. 6 Abs. ' +
              '1 UAbs. 1 lit. c DS‑GVO i. V. m § 25 Abs. 2 Nr. 2 TTDSG'
            }
            count={6.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung ist uns gesetzlich vorgeschrieben gem. Art. 5 Abs. 2 DS-GVO i. V. m. Art. 6 Abs. 1 UAbs. 1 lit. c DS‑GVO i. V. m. Art. 7 Abs. 1 DS-GVO i. V. m § 25 Abs. 2 Nr. 2 TTDSG.'
            }
            count={6.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten beträgt drei Jahre.'
            }
            count={6.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Bereitstellung der ' +
              'Einwilligungsverwaltung nutzen wir einen Einwilligungsmanagementdienstleister. ' +
              'Die oben genannten auf Sie ' +
              'beziehbaren Daten werden in die USA als ' +
              'Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können'
            }
            count={6.6}
          />
        </TextBlockSection>
        <TextBlockSection title="7. Kontaktaufnahme mit uns per E-Mail oder Telefon">
          <TextParagraph
            title={'Zweck'}
            description={'Diese Verarbeitung dient Ihrer Kontaktaufnahme mit uns.'}
            count={7.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Die uns von Ihnen ' +
              'zur Verfügung gestellten Kontaktdaten. Weitere Informationen erheben wir direkt bei Ihnen,' +
              ' wo sie notwendig und erheblich für die Beantwortung Ihrer Anfrage sind ' +
              'und uns von Ihnen zur Verfügung gestellt werden.'
            }
            count={7.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der ' +
              'oben genannten auf Sie beziehbaren Daten ' +
              'ist die Erbringung unserer Dienstleistungen, deren Vertragspartei Sie sind, ' +
              'sowie die Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage erfolgen, ' +
              'gem. Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO'
            }
            count={7.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie ' +
              'beziehbaren Daten ist erforderlich zur ' +
              'Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten ' +
              'nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={7.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Sich aus Ihrer Kontaktaufnahme ergebende Verarbeitungen Ihrer Daten, etwa ' +
              'für die Durchführung von Verträgen,' +
              ' werden gemäß der für sie geltenden gesetzlichen Aufbewahrungspflicht archiviert.'
            }
            count={7.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister mit Standort in Deutschland.' +
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen wir ' +
              'ein Kundenverwaltungssystem mit Standort in Deutschland.'
            }
            count={7.6}
          />
        </TextBlockSection>
        <TextBlockSection title="8. Kundenverwaltungsmanagement">
          <TextParagraph
            title={'Zweck'}
            description={'Diese Verarbeitung dient der Kundenverwaltung unseres Unternehmens.'}
            count={8.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten dabei Kundendaten als auf Sie beziehbare Daten.'}
            count={8.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist die Erbringung' +
              ' unserer Dienstleistungen, deren Vertragspartei Sie sind,' +
              ' sowie die Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage erfolgen, gem. Art. 6 Abs. 1 UAbs.' +
              ' 1 lit. b DS-GVO.'
            }
            count={8.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Wohnsinn GmbH ist gesetzlich verpflichtet, die oben genannte Verarbeitung vorzunehmen gem. Art. ' +
              '6 Abs. 1 UAbs. 1 lit. c DS‑GVO i. V. m § 174 AO und § 257 HGB.'
            }
            count={8.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der buchhaltungsrelevanten und steuerlich relevanten Daten beträgt 6 Jahre.'
            }
            count={8.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen wir ein Kundenverwaltungssystem mit Standort in Deutschland.'
            }
            count={8.6}
          />
        </TextBlockSection>
        <TextBlockSection title="9. Newsletterversand">
          <TextParagraph
            title={'Zweck'}
            description={'Diese Verarbeitung dient der Außendarstellung unseres Unternehmens.'}
            count={9.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie ' +
              'beziehbarer Daten: Vorname, Nachname, E-Mail-Adresse, ' +
              'Firmenname (optional) und Telefonnummer (optional).'
            }
            count={9.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ' +
              'ist Ihre ausdrückliche Einwilligung gem. Art. 6 Abs. 1 UAbs. 1 lit. a DS-GVO.'
            }
            count={9.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zum' +
              ' Widerruf Ihrer Einwilligung, bzw. bis zur Abbestellung des Newsletters.'
            }
            count={9.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen ' +
              'wir Newsletter-Dienstleister. Die oben genannten auf Sie beziehbaren Daten ' +
              'werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO ' +
              'übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren' +
              ' Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind ' +
              'Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den ' +
              'Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={9.5}
          />
          <TextParagraph
            title={'Ihr Recht auf Widerruf Ihrer Einwilligung'}
            description={
              'Sie können Ihre Einwilligung in diese Verarbeitung jederzeit mit Wirkung für die' +
              ' Zukunft schriftlich, per Mail an datenschutz@wohnsinn.com oder durch' +
              ' Anklicken des entsprechenden Abmelde-Links am Ende jedes Newsletters widerrufen.'
            }
            count={9.6}
          />
        </TextBlockSection>
        <TextBlockSection title="10. Newsletter-Reichweitenanalyse">
          <TextParagraph
            title={'Zweck'}
            description={
              'Diese Verarbeitung dient der Außendarstellung unseres Unternehmens durch den Versand von Newslettern.'
            }
            count={10.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Benutzerverhaltensdaten und Verbindungsdaten.'
            }
            count={10.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse an' +
              ' einer statistischen Auswertung des Nutzungsverhaltens innerhalb unserer Newsletter zum' +
              ' Zweck der Optimierung unseres Webangebots gem. Art. 6 Abs. 1 UAbs. 1 lit. f DS‑GVO.'
            }
            count={10.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie' +
              ' beziehbaren Daten ist bis zum Widerruf Ihrer Einwilligung, bzw.' +
              ' bis zur Abbestellung des Newsletters'
            }
            count={10.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen wir' +
              ' Newsletter-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die' +
              ' USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die ' +
              'Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das' +
              ' zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem.' +
              ' Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den ' +
              'Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={10.5}
          />
          <TextParagraph
            title={'Recht auf Widerspruch'}
            description={
              'Sie können gegen diese Verarbeitung der auf Sie beziehbaren Daten Widerspruch einlegen per E-Mail an datenschutz@wohnsinn.com.'
            }
            count={10.6}
          />
        </TextBlockSection>
        <TextBlockSection title="11. Chatfunktion">
          <TextParagraph
            title={'Zweck'}
            description={
              'Diese Verarbeitung dient der Außendarstellung unseres Unternehmens sowie der Bereitstellung einer Chat-Möglichkeit.'
            }
            count={11.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Nachrichtendaten, Verbindungsdaten und Endgerätedaten.'
            }
            count={11.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist die Durchführung erforderlicher vorvertraglicher Maßnahmen und die Erfüllung von Verträgen gem. Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.' +
              'Die Speicherung eines für die Einbindung der Chat-Funktion technisch notwendigen Cookies auf Ihrem Endgerät ist Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO i. V. m § 25 Abs. 2 Nr. 2 TTDSG.'
            }
            count={11.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich' +
              ' zur Durchführung vorvertraglicher Maßnahmen und zur Bearbeitung Ihrer Anfrage.' +
              ' Falls Sie uns die oben genannten auf Sie bezogenen' +
              ' Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={11.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten beträgt zehn Stunden.' +
              'Die Speicherdauer des technisch notwendigen Cookies beträgt zehn Stunden.'
            }
            count={11.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen ' +
              'wir einen Chat-Dienstleister. ' +
              'Die oben genannten auf Sie beziehbaren Daten werden in die USA als ' +
              'Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. ' +
              'Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in die zuvor genannten Drittländer ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={11.6}
          />
        </TextBlockSection>
        <TextBlockSection title="12. E-Mail-Benachrichtigung zur Verfügbarkeit von Wohnsinn">
          <TextParagraph
            title={'Zweck'}
            description={
              'Diese Verarbeitung dient der E-Mail-Benachrichtigung, sobald Wohnsinn in der jeweiligen Stadt verfügbar ist.'
            }
            count={12.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Stadt und E-Mail-Adresse.'
            }
            count={12.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist Ihre ausdrückliche Einwilligung gem. Art. 6 Abs. 1 UAbs. 1 lit. a DS-GVO.'
            }
            count={12.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zum Widerruf Ihrer Einwilligung.'
            }
            count={12.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen wir einen Formular-Dienstleister. ' +
              'Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss' +
              ' gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie' +
              ' beziehbaren Daten in die zuvor genannten Drittländer ohne Angemessenheitsbeschluss sind' +
              ' Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den' +
              ' Datenschutz, ' +
              'die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={12.5}
          />
          <TextParagraph
            title={'Ihr Recht auf Widerruf'}
            description={
              'Sie können Ihre Einwilligung in diese Verarbeitung jederzeit mit Wirkung für die Zukunft schriftlich, per Mail an datenschutz@wohnsinn.com widerrufen.'
            }
            count={12.6}
          />
        </TextBlockSection>
        <TextBlockSection title="13. Anmeldung für Vermieter">
          <TextParagraph
            title={'Zweck'}
            description={'Diese Verarbeitung dient der Bereitstellung der „Jetzt testen“-Möglichkeit für Vermieter.'}
            count={13.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten:'}
            count={13.2}
          >
            <li>Name;</li>
            <li>Ob Makler, Hausverwaltung, Wohnungsunternehmen, Privater Vermieter oder Sonstiges;</li>
            <li>Wie viele Vermietungen im Monat durchgeführt werden (1, 1-5, 5-10 oder +10);</li>
            <li>E-Mail-Adresse;</li>
          </TextParagraph>
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ' +
              'ist Ihre ausdrückliche Einwilligung gem. Art. 6 Abs. 1 UAbs. 1 lit. a DS-GVO.'
            }
            count={13.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zum Widerruf Ihrer Einwilligung.'
            }
            count={13.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie ' +
              'beziehbaren Daten nutzen wir einen Formular-Dienstleister. Die oben genannten auf Sie beziehbaren ' +
              'Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt.' +
              ' Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in die zuvor ' +
              'genannten Drittländer ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO.' +
              ' Die Datenimporteure bieten geeignete Garantien für den ' +
              'Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={13.5}
          />
          <TextParagraph
            title={'Ihr Recht auf Widerruf'}
            description={
              'Sie können Ihre Einwilligung in diese Verarbeitung jederzeit mit Wirkung für die Zukunft schriftlich, per Mail an datenschutz@wohnsinn.com widerrufen.'
            }
            count={13.6}
          />
        </TextBlockSection>
        <TextBlockSection title="14. Webanalyse">
          <TextParagraph
            title={'Zwecke'}
            description={
              'Wir nutzen Funktionen des Webanalysedienstes Hotjar zur Analyse des Benutzerverkehrs auf unserer Website zum Zweck der Optimierung unseres Webangebots.'
            }
            count={14.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Zum Erreichen des oben genannten Zwecks werden auf Sie beziehbare Verbindungsdaten und Endgerätedaten verarbeitet.'
            }
            count={14.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse an einer ' +
              'statistischen Auswertung des Besucherverkehrs auf unserer Website zum Zweck der' +
              ' Optimierung unseres Webangebots gem. Art. 6 Abs. 1 UAbs. 1 lit. f DS‑GVO.'
            }
            count={14.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten beträgt ein Jahr.'
            }
            count={14.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Die über Hotjar verarbeiteten oben genannten auf Sie beziehbaren Daten werden ' +
              'an Hotjar Ltd., Dragonara Business Centre 5th Floor, Dragonara Road, Paceville St.' +
              " Julian's, STJ 3141, Malta, übermittelt." +
              'Die über Hotjar verarbeiteten auf Sie beziehbaren Daten werden in die USA' +
              ' als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt.' +
              ' Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten' +
              ' in die zuvor genannten Drittländer ohne Angemessenheitsbeschluss sind Standardvertragsklauseln ' +
              'gem. Art. 46 DS-GVO. Hotjar bietet geeignete Garantien für den Datenschutz, ' +
              'die Sie auf Anfrage unter datenschutz@wohnsinn.com einsehen können.'
            }
            count={14.5}
          />
          <TextParagraph
            title={'Ihr Recht auf Widerspruch'}
            description={
              'Sie können gegen diese Verarbeitung der auf Sie beziehbaren Daten Widerspruch einlegen per E-Mail an datenschutz@wohnsinn.com.'
            }
            count={14.6}
          />
        </TextBlockSection>
        <TextBlockSection title="16. Instagram-Präsenz">
          <TextParagraph
            title={'Instagram-Präsenz'}
            description={
              'Diese Verarbeitung dient der Außendarstellung unseres Unternehmens.\n' +
              '\n' +
              'Wir haben mit Instagram eine Vereinbarung über die gemeinsame Verantwortlichkeit getroffen: https://www.facebook.com/legal/terms/page_controller_addendum.\n' +
              '\n' +
              'Instagram übernimmt gemäß der gemeinsamen Verantwortlichkeit die Erfüllung der Transparenzpflichten. Sie können sich hier über die Verarbeitung Ihrer personenbezogenen Daten informieren: https://de-de.facebook.com/help/instagram/519522125107875/.'
            }
            count={16.1}
          />
        </TextBlockSection>
        <TextBlockSection title="17. Facebook-Präsenz">
          <TextParagraph
            title={'Facebook-Präsenz'}
            description={
              'Diese Verarbeitung dient der Außendarstellung unseres Unternehmens.\n' +
              '\n' +
              'Wir haben mit Facebook Ireland Ltd. eine Vereinbarung über die gemeinsame Verantwortlichkeit getroffen: https://www.facebook.com/legal/terms/page_controller_addendum.\n' +
              '\n' +
              'Facebook übernimmt gemäß dieser Vereinbarung die Erfüllung der Transparenzpflichten und Sie können sich hier über die Verarbeitung Ihrer personenbezogenen Daten informieren: https://www.facebook.com/legal/terms/information_about_page_insights_data.'
            }
            count={17.1}
          />
        </TextBlockSection>
        <TextBlockSection title="18. LinkedIn-Präsenz">
          <TextParagraph
            title={'LinkedIn-Präsenz'}
            description={
              'Diese Verarbeitung dient der Außendarstellung unseres Unternehmens.\n' +
              '\n' +
              'Wir haben mit LinkedIn eine Vereinbarung über die gemeinsame Verantwortlichkeit getroffen: https://legal.linkedin.com/pages-joint-controller-addendum.\n' +
              '\n' +
              'LinkedIn übernimmt gemäß der gemeinsamen Verantwortlichkeit die Erfüllung der Transparenzpflichten. Sie können sich hier über die Verarbeitung Ihrer personenbezogenen Daten informieren: https://privacy.linkedin.com/de-de.'
            }
            count={18.1}
          />
        </TextBlockSection>
        <TextBlockSection title="19. Twitter-Präsenz">
          <TextParagraph
            title={'Twitter-Präsenz'}
            description={
              'Diese Verarbeitung dient der Außendarstellung unseres Unternehmens. Sie können sich hier über die Verarbeitung Ihrer personenbezogenen Daten informieren: https://twitter.com/en/privacy.'
            }
            count={19.1}
          />
        </TextBlockSection>
        <Section>
          <div title="Datenschutzerklärung Webapp">
            In dieser Datenschutzerklärung informieren wir Sie gem. Art. 12 – 14 DSGVO über unsere Verarbeitung auf Sie
            beziehbarer Daten im Rahmen Ihrer Benutzung unserer Webapp.
          </div>
        </Section>
        <TextBlockSection title="20. Verantwortlicher">
          Verantwortlicher für die Verarbeitung gem. Art. 24 DS‑GVO ist die
          <br />
          <br />
          Wohnsinn GmbH
          <br />
          Martinsfeld 12
          <br />
          50676 Köln
          <br />
          kontakt@wohnsinn.com
          <br /> <br />
          <br />
        </TextBlockSection>
        <TextBlockSection title="21. Kontaktdaten unseres Datenschutzbeauftragten">
          Per E-Mail an <a href="mailto:datenschutz@wohnsinn.com">datenschutz@wohnsinn.com</a> können Sie Kontakt zum
          Datenschutzbeauftragten des Verantwortlichen aufnehmen.
        </TextBlockSection>
        <TextBlockSection title="22. Ihre Rechte">
          <TextParagraph
            title={'Rechte'}
            count={22.1}
            description={
              'Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:'
            }
          >
            <li>Recht auf Auskunft sowie auf Erhalt einer Kopie Ihrer Daten,</li>
            <li>Recht auf Berichtigung,</li>
            <li>Recht auf Löschung und auf Vergessenwerden,</li>
            <li>Recht auf Einschränkung der Verarbeitung,</li>
            <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
            <li>Recht auf Datenübertragbarkeit.</li>
            <br />
            Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
            personenbezogenen Daten durch uns zu beschweren.
          </TextParagraph>
        </TextBlockSection>
        <TextBlockSection title="23. Bereitstellung der Webapp">
          <TextParagraph
            title={'Zwecke'}
            description={
              'Diese Verarbeitung dient dem Verbindungsaufbau unseres Webservers mit Ihrem Browser für Ihre Nutzung unserer Webapp.'
            }
            count={23.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Verbindungsdaten.'}
            count={23.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={23.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich für die Durchführung des Nutzungsvertrags. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihren Nutzungsvertrag mit uns nicht durchführen.'
            }
            count={23.4}
          ></TextParagraph>
          <TextParagraph
            title={'Speicherdauer'}
            count={23.5}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist die Dauer ihrer Sitzung.\n'
            }
          />
          <TextParagraph
            title={'Empfänger'}
            count={23.6}
            description={
              'Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
          />
        </TextBlockSection>
        <TextBlockSection title="24. Kundenverwaltung">
          <TextParagraph
            title={'Zweck'}
            description={'Diese Verarbeitung dient der Kundenverwaltung unseres Unternehmens.'}
            count={24.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten dabei Kundendaten als auf Sie beziehbare Daten.'}
            count={24.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist die Erbringung unserer Dienstleistungen, deren Vertragspartei Sie sind, sowie die Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage erfolgen, gem. Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={24.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Wohnsinn GmbH ist gesetzlich verpflichtet, die oben genannte Verarbeitung vorzunehmen gem. Art. 6 Abs. 1 UAbs. 1 lit. c DSGVO i. V. m § 174 AO und § 257 HGB.'
            }
            count={24.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der buchhaltungsrelevanten und steuerlich relevanten Daten beträgt 6 Jahre.'
            }
            count={24.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen wir ein Kundenverwaltungssystem mit Standort in Deutschland.'
            }
            count={24.6}
          />
        </TextBlockSection>
        <TextBlockSection title="25. Registrierung per Google- oder Microsoft-Konto">
          <TextParagraph
            title={'Zwecke'}
            description={
              'Diese Verarbeitung dient der Erreichung folgenden Zwecks: Dem Anmelden innerhalb der Webapp per Benutzerkontoverknüpfung mit Google oder Microsoft, sowie der Sicherheit der Verarbeitung (Gewährleistung des störungsfreien Betriebs der Benutzerverwaltung).'
            }
            count={25.1}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Google- oder Microsoft-Anmeldedaten.\n'
            }
            count={25.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist der Grundsatz der Richtigkeit sowie Integrität und Vertraulichkeit gem. Art. 5 Abs. 1 lit. d und f DS-GVO, i. V. m. Art. 6 Abs. 1 UAbs. 1 lit. c DS-GVO, da diese Verarbeitungstätigkeit eine technische und organisatorische Maßnahme gem. Art. 24, 28 und 32 DS-GVO zur Sicherung der Integrität darstellt, in Verbindung mit der Nachweisbarkeit der Einhaltung dieser Pflichten gem. Art. 5 Abs. 1 lit. d und f DS-GVO, i. V. m. Art. 5 Abs. 2 DS-GVO.\n'
            }
            count={25.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={25.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Die über Google verarbeiteten oben genannten auf Sie beziehbaren Daten werden an Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, übermittelt. Die über Microsoft verarbeiteten oben genannten auf Sie beziehbaren Daten werden an Microsoft Corporation, 1 Microsoft Way, Redmond, WA 98052-6399, USA, übermittelt. Die über Google und Microsoft verarbeiteten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in die zuvor genannten Drittländer ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Google und Microsoft bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage unter datenschutz@wohnsinn.com einsehen können.'
            }
            count={25.6}
          />
        </TextBlockSection>
        <TextBlockSection title="26. Benutzerkontoverwaltung für Mietinteressenten">
          <TextParagraph
            title={'Zweck'}
            count={26.1}
            description={
              'Diese Verarbeitung dient der Erreichung folgenden Zwecks: Der Verwaltung eines Benutzerkontos sowie Sicherheit der Verarbeitung (Gewährleistung des störungsfreien Betriebs der Benutzerverwaltung).'
            }
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Vorname, Nachname, E-Mail-Adresse, ggf. Google-Anmeldedaten und ggf. Microsoft-Anmeldedaten.'
            }
            count={26.2}
          />
          <TextParagraph
            title={'Rechtsgrundlage'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist der Grundsatz der Richtigkeit sowie Integrität und Vertraulichkeit gem. Art. 5 Abs. 1 lit. d und f DS-GVO, i. V. m. Art. 6 Abs. 1 UAbs. 1 lit. c DS-GVO, da diese Verarbeitungstätigkeit eine technische und organisatorische Maßnahme gem. Art. 24, 28 und 32 DS-GVO zur Sicherung der Integrität darstellt, in Verbindung mit der Nachweisbarkeit der Einhaltung dieser Pflichten gem. Art. 5 Abs. 1 lit. d und f DS-GVO, i. V. m. Art. 5 Abs. 2 DS-GVO.'
            }
            count={26.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={26.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={26.5}
          />
        </TextBlockSection>
        <TextBlockSection title="27. Benutzerkontoverwaltung für Vermieter">
          <TextParagraph
            title={'Zwecke'}
            count={27.1}
            description={
              'Diese Verarbeitung dient der Erreichung folgenden Zwecks: Der Verwaltung eines Benutzerkontos sowie Sicherheit der Verarbeitung (Gewährleistung des störungsfreien Betriebs der Benutzerverwaltung).'
            }
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Vorname, Nachname, E-Mail-Adresse, ggf. Anmeldedaten Google, ggf. Anmeldedaten Microsoft, Zahlungsdaten, Telefonnummer (optional), Anschrift, Firmenname (optional) und Firmenanschrift.'
            }
            count={27.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist der Grundsatz der Richtigkeit sowie Integrität und Vertraulichkeit gem. Art. 5 Abs. 1 lit. d und f DS-GVO, i. V. m. Art. 6 Abs. 1 UAbs. 1 lit. c DS-GVO, da diese Verarbeitungstätigkeit eine technische und organisatorische Maßnahme gem. Art. 24, 28 und 32 DS-GVO zur Sicherung der Integrität darstellt, in Verbindung mit der Nachweisbarkeit der Einhaltung dieser Pflichten gem. Art. 5 Abs. 1 lit. d und f DS-GVO, i. V. m. Art. 5 Abs. 2 DS-GVO.'
            }
            count={27.3}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={27.4}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={27.5}
          />
        </TextBlockSection>
        <TextBlockSection title="28. Wohnungssuche und Wohnungsinserat">
          <TextParagraph
            title={'Zwecke'}
            count={28.1}
            description={
              'Diese Verarbeitung dient der Durchführung des Nutzungsvertrags bzw. der Bereitstellung der Dienstleistung, sowie die Registrierung innerhalb der App.'
            }
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Im Rahmen der Verarbeitung Ihrer personenbezogenen Daten für die Erbringung unserer Dienstleistung erheben wir die für die Vertragsdurchführung notwendigen Daten ausschließlich direkt bei Ihnen, wo sie notwendig und erheblich für diese Verarbeitung sind.'
            }
            count={28.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={28.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich für die Durchführung des Nutzungsvertrags. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihren Nutzungsvertrag mit uns nicht durchführen.'
            }
            count={28.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={28.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.\n'
            }
            count={28.6}
          />
        </TextBlockSection>
        <TextBlockSection title="29. Passwort zurücksetzen">
          <TextParagraph
            title={'Zwecke'}
            count={29.1}
            description={'Diese Verarbeitung dient dem Zurücksetzen des Passworts des Nutzers durch den Nutzer.\n'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Benutzerstammdaten und Benutzeraktionen.\n'
            }
            count={29.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={29.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={29.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={29.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen wir einen Newsletter-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.\n'
            }
            count={29.6}
          />
        </TextBlockSection>
        <TextBlockSection title="30. Authentifizierung Ihrer Mobilfunknummer per SMS">
          <TextParagraph
            title={'Zwecke'}
            count={30.1}
            description={'Diese Verarbeitung dient der Verifizierung Ihrer Mobilfunknummer per SMS.\n'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten Ihre Mobilfunknummer als auf Sie beziehbares Datum.\n'}
            count={30.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={30.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={30.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={30.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Wir nutzen für die SMS-Verifizierung einen Kommunikationsplattform-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={30.6}
          />
        </TextBlockSection>
        <TextBlockSection title="31. Kontakt über WhatsApp">
          <TextParagraph
            title={'Zwecke'}
            count={31.1}
            description={'Diese Verarbeitung dient der Bereitstellung von WhatsApp-Benachrichtigungen.\n'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten Ihre Mobilfunknummer und Kommunikationsdaten als auf Sie beziehbare Daten.\n'}
            count={31.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={31.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={31.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={31.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Wir nutzen für den Kontakt über WhatsApp einen Kommunikationsplattform-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={31.6}
          />
        </TextBlockSection>
        <TextBlockSection title="32. SCHUFA-Bonitätscheck für Mietinteressenten">
          <TextParagraph
            title={'Zwecke'}
            count={32.1}
            description={'Diese Verarbeitung dient der Bereitstellung von SCHUFA-Bonitätschecks.\n'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Anrede, Vorname, Nachname, Geburtsdatum, E-Mail-Adresse, Straße, Hausnummer, PLZ, Ort, ggf. IBAN, ggf. Personalausweis.'
            }
            count={32.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist Ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={32.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={32.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={32.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Die über die SCHUFA verarbeiteten oben genannten auf Sie beziehbaren Daten werden an SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden, Deutschland, übermittelt.\n'
            }
            count={32.6}
          />
        </TextBlockSection>
        <TextBlockSection title="33. Entfernungssuche für Mietinteressenten">
          <TextParagraph
            title={'Zwecke'}
            count={33.1}
            description={
              'Diese Verarbeitung dient der Entfernungssuche zwischen einem Inserat und einem vom Nutzer angegebenen Ort.'
            }
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: von Ihnen eingegebene Adressen.\n'
            }
            count={33.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={33.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={33.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={33.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Wir Nutzen für die Bereitstellung der Entfernungssuche einen Karten-Dienstleister. Für die Bereitstellung der Website nutzen wir einen Hosting-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={33.6}
          />
        </TextBlockSection>
        <TextBlockSection title="34. Kartenansicht und Routenberechnung zu POIs für Mietinteressenten">
          <TextParagraph
            title={'Zwecke'}
            count={34.1}
            description={'Diese Verarbeitung dient der Kartenansicht und Routenberechnung zu POIs.\n'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Suchhistorie und Verbindungsdaten.\n'
            }
            count={34.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={34.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={34.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Löschung Ihres Benutzerkontos.'
            }
            count={34.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Die über Google Maps verarbeiteten oben genannten auf Sie beziehbaren Daten werden an Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, übermittelt.\n' +
              'Die über Google Maps verarbeiteten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in die zuvor genannten Drittländer ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Google bietet geeignete Garantien für den Datenschutz, die Sie auf Anfrage unter datenschutz@wohnsinn.com einsehen können.\n'
            }
            count={34.6}
          />
        </TextBlockSection>
        <TextBlockSection title="35. Buchung von 3D-Besichtigungstouren durch Mietinteressenten">
          <TextParagraph
            title={'Zwecke'}
            count={35.1}
            description={'Diese Verarbeitung dient der Buchung von 3D-Besichtigungstouren.'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten Benutzerstammdaten und Termindaten als auf Sie beziehbare Daten.\n'}
            count={35.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={35.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={35.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist bis zur Durchführung Ihrer 3D-Besichtigungstour.'
            }
            count={35.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Wir nutzen für die Buchung von 3D-Besichtigungstouren einen Termin-Buchungs-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={35.6}
          />
        </TextBlockSection>
        <TextBlockSection title="36. 3D-Besichtigungstouren">
          <TextParagraph
            title={'Zwecke'}
            count={36.1}
            description={'Diese Verarbeitung dient der Bereitstellung von 3D-Besichtigungstouren durch Immobilien.\n'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten Verbindungsdaten als auf Sie beziehbare Daten.'}
            count={36.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={36.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={36.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten beträgt die Dauer der 3D-Besichtigungstour.\n'
            }
            count={36.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Wir nutzen für die Bereitstellung der 3D-Besichtigungstouren einen 3D-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA und Australien als Drittländer ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in die zuvor genannten Drittländer ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.\n'
            }
            count={36.6}
          />
        </TextBlockSection>
        <TextBlockSection title="37. Kaufabwicklung">
          <TextParagraph
            title={'Zwecke'}
            count={37.1}
            description={
              'Diese Verarbeitung dient dem Verkauf von Dienstleistungen, sowie dem Erhalt von Nachrichten, die von Benutzern ausgelöst werden, wenn ein Benutzer eine Bestellung aufgibt.'
            }
          />
          <TextParagraph
            title={'Datenarten'}
            description={'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Bestelldaten.'}
            count={37.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={37.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist erforderlich zur Bearbeitung Ihrer Anfrage. Falls Sie uns die oben genannten auf Sie bezogenen Daten nicht zur Verfügung stellen, können wir Ihre Anfrage nicht bearbeiten.'
            }
            count={37.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten beträgt sechs Jahre entsprechend gesetzlicher Aufbewahrungspflichten.'
            }
            count={37.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Für die Speicherung der oben genannten auf Sie beziehbaren Daten nutzen wir einen Newsletter-Dienstleister. Die oben genannten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in das zuvor genannte Drittland ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Die Datenimporteure bieten geeignete Garantien für den Datenschutz, die Sie auf Anfrage an datenschutz@wohnsinn.com einsehen können.'
            }
            count={37.6}
          />
        </TextBlockSection>
        <TextBlockSection title="38. Zahlungsabwicklung">
          <TextParagraph
            title={'Zwecke'}
            count={38.1}
            description={'Diese Verarbeitung dient der Zahlungsabwicklung.\n'}
          />
          <TextParagraph
            title={'Datenarten'}
            description={
              'Wir verarbeiten dabei folgende Kategorien auf Sie beziehbarer Daten: Rechnungsdaten, Zahlungsdaten.\n'
            }
            count={38.2}
          />
          <TextParagraph
            title={'Rechtsgrundlagen'}
            description={
              'Die Rechtsgrundlage für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten ist ihre Zustimmung zum Nutzungsvertrag der Webapp gem.Art. 6 Abs. 1 UAbs. 1 lit. b DS-GVO.'
            }
            count={38.3}
          />
          <TextParagraph
            title={'Erforderlichkeit'}
            description={
              'Die Verarbeitung der oben genannten auf Sie beziehbaren Daten ist für die Zahlungsabwicklung als Teil Ihres Nutzungsvertrags für unsere Webapp erforderlich. Die gekauften Dienstleistungen sind kostenpflichtig und eine Zahlungsabwicklung daher für die Vertragsdurchführung notwendig.\n' +
              'Falls Sie Ihre Abrechnungsdaten für den Zweck der Zahlungsabwicklung nicht zur Verfügung stellen, können wir Ihnen die Dienstleistung nicht bereitstellen.'
            }
            count={38.4}
          />
          <TextParagraph
            title={'Speicherdauer'}
            description={
              'Die Speicherdauer für diese Verarbeitung der oben genannten auf Sie beziehbaren Daten beträgt sechs Jahre entsprechend gesetzlicher Aufbewahrungspflichten.'
            }
            count={38.5}
          />
          <TextParagraph
            title={'Empfänger'}
            description={
              'Die über Tink verarbeiteten oben genannten auf Sie beziehbaren Daten werden an Tink AB, Vasagatan 11, 111 20 Stockholm, Schweden, übermittelt.\n' +
              'Die über Stripe verarbeiteten oben genannten auf Sie beziehbaren Daten werden an Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103, USA, übermittelt.\n' +
              'Die über Stripe verarbeiteten auf Sie beziehbaren Daten werden in die USA als Drittland ohne Angemessenheitsbeschluss gem. Art. 45 DS-GVO übermittelt. Die Rechtsgrundlage für die Übermittlung der oben genannten auf Sie beziehbaren Daten in die zuvor genannten Drittländer ohne Angemessenheitsbeschluss sind Standardvertragsklauseln gem. Art. 46 DS-GVO. Stripe bietet geeignete Garantien für den Datenschutz, die Sie auf Anfrage unter datenschutz@wohnsinn.com einsehen können.\n'
            }
            count={38.6}
          />
        </TextBlockSection>
      </Section>
    </PageLayout>
  );
};

export default PrivacyView;
