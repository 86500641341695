import { IonIcon } from '@ionic/react';
import styles from './VirtualTourStatusIndicator.module.scss';
import { alertCircleOutline, playCircle } from 'ionicons/icons';
import { FC } from 'react';
import Text, { TEXT_TYPE } from '../typographie/Text';

const VirtualTourStatusIndicator: FC<{ matterportId: string }> = ({ matterportId }) => {
  let icon, label, color;

  if (matterportId) {
    color = 'green';
    icon = playCircle;
    label = '3D-Tour Live';
  } else {
    color = 'red';
    icon = alertCircleOutline;
    label = 'Keine 3D-Tour';
  }

  return (
    <div className={`${styles.virtualTourStatus} ${styles[color]}`}>
      <IonIcon icon={icon} size={'2x'} />
      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
        {label}
      </Text>
    </div>
  );
};

export default VirtualTourStatusIndicator;
