const getFunctionURLByEnv = (functionName: string): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'qas':
      return process.env.REACT_APP_USE_EMULATOR === 'true'
        ? `http://${process.env.REACT_APP_EMULATOR_HOST}${process.env.REACT_APP_EMULATOR_FUNCTION_URL_PREFIX}${functionName}`
        : `https://${functionName}${process.env.REACT_APP_FUNCTION_URL_POSTFIX}`;
    default:
      return `https://${functionName}${process.env.REACT_APP_FUNCTION_URL_POSTFIX}`;
  }
};

const FB_FUNCTION_URLS = {
  applications: {
    apartment: {
      get: getFunctionURLByEnv('getapplicationsforapartment'),
    },
    tenant: {
      get: getFunctionURLByEnv('getapplicationsfortenant'),
    },
  },
  apartments: {
    root: {
      get: getFunctionURLByEnv('getapartments'),
      update: getFunctionURLByEnv('updateapartment'),
      add: getFunctionURLByEnv('addapartment'),
      delete: getFunctionURLByEnv('deleteapartment'),
    },
    cost: {
      get: getFunctionURLByEnv('getapartmentcost'),
      update: getFunctionURLByEnv('updateapartmentcost'),
      add: getFunctionURLByEnv('addapartmentcost'),
      delete: getFunctionURLByEnv('deleteapartmentcost'),
    },
    desiredTenant: {
      get: getFunctionURLByEnv('getapartmentdesiredtenant'),
      update: getFunctionURLByEnv('updateapartmentdesiredtenant'),
      add: getFunctionURLByEnv('addapartmentdesiredtenant'),
      delete: getFunctionURLByEnv('deleteapartmentdesiredtenant'),
    },
    energySupply: {
      get: getFunctionURLByEnv('getapartmentenergysupply'),
      update: getFunctionURLByEnv('updateapartmentenergysupply'),
      add: getFunctionURLByEnv('addapartmentenergysupply'),
      delete: getFunctionURLByEnv('deleteapartmentenergysupply'),
    },
    equipment: {
      get: getFunctionURLByEnv('getapartmentequipment'),
      update: getFunctionURLByEnv('updateapartmentequipment'),
      add: getFunctionURLByEnv('addapartmentequipment'),
      delete: getFunctionURLByEnv('deleteapartmentequipment'),
    },
    editorList: {
      update: getFunctionURLByEnv('removeLandlordFromOrganizationEditorLists'),
    },
  },
  matches: getFunctionURLByEnv('getmatches'),
  getGeoInformation: getFunctionURLByEnv('getgeoinformation'),
  messaging: {
    sms: {
      triggerVerificationCode: getFunctionURLByEnv('triggerverificationcode'),
      verifyVerificationCode: getFunctionURLByEnv('verifyverificationcode'),
    },
  },
  user: {
    updateNotificationSettings: getFunctionURLByEnv('updatenotificationsettings'),
    sendEmailVerificationMail: getFunctionURLByEnv('sendemailverificationmail'),
    sendLandlordActivationRequestMail: getFunctionURLByEnv('sendlandlordactivationrequestmail'),
    sendPasswordResetMail: getFunctionURLByEnv('sendpasswordresetmail'),
    sendUserInvitationMail: getFunctionURLByEnv('senduserinvitationmail'),
    requestFTPDataMail: getFunctionURLByEnv('requestftpdatamail'),
    updatePassword: getFunctionURLByEnv('updatepassword'),
    aboutMe: {
      update: getFunctionURLByEnv('updatetenantaboutme'),
    },
  },
  version: {
    get: getFunctionURLByEnv('getversion'),
  },
};

export default FB_FUNCTION_URLS;
