import { FC, useContext, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { FormContext } from '../../../../core/context/form.context';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../typographie/Text';
import FormErrorMessage from '../FormErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './InputText.module.scss';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IonContent, IonPopover } from '@ionic/react';

interface ITextInputProps {
  disabled?: boolean;
  type?: 'tel' | 'text' | 'password' | 'email' | 'number';
  name: string;
  required?: boolean;
  label?: string;
  onFocus?: (e: any) => void;
  onBlurHandler?: (e: any) => void;
  hideErrorMessage?: boolean;
  testId?: string;
  helperText?: string;
  unit?: string;
  minNumber?: number;
  tabIndex?: number;
  tooltipText?: string;
  pattern?: string;
}

const TextInput: FC<ITextInputProps> = ({
  disabled,
  name,
  label,
  onFocus,
  onBlurHandler,
  required,
  hideErrorMessage = false,
  type = 'text',
  testId,
  helperText,
  unit,
  minNumber = 0,
  tabIndex,
  tooltipText,
  pattern,
}) => {
  const { control } = useContext(FormContext);
  const [unitVisible, setUnitVisible] = useState(false);

  const onBlur = (e: any, field: ControllerRenderProps) => {
    field.onBlur();
    if (onBlurHandler) {
      onBlurHandler(e);
    }
  };

  const handleOnChange = (field: ControllerRenderProps, e: any) => {
    field.onChange(e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className={'floating-wrapper'} data-testid={testId}>
            <div className={'floating-field'}>
              {tooltipText ? (
                <div className={styles.tooltip}>
                  <FontAwesomeIcon className={styles.icon} id={`popover-${name}`} icon={faInfoCircle} />

                  <IonPopover trigger={`popover-${name}`} triggerAction="hover">
                    <IonContent>
                      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION}>{tooltipText}</Text>
                    </IonContent>
                  </IonPopover>
                </div>
              ) : null}
              <input
                tabIndex={tabIndex}
                onInput={(e) => handleOnChange(field, e)}
                className={'floating-input'}
                // @ts-ignore
                onWheel={type === 'number' ? (e) => e.target.blur() : null}
                disabled={disabled}
                name={name}
                type={type}
                pattern={pattern}
                value={field?.value ? field.value : ''}
                onBlur={(e) => {
                  onBlur(e, field);
                  setUnitVisible(false);
                }}
                onFocus={() => {
                  setUnitVisible(true);
                  onFocus ? onFocus : null;
                }}
                min={minNumber}
                onKeyDown={
                  type === 'number'
                    ? (event: any) => {
                        const charCode = event.which ? event.which : event.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 188) {
                          event.preventDefault();
                        }
                      }
                    : null
                }
              />
              <label htmlFor={name} className={`floating-label ${field.value ? 'isInputFilled' : ''}`}>
                <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                  {label} {label && required ? '*' : ''}
                </Text>
              </label>
              {unit ? (
                <div className={'floating-unit'}>
                  <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_FOOTER} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                    {unit}
                  </Text>
                </div>
              ) : null}
            </div>

            {helperText && (
              <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
                {helperText}
              </Text>
            )}

            {!hideErrorMessage ? <FormErrorMessage error={error} /> : null}
          </div>
        );
      }}
    />
  );
};

export default TextInput;
