import { useContext } from 'react';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import { ITenantFilterParams } from '@wohnsinn/ws-ts-lib';
import TenantFilterParamsContext from '../../../core/context/tenant-filter-params.context';
import ModalContext from '../../../core/context/modal.context';
import styles from './FilterList.module.scss';
import { createEquipmentList } from '../ApartmentEquipment';
import { useTranslation } from 'react-i18next';
import { ITag } from '../../atoms/Tag';

export enum TENANT_FILTER {
  CITY = 'CITY',
  EQUIPMENT = 'EQUIPMENT',
  PERSONS = 'PERSONS',
  PETS = 'PETS',
  ROOMS = 'ROOMS',
  SHARED_USAGE = 'SHARED_USAGE',
  SIZE = 'SIZE',
  WARM_RENT = 'WARM_RENT',
}

const FilterList = () => {
  const { t } = useTranslation('common', { keyPrefix: 'apartment.equipment.equipmentList' });
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { openModal } = useContext(ModalContext);

  const hasFilterSet = (): boolean => {
    return !!(
      tenantFilterParams?.rooms ||
      tenantFilterParams?.arePetsAllowed ||
      tenantFilterParams?.location ||
      tenantFilterParams?.equipmentList?.length ||
      tenantFilterParams?.isSharedUsagePossible ||
      tenantFilterParams?.numberOfPeopleMovingIn ||
      tenantFilterParams?.rooms ||
      tenantFilterParams?.size ||
      tenantFilterParams?.warmRent
    );
  };

  const removeFilter = async (filter: TENANT_FILTER, equipment?: ITag) => {
    let newTenantFilterParams: ITenantFilterParams;

    switch (filter) {
      case TENANT_FILTER.CITY:
        newTenantFilterParams = { ...tenantFilterParams, location: null };
        break;
      case TENANT_FILTER.ROOMS:
        newTenantFilterParams = { ...tenantFilterParams, rooms: null };
        break;
      case TENANT_FILTER.WARM_RENT:
        newTenantFilterParams = { ...tenantFilterParams, warmRent: null };
        break;
      case TENANT_FILTER.PERSONS:
        newTenantFilterParams = { ...tenantFilterParams, numberOfPeopleMovingIn: null };
        break;
      case TENANT_FILTER.PETS:
        newTenantFilterParams = { ...tenantFilterParams, arePetsAllowed: null };
        break;
      case TENANT_FILTER.SIZE:
        newTenantFilterParams = { ...tenantFilterParams, size: null };
        break;
      case TENANT_FILTER.SHARED_USAGE:
        newTenantFilterParams = { ...tenantFilterParams, isSharedUsagePossible: null };
        break;
      case TENANT_FILTER.EQUIPMENT:
        // FILTER SELECTED EQUIPMENT FROM EQUIPMENT LIST
        const newEquipmentList = tenantFilterParams.equipmentList.filter((e) => e !== equipment.value);
        newTenantFilterParams = {
          ...tenantFilterParams,
          equipmentList: newEquipmentList,
        };
        break;
    }

    await updateTenantFilterParams(newTenantFilterParams);
  };

  return (
    <div className={styles.wrapper}>
      {hasFilterSet() ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={'Alle Filter entfernen'}
          onClick={() => openModal({ id: MODAL_IDENTIFIER.FILTER_RESET })}
        />
      ) : null}

      {tenantFilterParams?.location ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={`${tenantFilterParams.location.place_name}`}
          onClick={() => removeFilter(TENANT_FILTER.CITY)}
        />
      ) : null}

      {tenantFilterParams?.warmRent ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={`bis ${tenantFilterParams.warmRent} €`}
          onClick={() => removeFilter(TENANT_FILTER.WARM_RENT)}
        />
      ) : null}

      {tenantFilterParams?.numberOfPeopleMovingIn ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={`Ab ${tenantFilterParams.numberOfPeopleMovingIn} Personen`}
          onClick={() => removeFilter(TENANT_FILTER.PERSONS)}
        />
      ) : null}

      {tenantFilterParams?.rooms ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={`Ab ${tenantFilterParams.rooms} Zimmer`}
          onClick={() => removeFilter(TENANT_FILTER.ROOMS)}
        />
      ) : null}

      {tenantFilterParams?.size ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={`Ab ${tenantFilterParams.size} m2`}
          onClick={() => removeFilter(TENANT_FILTER.SIZE)}
        />
      ) : null}

      {tenantFilterParams?.equipmentList && tenantFilterParams.equipmentList.length
        ? createEquipmentList(tenantFilterParams.equipmentList, t).map((equipment) => (
            <CTAButton
              key={equipment.key}
              size={'tiny'}
              icon={faTimes}
              buttonStyle={BUTTON_STYLE.TERTIARY}
              buttonText={`${equipment.label}`}
              onClick={() => removeFilter(TENANT_FILTER.EQUIPMENT, equipment)}
            />
          ))
        : null}

      {tenantFilterParams && tenantFilterParams.isSharedUsagePossible !== null ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={tenantFilterParams.isSharedUsagePossible ? 'WG-Geeignet' : 'keine WG'}
          onClick={() => removeFilter(TENANT_FILTER.SHARED_USAGE)}
        />
      ) : null}

      {tenantFilterParams && tenantFilterParams?.arePetsAllowed !== null ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={tenantFilterParams.arePetsAllowed ? 'Haustiere erlaubt' : 'Keine Haustiere'}
          onClick={() => removeFilter(TENANT_FILTER.PETS)}
        />
      ) : null}
    </div>
  );
};

export default FilterList;
