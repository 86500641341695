import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import useWindowSize from '../../../core/hook/windowsize.hook';
import { ROUTES } from '../../../core/const/routes';
import MobileHeaderButton from '../../../component/atoms/Buttons/MobileHeaderButton';
import PageLayout from '../../../component/organisms/PageLayout';
import ThreeColumnsLayoutContext from '../../../core/context/three-columns-layout-context';
import ThreeColumnsLayoutWrapper from '../../../component/organisms/ThreeColumnsLayout/ThreeColumnsLayoutWrapper';
import TenantChatWrapper from '../../../component/organisms/Chat/TenantChatWrapper';

const ChatView: FC = () => {
  const { t } = useTranslation('common');
  const [showLeftSideBar, setShowLeftSideBar] = useState<boolean>(true);
  const [showRightSideBar, setShowRightSideBar] = useState<boolean>(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(true);
  const { isSmallerMd } = useWindowSize();
  const [showInfoButton, setShowInfoButton] = useState<boolean>(isSmallerMd);
  const [pageTitle, setPageTitle] = useState<string>(t(ROUTES.tenant.chat.title));

  useEffect(() => {
    setShowBackButton(false);
    setShowInfoButton(!isSmallerMd);
  }, [isSmallerMd]);

  const CustomBackButton = (): JSX.Element => {
    const handleCustomBackButton = () => {
      setPageTitle(t(ROUTES.tenant.chat.title));
      setShowLeftSideBar(true);
      setShowRightSideBar(false);
      setShowBackButton(false);
      setShowInfoButton(false);
    };

    return <MobileHeaderButton hideText text={t('back')} onClick={handleCustomBackButton} icon={faChevronLeft} />;
  };

  const ShowInfoButton = (): JSX.Element => {
    const handleInfoButton = () => {
      setShowRightSideBar(!showRightSideBar);
    };

    return (
      <MobileHeaderButton
        hideText
        text={t('showInfo')}
        onClick={handleInfoButton}
        icon={showRightSideBar ? faTimes : faHome}
      />
    );
  };

  const showLeft = (): void => {
    setShowRightSideBar(false);
    setShowLeftSideBar(true);
    setShowBackButton(false);
    setShowInfoButton(false);
  };
  const showMiddle = (): void => {
    setShowRightSideBar(false);
    setShowLeftSideBar(false);
    setShowBackButton(true);
    setShowInfoButton(true);
  };
  const showRight = (): void => {
    setShowRightSideBar(true);
    setShowLeftSideBar(false);
  };

  return (
    <PageLayout
      headerStartButton={showBackButton && isSmallerMd ? <CustomBackButton /> : null}
      headerEndButton={showInfoButton ? <ShowInfoButton /> : null}
      fullWidthPage
      pageTitle={pageTitle}
      disableScroll
      showMobilePageTitle
      appHeaderBottomBorder
      hideTabBar={!showLeftSideBar && isSmallerMd}
    >
      <ThreeColumnsLayoutContext.Provider
        value={{
          showLeftSideBar,
          showRightSideBar,
          showBackButton,
          setShowBackButton,
          setShowInfoButton,
          setPageTitle,
          showLeft,
          showRight,
          showMiddle,
        }}
      >
        <ThreeColumnsLayoutWrapper>
          <TenantChatWrapper />
        </ThreeColumnsLayoutWrapper>
      </ThreeColumnsLayoutContext.Provider>
    </PageLayout>
  );
};

export default ChatView;
