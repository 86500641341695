import { useContext } from 'react';
import ModalWrapper from '../ModalWrapper';
import ModalContext from '../../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import Accordion from '../../../atoms/Accordion';
import UserContext from '../../../../core/context/user.context';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { faqLandlord, faqTenant } from '../../../../core/const/faq';
import { useTranslation } from 'react-i18next';

const FaqModal = () => {
  const { openModal } = useContext(ModalContext);
  const { activeUserType } = useContext(UserContext);
  const { t } = useTranslation('common');

  return (
    <ModalWrapper backFunction={() => openModal({ id: MODAL_IDENTIFIER.SETTINGS })} title={t('faq')}>
      <Accordion items={activeUserType === USER_TYPE.TENANT ? faqTenant : faqLandlord} />
    </ModalWrapper>
  );
};

export default FaqModal;
