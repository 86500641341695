import FormHeader from '../../../../atoms/FormHeader';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import Avatar from '../../../../atoms/Avatar';
import Badge, { BADGE_COLORS } from '../../../../atoms/Badge';
import { IActivationRequest, ILandlordProfile, LANDLORD_ROLES } from '@wohnsinn/ws-ts-lib';
import CTAButton, { BUTTON_STYLE } from '../../../../atoms/Buttons/CTAButton';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import useOrganization from '../../../../../core/hook/organization.hook';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../../core/context/user.context';
import ModalContext from '../../../../../core/context/modal.context';
import { Unsubscribe } from '@firebase/firestore';
import { onSnapshot, Query } from 'firebase/firestore';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import InfoBox from '../../../../atoms/InfoBox';
import styles from './LandlordUserManagementForm.module.scss';

const LandlordUserManagementForm = () => {
  const { organization, organizationService } = useOrganization();
  const { t: c } = useTranslation('common', { keyPrefix: 'view.UserListView' });
  const { t } = useTranslation('common');
  const { landlordProfile } = useContext(UserContext);
  const [organisationUserList, setOrganisationUserList] = useState<ILandlordProfile[]>([]);
  const [activationRequestList, setActivationRequestList] = useState<IActivationRequest[]>([]);
  const { openModal, closeModal } = useContext(ModalContext);

  const fetchOrganizationUserListRef = (): Unsubscribe => {
    const organisationUsersQuery: Query<ILandlordProfile> = organizationService.getOrganizationUserListRefById(
      landlordProfile.organizationId
    );
    return onSnapshot(
      organisationUsersQuery,
      (snap) => {
        const organisationUserList: ILandlordProfile[] = snap.docs
          .filter((oU) => oU?.data().isOrganizationMembershipConfirmed)
          .map((doc) => doc.data());
        if (organisationUserList.length) {
          setOrganisationUserList(organisationUserList);
        } else {
          setOrganisationUserList([]);
        }
      },
      (error) => console.error(error)
    );
  };

  const fetchActivationRequestListRef = (): Unsubscribe => {
    const activationRequestListQuery: Query<IActivationRequest> = organizationService.getActivationRequestsListRef(
      landlordProfile.organizationId
    );

    return onSnapshot(
      activationRequestListQuery,
      (snap) => {
        if (snap.docs.length) {
          setActivationRequestList(snap.docs.map((doc) => doc.data()));
        } else {
          setActivationRequestList([]);
        }
      },
      (error) => console.error(error)
    );
  };

  useEffect(() => {
    let unsubscribeActivationRequest: Unsubscribe;
    let unsubscribeOrganizationUserList: Unsubscribe;
    if (landlordProfile?.organizationId && landlordProfile?.isOrganizationMembershipConfirmed) {
      unsubscribeOrganizationUserList = fetchOrganizationUserListRef();
      if (landlordProfile?.roles?.includes(LANDLORD_ROLES.ORGANIZATION_ADMIN)) {
        unsubscribeActivationRequest = fetchActivationRequestListRef();
      }
    }

    return () => {
      if (unsubscribeOrganizationUserList) {
        unsubscribeOrganizationUserList();
      }

      if (unsubscribeActivationRequest) {
        unsubscribeActivationRequest();
      }
    };
  }, []);
  const deleteInvitationHandler = async (email: string) => {
    await organizationService.deleteOrganizationActivationRequest(organization.id, email);
    closeModal();
  };

  const deleteUserFromOrganizationHandler = async (organizationId: string, landlordId: string) => {
    await organizationService.removeLandlordFromOrganization(organization.id, landlordId);
    closeModal();
  };

  const addUserHandler = () => {
    openModal({ id: MODAL_IDENTIFIER.CREATE_USER_INVITATION });
  };

  return (
    <div>
      <FormHeader
        title={'Nutzerverwaltung'}
        subtitle={'Verwalte hier deine Teammitglieder und deren Kontoberechtigungen.'}
        customCtaButtonTitle={c('addUser')}
        customOnClick={addUserHandler}
        customCtaIcon={faPlus}
      />

      <FormSectionTitle title={c('activeUsers')} />
      <div style={{ overflowX: 'auto' }}>
        <table className={'ws-table'}>
          <thead>
            <tr>
              <th>
                <span className={'sr-only'}></span>
              </th>
              <th align={'left'}>
                <Text
                  weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                  color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                  tag={'span'}
                  type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                >
                  Name
                </Text>
              </th>
              <th>
                <Text
                  weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                  color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                  tag={'span'}
                  type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                >
                  {t('role')}
                </Text>
              </th>
              <th>
                <span className="sr-only">Löschen?</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {organisationUserList.map((profile) => (
              <tr key={profile.uid}>
                <td align={'center'}>
                  <Avatar avatarUrl={profile.photoUrl} />
                </td>
                <td>
                  <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
                    {profile?.personalInformation?.firstName} {profile?.personalInformation?.lastName}
                  </Text>{' '}
                  <br />
                  <Text
                    color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                    tag={'span'}
                    type={TEXT_TYPE.TEXT_TYPE_FOOTER}
                    weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
                  >
                    {profile?.email}
                  </Text>
                </td>
                <td align={'center'}>
                  <Badge
                    text={
                      profile.roles[0] === LANDLORD_ROLES.ORGANIZATION_ADMIN
                        ? t(LANDLORD_ROLES.ORGANIZATION_ADMIN)
                        : t('user')
                    }
                  />
                </td>
                <td align={'center'}>
                  {!landlordProfile?.roles?.includes(LANDLORD_ROLES.ORGANIZATION_ADMIN) ? null : (
                    <CTAButton
                      buttonStyle={BUTTON_STYLE.SECONDARY}
                      buttonText={t('delete')}
                      hideText
                      icon={faTrash}
                      onClick={() =>
                        openModal({
                          id: MODAL_IDENTIFIER.DELETE_MODAL,
                          data: {
                            title: 'Benutzer entfernen?',
                            text: `Möchten Sie ${profile.email} wirklich aus Ihrer Organisation löschen?`,
                            deleteHandler: () => deleteUserFromOrganizationHandler(organization.id, profile.uid),
                          },
                        })
                      }
                      size={'small'}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {landlordProfile?.roles?.includes(LANDLORD_ROLES.ORGANIZATION_ADMIN) ? (
        <>
          <FormSectionTitle title={c('invitedUsers')} />
          <div className={styles.activationRequestList}>
            {activationRequestList.map((acr) => (
              <Badge
                color={BADGE_COLORS.PRIMARY}
                key={acr.email}
                text={acr.email}
                action={() =>
                  openModal({
                    id: MODAL_IDENTIFIER.DELETE_MODAL,
                    data: {
                      title: 'Einladung deaktivieren?',
                      text: `Möchten Sie die Einladung an ${acr.email} wirklich zurückziehen?`,
                      deleteHandler: () => deleteInvitationHandler(acr.email),
                    },
                  })
                }
                icon={faTimes}
              />
            ))}
          </div>
          {activationRequestList.length <= 0 ? <InfoBox text={'Keine offenen Einladungen.'} /> : null}
        </>
      ) : null}
    </div>
  );
};

export default LandlordUserManagementForm;
