import { FC, useContext, useState } from 'react';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import CompletedFormCheck from '../../../../component/atoms/CompletedFormCheck';
import UserContext from '../../../../core/context/user.context';
import { IonCol } from '@ionic/react';
import useTenantDocuments from '../../../../core/hook/tenant-document.hook';
import InfoBox, { INFO_BOX_ICONS } from '../../../../component/atoms/InfoBox';
import Section from '../../../../component/atoms/Section';
import styles from './AccountOverviewView.module.scss';
import Badge, { BADGE_COLORS } from '../../../../component/atoms/Badge';
import Text, { TEXT_WEIGHT } from '../../../../component/atoms/typographie/Text';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import ModalContext from '../../../../core/context/modal.context';
import { faGear } from '@fortawesome/pro-light-svg-icons';

import ActionBanner from '../../../../component/atoms/ActionBanner';
import useToast from '../../../../core/hook/toast.hook';
import MobileHeaderButton from '../../../../component/atoms/Buttons/MobileHeaderButton';
import PageTitle from '../../../../component/molecules/PageTitle';
import ProfileAvatar from '../../../../component/molecules/ProfileAvatar';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';

const AccountOverviewView: FC = () => {
  const { t } = useTranslation('common');
  const { t: f } = useTranslation('common', { keyPrefix: 'view.AccountOverviewView' });
  const { user, tenantProfile, landlordProfile, activeUserType, authService } = useContext(UserContext);
  const { sendInfoToast } = useToast();
  const { openModal } = useContext(ModalContext);
  const { hasIncomeProofDocument, hasSchufaCheckDocument } = useTenantDocuments(tenantProfile?.uid);
  const [isSendingVerificationMail, setIsSendingVerificationMail] = useState(false);

  const hasCompletedSelfDisclosure =
    tenantProfile?.household && tenantProfile?.personalInformation && hasIncomeProofDocument && hasSchufaCheckDocument;

  const resendVerificationMail = async (): Promise<void> => {
    setIsSendingVerificationMail(true);
    try {
      await authService.triggerSendVerificationLink(tenantProfile.email, activeUserType);
      await sendInfoToast('toast.resendEmailVerificationMail');
    } catch (e) {
      console.error('Error on resendVerificationMail', e);
    }
    setIsSendingVerificationMail(false);
  };

  return (
    <>
      <PageLayout
        headerEndButton={
          <MobileHeaderButton
            onClick={() => openModal({ id: MODAL_IDENTIFIER.SETTINGS })}
            icon={faGear}
            text={'open settings'}
            hideText
          />
        }
        hideTabBar={false}
        pageTitle={t(ROUTES.tenant.account.title)}
      >
        {!user.isEmailVerified ? (
          <>
            <div className={styles.banner}>
              <ActionBanner
                isLoading={isSendingVerificationMail}
                label={t('actionBanner.verifyYourEmail.label')}
                buttonText={t('actionBanner.verifyYourEmail.buttonText')}
                onClick={resendVerificationMail}
              />
            </div>
            <div className={styles.bannerSpace} />
          </>
        ) : null}

        <Section>
          <IonCol>
            <PageTitle
              pageTitle={t(ROUTES.tenant.account.overview.title)}
              secondPageTitle={'Hier siehst du dein Profil'}
            />
          </IonCol>
        </Section>

        <Section center reducePadding>
          <IonCol sizeMd={'12'} className={'ion-text-center'}>
            <div className={styles.avatarWrapper}>
              <ProfileAvatar profile={activeUserType === USER_TYPE.LANDLORD ? landlordProfile : tenantProfile} />
            </div>

            <div className={styles.name}>
              <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                {tenantProfile?.personalInformation?.firstName} {tenantProfile?.personalInformation?.lastName}
              </Text>
              <Text align={'center'}>{tenantProfile?.email}</Text>
            </div>
            {tenantProfile?.personalInformation && tenantProfile?.household && hasCompletedSelfDisclosure ? (
              <Badge text={t('view.AccountOverviewView.Badge.accountComplete')} color={BADGE_COLORS.SUCCESS} />
            ) : (
              <Badge text={t('view.AccountOverviewView.Badge.accountInComplete')} color={BADGE_COLORS.DANGER} />
            )}
          </IonCol>
        </Section>
        <Section reducePadding>
          <IonCol size={'12'} sizeMd={'6'}>
            <CompletedFormCheck
              pathname={t(ROUTES.tenant.account.profile.path)}
              status={!!tenantProfile?.personalInformation}
              positiveText={t('completed')}
              negativeText={t('complete')}
              text={t(ROUTES.tenant.account.profile.title)}
            />
          </IonCol>

          <IonCol size={'12'} sizeMd={'6'}>
            <CompletedFormCheck
              pathname={t(ROUTES.tenant.account.household.path)}
              status={!!tenantProfile?.household}
              positiveText={t('completed')}
              negativeText={t('complete')}
              text={t(ROUTES.tenant.account.household.title)}
            />
          </IonCol>

          <IonCol size={'12'} sizeMd={'6'}>
            <CompletedFormCheck
              pathname={t(ROUTES.tenant.account.aboutMe.path)}
              status={!!tenantProfile?.aboutMe?.description}
              positiveText={t('completed')}
              negativeText={t('complete')}
              text={t(ROUTES.tenant.account.aboutMe.title)}
            />
          </IonCol>

          <IonCol size={'12'} sizeMd={'6'}>
            <CompletedFormCheck
              pathname={t(ROUTES.tenant.account.introductionVideo.path)}
              status={!!tenantProfile?.aboutMe?.introductionVideo}
              positiveText={t('uploaded')}
              negativeText={t('upload')}
              text={t(ROUTES.tenant.account.introductionVideo.title)}
            />
          </IonCol>

          <IonCol size={'12'} sizeMd={'6'}>
            <CompletedFormCheck
              pathname={t(ROUTES.tenant.account.incomeProof.path)}
              status={hasIncomeProofDocument && hasSchufaCheckDocument}
              positiveText={t('uploaded')}
              negativeText={t('upload')}
              text={t(ROUTES.tenant.account.incomeProof.title)}
            />
          </IonCol>
          {/*<IonCol size={'12'} sizeMd={'6'}>*/}
          {/*  <CompletedFormCheck*/}
          {/*    pathname={t(ROUTES.tenant.account.notifications.path)}*/}
          {/*    status={hasIncomeProofDocument && hasSchufaCheckDocument}*/}
          {/*    positiveText={t('uploaded')}*/}
          {/*    negativeText={t('upload')}*/}
          {/*    text={t(ROUTES.tenant.account.notifications.title)}*/}
          {/*  />*/}
          {/*</IonCol>*/}
          {tenantProfile?.personalInformation && tenantProfile?.household && hasCompletedSelfDisclosure ? null : (
            <IonCol size={'12'}>
              <InfoBox text={f('infoApplicationFolderIncomplete')} icon={INFO_BOX_ICONS.LIGHT_BULB} />
            </IonCol>
          )}
        </Section>
      </PageLayout>
    </>
  );
};

export default AccountOverviewView;
