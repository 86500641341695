import styles from '../ThreeColumnsLayout.module.scss';
import { FC, PropsWithChildren } from 'react';

const ThreeColumnsLayoutWrapper: FC<PropsWithChildren<{ scrollEnabled?: boolean }>> = ({
  children,
  scrollEnabled = false,
}) => {
  return (
    <div className={`${styles.threeColumnsLayoutWrapper} ${scrollEnabled ? styles.scrollEnabled : ''}`}>{children}</div>
  );
};

export default ThreeColumnsLayoutWrapper;
