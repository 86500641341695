import { createContext, Dispatch, SetStateAction } from 'react';

export interface IScrollPositionContextProps {
  scrollPos: number;
  setScrollPos: Dispatch<SetStateAction<number>>;
}

const ScrollPositionProviderContext = createContext<IScrollPositionContextProps>({
  scrollPos: 0,
  setScrollPos: null,
});

export default ScrollPositionProviderContext;
