import { FC, useContext, useState } from 'react';
import UserContext from '../../../core/context/user.context';
import { IApplication, TChatMessageCreate, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import styles from './Chat.module.scss';
import useChat from '../../../core/hook/chat.hook';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { ReactI18NextChild, useTranslation } from 'react-i18next';
import ChatMessageList from './ChatMessageList';
import TextEditor from '../../atoms/formElement/TextEditor';
import { FormContext } from '../../../core/context/form.context';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import CTAButton from '../../atoms/Buttons/CTAButton';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

const Chat: FC<{ application: IApplication; header?: ReactI18NextChild }> = ({ application, header }) => {
  const { t } = useTranslation('common');
  const { activeUserType, tenantProfile, landlordProfile, user } = useContext(UserContext);
  const isTenantAndChatIsDisabled = application?.isChatDisabled && application?.tenantProfile.uid === user?.uid;
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { chatService, chatMessageList, isLoadingMessages, setIsTyping } = useChat(
    application.tenantProfile?.uid,
    application?.landlordId,
    user?.uid,
    application?.apartmentId,
    application.editorList
  );

  const { control, handleSubmit, reset } = useForm<{ text: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(
      Joi.object({ text: { editorState: Joi.string().min(10).required(), html: Joi.string().min(55).required() } })
    ),
  });

  const onError = (err: Partial<FieldErrorsImpl<{ text: string }>>): void => {
    console.error(err);
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const submitForm = async (data: any) => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const landlordName =
      landlordProfile?.personalInformation?.firstName && landlordProfile?.personalInformation?.lastName
        ? `${landlordProfile?.personalInformation.firstName} ${landlordProfile?.personalInformation.lastName}`
        : t('landlord');
    const tenantName =
      tenantProfile?.personalInformation?.firstName && tenantProfile?.personalInformation?.lastName
        ? `${tenantProfile?.personalInformation.firstName} ${tenantProfile?.personalInformation.lastName}`
        : '';
    const senderName = activeUserType === USER_TYPE.LANDLORD ? landlordName : tenantName;

    if (user?.uid) {
      const message: TChatMessageCreate = {
        attachment: null,
        apartmentId: application.apartmentId,
        landlordId: application.landlordId,
        tenantId: application.tenantProfile.uid,
        senderId: user.uid,
        senderName,
        senderAvatar: tenantProfile ? tenantProfile.photoUrl : landlordProfile.photoUrl,
        text: data.text,
      };

      try {
        await chatService.sendMessage(application, message);
        setIsTyping(false);
        reset({ text: null });
        setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUCCESS);
      } catch (e) {
        setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);
        console.error('error sending message', e);
      }
    }
  };

  const LoadingMessageListPlaceholder = () => (
    <div className={styles.loadingMessagesListPlaceholder}>
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className="sr-only">loading chat</div>
    </div>
  );
  const LoadingChatInputPlaceholder = () => (
    <div className={styles.loadingChatInputPlaceholder}>
      <div className="sr-only">loading chat</div>
    </div>
  );

  return (
    <div className={styles.chatView}>
      {header ? <div className={styles.breadcrumbs}>{header}</div> : null}

      {isLoadingMessages ? <LoadingMessageListPlaceholder /> : <ChatMessageList messageList={chatMessageList} />}

      {isLoadingMessages ? (
        <LoadingChatInputPlaceholder />
      ) : (
        !isTenantAndChatIsDisabled && (
          <FormContext.Provider value={{ control }}>
            <div className={styles.chatInputWrapper}>
              <form
                name={'chatInputForm'}
                id={'chatInputForm'}
                className={styles.form}
                onSubmit={handleSubmit(submitForm, onError)}
              >
                <TextEditor
                  isAutofocused={true}
                  showErrors={false}
                  onSubmit={handleSubmit(submitForm)}
                  name={'text'}
                  placeholder={t('component.organisms.chat.inputPlaceholder')}
                />

                <CTAButton
                  icon={faPaperPlane}
                  customStyling={styles.sendButton}
                  hideText
                  buttonText={'send'}
                  disabled={submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                />
              </form>
            </div>
          </FormContext.Provider>
        )
      )}
    </div>
  );
};

export default Chat;
