import { Redirect, Route } from 'react-router-dom';
import { ComponentType, FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from '../../../core/hook/toast.hook';

export interface IActivateRouteError {
  message?: string;
  RedirectComponent?: ComponentType<any>;
}

export interface IGuardedRouteProps {
  Component: ReactElement;
  cantActivate: { cantActivate: boolean; message?: string; RedirectComponent?: ComponentType<any> }[];
  path: string;
  exact?: boolean;
}

const GuardedRoute: FC<IGuardedRouteProps> = ({ Component, cantActivate = [], exact = false, path }) => {
  const { t } = useTranslation('common');
  const { sendWarningToast } = useToast();
  const [cantActivateError, setCantActivateError] = useState<IActivateRouteError>(null);

  useEffect(() => {
    if (cantActivateError && cantActivateError?.message) {
      sendWarningToast(t(cantActivateError.message));
    }
  }, [cantActivateError]);

  return !cantActivateError ? (
    cantActivate?.every((a) => {
      if (a.cantActivate) {
        setCantActivateError({ message: a?.message, RedirectComponent: a?.RedirectComponent });
      }
      return !a?.cantActivate;
    }) ? (
      <Route exact={exact} path={path}>
        {Component}
      </Route>
    ) : (
      cantActivateError?.RedirectComponent && <cantActivateError.RedirectComponent />
    )
  ) : (
    <Route render={() => <Redirect to={'/'} />} />
  );
};

export default GuardedRoute;
