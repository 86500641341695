import HeadlineText from '../../../../../component/molecules/HeadlineText';
import Accordion from '../../../../../component/atoms/Accordion';
import styles from './LandlordLandingWohnsinnExplainedSection.module.scss';
import { useState } from 'react';

const LandlordLandingAboutWohnsinnSection = () => {
  const [selectedTab, setSelectedTab] = useState('accordion-0');
  const renderMockUp = () => {
    switch (selectedTab) {
      case 'accordion-0':
        return <img src={'/assets/images/vorstellungsvideos.svg'} alt="vorstellungsvideos" />;
      case 'accordion-1':
        return <img src={'/assets/images/wunschmieter-matching.svg'} alt="wunschmieter-matching" />;
      case 'accordion-2':
        return <img src={'/assets/images/schnittstellen.svg'} alt="schnittstellen" />;
      default:
        return <img src={'/assets/images/vorstellungsvideos.svg'} alt="vorstellungsvideos" />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <HeadlineText
        headline={'Wohnsinn macht den Vermietungsprozess angenehm'}
        text={
          'Wohnsinn deckt eine Vielzahl von Aktivitäten und Prozessen in der Vermietung von Mietobjekten ab, was zu Zeit- und Kosteneinsparungen führt.'
        }
      />
      <div className={styles.content}>
        <div className={styles.accordion}>
          <Accordion selectedTab={selectedTab} onChange={(e: any) => setSelectedTab(e.detail.value)} items={CONTENTS} />
        </div>

        <div className={styles.mockup}>{renderMockUp()}</div>
      </div>
    </div>
  );
};

const CONTENTS = [
  {
    label: 'view.LandlordLanding.explanation.introductionVideo.label',
    content: 'view.LandlordLanding.explanation.introductionVideo.content',
  },
  {
    label: 'view.LandlordLanding.explanation.desiredTenant.label',
    content: 'view.LandlordLanding.explanation.desiredTenant.content',
  },
  {
    label: 'view.LandlordLanding.explanation.interface.label',
    content: 'view.LandlordLanding.explanation.interface.content',
  },
];

export default LandlordLandingAboutWohnsinnSection;
