import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import ModalContext from '../../../../core/context/modal.context';
import useToast from '../../../../core/hook/toast.hook';
import ApartmentServiceContext from '../../../../core/context/apartment-service.context';
import styles from './PublishApartmentModal.module.scss';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';

const PublishApartmentModal: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { sendErrorToast, sendSuccessToast } = useToast();
  const { apartmentService } = useContext(ApartmentServiceContext);
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.PublishApartmentModal' });
  const apartment = modalData.apartment;

  const allApartmentDataAvailable =
    apartment?.mainInformation &&
    apartment?.energySupply &&
    apartment?.cost &&
    apartment?.desiredTenant &&
    apartment?.equipment &&
    apartment?.media?.length;

  const handlePublishApartment = async () => {
    try {
      if (!apartment?.isPublished) {
        await apartmentService.updateApartmentPublishState(apartment, true);
        await sendSuccessToast(m('toast.published.success'));
      } else {
        await apartmentService.updateApartmentPublishState(apartment, false);
        await sendSuccessToast(m('toast.deactivate.success'));
      }
    } catch (err) {
      await sendErrorToast(t('toast.error'));
    }
    closeModal();
  };

  return (
    <ModalWrapper noHeadingMargin={true}>
      <div
        className={
          styles.iconWrapper + ' ' + (allApartmentDataAvailable && !apartment?.isPublished ? styles.success : '')
        }
      >
        {allApartmentDataAvailable && !apartment?.isPublished ? (
          <img data-testid={'ctaCard.image'} src={t('pictogram.check')} alt={t('check')} />
        ) : (
          <img data-testid={'ctaCard.image'} src={t('pictogram.attention')} alt={t('attention')} />
        )}
      </div>
      <Headline size={HEADLINE_SIZE.H2}>
        {m(
          apartment?.isPublished
            ? 'deactivate.title'
            : allApartmentDataAvailable
            ? 'activate.title'
            : 'unComplete.title'
        )}
      </Headline>

      <Text tag={'p'}>
        {m(
          apartment?.isPublished ? 'deactivate.text' : allApartmentDataAvailable ? 'activate.text' : 'unComplete.text'
        )}
      </Text>

      {!apartment?.isPublished && (
        <ul className={styles.list}>
          {!!!apartment?.mainInformation && (
            <li>
              <Text tag={'span'}>{m('mainInfo')}</Text>
            </li>
          )}
          {!!!apartment?.energySupply && (
            <li>
              <Text tag={'span'}>{m('energySupply')}</Text>
            </li>
          )}
          {!!!apartment?.cost && (
            <li>
              <Text tag={'span'}>{m('cost')}</Text>
            </li>
          )}
          {!!!apartment?.desiredTenant && (
            <li>
              <Text tag={'span'}>{m('desiredTenant')}</Text>
            </li>
          )}
          {!!!apartment?.equipment && (
            <li>
              <Text tag={'span'}>{m('equipment')}</Text>
            </li>
          )}
          {apartment?.media?.length === 0 && (
            <li>
              <Text tag={'span'}>{m('media')}</Text>
            </li>
          )}
        </ul>
      )}

      <div className={styles.buttonWrapper}>
        <CTAButton
          buttonStyle={BUTTON_STYLE.SECONDARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          onClick={() => closeModal()}
          buttonText={t('cancel')}
        />
        <CTAButton
          onClick={handlePublishApartment}
          buttonText={m(
            apartment?.isPublished
              ? 'button.deactivate.label'
              : allApartmentDataAvailable
              ? 'button.activate.label'
              : 'button.complete.label'
          )}
        />
      </div>
    </ModalWrapper>
  );
};

export default PublishApartmentModal;
