import React, { FC } from 'react';
import styles from './TunnelStepper.module.scss';
import Text from '../../../atoms/typographie/Text';
import { IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export enum TUNNEL_STEP_STATE {
  ACTIVE = 'ACTIVE',
  CURRENT = 'CURRENT',
  INACTIVE = 'INACTIVE',
}
export interface ITunnelStepperStepProps {
  state: TUNNEL_STEP_STATE;
  label: string;
  link?: string;
  onClick?: () => any;
}
export interface ITunnelStepperProps {
  steps: ITunnelStepperStepProps[];
}

const renderStepIcon = (state: TUNNEL_STEP_STATE): string => {
  switch (state) {
    case TUNNEL_STEP_STATE.ACTIVE:
      return '/assets/images/stepper-active.png';
    case TUNNEL_STEP_STATE.CURRENT:
      return '/assets/images/stepper-dot.png';
    case TUNNEL_STEP_STATE.INACTIVE:
      return '/assets/images/stepper-inactive.png';
  }
};

const TunnelStepperStep: FC<ITunnelStepperStepProps> = ({ state, label, link, onClick }) => {
  const { t } = useTranslation('common');

  return (
    <IonRouterLink onClick={onClick ? onClick : null} routerLink={link ? t(link) : undefined}>
      <div className={styles.step}>
        <img src={renderStepIcon(state)} alt="stepper" />
        <span className={styles.label}>
          <Text tag={'span'}>{label}</Text>
        </span>
      </div>
    </IonRouterLink>
  );
};

const TunnelStepper: FC<ITunnelStepperProps> = ({ steps }) => {
  return (
    <div className={styles.stepper}>
      {steps.map((step, index) => {
        return (
          step.state === TUNNEL_STEP_STATE.CURRENT && (
            <span key={`${step.label}-${index}`} className={styles.mobileLabel}>
              <Text tag={'span'}>{step.label}</Text>
            </span>
          )
        );
      })}
      {steps.map((step, index) => (
        <React.Fragment key={`${step.label}-${index}`}>
          <TunnelStepperStep key={step.label} {...step} />
          {index < steps.length - 1 && (
            <div className={`${styles.divider} ${step.state === TUNNEL_STEP_STATE.ACTIVE ? styles.active : ''}`} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TunnelStepper;
