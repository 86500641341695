import { FC, useContext } from 'react';
import { ROUTES } from '../../core/const/routes';
import { IonRow } from '@ionic/react';
import PageLayout from '../../component/organisms/PageLayout';
import { MODAL_IDENTIFIER } from '../../core/enum/modals.enum';
import ModalContext from '../../core/context/modal.context';
import { useTranslation } from 'react-i18next';
import Headline from '../../component/atoms/typographie/Headline';

const PasswordResetConfirmationView: FC = () => {
  const { t } = useTranslation('common');
  const { openModal } = useContext(ModalContext);
  return (
    <PageLayout pageTitle={t(ROUTES.passwordResetConfirmation.title)}>
      <IonRow class="ion-justify-content-center">
        <Headline>{t('component.atoms.formElement.FormErrorMessage.passwordReseted')}</Headline>
        <button onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })} type={'button'}>
          {t('signIn')}
        </button>
      </IonRow>
    </PageLayout>
  );
};

export default PasswordResetConfirmationView;
