import { IonCol, IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../core/const/routes';
import Section from '../../atoms/Section';
import Card, { CARD_COLORS } from '../../atoms/Card';
import { PopupButton } from '@typeform/embed-react';

const TenantServices = () => {
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ServicesView' });

  return (
    <>
      <Section>
        <IonCol size="12" size-md="6">
          <IonRouterLink routerLink={t(ROUTES.services.createSchufa.path)}>
            <Card
              headline={v('iconBox.schufa.title')}
              text={v('iconBox.schufa.text')}
              iconSrc={v('iconBox.schufa.iconSrc')}
              iconAltText={v('iconBox.schufa.altText')}
            />
          </IonRouterLink>
        </IonCol>
        <IonCol size="12" size-md="6">
          <IonRouterLink routerLink={t(ROUTES.services.compareCheck24.path).replace(':type', 'gas')}>
            <Card
              headline={v('iconBox.gas.title')}
              text={v('iconBox.gas.text')}
              iconSrc={v('iconBox.gas.iconSrc')}
              iconAltText={v('iconBox.gas.altText')}
              color={CARD_COLORS.RED}
            />
          </IonRouterLink>
        </IonCol>
        <IonCol size="12" size-md="6">
          <IonRouterLink routerLink={t(ROUTES.services.compareCheck24.path).replace(':type', 'power')}>
            <Card
              headline={v('iconBox.power.title')}
              text={v('iconBox.power.text')}
              iconSrc={v('iconBox.power.iconSrc')}
              iconAltText={v('iconBox.power.altText')}
              color={CARD_COLORS.GREEN}
            />
          </IonRouterLink>
        </IonCol>
        <IonCol size="12" size-md="6">
          <IonRouterLink routerLink={t(ROUTES.services.compareCheck24.path).replace(':type', 'dsl')}>
            <Card
              headline={v('iconBox.dsl.title')}
              text={v('iconBox.dsl.text')}
              iconSrc={v('iconBox.dsl.iconSrc')}
              iconAltText={v('iconBox.dsl.altText')}
              color={CARD_COLORS.GREY}
            />
          </IonRouterLink>
        </IonCol>
        <IonCol size="12" size-md="6">
          <PopupButton id={'CysxZlWc'}>
            <Card
              headline={v('iconBox.skz.title')}
              text={v('iconBox.skz.text')}
              iconSrc={v('iconBox.skz.iconSrc')}
              iconAltText={v('iconBox.skz.altText')}
              color={CARD_COLORS.RED}
            />
          </PopupButton>
        </IonCol>
      </Section>
    </>
  );
};

export default TenantServices;
