import { DatabaseService } from './database.service';
import { arrayRemove, arrayUnion, Query } from 'firebase/firestore';
import { FIRESTORE_COLLECTION_PATH, IApplication, IChatMessage, TChatMessageCreate } from '@wohnsinn/ws-ts-lib';
import { generateUUID } from '@ionic/cli/lib/utils/uuid';

export interface IIsTyping {
  isLandlordTyping?: boolean;
  isTenantTyping?: boolean;
}

export class ChatService {
  constructor(private databaseService: DatabaseService) {}

  public getChatMessageListRef(tenantId: string, apartmentId: string, landlordId: string): Query<IChatMessage> {
    return this.databaseService.getCollectionRefWithParams<IChatMessage>(
      this.databaseService.getCollectionGroupRef('chatMessages', { fetchWithId: true }),
      {
        where: [
          { fieldPath: 'landlordId', opStr: '==', value: landlordId },
          { fieldPath: 'tenantId', opStr: '==', value: tenantId },
          { fieldPath: 'apartmentId', opStr: '==', value: apartmentId },
        ],
        orderBy: { fieldPath: 'createdAt', directionStr: 'asc' },
      }
    );
  }

  /**
   * Clear unread chats on apartment
   * @param {string} apartmentId
   * @param {string} landlordId
   * @param {string} tenantId
   */
  public async clearUnreadTenantChatsRefOnApartment(
    apartmentId: string,
    landlordId: string,
    tenantId: string
  ): Promise<void> {
    try {
      const batch = this.databaseService.getBatch();
      const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', landlordId)
        .replace('{landlordId}', landlordId)}/${apartmentId}`;
      const apartmentDocRef = this.databaseService.getDocRef(apartmentPath, { fetchWithId: true });

      batch.update(apartmentDocRef, { unreadTenantChatsRef: arrayRemove(tenantId) });

      await batch.commit();
    } catch (error) {
      console.error('Error on clearUnreadTenantChatsRefOnApartment: ', error);
    }
  }

  /**
   * Clear unread messages from tenants on application
   */
  public async clearUnreadTenantMessagesRefOnApplication(
    landlordId: string,
    apartmentId: string,
    tenantId: string
  ): Promise<void> {
    const batch = this.databaseService.getBatch();

    const applicationPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}/applications/${tenantId}`;
    const applicationDocRef = this.databaseService.getDocRef(applicationPath, { fetchWithId: true });
    batch.set(applicationDocRef, { unreadTenantMessagesRef: [] }, { merge: true });

    return batch.commit();
  }

  public async sendMessage(application: IApplication, message: TChatMessageCreate): Promise<void> {
    const batch = this.databaseService.getBatch();
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)}/${application.apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${application.tenantProfile.uid}`;
    const messageId = generateUUID();
    const messagesPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.chatMessages
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)
      .replace('{apartmentId}', application.apartmentId)
      .replace('{tenantId}', application.tenantProfile.uid)}/${messageId}`;
    const apartmentDocRef = this.databaseService.getDocRef(apartmentPath, { fetchWithId: true });
    const applicationDocRef = this.databaseService.getDocRef(applicationPath, { fetchWithId: true });
    const messageDocRef = this.databaseService.getDocRef(messagesPath, { setCreatedAt: true });

    // @NIKLAS TODO: DO WE NEED TO CHECK FOR EDITOR LIST?
    if (message.senderId === message.landlordId) {
      batch.update(applicationDocRef, {
        isChatDisabled: false,
        unreadLandlordMessagesRef: arrayUnion(messageDocRef.id),
      });
    } else {
      // Add TenantID to unreadTenantChats on apartment
      batch.update(apartmentDocRef, { unreadTenantChatsRef: arrayUnion(application.tenantProfile.uid) });
      // Add TenantID to unreadTenantMessages on application
      batch.update(applicationDocRef, {
        unreadTenantMessagesRef: arrayUnion(messageDocRef.id),
      });
    }
    batch.update(applicationDocRef, {
      lastMessageSenderId: message.senderId,
      lastMessage: message.text,
      updatedAt: new Date(),
    });
    batch.set(messageDocRef, message);
    return batch.commit().catch(console.error);
  }

  public updateIsTypingStatus(application: IApplication, isTyping: IIsTyping): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.applications
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)
      .replace('{apartmentId}', application.apartmentId)}/${application.tenantProfile.uid}`;

    return this.databaseService.setDbDoc({ ...isTyping }, path);
  }
}
