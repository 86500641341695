import ModalWrapper from '../../ModalWrapper';
import { FC, useContext } from 'react';
import CTAButton, { BUTTON_STYLE } from '../../../../atoms/Buttons/CTAButton';
import ModalContext from '../../../../../core/context/modal.context';
import { useTranslation } from 'react-i18next';
import TenantFilterParamsContext from '../../../../../core/context/tenant-filter-params.context';
import styles from './FilterPersonsModal.module.scss';
import Headline, { HEADLINE_SIZE } from '../../../../atoms/typographie/Headline';
import Text, { TEXT_TYPE, TEXT_VARIANT } from '../../../../atoms/typographie/Text';
import { FormContext } from '../../../../../core/context/form.context';
import { useForm } from 'react-hook-form';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import Toggle from '../../../../atoms/formElement/Toggle';

const FilterPersonsModal: FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);

  const { control, watch, handleSubmit, setValue } = useForm<{
    numberOfPeopleMovingIn: number;
    arePetsAllowed: boolean;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      arePetsAllowed: tenantFilterParams?.arePetsAllowed
        ? tenantFilterParams.arePetsAllowed
        : tenantFilterParams?.arePetsAllowed === null
        ? null
        : false,
      numberOfPeopleMovingIn: tenantFilterParams?.numberOfPeopleMovingIn
        ? tenantFilterParams.numberOfPeopleMovingIn
        : null,
    },
  });

  const arePetsAllowed = watch().arePetsAllowed;
  const numberOfPeopleMovingIn = watch().numberOfPeopleMovingIn;
  const updateTenantFilterParamsHandler = async () => {
    await updateTenantFilterParams({ ...tenantFilterParams, arePetsAllowed, numberOfPeopleMovingIn });
    closeModal();
  };

  return (
    <ModalWrapper title={'Wer zieht ein?'}>
      <FormContext.Provider value={{ control }}>
        <form className={styles.form} onSubmit={handleSubmit(updateTenantFilterParamsHandler)}>
          <div className={styles.suggestionsTitle}>
            <div className={styles.counter}>
              <div>
                <Headline noMargin size={HEADLINE_SIZE.H3}>
                  Wie viele Personen ziehen ein?
                </Headline>
                <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
                  Gib an wie viele Personen mit dir einziehen.
                </Text>
              </div>

              <div className={styles.counterButtonWrapper}>
                <CTAButton
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  onClick={() =>
                    setValue('numberOfPeopleMovingIn', numberOfPeopleMovingIn > 0 ? numberOfPeopleMovingIn - 1 : null)
                  }
                  type={'button'}
                  buttonText={'minus'}
                  icon={faMinus}
                  size={'small'}
                  hideText
                />{' '}
                {numberOfPeopleMovingIn ? numberOfPeopleMovingIn : 0}
                <CTAButton
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  onClick={() => setValue('numberOfPeopleMovingIn', numberOfPeopleMovingIn + 1)}
                  type={'button'}
                  buttonText={'plus'}
                  icon={faPlus}
                  size={'small'}
                  hideText
                />
              </div>
            </div>
          </div>

          <br />

          <div className={styles.petsWrapper}>
            <div style={{ width: '100%' }}>
              <Headline noMargin size={HEADLINE_SIZE.H3}>
                Haustiere auch dabei?
              </Headline>
              <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
                Gib an ob Haustiere mit dir einziehen.
              </Text>
            </div>

            <Toggle name={'arePetsAllowed'} />
          </div>
          <br />

          <div className={'ion-margin-top'}>
            <CTAButton type={'submit'} rounded={false} expand={'block'} buttonText={t('save')} />
          </div>
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default FilterPersonsModal;
