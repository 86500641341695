import Text, { TEXT_COLOR, TEXT_TYPE } from '../typographie/Text';
import { Trans, useTranslation } from 'react-i18next';
import styles from './AcceptPrivacyInfoText.module.scss';
import { ROUTES } from '../../../core/const/routes';

const AcceptPrivacyInfoText = () => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.wrapper}>
      <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
        <Trans
          i18nKey={t('termsText.text')}
          components={{
            link1: (
              <a
                className={styles.link}
                href={t(ROUTES.links.terms)}
                title={t('termsText.terms')}
                target={'_blank'}
                rel={'noreferrer noopener'}
              />
            ),
            link2: (
              <a
                className={styles.link}
                href={t(ROUTES.links.dataProtection)}
                title={t('dataProtection')}
                target={'_blank'}
                rel={'noreferrer noopener'}
              />
            ),
          }}
        />
      </Text>
    </div>
  );
};

export default AcceptPrivacyInfoText;
