import GuardedRoute from '../component/atoms/GuardedRoute';
import ModalLoader from '../component/molecules/modals/ModalLoader';
import UserContext from '../core/context/user.context';
import { IonReactRouter } from '@ionic/react-router';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from '../core/const/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// VIEWS
//------------------
//------ GENERAL
//------------------
import NotAuthorizedView from '../view/general/NotAuthorized';
import NotFoundView from '../view/general/NotFoundView';
import RedirectView from '../view/general/RedirectView';
//------------------
//------ LANDLORD
//------------------
// account
import AccountView from '../view/landlord/account/AccountView';
// apartment
import ApartmentListView from '../view/landlord/apartment/ApartmentListView';
import ApartmentOverviewView from '../view/landlord/apartment/ApartmentOverviewView';
import ApartmentPreviewView from '../view/landlord/apartment/ApartmentPreviewView';
import ApartmentPublishProcessTunnel from '../view/landlord/apartment/ApartmentPublishProcessTunnel';
import ApartmentCreateView from '../view/landlord/apartment/ApartmentCreateView';
// application
import ApplicationListView from '../view/landlord/application/ApplicationListView';
import ApplicationOverviewView from '../view/landlord/application/ApplicationOverviewView';
import RegistrationTunnelView from '../view/landlord/RegistrationTunnelView';
//------------------
//------ SERVICES
//------------------
import CompareWithCheck24View from '../view/services/CompareWithCheck24View';
import CreateSchufaView from '../view/services/CreateSchufaView';
import ServicesView from '../view/services/ServicesView';
//------------------
//------ TENANT
//------------------
// account
import AboutMeView from '../view/tenant/account/AboutMeView';
import AccountOverviewView from '../view/tenant/account/AccountOverviewView';
import HouseholdView from '../view/tenant/account/HouseholdView';
import IncomeProofView from '../view/tenant/account/IncomeProofView';
import IntroductionVideoView from '../view/tenant/account/IntroductionVideoView';
import NotificationSettingsView from '../view/tenant/account/NotificationSettingsView';
import TenantProfileView from '../view/tenant/account/TenantProfileView';
// apartment
import TenantMatchingApartmentList from '../view/tenant/matching/ApartmentListView';
import TenantMatchingApartmentStack from '../view/tenant/matching/ApartmentStackView';
// bookmarks
import BookmarksView from '../view/tenant/BookmarksView';
// chat
import ChatView from '../view/tenant/ChatView';
//------------------
//------ ORGANIZATION
//------------------
import OrganizationPortfolioView from '../view/landlord/organization/OrganizationPortfolioView';
//------------------

// UNSORTED
import PasswordResetConfirmationView from '../view/PasswordResetSuccessView';
import UnsubscribeView from '../view/UnsubscribeView';
import VersionView from '../view/VersionView';
import ShareApartmentView from '../view/ShareApartmentView';
import LandlordLandingView from '../view/landlord/landing/LandlordLandingView';
import MaintenanceRouter from './maintenance-router';
import NotVerifiedLandlordRouter from './not-verified-landlord-router';
import AGBView from '../view/general/AGB';
import PrivacyView from '../view/general/PrivacyView';
import ImprintView from '../view/general/ImprintView';

const LinearRouter: FC<{ isMaintenanceMode: boolean }> = ({ isMaintenanceMode }) => {
  const { t } = useTranslation('common');
  const { landlordProfile, user, tenantProfile, activeUserType } = useContext(UserContext);
  return (
    <IonReactRouter>
      <ModalLoader />
      {isMaintenanceMode ? (
        <MaintenanceRouter />
      ) : user && activeUserType === USER_TYPE.LANDLORD && !landlordProfile?.isVerifiedLandlord ? (
        <NotVerifiedLandlordRouter />
      ) : (
        <Switch>
          {/* TENANT & LANDLORD ROUTES*/}

          <Route exact path={t(ROUTES.general.agb.path)}>
            <AGBView />
          </Route>
          <Route exact path={t(ROUTES.general.privacy.path)}>
            <PrivacyView />
          </Route>
          <Route exact path={t(ROUTES.general.imprint.path)}>
            <ImprintView />
          </Route>

          <Route exact path={t(ROUTES.landlord.landing.path)}>
            <LandlordLandingView />
          </Route>
          {/*Account*/}
          <GuardedRoute
            exact
            path={t(ROUTES.landlord.account.overview.path)}
            Component={<AccountView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: activeUserType === USER_TYPE.TENANT,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
            ]}
          />

          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.overview.path)}
            Component={<AccountOverviewView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
            ]}
          />
          {/* Application Folder */}
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.applicationFolder.aboutMe)}
            Component={<AboutMeView isApplicationFolderTunnel={true} />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.applicationFolder.profile)}
            Component={<TenantProfileView isApplicationFolderTunnel={true} />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.applicationFolder.household)}
            Component={<HouseholdView isApplicationFolderTunnel={true} />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.applicationFolder.incomeProof)}
            Component={<IncomeProofView isApplicationFolderTunnel={true} />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.applicationFolder.introductionVideo)}
            Component={<IntroductionVideoView isApplicationFolderTunnel={true} />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.aboutMe.path)}
            Component={<AboutMeView isApplicationFolderTunnel={false} />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.services.createSchufa.path)}
            Component={<CreateSchufaView />}
            cantActivate={[]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.household.path)}
            Component={<HouseholdView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.incomeProof.path)}
            Component={<IncomeProofView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.introductionVideo.path)}
            Component={<IntroductionVideoView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!tenantProfile,
                message: t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.services.compareCheck24.path)}
            Component={<CompareWithCheck24View />}
            cantActivate={[]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.profile.path)}
            Component={<TenantProfileView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: activeUserType === USER_TYPE.LANDLORD,
                message:
                  activeUserType === USER_TYPE.TENANT
                    ? t('toast.cantActivateErrorMessages.notLandlord')
                    : t('toast.cantActivateErrorMessages.notTenant'),
              },
            ]}
          />

          <GuardedRoute
            exact
            path={t(ROUTES.tenant.account.notifications.path)}
            Component={<NotificationSettingsView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
            ]}
          />

          {/*Tenant Chat */}
          {/*IMPORTANT! - THIS ROUTE MUST BE OVER APPLICATIONS*/}
          <GuardedRoute
            exact
            path={t(ROUTES.tenant.chat.path)}
            Component={<ChatView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
            ]}
          />
          <GuardedRoute exact path={t(ROUTES.services.overview.path)} Component={<ServicesView />} cantActivate={[]} />

          <Route
            exact
            path={t(ROUTES.signIn.path)}
            render={() =>
              user && user.activeUserType === USER_TYPE.LANDLORD ? (
                <Redirect to={t(ROUTES.landlord.apartment.list.path)} />
              ) : (
                <Redirect to={t(ROUTES.tenant.matching.list.path)} />
              )
            }
          />

          <Route
            exact
            path={t(ROUTES.register.tenant.path)}
            render={() =>
              !user && user.activeUserType === USER_TYPE.LANDLORD ? (
                <Redirect to={t(ROUTES.landlord.apartment.list.path)} />
              ) : (
                <Redirect to={t(ROUTES.tenant.matching.list.path)} />
              )
            }
          />

          <Route exact path={t(ROUTES.landlord.register.path)} component={RegistrationTunnelView} />

          {/*Applications*/}
          <GuardedRoute
            exact
            path={t(ROUTES.landlord.application.list.path)}
            Component={<ApplicationListView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.landlord.application.overview.path)}
            Component={<ApplicationOverviewView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
            ]}
          />

          <GuardedRoute
            exact
            path={t(ROUTES.landlord.apartment.list.path)}
            Component={<ApartmentListView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!landlordProfile,
                message: t('toast.cantActivateErrorMessages.notLandlord'),
              },
            ]}
          />

          <GuardedRoute
            exact
            path={t(ROUTES.landlord.apartment.new.path)}
            Component={<ApartmentCreateView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!landlordProfile,
                message: t('toast.cantActivateErrorMessages.notLandlord'),
              },
            ]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.landlord.apartment.overview.path)}
            Component={<ApartmentOverviewView />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
              {
                cantActivate: !!!landlordProfile,
                message: t('toast.cantActivateErrorMessages.notLandlord'),
              },
            ]}
          />

          <GuardedRoute
            exact
            path={t(ROUTES.landlord.apartment.preview.path)}
            Component={<ApartmentPreviewView />}
            cantActivate={[]}
          />
          <GuardedRoute
            exact
            path={t(ROUTES.landlord.apartment.publish.path)}
            Component={<ApartmentPublishProcessTunnel />}
            cantActivate={[
              {
                cantActivate: !!!user,
                message: t('toast.cantActivateErrorMessages.notLoggedIn'),
              },
            ]}
          />
          {/*Bookmarks*/}
          <GuardedRoute
            exact
            path={`${t(ROUTES.tenant.bookmark.router)}`}
            Component={<BookmarksView />}
            cantActivate={[]}
          />

          <GuardedRoute path={`${t(ROUTES.unsubscribe.path)}/:id`} Component={<UnsubscribeView />} cantActivate={[]} />

          <GuardedRoute
            exact
            path={`${t(ROUTES.landlord.organization.portfolio.path)}`}
            Component={<OrganizationPortfolioView />}
            cantActivate={[]}
          />

          <GuardedRoute
            exact
            path={`${t(ROUTES.landlord.organization.portfolio.apartment.path)}`}
            Component={<ShareApartmentView isPortfolio />}
            cantActivate={[]}
          />
          <GuardedRoute
            exact
            path={`${t(ROUTES.tenant.shareApartment.path)}`}
            Component={<ShareApartmentView />}
            cantActivate={[]}
          />
          {/*Redirect */}
          <Route exact path={t(ROUTES.general.redirect.path)} component={RedirectView} />
          <Route exact path={`${t(ROUTES.general.redirect.path)}/:redirectType`} component={RedirectView} />
          {/*Error 404*/}
          <Route exact path={t(ROUTES.general.notFound.path)}>
            <NotFoundView />
          </Route>
          {/*Error 403*/}
          <Route exact path={t(ROUTES.general.notAuthorized.path)}>
            <NotAuthorizedView />
          </Route>
          {/*Password Reset Confirmation*/}
          <Route exact path={t(ROUTES.passwordResetConfirmation.path)}>
            <PasswordResetConfirmationView />
          </Route>
          {/*Fallback Route*/}

          <Route
            exact
            path={`${t(ROUTES.tenant.matching.list.path)}/:page`}
            render={() =>
              user && user.activeUserType === USER_TYPE.LANDLORD ? (
                <Redirect to={t(ROUTES.landlord.apartment.list.path)} />
              ) : (
                <TenantMatchingApartmentList />
              )
            }
          />
          <Route
            exact
            path={t(ROUTES.tenant.matching.list.path)}
            render={() =>
              user && user.activeUserType === USER_TYPE.LANDLORD ? (
                <Redirect to={t(ROUTES.landlord.apartment.list.path)} />
              ) : (
                <TenantMatchingApartmentList />
              )
            }
          />

          <Route
            exact
            path={t(ROUTES.tenant.matching.stack.firstApartment.path)}
            component={TenantMatchingApartmentStack}
          />

          <Route
            exact
            path={`${t(ROUTES.tenant.matching.stack.selectedApartment.path)}`}
            component={TenantMatchingApartmentStack}
          />

          <Route exact path={t(ROUTES.landlord.account.activateAccount.path)}>
            <Redirect to={t(ROUTES.landlord.apartment.list.path)} />
          </Route>

          <Route exact path={'/'}>
            <Redirect to={t(ROUTES.tenant.matching.list.path)} />
          </Route>

          {/*Fallback if no route matches*/}
          <Route render={() => <Redirect to={t(ROUTES.general.notFound.path)} />} />

          {process.env.REACT_APP_ENVIRONMENT !== 'production' ? (
            <Route path={'/version'}>
              <VersionView />
            </Route>
          ) : null}
        </Switch>
      )}
    </IonReactRouter>
  );
};

export default LinearRouter;
