import { ISelectOption } from '@wohnsinn/ws-ts-lib';

export const getTranslatedOptions = <T>(
  selectOptions: ISelectOption<T>[],
  transLateFn: (key: string) => string
): ISelectOption[] => {
  return selectOptions.map((o) => {
    return { value: o.value, label: transLateFn(o.label) };
  });
};

export const BOOLEAN_SELECT_OPTIONS: ISelectOption<boolean>[] = [
  { value: true, label: 'Ja' },
  { value: false, label: 'Nein' },
];
