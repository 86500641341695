import { ChangeEvent, FC, KeyboardEvent, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import styles from './ApartmentInLocationFinder.module.scss';
import useAccessibleNavigation from '../../../core/hook/use-accessible.navigation';
import { DebounceInput } from 'react-debounce-input';
import useSearchQuery from '../../../core/hook/search-query.hook';
import { IonRange } from '@ionic/react';
import TenantFilterParamsContext from '../../../core/context/tenant-filter-params.context';
import ApartmentInLocationFinderMap from './ApartmentInLocationFinderMap';
import { IGeoPoint, ILocationSelection, TCenter } from '@wohnsinn/ws-ts-lib';
import { IonRangeCustomEvent } from '@ionic/core/dist/types/components';
import { RangeKnobMoveEndEventDetail } from '@ionic/core';
import { useTranslation } from 'react-i18next';
import MixpanelTrackingServiceContext from '../../../core/context/mixpanel-tracking-service.context';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Text, { TEXT_TYPE, TEXT_VARIANT } from '../../atoms/typographie/Text';

const ApartmentInLocationFinder: FC = () => {
  const INITIAL_RADIUS_IN_KM = 25;
  const { handleKeyboardNavigation } = useAccessibleNavigation();
  const { updateTenantFilterParams, tenantFilterParams } = useContext(TenantFilterParamsContext);
  const ref = useRef(null);
  const query = useSearchQuery();
  const [suggestionList, setSuggestionList] = useState<any>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<ILocationSelection>();
  const { t } = useTranslation('common');
  const { mixpanelTrackingService } = useContext(MixpanelTrackingServiceContext);

  useEffect(() => {
    mixpanelTrackingService.trackPlaceName(query.get('place_name'));
    if (query.get('center') && tenantFilterParams && !tenantFilterParams?.location) {
      const center: TCenter = JSON.parse(query.get('center'));
      const geoPoint: IGeoPoint = { lat: center[0], lng: center[1] };
      const selectedParams: ILocationSelection = {
        center,
        geoPoint,
        id: query.get('id'),
        place_name: query.get('place_name'),
        radius: INITIAL_RADIUS_IN_KM,
      };
      updateTenantFilterParams({ ...tenantFilterParams, location: selectedParams });
      setSelectedSuggestion(selectedParams);
    }
    if (tenantFilterParams?.location) {
      const selectedParams: ILocationSelection = {
        ...tenantFilterParams?.location,
      };
      setSelectedSuggestion(selectedParams);
    }
  }, [tenantFilterParams]);

  const fetchSuggestions = async (searchString: string): Promise<void> => {
    try {
      const mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json`;
      const suggestionListData = await (
        await fetch(
          `${mapBoxApiUrl}?country=DE&language=DE&&types=place,locality,postcode,district,neighborhood&&&access_token=${process.env.REACT_APP_MAP_BOX_TOKEN}`
        )
      ).json();

      setSuggestionList(suggestionListData?.features);
    } catch (e) {
      console.error('error on fetchSuggestions', e);
    }
  };

  const handleSearch = async (searchString: string): Promise<void> => {
    if (searchString.length) {
      await fetchSuggestions(searchString);
    }
  };

  const onSelect = async (e: KeyboardEvent | MouseEvent, selection: any) => {
    const center: TCenter = selection.center;
    const geoPoint: IGeoPoint = { lat: selection.center[1], lng: selection.center[0] };
    const radius: number = selection?.radius ? selection.radius : INITIAL_RADIUS_IN_KM;

    const selectedParams: ILocationSelection = {
      center,
      geoPoint,
      place_name: selection.place_name,
      radius,
      id: selection.id,
    };
    setSelectedSuggestion({ ...selectedParams, radius });
    await updateTenantFilterParams({ ...tenantFilterParams, location: selectedParams });
    ref.current.value = selection.place_name;
    setSuggestionList([]);
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.suggestionsTitle}>
        <Headline noMargin size={HEADLINE_SIZE.H3}>
          Wohnort
        </Headline>
        <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
          Wähle eine Stadt und einen Radius für deine neue Traumwohnung
        </Text>
      </div>
      <div className={styles.wrapper}>
        <DebounceInput
          ref={ref}
          debounceTimeout={200}
          key={'searchInput'}
          type={'search'}
          autoComplete={'off'}
          className={styles.input}
          placeholder={t('component.organisms.searchTunnelSlides.City.placeholder')}
          name="addressSearch"
          value={selectedSuggestion?.place_name ? selectedSuggestion.place_name : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch((e.target as HTMLInputElement).value)}
        />

        {suggestionList?.length > 0 && (
          <ul className={styles.optionWrapper}>
            {suggestionList.map((suggestion: any) => (
              <li
                onKeyDown={(e) =>
                  handleKeyboardNavigation(e, {
                    enableArrowKeyNavigation: true,
                    enterHandler: (e) => onSelect(e, suggestion),
                    spaceHandler: (e) => onSelect(e, suggestion),
                  })
                }
                onClick={(e) => onSelect(e, suggestion)}
                key={suggestion.place_name}
                tabIndex={0}
                className={styles.option}
              >
                {suggestion.place_name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {selectedSuggestion?.place_name && (
        <IonRange
          className={styles.range}
          min={1}
          pin={true}
          step={1}
          max={50}
          value={selectedSuggestion?.radius ? selectedSuggestion.radius : INITIAL_RADIUS_IN_KM}
          onIonKnobMoveEnd={(e: IonRangeCustomEvent<RangeKnobMoveEndEventDetail>) =>
            updateTenantFilterParams({
              ...tenantFilterParams,
              location: {
                ...selectedSuggestion,
                radius: (e.target as HTMLIonRangeElement).value as number,
              },
            })
          }
          pinFormatter={(value: number) => `${value} ${t('range')}`}
        />
      )}
      <div className={styles.mapWrapper}>
        <ApartmentInLocationFinderMap
          height={'250px'}
          radius={selectedSuggestion?.radius ?? 5}
          center={selectedSuggestion?.center}
        />
      </div>
    </div>
  );
};

export default ApartmentInLocationFinder;
