import { FC } from 'react';
import { IOrganization } from '@wohnsinn/ws-ts-lib';
import styles from './OrganizationInformation.module.scss';
import Text, { TEXT_COLOR } from '../typographie/Text';
import { useTranslation } from 'react-i18next';

const OrganizationInformation: FC<{ organization: IOrganization }> = ({ organization }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.wrapper}>
      {organization?.photoUrl && (
        <img className={styles.image} src={organization.photoUrl} alt={`${organization?.title} - Logo`} />
      )}

      {organization?.title && <Text tag={'span'}>{organization.title}</Text>}

      {organization?.website && (
        <a href={organization.website}>
          <br />
          <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
            {t('website')}
          </Text>
        </a>
      )}

      {organization?.imprintLink && (
        <a href={organization.imprintLink} target={'_blank'} rel="noreferrer">
          <br />
          <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
            {t('imprint')}
          </Text>
        </a>
      )}

      {organization?.dataProtectionLink && (
        <a href={organization.dataProtectionLink} target={'_blank'} rel="noreferrer">
          <br />
          <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
            {t('dataProtection')}
          </Text>
        </a>
      )}
    </div>
  );
};

export default OrganizationInformation;
