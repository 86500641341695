import { FC } from 'react';
import useApartment from '../../../../core/hook/apartment.hook';
import ApartmentInformation from '../../../../component/organisms/ApartmentInformation';
import MapboxMap from '../../../../component/molecules/Map';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import styles from './ApartmentPreviewView.module.scss';
import useWindowSize from '../../../../core/hook/windowsize.hook';

const ApartmentPreviewView: FC = () => {
  const { apartment, isLoading } = useApartment();
  const { t } = useTranslation('common');
  const { isGreaterMd } = useWindowSize();

  return (
    <PageLayout showBackButton pageTitle={t(ROUTES.landlord.apartment.preview.title)} fullWidthPage>
      <div className={styles.wrapper}>
        <div className={styles.apartmentInformation}>
          {apartment && !isLoading && <ApartmentInformation apartment={apartment} />}
        </div>
        <div className={styles.map}>
          {!isLoading && !!apartment && isGreaterMd && <MapboxMap isInteractive={true} apartments={[apartment]} />}
        </div>
      </div>
    </PageLayout>
  );
};

export default ApartmentPreviewView;
