import { getApps, initializeApp } from 'firebase/app';
import FIREBASE_OPTIONS from '../../firebase.config';
import { getRemoteConfig, fetchAndActivate, getValue, RemoteConfig, getBoolean } from '@firebase/remote-config';

export class RemoteConfigService {
  private remoteConfig: RemoteConfig;
  private isFetched: boolean;

  constructor() {
    this.setup();
  }

  private async setup() {
    if (getApps().length === 0) {
      this.remoteConfig = getRemoteConfig(initializeApp(FIREBASE_OPTIONS));
    } else {
      this.remoteConfig = getRemoteConfig(getApps()[0]);
    }
    this.remoteConfig.settings.minimumFetchIntervalMillis = 36000;
    this.remoteConfig.app.automaticDataCollectionEnabled = true;
    if (!this.isFetched) {
      this.isFetched = await fetchAndActivate(this.remoteConfig);
    }
  }

  public async getRemoteConfigValue(parameterName: string): Promise<unknown> {
    if (!this.isFetched) {
      this.isFetched = await fetchAndActivate(this.remoteConfig);
    }
    return getValue(this.remoteConfig, parameterName);
  }

  public async getRemoteConfigBoolean(parameterName: string): Promise<boolean> {
    if (!this.isFetched) {
      this.isFetched = await fetchAndActivate(this.remoteConfig);
    }
    return getBoolean(this.remoteConfig, parameterName);
  }
}
