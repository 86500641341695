import { FC } from 'react';
import styles from './SuggestionButton.module.scss';
import Text, { TEXT_WEIGHT } from '../typographie/Text';

const SuggestionButton: FC<{ onClick: any; label: string }> = ({ onClick, label }) => {
  return (
    <button type={'button'} onClick={onClick} className={styles.suggestionBtn}>
      <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} align={'center'}>
        {label}
      </Text>
    </button>
  );
};

export default SuggestionButton;
