import { useContext, useEffect, useState } from 'react';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import { IOrganization } from '@wohnsinn/ws-ts-lib';
import UserContext from '../context/user.context';
import OrganizationService from '../service/organisation.service';

const useOrganization = (): {
  organizationService: OrganizationService;
  organization: IOrganization;
  isLoading: boolean;
} => {
  const { organizationService, landlordProfile } = useContext(UserContext);
  const [organization, setOrganization] = useState<IOrganization>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (landlordProfile?.organizationId) {
      (async () => {
        unsubscribe = onSnapshot(
          organizationService.getOrganizationRefById(landlordProfile?.organizationId),
          (organizationListSnap) => {
            setOrganization(organizationListSnap.data());
            setIsLoading(false);
          },
          (err) => console.error('error on useApartmentHook: ', err)
        );
      })();
    }
    return unsubscribe;
  }, [landlordProfile]);

  return { organizationService, organization, isLoading };
};

export default useOrganization;
