import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import DocumentUploadDropZone, { UPLOAD_TYPE } from '../../../../molecules/DocumentUploadDropZone';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import DocumentUploadFileList from '../../../../molecules/DocumentUploadDropZone/DocumentUploadFileList';
import { IonGrid, IonCol, useIonRouter, IonRow } from '@ionic/react';
import ModalContext from '../../../../../core/context/modal.context';
import UserContext from '../../../../../core/context/user.context';
import useTenantDocuments from '../../../../../core/hook/tenant-document.hook';
import { useContext, useState, useEffect, FC } from 'react';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormLayout from '../../../FormLayout';
import { ITenantFormProps } from '../TenantProfileForm';
import PageTitle from '../../../../molecules/PageTitle';
import { ROUTES } from '../../../../../core/const/routes';
import LOCAL_STORAGE_KEYS from '../../../../../core/enum/local-storage.enum';

const TenantIncomeProofForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const [schufaCheckDocumentUploadList, setSchufaCheckUploadList] = useState<IUpload[]>([]);
  const [incomeProofDocumentUploadList, setIncomeProofDocumentUploadList] = useState<IUpload[]>([]);
  const { tenantProfile } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { schufaCheckDocumentList, incomeProofDocumentList } = useTenantDocuments(tenantProfile?.uid);
  const router = useIonRouter();
  const redirectUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);

  const { t } = useTranslation('common', { keyPrefix: 'view.AccountView.IncomeProofView' });
  const { t: r } = useTranslation('common');

  useEffect(() => {
    setSchufaCheckUploadList(schufaCheckDocumentList);
    setIncomeProofDocumentUploadList(incomeProofDocumentList);
  }, [schufaCheckDocumentList, incomeProofDocumentList]);

  const updateUploadStateHandler = (uploadList: IUpload[], uploadType: UPLOAD_TYPE): void => {
    let uniqueUpload: IUpload[] = [];
    switch (uploadType) {
      case UPLOAD_TYPE.SCHUFA:
        uniqueUpload = uniqBy<IUpload>([...schufaCheckDocumentUploadList, ...uploadList], 'fileName');
        setSchufaCheckUploadList(uniqueUpload);
        break;
      case UPLOAD_TYPE.INCOME_PROOF:
        uniqueUpload = uniqBy<IUpload>([...incomeProofDocumentUploadList, ...uploadList], 'fileName');
        setIncomeProofDocumentUploadList(uniqueUpload);
        break;
    }
  };

  const documentDeleteHandler = (upload: IUpload, uploadType: UPLOAD_TYPE): void => {
    switch (uploadType) {
      case UPLOAD_TYPE.SCHUFA:
        setSchufaCheckUploadList(
          uniqBy<IUpload>(
            schufaCheckDocumentUploadList.filter((u) => u.id !== upload.id),
            'fileName'
          )
        );
        break;
      case UPLOAD_TYPE.INCOME_PROOF:
        setIncomeProofDocumentUploadList(
          uniqBy<IUpload>(
            schufaCheckDocumentUploadList.filter((u) => u.id !== upload.id),
            'fileName'
          )
        );
        break;
    }
  };

  const FINISH_BUTTON = {
    text: r('finish'),
    action: () => {
      if (redirectUrl) {
        return router.push(redirectUrl);
      }
      return router.push(r(ROUTES.tenant.matching.list.path), 'forward');
    },
  };

  const BACK_BUTTON = {
    text: r('back'),
    action: () => router.push(r(ROUTES.tenant.account.overview.path), 'back'),
  };

  return (
    <>
      {!isApplicationFolderTunnel && <PageTitle pageTitle={r(ROUTES.tenant.account.incomeProof.title)} />}
      <FormLayout notSubmitButton={isApplicationFolderTunnel ? FINISH_BUTTON : BACK_BUTTON} showSubmitButton={false}>
        <IonGrid className={'wohnsinn-forms-grid'}>
          <IonRow className={'wohnsinn-form-section'}>
            <IonCol size={'12'}>
              <FormSectionTitle title={t('schufa')} />
              <DocumentUploadDropZone
                uploadType={UPLOAD_TYPE.SCHUFA}
                onUploadButtonClick={() => openModal({ id: MODAL_IDENTIFIER.CREATE_OR_UPLOAD_SCHUFA })}
                onFileSelection={closeModal}
                onUploadListCreation={updateUploadStateHandler}
              />

              <DocumentUploadFileList
                uploadType={UPLOAD_TYPE.SCHUFA}
                documentUploadList={schufaCheckDocumentUploadList}
                onDocumentDelete={documentDeleteHandler}
              />
            </IonCol>
            <IonCol size={'12'}>
              <FormSectionTitle title={t('incomeProof')} />
              <DocumentUploadDropZone
                uploadType={UPLOAD_TYPE.INCOME_PROOF}
                onUploadListCreation={updateUploadStateHandler}
              />
              <DocumentUploadFileList
                uploadType={UPLOAD_TYPE.INCOME_PROOF}
                documentUploadList={incomeProofDocumentUploadList}
                onDocumentDelete={documentDeleteHandler}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </FormLayout>
    </>
  );
};

export default TenantIncomeProofForm;
