import { FC, useContext, useEffect, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import Toggle from '../../../../atoms/formElement/Toggle';
import TextInput from '../../../../atoms/formElement/InputText';
import TextEditor from '../../../../atoms/formElement/TextEditor';
import { FormContext } from '../../../../../core/context/form.context';
import { FirebaseFunctionsContext } from '../../../../../core/context/firebase-functions.context';
import useToast from '../../../../../core/hook/toast.hook';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { EQUIPMENT, IApartment, IApartmentEquipment } from '@wohnsinn/ws-ts-lib';
import FB_FUNCTION_URLS from '../../../../../core/const/fb-function-names';
import FormHeader from '../../../../atoms/FormHeader';
import FormFooter from '../../../../atoms/FormFooter';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import SplitLine from '../../../../atoms/SplitLine';
import styles from './ApartmentEquipmentForm.module.scss';

export interface IApartmentEquipmentFormValue {
  description: { editorState: string; html: string };
  kitchenPrice: number | null;
  parkingSpotRent: number | null;
  hasGarden: boolean;
  hasKitchen: boolean;
  hasElevator: boolean;
  hasGarage: boolean;
  hasBalconyOrTerrace: boolean;
  hasParkingSpot: boolean;
  isAccessible: boolean;
  hasBasement: boolean;
  hasGuestToilet: boolean;
  isFurnished: boolean;
  hasParkingSpotRent: boolean;
  hasKitchenPrice: boolean;
}

const ApartmentEquipmentForm: FC<{ scrollToTop: () => any; apartment: IApartment }> = ({ apartment, scrollToTop }) => {
  const { firebaseFunctionsService } = useContext(FirebaseFunctionsContext);
  const { sendInfoToast } = useToast();
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.equipment' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { control, reset, handleSubmit, watch, setValue } = useForm<IApartmentEquipmentFormValue>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });

  const hasBalconyOrTerrace: boolean = watch('hasBalconyOrTerrace');
  const hasKitchen: boolean = watch('hasKitchen');
  const hasElevator: boolean = watch('hasElevator');
  const hasGarden: boolean = watch('hasGarden');
  const hasParkingSpot: boolean = watch('hasParkingSpot');
  const isAccessible: boolean = watch('isAccessible');
  const hasBasement: boolean = watch('hasBasement');
  const hasGuestToilet: boolean = watch('hasGuestToilet');
  const isFurnished: boolean = watch('isFurnished');
  const hasParkingSpotRent: boolean = watch('hasParkingSpotRent');
  const hasKitchenPrice: boolean = watch('hasKitchenPrice');
  const description: { editorState: string; html: string } = watch('description');
  const parkingSpotRent: number = watch('parkingSpotRent');

  useEffect(() => {
    if (!!apartment?.equipment) {
      reset({
        hasBalconyOrTerrace: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_BALCONY_OR_TERRACE),
        hasBasement: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_BASEMENT),
        hasElevator: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_ELEVATOR),
        hasGarden: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_GARDEN),
        hasGuestToilet: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_GUEST_TOILET),
        hasKitchen: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_KITCHEN),
        hasParkingSpot: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_PARKING_SPOT),
        isAccessible: apartment.equipment.equipmentList.includes(EQUIPMENT.IS_ACCESSIBLE),
        isFurnished: apartment.equipment.equipmentList.includes(EQUIPMENT.IS_FURNISHED),
        kitchenPrice: apartment.equipment.kitchenPrice,
        parkingSpotRent: apartment.equipment.parkingSpotRent,
        hasParkingSpotRent: apartment.equipment.parkingSpotRent == null,
        hasKitchenPrice: apartment.equipment.kitchenPrice != null,
        description: apartment.equipment.description,
      });
    }
  }, [apartment]);

  const formatEquipment = (): IApartmentEquipment => {
    const equipmentList: EQUIPMENT[] = [];

    if (hasBalconyOrTerrace) {
      equipmentList.push(EQUIPMENT.HAS_BALCONY_OR_TERRACE);
    }

    if (hasElevator) {
      equipmentList.push(EQUIPMENT.HAS_ELEVATOR);
    }

    if (hasKitchen) {
      equipmentList.push(EQUIPMENT.HAS_KITCHEN);
    }

    if (hasGarden) {
      equipmentList.push(EQUIPMENT.HAS_GARDEN);
    }

    if (hasParkingSpot) {
      equipmentList.push(EQUIPMENT.HAS_PARKING_SPOT);
    }

    if (isAccessible) {
      equipmentList.push(EQUIPMENT.IS_ACCESSIBLE);
    }

    if (hasBasement) {
      equipmentList.push(EQUIPMENT.HAS_BASEMENT);
    }

    if (hasGuestToilet) {
      equipmentList.push(EQUIPMENT.HAS_GUEST_TOILET);
    }

    if (isFurnished) {
      equipmentList.push(EQUIPMENT.IS_FURNISHED);
    }
    let newKitchenPrice: number;

    if (watch('kitchenPrice') > 1 && watch('hasKitchenPrice')) {
      newKitchenPrice = watch('kitchenPrice');
    }

    if (!watch('kitchenPrice')) newKitchenPrice = null;

    return {
      kitchenPrice: newKitchenPrice,
      parkingSpotRent,
      equipmentList,
      description,
    };
  };

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    scrollToTop();
    await sendInfoToast('toast.success');
  };

  const onError = (err: any): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    if (apartment.id && apartment.equipment) {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentEquipment; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.equipment.update,
          {
            params: { apartmentId: apartment.id },
            body: formatEquipment(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    } else {
      return firebaseFunctionsService
        .callFbFunction<{ body: IApartmentEquipment; params: { apartmentId: string } }, void>(
          FB_FUNCTION_URLS.apartments.equipment.add,
          {
            params: { apartmentId: apartment.id },
            body: formatEquipment(),
          }
        )
        .then(onSuccess)
        .catch((err) => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (hasParkingSpotRent) {
      setValue('parkingSpotRent', null);
    }
    if (!hasKitchenPrice) {
      setValue('kitchenPrice', null);
    }
  }, [hasParkingSpotRent, hasKitchenPrice]);

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(saveDataToDB, onError)} noValidate autoComplete="off">
        <FormHeader title={'Ausstattung'} buttonSubmitMode={buttonSubmitMode} />
        <IonGrid className={'wohnsinn-forms-grid'}>
          <IonRow>
            <IonCol size="12">
              <div className={styles.equipmentToggleWrapper}>
                <Toggle label={a('equipmentList.HAS_ELEVATOR')} name={'hasElevator'} />
                <Toggle label={a('equipmentList.HAS_BALCONY_OR_TERRACE')} name={'hasBalconyOrTerrace'} />
                <Toggle label={a('equipmentList.IS_ACCESSIBLE')} name={'isAccessible'} />
                <Toggle label={a('equipmentList.HAS_KITCHEN')} name={'hasKitchen'} />
                {watch('hasKitchen') && (
                  <div style={{ paddingLeft: '1rem' }}>
                    <Toggle label={a('takeoverPrice')} name={'hasKitchenPrice'} />
                    {watch('hasKitchenPrice') && (
                      <TextInput
                        name={'kitchenPrice'}
                        type={'number'}
                        pattern="[0-9]*"
                        unit={t('currency')}
                        label={a('takeoverPrice')}
                      />
                    )}
                  </div>
                )}
                <Toggle label={a('equipmentList.HAS_GARDEN')} name={'hasGarden'} />
                <Toggle label={a('equipmentList.HAS_GUEST_TOILET')} name={'hasGuestToilet'} />
                <Toggle label={a('equipmentList.HAS_BASEMENT')} name={'hasBasement'} />
                <Toggle label={a('equipmentList.IS_FURNISHED')} name={'isFurnished'} />
                <Toggle label={a('equipmentList.HAS_PARKING_SPOT')} name={'hasParkingSpot'} />

                {watch('hasParkingSpot') ? (
                  <>
                    <Toggle label={a('includedInRent')} name={'hasParkingSpotRent'} />
                    {!watch('hasParkingSpotRent') ? (
                      <TextInput name={'parkingSpotRent'} type={'number'} pattern="[0-9]*" unit={t('currency')} />
                    ) : null}
                  </>
                ) : null}
              </div>

              <SplitLine />

              <FormSectionTitle title={'Weitere Ausstattung'} />
              <TextEditor isAutofocused placeholder={a('description.placeholder')} name={'description'} size={'2x'} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentEquipmentForm;
