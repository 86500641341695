import { FC } from 'react';
import styles from './Tag.module.scss';
import Text, { TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../typographie/Text';

export interface ITag {
  key: string;
  label: string;
  value?: any;
}

export enum TAG_COLOR {
  DEFAULT = 'DEFAUTL',
  'BLUE' = 'BLUE',
}

export interface ITagProps {
  tag: ITag;
  size?: 'small' | 'default';
  color?: TAG_COLOR;
}

const Tag: FC<ITagProps> = ({ tag, size, color = TAG_COLOR.DEFAULT }) => {
  return (
    <span
      data-testid={`tag.${tag.key}`}
      className={`${styles.tag} ${size === 'small' ? styles.small : ''} ${styles[color]}`}
    >
      <Text
        weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
        tag={'span'}
        type={size === 'small' ? TEXT_TYPE.TEXT_TYPE_CAPTION : TEXT_TYPE.TEXT_TYPE_BODY}
        variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
      >
        {tag.label}
      </Text>
    </span>
  );
};

export default Tag;
