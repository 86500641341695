enum LOCAL_STORAGE_KEYS {
  CURRENT_MODAL = 'CURRENT_MODAL',
  NOT_COMMING_FROM_STACK = 'NOT_COMMING_FROM_STACK',
  DARK_MODE = 'DARK_MODE',
  EMAIL = 'EMAIL',
  HAS_SEEN_BOOKMARK_INFO = 'HAS_SEEN_BOOKMARK_INFO',
  IS_COMMERCIAL_LANDLORD = 'IS_COMMERCIAL_LANDLORD',
  NOT_LOGGED_IN_USER_RATINGS = 'NOT_LOGGED_IN_USER_RATINGS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  REDIRECT_URL = 'REDIRECT_URL',
  SCROLL_POSITION = 'SCROLL_POSITION',
  TENANT_FILTER_PARAMS_ID = 'TENANT_FILTER_PARAMS_ID',
  TOUR_MATCHLIST_SEEN = 'TOUR_MATCHLIST_SEEN',
  USER_HAS_SEEN_HOW_TO_SLIDES = 'USER_HAS_SEEN_HOW_TO_SLIDES',
  USER_TYPE = 'USER_TYPE',
}

export default LOCAL_STORAGE_KEYS;
