import { FC, useContext, useState } from 'react';
import { useIonPicker } from '@ionic/react';
import { FormContext } from '../../../../core/context/form.context';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import differenceInYears from 'date-fns/differenceInYears';
import { addYears, format, getDay, getMonth, getYear, subYears } from 'date-fns';
import FormErrorMessage from '../FormErrorMessage';
import styles from './MobileDatepicker.module.scss';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../typographie/Text';

export interface IDateInputProps {
  startDate?: Date;
  endDate?: Date;
  label: string;
  name: string;
  required?: boolean;
  hideErrorMessage?: boolean;
}

const generateYears = (startDate: Date, endDate: Date): number[] => {
  const years = [];
  while (differenceInYears(startDate, endDate) < 0) {
    years.push(getYear(startDate));
    startDate = addYears(startDate, 1);
  }
  years.push(getYear(startDate));
  return years;
};

const months: string[] = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const MobileDateInput: FC<IDateInputProps> = (props) => {
  const {
    startDate = subYears(new Date(), 100),
    endDate = new Date(),
    name,
    hideErrorMessage = false,
    label,
    required,
  } = props;
  const { t } = useTranslation('common');
  const years: number[] = generateYears(startDate, endDate);
  const { control } = useContext(FormContext);
  const [presentDatePicker, dismiss] = useIonPicker();
  const [isOpen, setIsOpen] = useState(false);

  const getDayIndex = (date: Date | number): number => {
    return date ? getDay(date) : getDay(new Date());
  };

  const getMonthIndex = (date: Date | number): number => {
    return date ? getMonth(date) : getMonth(new Date());
  };

  const handlePresentDatePicker = (e: any, field: ControllerRenderProps) => {
    if (!isOpen) {
      setIsOpen(true);
      presentDatePicker({
        onWillDismiss: () => {
          setIsOpen(false);
        },
        id: name,
        buttons: [
          {
            text: t('ok'),
            handler: (selected) => {
              const date = new Date(selected.year.value, selected.month.value, selected.day.value);
              field.onChange(date);
            },
          },
          {
            text: t('cancel'),
            handler: () => {
              setIsOpen(false);
              dismiss();
            },
          },
        ],
        columns: [
          {
            name: 'day',
            selectedIndex: getDayIndex(field.value) - 1,
            options: [...Array(31)].map((value, i) => {
              return { text: `${i + 1}`, value: i + 1 };
            }),
          },
          {
            name: 'month',
            selectedIndex: getMonthIndex(field.value),
            options: months.map((m, i) => {
              return { text: t(`months.${m}`), value: i };
            }),
          },
          {
            name: 'year',
            selectedIndex: years.findIndex((y) => y === getYear(field.value)),
            options: years.map((year) => {
              return { text: `${year}`, value: year };
            }),
          },
        ],
      });
    }
  };

  const getDisplayValue = (value: Date, type?: any): string => {
    if (value) {
      const x = format(value, t('dateFormat'));
      return x;
    } else {
      return format(new Date(), t('dateFormat'));
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={'form-field'}>
          <button
            onBlur={field.onBlur}
            onClick={(e) => handlePresentDatePicker(e, field)}
            className={styles.dropdownWrapper}
          >
            <div className={styles.dateFieldWrapper}>
              <Text
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                tag={'span'}
                type={TEXT_TYPE.TEXT_TYPE_FOOTER}
                className={styles.dateFieldWrapperLabel}
              >
                {label} {label && required && '*'}
              </Text>
              {getDisplayValue(field.value, 'd')}
            </div>
          </button>
          {!hideErrorMessage && <FormErrorMessage error={error} />}
        </div>
      )}
    />
  );
};

export default MobileDateInput;
