import TabBarItem from '../../atoms/TabBarItem';
import styles from './TabBarMenu.module.scss';
import { FC } from 'react';
import { IMenuItemProps } from '../../../core/const/menu-items';

export interface ITabBarMenuItemProps {
  tabBarMenuItemList: IMenuItemProps[];
}

const TabBarMenu: FC<ITabBarMenuItemProps> = ({ tabBarMenuItemList }) => {
  return (
    <div className={styles.footerWrapper}>
      {tabBarMenuItemList.map((menuItem, i) => (
        <TabBarItem
          key={`${menuItem.label}-${i}-${menuItem.icon.iconName}`}
          label={menuItem.label}
          icon={menuItem.icon}
          iconActive={menuItem.iconActive}
          link={menuItem.link}
          notifications={menuItem.notifications}
          shouldOpenModal={menuItem.shouldOpenModal}
        />
      ))}
    </div>
  );
};
export default TabBarMenu;
