import { FC } from 'react';
import { IApartmentCost } from '@wohnsinn/ws-ts-lib';
import ItemList, { TItemListItem } from '../ItemList';
import { useTranslation } from 'react-i18next';

export interface IApartmentCostsProps {
  apartmentCosts?: IApartmentCost;
  kitchenPrice?: number;
  parkingSpotRent?: number;
}

const ApartmentCosts: FC<IApartmentCostsProps> = ({ apartmentCosts, kitchenPrice, parkingSpotRent }) => {
  const { t } = useTranslation('common');

  const items: TItemListItem[] = [];

  if (kitchenPrice) {
    items.push({ key: 'kitchenPrice', label: t('apartment.cost.kitchenPrice.label'), value: `${kitchenPrice} €` });
  }
  if (parkingSpotRent) {
    items.push({
      key: 'parkingSpotRent',
      label: t('apartment.cost.parkingSpotRent.label'),
      value: `${parkingSpotRent} €`,
    });
  }

  if (apartmentCosts) {
    if (apartmentCosts.coldRent) {
      items.push({
        key: 'coldRent',
        label: t('apartment.cost.coldRent.label'),
        value: `${apartmentCosts.coldRent} €`,
      });
    }

    if (apartmentCosts.warmRent) {
      items.push({ key: 'warmRent', label: t('apartment.cost.warmRent.label'), value: `${apartmentCosts.warmRent} €` });
    }
    if (apartmentCosts.deposit) {
      items.push({ key: 'deposit', label: t('apartment.cost.deposit.label'), value: `${apartmentCosts.deposit}` });
    }
    if (apartmentCosts.coldRent) {
      items.push({
        key: 'operationalCosts',
        label: t('apartment.cost.operationalCost.label'),
        value: `${apartmentCosts.operationalCosts} €`,
      });
    }

    if (apartmentCosts.heatingCosts) {
      items.push({
        key: 'heatingCosts',
        label: t('apartment.cost.heatingCosts'),
        value: `${apartmentCosts.heatingCosts} €`,
      });
    }

    if (apartmentCosts.isHeatingIncluded) {
      items.push({ key: 'isHeatingIncluded', label: t('apartment.cost.isHeatingIncluded.short'), value: t('yes') });
    } else {
      items.push({ key: 'isHeatingIncluded', label: t('apartment.cost.isHeatingIncluded.short'), value: t('no') });
    }
  }

  return <ItemList testId={'apartmentCost'} items={items} />;
};

export default ApartmentCosts;
