import FormHeader from '../../../../atoms/FormHeader';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import { FormContext } from '../../../../../core/context/form.context';
import Dropdown from '../../../../atoms/formElement/Dropdown';
import InputText from '../../../../atoms/formElement/InputText';
import CTACard from '../../../../molecules/Cards/CTACard';
import { useContext, useState } from 'react';
import UserContext from '../../../../../core/context/user.context';
import useToast from '../../../../../core/hook/toast.hook';
import { Trans, useTranslation } from 'react-i18next';
import { ISelectOption, MEDIA_TYPE, OPEN_IMMO_SOFTWARES, TOpenImmoVideo } from '@wohnsinn/ws-ts-lib';
import { useForm } from 'react-hook-form';
import { IAccordionItem } from '../../../../atoms/Accordion';
import LightShow from '../../../../molecules/LightShow';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import SplitLine from '../../../../atoms/SplitLine';
import Text, { TEXT_TYPE, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import styles from './LandlordInterfacesForm.module.scss';
import { IonRow } from '@ionic/react';

const LandlordInterfacesForm = () => {
  const { landlordProfile, userService } = useContext(UserContext);
  const FTPInformation = landlordProfile?.FTPInformation;
  const credentials = landlordProfile?.FTPInformation?.credentials;
  const { sendSuccessToast, sendWarningToast } = useToast();
  const { t } = useTranslation('common');
  const { t: o } = useTranslation('common', { keyPrefix: 'view.AccountView.OpenImmoView' });
  const [showCustomSoftware, setShowCustomSoftware] = useState<boolean>(false);
  const [selectedSoftware, setSelectedSoftware] = useState<OPEN_IMMO_SOFTWARES>(null);
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [introductionVideo, setIntroductionVideo] = useState<TOpenImmoVideo>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const OPENIMMO_SOFTWARE_OPTION_LIST: ISelectOption<OPEN_IMMO_SOFTWARES>[] = [
    { value: OPEN_IMMO_SOFTWARES.FLOWFACT_PERFORMER, label: 'Flowfact Performer' },
    { value: OPEN_IMMO_SOFTWARES.FLOWFACT, label: 'Flowfact' },
    { value: OPEN_IMMO_SOFTWARES.ONOFFICE, label: 'onOffice' },
    { value: OPEN_IMMO_SOFTWARES.PROPSTACK, label: 'Propstack' },
    { value: OPEN_IMMO_SOFTWARES.OTHER, label: 'Eigene Angabe' },
  ];

  const { control, getValues } = useForm<{
    software: OPEN_IMMO_SOFTWARES;
    custom?: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const handleSoftware = (software: OPEN_IMMO_SOFTWARES): void => {
    setSelectedSoftware(software);
    switch (software) {
      case OPEN_IMMO_SOFTWARES.OTHER:
        setShowCustomSoftware(true);
        break;
      default:
        setShowCustomSoftware(false);
        break;
    }
  };

  const requestFTPData = async (): Promise<void> => {
    const values = getValues();

    if (!values.software || (values.software === OPEN_IMMO_SOFTWARES.OTHER && !values.custom)) {
      await sendWarningToast('view.AccountView.OpenImmoView.toast.emptyField');
    }

    if (values.software !== OPEN_IMMO_SOFTWARES.OTHER && values.custom) {
      delete values.custom;
    }

    try {
      await userService.requestFTPData(landlordProfile.email, landlordProfile.uid, values);
      await sendSuccessToast('view.AccountView.OpenImmoView.toast.requestSuccessful');
    } catch (err) {
      console.error('Error requesting FTP Data', err);
      await sendWarningToast('view.AccountView.OpenImmoView.toast.requestFailed');
    }
  };

  const handleVideoClick = (videoUrl: string) => {
    setIntroductionVideo({
      alt: 'alt',
      url: videoUrl,
      mediaType: MEDIA_TYPE.VIDEO,
    });
    setIsLightShowOpen(true);
  };

  const infoAccordionItems: IAccordionItem[] = [
    {
      label: o('introductionSection.accordion.flowfactPerformer.title'),
      content: (
        <Trans
          i18nKey={o('introductionSection.accordion.flowfactPerformer.text')}
          components={{
            link1: (
              <a
                onClick={() => handleVideoClick(o('introductionSection.accordion.flowfactPerformer.url'))}
                title={o('introductionSection.accordion.flowfactPerformer.title')}
                target={'_blank'}
                rel={'noreferrer noopener'}
              />
            ),
          }}
        />
      ),
    },
    {
      label: (
        <img
          src={o('introductionSection.accordion.flowfact.logo')}
          alt={o('introductionSection.accordion.flowfact.title')}
        />
      ),
      content: (
        <Trans
          i18nKey={o('introductionSection.accordion.flowfact.text')}
          components={{
            link1: (
              <a
                onClick={() => handleVideoClick(o('introductionSection.accordion.flowfact.url'))}
                title={o('introductionSection.accordion.flowfact.title')}
                target={'_blank'}
                rel={'noreferrer noopener'}
              />
            ),
          }}
        />
      ),
    },
    {
      label: (
        <img
          src={o('introductionSection.accordion.onOffice.logo')}
          alt={o('introductionSection.accordion.onOffice.title')}
        />
      ),
      content: (
        <Trans
          i18nKey={o('introductionSection.accordion.onOffice.text')}
          components={{
            link1: (
              <a
                onClick={() => handleVideoClick(o('introductionSection.accordion.onOffice.url'))}
                title={o('introductionSection.accordion.onOffice.title')}
                target={'_blank'}
                rel={'noreferrer noopener'}
              />
            ),
          }}
        />
      ),
    },
    {
      label: (
        <img
          src={o('introductionSection.accordion.propstack.logo')}
          alt={o('introductionSection.accordion.propstack.title')}
        />
      ),
      content: (
        <Trans
          i18nKey={o('introductionSection.accordion.propstack.text')}
          components={{
            link1: (
              <a
                onClick={() => handleVideoClick(o('introductionSection.accordion.propstack.url'))}
                title={o('introductionSection.accordion.propstack.title')}
                target={'_blank'}
                rel={'noreferrer noopener'}
              />
            ),
          }}
        />
      ),
    },
  ];

  const copyToClipboard = (id: string): void => {
    const text = document.getElementById(id).innerHTML;
    navigator.clipboard.writeText(text).then(() => sendSuccessToast('view.AccountView.OpenImmoView.toast.copied'));
  };

  return (
    <div>
      {introductionVideo ? (
        <LightShow open={isLightShowOpen} onClose={() => setIsLightShowOpen(false)} media={[introductionVideo]} />
      ) : null}
      <FormHeader
        title={'OpenImmo Schnittstelle'}
        subtitle={'Lade deine Objekte einfach per Schnittstelle in unser Portal.'}
      />
      <SplitLine />

      {!FTPInformation?.isRequested && !FTPInformation?.isApproved ? (
        <>
          <FormSectionTitle
            title={'Zugangsdaten beantragen'}
            subtitle={'Beantrage deine Zugangsdaten und übertrage Objekte einfach über deine Software.'}
          />
          <FormContext.Provider value={{ control }}>
            <form>
              <Dropdown
                label={o('accessDataSection.label')}
                name={'software'}
                optionList={OPENIMMO_SOFTWARE_OPTION_LIST}
                onChange={(e: any) => handleSoftware(e)}
                required
              />
              {showCustomSoftware && <InputText label={'Andere Software'} name={'custom'} />}
            </form>
            <CTAButton disabled={!selectedSoftware} buttonText={'Jetzt beantragen'} onClick={() => requestFTPData()} />
          </FormContext.Provider>
        </>
      ) : null}

      {FTPInformation?.isRequested && !FTPInformation?.isApproved ? (
        <IonRow>
          <CTACard
            title={o('accessDataSection.requestedCard.title')}
            text={o('accessDataSection.requestedCard.text')}
            imgSrc={t('pictogram.check')}
            imgAltText={o('accessDataSection.requestedCard.title')}
          />
        </IonRow>
      ) : null}

      {FTPInformation?.isRequested && FTPInformation?.isApproved ? (
        <>
          <FormSectionTitle title={o('accessDataSection.providerNumber')} />
          <div className={styles.copyButtonWrapper}>
            <Text tag={'span'} id={'provider'} type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
              {credentials?.providerNumber}
            </Text>
            <button className={styles.button} type={'button'} onClick={() => copyToClipboard('provider')}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>

          <FormSectionTitle title={o('accessDataSection.server')} />
          <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
            {credentials?.server}
          </Text>

          <FormSectionTitle title={o('accessDataSection.port')} />
          <Text type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
            {credentials?.port}
          </Text>

          <FormSectionTitle title={o('accessDataSection.password')} />
          <div>
            <span id={'pass'} className={'hidden'}>
              {credentials?.password}
            </span>
            <div className={styles.copyButtonWrapper}>
              <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                {showPassword ? credentials?.password : '******************'}
              </Text>
              <button className={styles.button} type={'button'} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
              </button>
              <button className={styles.button} type={'button'} onClick={() => copyToClipboard('pass')}>
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </div>
          </div>

          <Text>
            <Trans
              i18nKey={o('accessDataSection.sendEmailText')}
              components={{
                link1: <a href={t('component.atoms.GetInTouch.hrefMail')} rel={'noreferrer noopener'} />,
              }}
            />
          </Text>
        </>
      ) : null}

      {/*<SplitLine />*/}

      {/*<FormSectionTitle title={'Anleitung für deine Immobiliensoftware'} />*/}
      {/*<Accordion items={infoAccordionItems}></Accordion>*/}
    </div>
  );
};

export default LandlordInterfacesForm;
