import { FirebaseFunctionsService } from './firebase-functions-service';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import { HttpsCallableResult } from 'firebase/functions';

class TwilioService {
  constructor(private firebaseFunctionService: FirebaseFunctionsService) {}

  public requestVerificationCode(phoneNumber: string): Promise<HttpsCallableResult> {
    return this.firebaseFunctionService.callFbFunction<{ body: { phoneNumber: string } }, void>(
      FB_FUNCTION_URLS.messaging.sms.triggerVerificationCode,
      {
        body: { phoneNumber },
      }
    );
  }

  public verifyVerificationCode(phoneNumber: string, codeInput: number): Promise<HttpsCallableResult> {
    return this.firebaseFunctionService.callFbFunction<{ body: { phoneNumber: string; codeInput: number } }, void>(
      FB_FUNCTION_URLS.messaging.sms.verifyVerificationCode,
      {
        body: { phoneNumber, codeInput },
      }
    );
  }
}

export default TwilioService;
