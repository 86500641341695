import i18next, { InitOptions } from 'i18next';
import common_de from './common.de.json';

export const I18N_NEXT_CONFIG: InitOptions = {
  interpolation: { escapeValue: false },
  lng: 'de',
  debug: false,
  resources: {
    de: {
      common: common_de,
    },
  },
};

i18next.init(I18N_NEXT_CONFIG);

export default i18next;
