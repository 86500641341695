import styles from './Badge.module.scss';
import { IonBadge } from '@ionic/react';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../typographie/Text';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum BADGE_COLORS {
  PRIMARY = 'PRIMARY',
  DANGER = 'DANGER',
  LIGHT = 'LIGHT',
  DARK_TRANSPARENCY = 'DARK_TRANSPARENCY',
  RED = 'RED',
  DARK = 'DARK',
  SUCCESS = 'SUCCESS',
  DISABLED = 'DISABLED',
}

export enum BADGE_SIZE {
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
}

export interface IBadgeProps {
  text: string;
  color?: BADGE_COLORS;
  textColor?: TEXT_COLOR;
  softenRadius?: boolean;
  textWeight?: TEXT_WEIGHT;
  textType?: TEXT_TYPE;
  icon?: IconProp;
  action?: () => any;
}

const Badge: FC<IBadgeProps> = ({
  text,
  color = BADGE_COLORS.SUCCESS,
  textColor = TEXT_COLOR.TEXT_COLOR_DARK,
  softenRadius = false,
  textWeight = TEXT_WEIGHT.TEXT_WEIGHT_LIGHT,
  textType = TEXT_TYPE.TEXT_TYPE_CAPTION,
  icon,
  action,
}) => {
  return (
    <IonBadge className={`${styles.badge} ${styles[color]} ${!softenRadius ? styles.softenRadius : ''}`}>
      {icon && action ? <FontAwesomeIcon className={styles.icon} icon={icon} onClick={action} /> : null}
      <Text color={textColor} weight={textWeight} type={textType} tag={'span'}>
        {text}
      </Text>
    </IonBadge>
  );
};

export default Badge;
