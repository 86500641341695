import styles from './ApartmentListLoadingView.module.scss';
const ApartmentListLoadingView = () => {
  return (
    <div className={styles.view}>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
      <div className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentListLoadingView;
