import { createContext } from 'react';
import { MODAL_IDENTIFIER } from '../enum/modals.enum';
import { IOpenModalProps } from '../hook/modal.hook';

export interface IModalContextProps<T = any> {
  openModal: <T = any>(props: IOpenModalProps) => void;
  closeModal: () => void;
  modalData: T;
  currentModalId: MODAL_IDENTIFIER;
  onDismiss: () => void;
  allowBackdropDismiss: boolean;
}

const ModalContext = createContext<IModalContextProps>({
  openModal: null,
  closeModal: null,
  currentModalId: null,
  modalData: null,
  onDismiss: null,
  allowBackdropDismiss: null,
});

export default ModalContext;
