import { FC, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../../../component/organisms/PageLayout';
import useApartment from '../../../../core/hook/apartment.hook';
import { ROUTES } from '../../../../core/const/routes';
import { IonCol, useIonRouter } from '@ionic/react';
import UserContext from '../../../../core/context/user.context';
import PageTitle from '../../../../component/molecules/PageTitle';
import Section from '../../../../component/atoms/Section';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import ModalContext from '../../../../core/context/modal.context';
import useToast from '../../../../core/hook/toast.hook';
import FormTabs, { IFormTab } from '../../../../component/organisms/FormTabs';
import ApartmentMainInformationForm from '../../../../component/organisms/forms/apartment/ApartmentMainInformationForm';
import ApartmentCostsForm from '../../../../component/organisms/forms/apartment/ApartmentCostsForm';
import ApartmentEnergySupplyForm from '../../../../component/organisms/forms/apartment/ApartmentEnergySupplyForm';
import {
  faAddressBook,
  faEye,
  faPause,
  faArrowUpFromBracket,
  faExclamationCircle,
  faEuroSign,
  faLightbulb,
  faPeople,
  faGarage,
  faCamera,
  faCube,
  faAddressCard,
  faCircleInfo,
} from '@fortawesome/pro-light-svg-icons';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import ApartmentDesiredTenantForm from '../../../../component/organisms/forms/apartment/ApartmentDesiredTenantForm';
import ApartmentEquipmentForm from '../../../../component/organisms/forms/apartment/ApartmentEquipmentForm';
import ApartmentMediaForm from '../../../../component/organisms/forms/apartment/ApartmentMediaForm';
import ApartmentContactPersonsForm from '../../../../component/organisms/forms/apartment/ApartmentContactPersonsForm';
import { BADGE_COLORS } from '../../../../component/atoms/Badge';
import ApartmentVirtualTourForm from '../../../../component/organisms/forms/apartment/ApartmentVirtualTourForm';
import CTAButton, { BUTTON_STYLE } from '../../../../component/atoms/Buttons/CTAButton';
import styles from './ApartmentOverviewView.module.scss';
import scrollTo from '../../../../core/helper/scroll-to';

enum APARTMENT_FORMS {
  MAIN_INFORMATIONS = 'MAIN_INFORMATIONS',
  ENERGY_SUPPLY = 'ENERGY_SUPPLY',
  COSTS = 'COSTS',
  DESIRED_TENANT = 'DESIRED_TENANT',
  EQUIPMENT = 'EQUIPMENT',
  MEDIA = 'MEDIA',
  CONTACT_PERSONS = 'CONTACT_PERSONS',
  VIRTUAL_TOUR = 'VIRTUAL_TOUR',
}

const ApartmentOverviewView: FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const { user, landlordProfile } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const { apartment } = useApartment();
  const router = useIonRouter();
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApartmentOverviewView' });
  const { sendSuccessToast } = useToast();
  const [activeTab, setActiveTab] = useState<APARTMENT_FORMS>(APARTMENT_FORMS.MAIN_INFORMATIONS);

  const onShareLinkClick = async () => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}${t(ROUTES.tenant.shareApartment.path).replace(':apartmentId', apartment.id)}`
    );
    await sendSuccessToast(t('toast.copyLink'));
  };

  if (!apartment) {
    return <div>loading auf der ApartmentOverviewView</div>;
  }

  const handleApartmentPublish = async (): Promise<void> => {
    if (user?.isPhoneNumberVerified) {
      openModal({ id: MODAL_IDENTIFIER.PUBLISH_APARTMENT, data: { apartment } });
    } else {
      router.push(t(ROUTES.landlord.apartment.publish.path).replace(':apartmentId', apartment.id));
    }
  };

  const FORM_TABS: IFormTab[] = [
    {
      icon: faCircleInfo,
      label: 'Stamminformationen',
      value: APARTMENT_FORMS.MAIN_INFORMATIONS,
      status: !!apartment.mainInformation ? null : faExclamationCircle,
    },
    {
      icon: faEuroSign,
      label: 'Kosten',
      value: APARTMENT_FORMS.COSTS,
      status: !!apartment.cost ? null : faExclamationCircle,
    },
    {
      icon: faLightbulb,
      label: 'Energieversorgung',
      value: APARTMENT_FORMS.ENERGY_SUPPLY,
      status: !!apartment.energySupply ? null : faExclamationCircle,
    },
    {
      icon: faPeople,
      label: 'Wunschmieter:innen',
      value: APARTMENT_FORMS.DESIRED_TENANT,
      status: !!apartment.desiredTenant ? null : faExclamationCircle,
    },
    {
      icon: faGarage,
      label: 'Ausstattung',
      value: APARTMENT_FORMS.EQUIPMENT,
      status: !!apartment.equipment ? null : faExclamationCircle,
    },
    {
      icon: faCamera,
      label: 'Bilder & Videos',
      value: APARTMENT_FORMS.MEDIA,
      status: !!apartment?.media?.length ? null : faExclamationCircle,
    },
    {
      icon: faCube,
      label: '3D-Tour',
      value: APARTMENT_FORMS.VIRTUAL_TOUR,
      status: !!apartment.matterportId ? null : faExclamationCircle,
    },
  ];

  if (landlordProfile?.isOrganizationMembershipConfirmed) {
    FORM_TABS.push({ icon: faAddressCard, label: 'Ansprechpartner:in', value: APARTMENT_FORMS.CONTACT_PERSONS });
  }

  const renderForms = () => {
    switch (activeTab) {
      case APARTMENT_FORMS.MAIN_INFORMATIONS:
        return <ApartmentMainInformationForm scrollToTop={() => scrollTo(contentRef)} apartment={apartment} />;
      case APARTMENT_FORMS.COSTS:
        return <ApartmentCostsForm scrollToTop={() => scrollTo(contentRef)} apartment={apartment} />;
      case APARTMENT_FORMS.ENERGY_SUPPLY:
        return <ApartmentEnergySupplyForm scrollToTop={() => scrollTo(contentRef)} apartment={apartment} />;
      case APARTMENT_FORMS.DESIRED_TENANT:
        return <ApartmentDesiredTenantForm scrollToTop={() => scrollTo(contentRef)} apartment={apartment} />;
      case APARTMENT_FORMS.EQUIPMENT:
        return <ApartmentEquipmentForm scrollToTop={() => scrollTo(contentRef)} apartment={apartment} />;
      case APARTMENT_FORMS.MEDIA:
        return <ApartmentMediaForm apartment={apartment} />;
      case APARTMENT_FORMS.CONTACT_PERSONS:
        return <ApartmentContactPersonsForm apartment={apartment} />;
      case APARTMENT_FORMS.VIRTUAL_TOUR:
        return <ApartmentVirtualTourForm apartment={apartment} />;
      default:
        return <ApartmentMainInformationForm scrollToTop={() => scrollTo(contentRef)} apartment={apartment} />;
    }
  };

  return (
    <PageLayout contentRef={contentRef} pageTitle={t(ROUTES.landlord.apartment.overview.title)} showBackButton>
      <Section>
        <IonCol>
          <PageTitle
            showSubmitButton={false}
            pageTitle={`${apartment?.mainInformation?.address.street} ${apartment?.mainInformation?.address.houseNumber}`}
            secondPageTitle={`${apartment?.mainInformation?.address?.postalCode} ${apartment?.mainInformation?.address?.city}`}
            badgeText={apartment.isPublished ? a('active') : a('inActive')}
            badgeColor={apartment.isPublished ? BADGE_COLORS.SUCCESS : BADGE_COLORS.DANGER}
          >
            <div className={styles.ctaBar}>
              <CTAButton
                disabled={!user.isEmailVerified}
                expandMobile={true}
                buttonText={apartment.isPublished ? t('unPublishApartment') : t('publishApartment')}
                onClick={handleApartmentPublish}
                icon={apartment.isPublished ? faPause : faPlay}
                buttonStyle={apartment.isPublished ? BUTTON_STYLE.DANGER_INVERTED : BUTTON_STYLE.PRIMARY}
                size={'small'}
              />

              {apartment?.applicationRefList?.length ? (
                <CTAButton
                  expandMobile={true}
                  buttonText={a('goToApplications')}
                  link={`${t(ROUTES.landlord.application.overview.path)}/`.replace(':apartmentId', apartment.id)}
                  icon={faAddressBook}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  size={'small'}
                />
              ) : null}

              <CTAButton
                expandMobile={true}
                buttonText={a('preview')}
                buttonStyle={BUTTON_STYLE.SECONDARY}
                size={'small'}
                icon={faEye}
                link={t(ROUTES.landlord.apartment.preview.path).replace(':apartmentId', apartment.id)}
              />

              {apartment?.isPublished && (
                <CTAButton
                  expandMobile={true}
                  buttonText={'Inserat teilen'}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  size={'small'}
                  icon={faArrowUpFromBracket}
                  onClick={onShareLinkClick}
                />
              )}
            </div>
          </PageTitle>
        </IonCol>
      </Section>

      <FormTabs tabs={FORM_TABS} activeTab={activeTab} setActiveTab={setActiveTab}>
        {renderForms()}
      </FormTabs>
    </PageLayout>
  );
};

export default ApartmentOverviewView;
