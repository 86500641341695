import styles from './ApplicantInformationModal.module.scss';
import { Dispatch, FC, SetStateAction } from 'react';

import ApplicantInformationSection from '../ApplicantInformationSection';
import ApplicantInformationHeader from '../ApplicantInformationHeader';
import LandlordRatingButtons from '../../../molecules/LandlordRatingButtons';
import ApplicantInformationUserInfo from '../ApplicantInformationUserInfo';
import ApplicantModalWrapper from '../ApplicantModalWrapper';
import useApplication from '../../../../core/hook/application.hook';

const ApplicantInformationModal: FC<{
  apartmentId: string;
  showApplicantInformation: boolean;
  setShowApplicantInformation: Dispatch<SetStateAction<boolean>>;
  tenantId: string;
  landlordId: string;
}> = ({ setShowApplicantInformation, showApplicantInformation, tenantId, landlordId, apartmentId }) => {
  const { applicationList, loadingApplication } = useApplication(apartmentId, tenantId, landlordId);

  if (loadingApplication) {
    return <div>lädt application daten</div>;
  }

  return (
    <>
      <div className={`${styles.ratingButtons} ${showApplicantInformation ? styles.show : ''}`}>
        <LandlordRatingButtons
          big
          landlordRating={applicationList[0].landlordRating}
          tenantProfileId={applicationList[0].tenantProfile.uid}
        />
      </div>
      <ApplicantModalWrapper setShowModal={setShowApplicantInformation} showModal={showApplicantInformation}>
        <div className={styles.wrapper}>
          <div className={styles.noPrint}>
            <ApplicantInformationHeader closeAction={() => setShowApplicantInformation(false)} />
          </div>

          <div className={styles.content}>
            <ApplicantInformationUserInfo tenantProfile={applicationList[0].tenantProfile} />
            <ApplicantInformationSection tenantProfile={applicationList[0].tenantProfile} />
          </div>
        </div>
      </ApplicantModalWrapper>
    </>
  );
};

export default ApplicantInformationModal;
