import SwiperViewLayout from '../../../component/organisms/SwiperTunnel/SwiperViewLayout';
import LandlordRegistrationTunnel from '../../../component/organisms/SwiperTunnel/LandlordRegistrationTunnel';

const RegistrationTunnelView = () => {
  return (
    <SwiperViewLayout>
      <LandlordRegistrationTunnel />
    </SwiperViewLayout>
  );
};

export default RegistrationTunnelView;
