import ModalWrapper from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../../atoms/typographie/Text';
import CTAButton from '../../../../atoms/Buttons/CTAButton';
import Spacer from '../../../../atoms/Spacer';
import ModalContext from '../../../../../core/context/modal.context';
import { useContext, useEffect } from 'react';
import { MODAL_IDENTIFIER } from '../../../../../core/enum/modals.enum';
import LOCAL_STORAGE_KEYS from '../../../../../core/enum/local-storage.enum';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import UserContext from '../../../../../core/context/user.context';
import useToast from '../../../../../core/hook/toast.hook';

const EmailVerificationModal = () => {
  const { openModal, closeModal } = useContext(ModalContext);
  const { user, authService, activeUserType, landlordProfile, tenantProfile } = useContext(UserContext);
  const { sendInfoToast } = useToast();
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.signIn.EmailVerificationModal' });
  const email = localStorage.getItem('userC');

  const handleSubmit = async () => {
    const userType: USER_TYPE = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TYPE) as USER_TYPE;
    const hasCompletedSelfDisclosure = tenantProfile?.household && tenantProfile?.personalInformation;

    if (userType === USER_TYPE.LANDLORD || hasCompletedSelfDisclosure) {
      closeModal();
    } else {
      openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
    }
  };

  const resendVerificationMail = async (): Promise<void> => {
    const email: string = activeUserType === USER_TYPE.LANDLORD ? landlordProfile.email : tenantProfile.email;
    try {
      await authService.triggerSendVerificationLink(email, activeUserType);
      await sendInfoToast('toast.resendEmailVerificationMail');
    } catch (e) {
      console.error('Error on resendVerificationMail', e);
    }
  };

  useEffect(() => {
    if (user?.isEmailVerified) {
      handleSubmit();
    }
  }, [user]);

  return (
    <ModalWrapper title={m('title')} icon={t('pictogram.mail')}>
      <Text align={'center'}>{m('text', { replace: { email } })}</Text>
      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} align={'center'}>
        {m('subText')}
        <button onClick={resendVerificationMail}>
          <Text
            color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
          >
            {m('sendNew')}
          </Text>
        </button>
      </Text>
      <Spacer />
      <CTAButton expand={'block'} buttonText={t('skip')} onClick={handleSubmit} />
    </ModalWrapper>
  );
};

export default EmailVerificationModal;
