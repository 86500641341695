import { FC } from 'react';
import styles from './LinkElement.module.scss';
import { IonItem, IonLabel } from '@ionic/react';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ILinkElementProps {
  text: string;
  routerLink?: string;
  testId?: string;
  icon?: IconDefinition;
  iconText?: string;
  color?: 'red' | 'blue';
  onClick?: () => any;
  showStatus?: boolean;
}

const LinkElement: FC<ILinkElementProps> = ({
  text,
  routerLink,
  testId,
  iconText,
  icon,
  color,
  onClick,
  showStatus = true,
}) => {
  return (
    <IonItem
      lines={'none'}
      className={styles.linkElement}
      routerLink={routerLink}
      onClick={onClick}
      data-testid={testId}
    >
      <IonLabel className={styles.label}>{text}</IonLabel>
      {showStatus && (
        <>
          {/*<span>{iconText ? iconText : null}</span>*/}
          <span id="hover-trigger" slot={'end'} className={`${styles.icon} ${styles[color]}`}>
            {icon ? <FontAwesomeIcon color={!color ? 'black' : 'white'} icon={icon} /> : null}
          </span>
        </>
      )}
    </IonItem>
  );
};

export default LinkElement;
