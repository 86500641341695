import { getApps, initializeApp } from 'firebase/app';
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
  httpsCallableFromURL,
  HttpsCallableResult,
} from 'firebase/functions';
import FIREBASE_OPTIONS from '../../firebase.config';

export class FirebaseFunctionsService {
  private functions: Functions;

  constructor() {
    if (getApps().length === 0) {
      this.functions = getFunctions(initializeApp(FIREBASE_OPTIONS), 'europe-west4');
    } else {
      this.functions = getFunctions(getApps()[0], 'europe-west4');
    }

    if (process.env.REACT_APP_USE_EMULATOR === 'true' && process.env.REACT_APP_ENVIRONMENT === 'qas') {
      connectFunctionsEmulator(this.functions, process.env.REACT_APP_EMULATOR_HOST, 5001);
    }
  }

  public callFbFunction<REQ = any, RES = any>(functionUrl: string, params: REQ): Promise<HttpsCallableResult<RES>> {
    const executableFn = httpsCallableFromURL<REQ, RES>(this.functions, functionUrl);

    return executableFn(params);
  }
}
