import { FC, useContext, useEffect, useState } from 'react';
import styles from './DocumentUploadItem.module.scss';
import { faDownload, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FILE_UPLOAD_STATUS, IUpload, TDocumentUpload } from '@wohnsinn/ws-ts-lib';
import { UPLOAD_TYPE } from '../index';
import UserContext from '../../../../core/context/user.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonProgressBar } from '@ionic/react';
import StorageServiceContext from '../../../../core/context/storage-service.context';

const DocumentUploadItem: FC<{
  uploadItemData: IUpload;
  uploadType: UPLOAD_TYPE;
  onDocumentDelete?: (upload: IUpload, uploadType: UPLOAD_TYPE) => void;
  documentName?: string;
}> = ({ uploadItemData, uploadType, onDocumentDelete, documentName }) => {
  const { tenantProfile, userService } = useContext(UserContext);
  const { storageService } = useContext(StorageServiceContext);
  const [progress, setProgress] = useState<number>(100);

  useEffect(() => {
    uploadItemData?.uploadTask?.on(
      'state_changed',
      () => {
        setProgress(
          parseInt(
            (
              (uploadItemData.uploadTask.snapshot.bytesTransferred / uploadItemData.uploadTask.snapshot.totalBytes) *
              100
            ).toFixed(0)
          )
        );
      },
      async (err) => {
        uploadItemData.errors.push(err.message);
      },
      async () => {
        const uploadDocument: TDocumentUpload = {
          creatorId: uploadItemData.creatorId,
          extension: uploadItemData.extension,
          fileName: uploadItemData.fileName,
          url: uploadItemData.url,
          id: uploadItemData.id,
          alt: uploadItemData.fileName,
          mediaType: uploadItemData.mediaType,
          updatedAt: new Date(),
          uploadPath: uploadItemData.uploadPath,
        };

        switch (uploadType) {
          case UPLOAD_TYPE.INCOME_PROOF:
            return userService.addIncomeProofDocument(tenantProfile.uid, uploadDocument);

          case UPLOAD_TYPE.SCHUFA:
            return userService.addSchufaCheckDocument(tenantProfile.uid, uploadDocument);
        }
      }
    );
  }, [tenantProfile]);

  const deleteDocumentHandler = () => {
    switch (uploadType) {
      case UPLOAD_TYPE.INCOME_PROOF:
        return userService.deleteIncomeProofDocument(tenantProfile.uid, uploadItemData);
      case UPLOAD_TYPE.SCHUFA:
        return userService.deleteSchufaCheckDocument(tenantProfile.uid, uploadItemData);
      default:
        return;
    }
  };

  const downloadDocument = () => {
    const windowReference = window.open();
    storageService.getDownloadUrl(`${uploadItemData.uploadPath}`).then((href) => {
      windowReference.location = href;
    });
  };

  const cancelDocumentUpload = async () => {
    uploadItemData.uploadTask.cancel();
    onDocumentDelete(uploadItemData, uploadType);
  };

  return (
    <div className={styles.wrapper}>
      {progress < 100 ? (
        <IonProgressBar value={uploadItemData.status === FILE_UPLOAD_STATUS.CREATED ? progress : 100} />
      ) : null}

      <div className={styles.uploadItem}>
        <div className={styles.uploadItemContent}>
          <h4 className={styles.description}>{documentName ? documentName : 'Dokument'}</h4>
          <div className={styles.buttonWrapper}>
            {progress === 100 ? (
              <button
                onClick={() => downloadDocument()}
                type={'button'}
                className={styles.uploadActionButton}
                aria-label={`Button lädt herunter ${uploadItemData.alt}`}
              >
                <span className="sr-only">Herunterladen</span>
                <FontAwesomeIcon icon={faDownload} />
              </button>
            ) : (
              <button
                onClick={() => cancelDocumentUpload()}
                type={'button'}
                className={styles.uploadActionButton}
                aria-label={`Upload von ${uploadItemData.alt} abbrechen`}
              >
                <span>Abbrechen</span>
              </button>
            )}
            <button
              onClick={() => deleteDocumentHandler()}
              type={'button'}
              className={styles.uploadActionButton}
              aria-label={`Klick löscht ${uploadItemData.alt}`}
            >
              <span className="sr-only">Löschen</span>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentUploadItem;
