import ModalWrapper from '../ModalWrapper';
import HowToSlides from '../../../organisms/HowToSlides';
import { useTranslation } from 'react-i18next';

const HowToSlidesModal = () => {
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.HowToSlides' });
  return (
    <ModalWrapper canClose={false} title={t('title')}>
      <HowToSlides />
    </ModalWrapper>
  );
};

export default HowToSlidesModal;
