export enum MODAL_IDENTIFIER {
  ADD_NEW_ORGANIZATION = 'ADD_NEW_ORGANIZATION',
  COMPLETE_APPLICATION_FOLDER = 'COMPLETE_APPLICATION_FOLDER',
  CONFIRM_APPLICATION = 'CONFIRM_APPLICATION',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  CONTACT_MODAL = 'CONTACT_MODAL',
  CREATE_OR_UPLOAD_SCHUFA = 'CREATE_OR_UPLOAD_SCHUFA',
  CREATE_USER_INVITATION = 'CREATE_USER_INVITATION',
  CROP_IMAGE = 'CROP_IMAGE',
  DELETE_MODAL = 'DELETE_MODAL',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  FAQ = 'FAQ',
  FILTER_MODAL = 'FILTER_MODAL',
  FILTER_PERSONS = 'FILTER_PERSONS',
  FILTER_RENT = 'FILTER_RENT',
  FILTER_RESET = 'FILTER_RESET',
  FILTER_ROOMS = 'FILTER_ROOMS',
  HOW_TO_SLIDES = 'HOW_TO_SLIDES',
  INFO_TEXT = 'INFO_TEXT',
  MAP_MODAL = 'MAP_MODAL',
  MISSING_APPLICATION_FOLDER = 'MISSING_APPLICATION_FOLDER',
  NONE = 'NONE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PUBLISH_APARTMENT = 'PUBLISH_APARTMENT',
  REGISTER_USER = 'REGISTER_USER',
  FILTER_LOCATION = 'FILTER_LOCATION',
  SETTINGS = 'SETTINGS',
  SIGN_IN = 'SIGN_IN',
  VIRTUAL_TOUR = 'VIRTUAL_TOUR',
  YOUVE_BEEN_INVITED_MODAL = 'YOUVE_BEEN_INVITED_MODAL',
}
