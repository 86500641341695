import { useContext, useEffect, useState } from 'react';
import { IApplication, IMedia, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { useParams } from 'react-router';
import useApartment from '../../../../core/hook/apartment.hook';
import useLandlordApplicationListHook from '../../../../core/hook/landlord-application-list.hook';
import ApplicationServiceContext from '../../../../core/context/application-service.context';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { IonBreadcrumb, IonCol, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import PageTitle from '../../../../component/molecules/PageTitle';
import Breadcrumbs from '../../../../component/molecules/Breadcrumbs';
import Section from '../../../../component/atoms/Section';
import styles from './ApplicationOverviewView.module.scss';
import LightShow from '../../../../component/molecules/LightShow';
import ApplicantInformationModal from '../../../../component/organisms/ApplicantInformation/ApplicantInformationModal';
import { sortApplicationsByIncome } from './sort-applications-helper';
import ApplicantChatModal from '../../../../component/organisms/ApplicantInformation/ApplicantChatModal';
import InfoBox from '../../../../component/atoms/InfoBox';
import ApplicationOverviewTable from '../../../../component/organisms/Tables/ApplicationOverviewTable';
import ChatServiceContext from '../../../../core/context/chat-service.context';
import Text, { TEXT_VARIANT } from '../../../../component/atoms/typographie/Text';

export interface IRatedApplicantLists {
  ALL: IApplication[];
  INVITED: IApplication[];
  LIKE: IApplication[];
  MAYBE: IApplication[];
  NONE: IApplication[];
  NOPE: IApplication[];
}

const EMPTY_APPLICANT_LIST: IRatedApplicantLists = {
  ALL: [],
  INVITED: [],
  LIKE: [],
  MAYBE: [],
  NONE: [],
  NOPE: [],
};

const ApplicationOverviewView = () => {
  const { chatService } = useContext(ChatServiceContext);
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationOverviewView' });
  const { apartmentId } = useParams<{ apartmentId: string }>();
  const [selectedRating, setSelectedRating] = useState<MATCHING_MODE | 'ALL'>('ALL');
  const [sortedApplicantList, setSortedApplicantList] = useState<IRatedApplicantLists>(EMPTY_APPLICANT_LIST);
  const { apartment } = useApartment(apartmentId);
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [introductionVideo, setIntroductionVideo] = useState<IMedia>(null);
  const { applicationService } = useContext(ApplicationServiceContext);
  const [showApplicantInformation, setShowApplicantInformation] = useState(false);
  const [showApplicantChat, setShowApplicantChat] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const { applicationList: landlordApplicationHookApplicationList, isLoading } = useLandlordApplicationListHook({
    apartmentId,
  });

  useEffect(() => {
    if (apartment) {
      let applicationListData = landlordApplicationHookApplicationList;

      // SORT APPLICANTS BY DESIRED TENANTS OF THE APARTMENT
      // TODO: DO WE EVEN NEED THIS AT THIS POINT?
      const sortedApplications = applicationService.filterApplicationListByDesiredTenants(
        applicationListData,
        apartment
      );

      applicationListData = [...sortedApplications.desiredTenantApplications, ...sortedApplications.otherApplications];

      // SORT BY RATINGS
      const likeList = filterApplicantListByRating(applicationListData, MATCHING_MODE.LIKE);
      const maybeList = filterApplicantListByRating(applicationListData, MATCHING_MODE.MAYBE);
      const nopeList = filterApplicantListByRating(applicationListData, MATCHING_MODE.NOPE);
      const unsortedList = filterApplicantListByRating(applicationListData, MATCHING_MODE.NONE);

      const sortedApplicantList: IRatedApplicantLists = {
        ALL: applicationListData,
        INVITED: [],
        LIKE: likeList,
        MAYBE: maybeList,
        NONE: unsortedList,
        NOPE: nopeList,
      };

      if (isInitialRender) {
        setSelectedApplication(applicationListData[0]);
        setIsInitialRender(false);
      }
      setSortedApplicantList(sortedApplicantList);
    }
  }, [applicationService, apartment, landlordApplicationHookApplicationList]);

  const filterApplicantListByRating = (list: IApplication[], rating: MATCHING_MODE): IApplication[] => {
    return applicationService.filterApplicantListByRating(list, rating);
  };

  const handleApplicantInformationClick = (application: IApplication) => {
    setSelectedApplication(application);
    setShowApplicantInformation(true);
  };

  const handleClickOnChat = async (application: IApplication) => {
    setSelectedApplication(application);

    await chatService.clearUnreadTenantMessagesRefOnApplication(
      application.landlordId,
      application.apartmentId,
      application.tenantProfile.uid
    );

    await chatService.clearUnreadTenantChatsRefOnApartment(
      application.apartmentId,
      application.landlordId,
      application.tenantProfile.uid
    );

    setShowApplicantChat(true);
  };

  const handleIntroductionVideoClick = (video: IMedia) => {
    setIntroductionVideo(video);
    setIsLightShowOpen(true);
  };

  const sortApplicantByIncome = () => {
    const unsorted = sortApplicationsByIncome(sortedApplicantList[MATCHING_MODE.NONE], sortAsc);
    const like = sortApplicationsByIncome(sortedApplicantList[MATCHING_MODE.LIKE], sortAsc);
    const maybe = sortApplicationsByIncome(sortedApplicantList[MATCHING_MODE.MAYBE], sortAsc);
    const nope = sortApplicationsByIncome(sortedApplicantList[MATCHING_MODE.NOPE], sortAsc);
    const all = sortApplicationsByIncome(sortedApplicantList['ALL'], sortAsc);
    const invited: IApplication[] = [];
    setSortAsc(!sortAsc);

    const sortedLists = {
      ALL: all,
      NONE: unsorted,
      LIKE: like,
      MAYBE: maybe,
      NOPE: nope,
      INVITED: invited,
    };

    setSortedApplicantList({ ...sortedLists });
  };

  if (!apartment || isLoading) {
    return <div>{a('loading')}</div>;
  }

  return (
    <>
      <PageLayout hideTabBar={false} pageTitle={t(ROUTES.landlord.application.overview.title)} fullWidthPage>
        <div style={{ width: '90%', margin: '0 auto' }}>
          {introductionVideo ? (
            <LightShow open={isLightShowOpen} onClose={() => setIsLightShowOpen(false)} media={[introductionVideo]} />
          ) : null}

          <Breadcrumbs backRoute={t(ROUTES.landlord.apartment.list.path)}>
            <IonBreadcrumb routerLink={t(ROUTES.landlord.application.list.path)}>{t('applications')}</IonBreadcrumb>
            <IonBreadcrumb>{t(ROUTES.landlord.application.overview.title)}</IonBreadcrumb>
          </Breadcrumbs>

          <PageTitle
            showSubmitButton={false}
            pageTitle={`${apartment?.mainInformation?.address.street} ${apartment?.mainInformation?.address.houseNumber}`}
            secondPageTitle={`${apartment?.mainInformation?.address?.postalCode} ${apartment?.mainInformation?.address?.city}`}
          />
        </div>

        <div className={styles.overflowRow}>
          <Section>
            <IonCol size={'12'} sizeLg={'7'}>
              <IonSegment value={selectedRating}>
                <IonSegmentButton value={'ALL'} onClick={() => setSelectedRating('ALL')}>
                  <IonLabel>
                    <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>{`${a('all')} (${
                      sortedApplicantList['ALL'].length
                    })`}</Text>
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={MATCHING_MODE.LIKE} onClick={() => setSelectedRating(MATCHING_MODE.LIKE)}>
                  <IonLabel>
                    <Text className={styles.segmentLabel} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>{`${a(
                      'favorite'
                    )} (${sortedApplicantList[MATCHING_MODE.LIKE].length})`}</Text>
                    <img className={styles.segmentIcon} src={t('icons.heart_like')} alt={'Likes'} />
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={MATCHING_MODE.MAYBE} onClick={() => setSelectedRating(MATCHING_MODE.MAYBE)}>
                  <IonLabel>
                    <Text className={styles.segmentLabel} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>{`${a(
                      'interesting'
                    )} (${sortedApplicantList[MATCHING_MODE.MAYBE].length})`}</Text>
                    <img className={styles.segmentIcon} src={t('icons.heart_maybe')} alt={'Likes'} />
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={MATCHING_MODE.NOPE} onClick={() => setSelectedRating(MATCHING_MODE.NOPE)}>
                  <IonLabel>
                    <Text className={styles.segmentLabel} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
                      {`${a('uninteresting')} (${sortedApplicantList[MATCHING_MODE.NOPE].length})`}
                    </Text>
                    <img className={styles.segmentIcon} src={t('icons.heart_nope')} alt={'Likes'} />
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={MATCHING_MODE.NONE} onClick={() => setSelectedRating(MATCHING_MODE.NONE)}>
                  <IonLabel>
                    <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>{`${a('unsorted')} (${
                      sortedApplicantList[MATCHING_MODE.NONE].length
                    })`}</Text>
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
            <IonCol size={'12'}>
              <ApplicationOverviewTable
                handleIntroductionVideoClick={handleIntroductionVideoClick}
                handleApplicantInformationClick={handleApplicantInformationClick}
                handleClickOnChat={handleClickOnChat}
                setShowApplicantInformation={setShowApplicantInformation}
                selectedRating={selectedRating}
                sortedApplicantList={sortedApplicantList}
                sortAsc={sortAsc}
                sortApplicantByIncome={sortApplicantByIncome}
              />
              {!sortedApplicantList[selectedRating].length ? (
                <InfoBox
                  text={selectedRating === MATCHING_MODE.NONE ? a('noApplications') : a('noApplicationsInRating')}
                />
              ) : null}
            </IonCol>
          </Section>
        </div>
      </PageLayout>

      {selectedApplication ? (
        <ApplicantInformationModal
          apartmentId={apartmentId}
          tenantId={selectedApplication.tenantProfile.uid}
          landlordId={selectedApplication.landlordId}
          setShowApplicantInformation={setShowApplicantInformation}
          showApplicantInformation={showApplicantInformation}
        />
      ) : null}

      {selectedApplication ? (
        <ApplicantChatModal
          showApplicantChat={showApplicantChat}
          setShowApplicantChat={setShowApplicantChat}
          application={selectedApplication}
        />
      ) : null}
    </>
  );
};
export default ApplicationOverviewView;
