import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import ApplicationListContext from '../../../core/context/application-list.context';
import useTenantApplicationListHook from '../../../core/hook/tenant-application-list.hook';
import LOCAL_STORAGE_KEYS from '../../../core/enum/local-storage.enum';
import NotificationBadgesContext from '../../../core/context/notifications-badges-context';
import UserContext from '../../../core/context/user.context';
import mixpanel from 'mixpanel-browser';
import { useCookies } from 'react-cookie';
import usePaginatedApartmentsHook from '../../../core/hook/paginated-apartments.hook';
import PaginatedApartmentsContext from '../../../core/context/paginated-apartments.context';
const AppDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cookies] = useCookies();
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
    opt_out_tracking_by_default: true,
  });

  useEffect(() => {
    const isAnalyticsYesOnCookieYesCookie = cookies?.['cookieyes-consent']?.includes('analytics:yes');

    if (process.env.REACT_APP_USE_EMULATOR !== 'true') {
      if (window && document) {
        document.addEventListener('cookieyes_consent_update', async function (eventData) {
          // @ts-ignore
          const data: any = eventData?.detail;
          if (data?.accepted?.includes('analytics')) {
            mixpanel.opt_in_tracking();
          } else {
            mixpanel.opt_out_tracking();
            mixpanel.has_opted_out_tracking({
              cookie_prefix: 'has_opted_out_tracking: else analytics is included',
            });
          }
        });
        if (isAnalyticsYesOnCookieYesCookie) {
          mixpanel.opt_in_tracking();
          mixpanel.track('isTrackingActive', { isTrackingActive: 'yes' });
        }
      }
    }
  }, [window, document]);

  const { user } = useContext(UserContext);
  const applications = useTenantApplicationListHook();
  const parsedRatings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS));
  const [bookmarkNotifications, setBookmarkNotifications] = useState<number>(
    parsedRatings && parsedRatings['MAYBE'] ? parsedRatings['MAYBE'].length : 0
  );
  const { apartments, otherApartments, currentPage, setCurrentPage, pagesCount } = usePaginatedApartmentsHook();

  useEffect(() => {
    if (user?.uid) {
      mixpanel.identify(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (!user && !localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
      setBookmarkNotifications(0);
    }
  }, [user]);

  return (
    <NotificationBadgesContext.Provider value={{ bookmarkNotifications, setBookmarkNotifications }}>
      <PaginatedApartmentsContext.Provider
        value={{ apartments, otherApartments, currentPage, pagesCount, setCurrentPage }}
      >
        <ApplicationListContext.Provider
          value={{
            likeList: applications.applicationListLike,
            maybeList: applications.applicationListMaybe,
            nopeList: applications.applicationListNope,
            isLoading: applications.isLoading,
            unreadNotifications: applications.unreadChatNotifications,
          }}
        >
          {children}
        </ApplicationListContext.Provider>
      </PaginatedApartmentsContext.Provider>
    </NotificationBadgesContext.Provider>
  );
};

export default AppDataProvider;
