import PageLayout from '../../../component/organisms/PageLayout';
import { Helmet } from 'react-helmet-async';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const CompareWithCheck24View: FC = () => {
  const { t } = useTranslation('common');
  const { type } = useParams<{ type: 'gas' | 'power' | 'dsl' }>();

  return (
    <PageLayout showBackButton pageTitle={'Check 24 Vergleichsrechner'}>
      <Helmet>
        <script src={`https://files.check24.net/widgets/auto/215379/c24pp-${type}-iframe/${type}-iframe.js`} />
      </Helmet>
      <div style={{ width: '100%' }} id={`c24pp-${type}-iframe`} data-style={'Wohnsinn'} />
    </PageLayout>
  );
};

export default CompareWithCheck24View;
