import UserContext from '../../../../../../core/context/user.context';
import { useContext, useEffect, useState } from 'react';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import { useForm } from 'react-hook-form';
import LOCAL_STORAGE_KEYS from '../../../../../../core/enum/local-storage.enum';
import useToast from '../../../../../../core/hook/toast.hook';
import { FormContext } from '../../../../../../core/context/form.context';
import InputText from '../../../../../atoms/formElement/InputText';
import TunnelSlide from '../../../SlideLayouts/TunnelSlideLayout';
import styles from './EnterPhoneNumber.module.scss';
import TwilioServiceContext from '../../../../../../core/context/twilio-service.context';
import { useTranslation } from 'react-i18next';
import LoadingAnimation from '../../../../../atoms/LoadingAnimation';

const EnterPhoneNumber = () => {
  const { user } = useContext(UserContext);
  const { twilioService } = useContext(TwilioServiceContext);
  const { t } = useTranslation('common');
  const [isLoadingVerifiedState, setIsLoadingVerifiedState] = useState(true);
  const [isSendingSMS, setIsSendingSMS] = useState<boolean>(false);
  const { sendSuccessToast, sendInfoToast, sendErrorToast } = useToast();
  const { control, watch, handleSubmit } = useForm({ mode: 'onSubmit', reValidateMode: 'onBlur' });
  const swiper = useSwiper();
  const { isActive } = useSwiperSlide();
  const phoneNumber = watch('phoneNumber');

  useEffect(() => {
    if (isActive) {
      setIsLoadingVerifiedState(true);

      if (user?.isPhoneNumberVerified) {
        swiper.slideTo(3);
      } else {
        swiper.allowSlideNext = false;
      }
      setIsLoadingVerifiedState(false);
    }
  }, [isActive, swiper, setIsLoadingVerifiedState, user]);

  const sendVerificationCode = async () => {
    setIsSendingSMS(true);
    if (phoneNumber) {
      try {
        await twilioService.requestVerificationCode(phoneNumber);
        localStorage.setItem(LOCAL_STORAGE_KEYS.PHONE_NUMBER, phoneNumber);
        await sendSuccessToast('Es wurde ein Verifizierungscode an ihre Mobilrufnummer gesendet.');
        swiper.allowSlideNext = true;
        swiper.slideNext(100);
        setIsSendingSMS(false);
      } catch (e) {
        await sendErrorToast('Es ist ein Fehler aufgetreten');
        setIsSendingSMS(false);
        console.error(e);
      }
    } else {
      setIsSendingSMS(false);
      await sendInfoToast(t(''));
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(sendVerificationCode, (error) => console.error('error', error))}>
        <TunnelSlide
          title={'Verifizierung per SMS'}
          allowSlideNext={phoneNumber?.length > 3 && !isSendingSMS}
          customCTALabel={t('sendSMS')}
          isFormSubmit={true}
        >
          {isSendingSMS ? (
            <LoadingAnimation size={'DEFAULT'} />
          ) : isLoadingVerifiedState ? (
            <p className={styles.loading}>loading</p>
          ) : (
            <div className={styles.enterPhoneNumber}>
              <h2 className={styles.subHeadline}>Bitte verifiziere dein Konto</h2>
              <p className={styles.description}>
                Durch die einmalige Verifizierung schützt du dein Konto vor Dritten. Diese erfolgt per SMS und ist in
                wenigen Sekunden erledigt.
              </p>

              <div className={styles.input}>
                <InputText name={'phoneNumber'} label={'Mobilrufnummer'} required />
              </div>
            </div>
          )}
        </TunnelSlide>
      </form>
    </FormContext.Provider>
  );
};

export default EnterPhoneNumber;
