import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import {
  TENANT_PERSONAL_INFORMATION_SCHEMA,
  ISelectOption,
  ITenantPersonalInformation,
  JOB_STATUS,
} from '@wohnsinn/ws-ts-lib';
import { FormContext } from '../../../../../core/context/form.context';
import InputText from '../../../../atoms/formElement/InputText';
import UserContext from '../../../../../core/context/user.context';
import useToast from '../../../../../core/hook/toast.hook';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import Dropdown from '../../../../atoms/formElement/Dropdown';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../../../atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from '../../../../../core/const/select-options';
import DateInput from '../../../../atoms/formElement/DateInput';
import FormLayout from '../../../FormLayout';
import { ROUTES } from '../../../../../core/const/routes';
import PageTitle from '../../../../molecules/PageTitle';

export const DEFAULT_TENANT_PERSONAL_INFORMATION_DATA: ITenantPersonalInformation = {
  dateOfBirth: new Date(),
  firstName: null,
  hasInsolvencyProceedings: false,
  hadEvictions: false,
  jobStatus: JOB_STATUS.NONE,
  employerName: null,
  customJobDescription: null,
  lastName: null,
  phoneNumber: null,
};

export const TENANT_JOB_SELECT_OPTION_LIST: ISelectOption<JOB_STATUS>[] = [
  { value: JOB_STATUS.EMPLOYED, label: 'Angestellte/r' },
  { value: JOB_STATUS.SEEKING, label: 'Arbeitssuchende/r' },
  { value: JOB_STATUS.TRAINEE, label: 'Auszubildene/r' },
  { value: JOB_STATUS.OFFICAL, label: 'Beamte/r' },
  { value: JOB_STATUS.PHD_STUDENT, label: 'Doktorand/in' },
  { value: JOB_STATUS.HOUSEKEEPER, label: 'Hausfrau/mann' },
  { value: JOB_STATUS.FREELANCER, label: 'Freiberufler/in' },
  { value: JOB_STATUS.RENT, label: 'Rentner/in' },
  { value: JOB_STATUS.SELF_EMPLOYED, label: 'Selbstständige/r' },
  { value: JOB_STATUS.STUDENT, label: 'Studierende/r' },
  { value: JOB_STATUS.CUSTOM, label: 'Eigene Angabe' },
];

export interface ITenantFormProps {
  isApplicationFolderTunnel?: boolean;
}

const TenantProfileForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantProfile, userService } = useContext(UserContext);
  const { sendSuccessToast } = useToast();
  const { t } = useTranslation('common');
  const { t: p } = useTranslation('common', { keyPrefix: 'view.AccountView.TenantProfileView' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const [employerNamePlaceholder, setEmployerNamePlaceholder] = useState<{ placeholder: string }>(null);
  const [showJobDescription, setShowJobDescription] = useState<boolean>(false);
  const router = useIonRouter();
  const [jobDescription, setJobDescription] = useState<{ placeholder: string; label: string }>({
    placeholder: 'Beruf',
    label: 'Beruf',
  });

  const { control, reset, watch, setValue, handleSubmit } = useForm<ITenantPersonalInformation>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(TENANT_PERSONAL_INFORMATION_SCHEMA)),
    defaultValues: DEFAULT_TENANT_PERSONAL_INFORMATION_DATA,
  });

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    try {
      await sendSuccessToast('toast.profileUpdated');
      if (isApplicationFolderTunnel) {
        router.push(t(ROUTES.tenant.account.applicationFolder.household), 'forward');
      } else {
        router.push(t(ROUTES.tenant.account.overview.path), 'back');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateTenantProfile = (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    return userService.updateTenantPersonalInformation(tenantProfile.uid, watch()).then(onSuccess);
  };

  useEffect(() => {
    if (tenantProfile?.personalInformation) {
      reset(tenantProfile.personalInformation);
      handleJobLabel(tenantProfile.personalInformation.jobStatus, true);
    }
  }, [tenantProfile]);

  const handleJobLabel = (jobStatus: JOB_STATUS, isInitial = true) => {
    setJobDescription({ placeholder: 'Beruf', label: 'Beruf' });
    setEmployerNamePlaceholder(null);
    if (isInitial === false && jobStatus !== tenantProfile?.personalInformation?.jobStatus) {
      setValue('employerName', null);
      setValue('customJobDescription', null);
    }
    switch (jobStatus) {
      case JOB_STATUS.FREELANCER:
      case JOB_STATUS.OFFICAL:
        setShowJobDescription(true);
        break;
      case JOB_STATUS.SEEKING:
        setShowJobDescription(false);
        break;
      case JOB_STATUS.TRAINEE:
      case JOB_STATUS.EMPLOYED:
        setShowJobDescription(true);
        setEmployerNamePlaceholder({ placeholder: 'Arbeitgeber' });
        break;
      case JOB_STATUS.SELF_EMPLOYED:
        setShowJobDescription(true);
        setEmployerNamePlaceholder({ placeholder: 'Unternehmen' });
        break;
      case JOB_STATUS.PHD_STUDENT:
      case JOB_STATUS.STUDENT:
        setShowJobDescription(false);
        setEmployerNamePlaceholder({ placeholder: 'Universität' });
        break;
      case JOB_STATUS.CUSTOM:
        setShowJobDescription(true);
        setJobDescription({ placeholder: 'Eigene Angabe', label: 'Eigene Angabe' });
        break;
      default:
        setShowJobDescription(false);
        setEmployerNamePlaceholder(null);
    }
  };

  const handleError = (e: any) => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    console.error(e);
  };

  const CANCEL_BUTTON = {
    text: t('cancel'),
    action: () => router.push(t(ROUTES.tenant.account.overview.path), 'back'),
  };

  return (
    <FormContext.Provider value={{ control, setValue }}>
      <form onSubmit={handleSubmit(updateTenantProfile, handleError)}>
        {!isApplicationFolderTunnel && (
          <PageTitle
            pageTitle={t(ROUTES.tenant.account.profile.title)}
            notSubmitButton={CANCEL_BUTTON}
            showSubmitButton
            submitMode={buttonSubmitMode}
          />
        )}
        <FormLayout submitMode={buttonSubmitMode} notSubmitButton={isApplicationFolderTunnel ? null : CANCEL_BUTTON}>
          <IonGrid className={'wohnsinn-forms-grid'}>
            <IonRow className={'wohnsinn-form-section'}>
              <IonCol size={'12'} sizeMd={'6'}>
                <FormSectionTitle title={p('personalInformation')} />
                <InputText label={t('firstName.label')} name={'firstName'} required />
                <InputText label={t('lastName.label')} name={'lastName'} required />
                <InputText type={'tel'} label={t('phoneNumber.label')} name={'phoneNumber'} required />
                <DateInput
                  label={t('dateOfBirth.label')}
                  name={'dateOfBirth'}
                  endDate={new Date()}
                  startDate={new Date(1920, 0, 1)}
                  required
                />
              </IonCol>
              <IonCol size={'12'} sizeMd={'6'}>
                <FormSectionTitle title={p('jobSection')} />
                <Dropdown
                  name="jobStatus"
                  label={p('jobStatus.label')}
                  placeholder={p('jobStatus.placeholder')}
                  optionList={TENANT_JOB_SELECT_OPTION_LIST}
                  onChange={(e: any) => handleJobLabel(e, false)}
                  required
                />
                {showJobDescription && (
                  <InputText label={jobDescription.label} name={'customJobDescription'} required />
                )}
                {employerNamePlaceholder && (
                  <InputText label={employerNamePlaceholder.placeholder} name={'employerName'} required />
                )}
                <FormSectionTitle title={p('legalSection')} />
                <InputOptionList
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  name={'hasInsolvencyProceedings'}
                  label={p('hasInsolvencySubHeader')}
                  mode={'radio'}
                />
                <InputOptionList
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  name={'hadEvictions'}
                  label={p('hadEvictionsSubHeader')}
                  mode={'radio'}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </FormLayout>
      </form>
    </FormContext.Provider>
  );
};

export default TenantProfileForm;
