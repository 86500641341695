import { createContext } from 'react';

export const FormContext = createContext<{
  control: any;
  watch?: any;
  setValue?: (name: any, value: any) => void;
}>({
  control: null,
  watch: null,
  setValue: null,
});
