import DatabaseService from './database.service';
import { DocumentReference, getDocs, query, Query, where } from 'firebase/firestore';
import LOCAL_STORAGE_KEYS from '../enum/local-storage.enum';
import {
  FIRESTORE_COLLECTION_PATH,
  IAboutMe,
  IApartment,
  IHousehold,
  ILandlordPersonalInformation,
  ILandlordProfile,
  INotificationSettings,
  IOrganization,
  ITenantPersonalInformation,
  ITenantProfile,
  IUserWithRoles,
  IVideoUpload,
  LANDLORD_ROLES,
  MATCHING_MODE,
  OPEN_IMMO_SOFTWARES,
  TApplicationCreate,
  TDocumentUpload,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import { User } from 'firebase/auth';
import MixpanelTrackingService from './mixpanel-tracking.service';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import { FirebaseFunctionsService } from './firebase-functions-service';
import ApplicationService from './application.service';

class UserService {
  constructor(
    private databaseService: DatabaseService,
    private mixpanelService: MixpanelTrackingService,
    private firebaseFunctionsService: FirebaseFunctionsService,
    private applicationService: ApplicationService
  ) {}

  public getUserDocRefByUid(uid: string): DocumentReference {
    return this.databaseService.getDocRef(`${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`);
  }

  public getSchufaCheckDocumentListRef(uid: string): Query<TDocumentUpload> {
    const collectionPath = FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid);

    const colRef: Query<TDocumentUpload> = this.databaseService.getCollectionRef<TDocumentUpload>(collectionPath);
    return this.databaseService.getCollectionRefWithParams<TDocumentUpload>(colRef, {
      where: [{ fieldPath: 'creatorId', opStr: '==', value: uid }],
    });
  }

  public addIncomeProofDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.databaseService.setDbDoc<TDocumentUpload>(upload, path, false);
  }

  public addSchufaCheckDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;
    return this.databaseService.setDbDoc<TDocumentUpload>(upload, path, false);
  }

  public deleteIncomeProofDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.databaseService.deleteDbDoc(path);
  }

  public deleteSchufaCheckDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.databaseService.deleteDbDoc(path);
  }

  public async createProfileFromExistingProfile(
    newUserType: USER_TYPE,
    user: IUserWithRoles,
    tenantProfile: ITenantProfile,
    landlordProfile: ILandlordProfile
  ): Promise<void> {
    const batch = this.databaseService.getBatch();
    const selectedUserType: USER_TYPE = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TYPE) as USER_TYPE;
    const tfpId = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_FILTER_PARAMS_ID);

    let userRolesData;
    switch (newUserType) {
      case USER_TYPE.LANDLORD:
        if (tenantProfile) {
          userRolesData = { isLandlord: true };

          const landlordProfileDocPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace(
            '{uid}',
            user.uid
          )}/${user.uid}`;

          const landlordProfileData: ILandlordProfile = {
            createdAt: new Date(),
            displayName: tenantProfile.displayName,
            email: tenantProfile.email,
            FTPInformation: null,
            organizationId: null,
            isVerifiedLandlord: false,
            personalInformation: null,
            photoUrl: tenantProfile.photoUrl,
            uid: user.uid,
            isCommercialLandlord: false,
            roles: [],
            updatedAt: new Date(),
          };

          batch.set(this.databaseService.getDocRef<ILandlordProfile>(landlordProfileDocPath), landlordProfileData);
        }
        break;

      case USER_TYPE.TENANT:
        if (landlordProfile) {
          userRolesData = { isTenant: true };

          const tenantProfileDocPath = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
            '{uid}',
            user.uid
          )}/${user.uid}`;

          const tenantProfileData: ITenantProfile = {
            createdAt: new Date(),
            displayName: landlordProfile.displayName,
            email: landlordProfile.email,
            household: null,
            aboutMe: null,
            personalInformation: null,
            photoUrl: landlordProfile.photoUrl,
            readPermissionGrantedUsers: null,
            tenantFilterParamsId: tfpId ? tfpId : null,
            uid: user.uid,
            updatedAt: new Date(),
          };

          batch.set(this.databaseService.getDocRef<ITenantProfile>(tenantProfileDocPath), tenantProfileData);
        }
        break;
      default:
        throw Error(`invalid userType: ${selectedUserType}`);
    }

    const userDocPath = `${FIRESTORE_COLLECTION_PATH.users.root}/${user.uid}`;
    batch.set(this.databaseService.getDocRef<IUserWithRoles>(userDocPath), userRolesData, { merge: true });
    return await batch.commit();
  }

  public getTenantProfileRefByUid(uid: string): DocumentReference {
    return this.databaseService.getDocRef<ITenantProfile>(
      `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`
    );
  }

  public getIncomeProofDocumentListRef(uid: string): Query<TDocumentUpload> {
    const collectionPath = FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid);

    const colRef: Query<TDocumentUpload> = this.databaseService.getCollectionRef<TDocumentUpload>(collectionPath);
    return this.databaseService.getCollectionRefWithParams<TDocumentUpload>(colRef, {
      where: { fieldPath: 'creatorId', opStr: '==', value: uid },
    });
  }

  public getLandlordProfileRefByUid(uid: string): DocumentReference {
    return this.databaseService.getDocRef(
      `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`
    );
  }

  public setActiveUserType(uid: string, userType: USER_TYPE): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`;
    return this.databaseService.setDbDoc<{ activeUserType: USER_TYPE; updatedAt: Date }>(
      { activeUserType: userType, updatedAt: new Date() },
      path
    );
  }

  public getLandlordProfile(uid: string): Promise<ILandlordProfile | void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;

    return this.databaseService
      .getDbDoc<ILandlordProfile>(path)
      .then((landlordProfile) => {
        return landlordProfile;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public getTenantProfile(uid: string): Promise<ITenantProfile | void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.databaseService
      .getDbDoc<ITenantProfile>(path)
      .then((tenantProfile) => {
        return tenantProfile;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Update Landlords personal inforamtion
   * @param uid
   * @param personalInformation
   */
  public updateLandlordPersonalInformation(
    uid: string,
    personalInformation: ILandlordPersonalInformation
  ): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;

    return this.databaseService.setDbDoc<{ personalInformation: ILandlordPersonalInformation; updatedAt: Date }>(
      { personalInformation, updatedAt: new Date() },
      path
    );
  }

  public updateTenantPersonalInformation(uid: string, personalInformation: ITenantPersonalInformation): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.databaseService.setDbDoc<{ personalInformation: ITenantPersonalInformation; updatedAt: Date }>(
      { personalInformation, updatedAt: new Date() },
      path
    );
  }

  public updateTenantIntroductionVideo(uid: string, introductionVideo: IVideoUpload): Promise<void> {
    type TAboutMeVideoUpdate = Omit<IAboutMe, 'description'>;

    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.databaseService.setDbDoc<{ aboutMe: TAboutMeVideoUpdate }>(
      {
        aboutMe: {
          introductionVideo: introductionVideo
            ? {
                ...introductionVideo,
                hdVideoRef: null,
                sdVideoRef: null,
              }
            : null,
        },
      },
      path,
      true
    );
  }

  public updateUserNotificationSettings(
    uid: string,
    notificationSettings: Partial<INotificationSettings>
  ): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`;
    return this.databaseService.setDbDoc<Partial<IUserWithRoles>>(
      {
        notificationSettings: {
          isPushEnabled: true,
          isWhatsappEnabled: true,
          isMailEnabled: notificationSettings.isMailEnabled,
        },
      },
      path
    );
  }

  public async requestFTPData(
    email: string,
    uid: string,
    openImmoTools: {
      software: OPEN_IMMO_SOFTWARES;
      custom?: string;
    }
  ): Promise<void> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
      await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.requestFTPDataMail, {
        body: {
          email,
          software:
            openImmoTools.software !== OPEN_IMMO_SOFTWARES.OTHER ? openImmoTools.software : openImmoTools.custom,
        },
      });
      return this.databaseService.setDbDoc<Partial<ILandlordProfile>>(
        {
          FTPInformation: {
            credentials: {
              password: null,
              port: null,
              providerNumber: null,
              server: null,
            },
            isRequested: true,
            isApproved: false,
            software: openImmoTools.software,
            custom: openImmoTools.custom ? openImmoTools.custom : null,
          },
        },
        path
      );
    } catch (e) {
      console.error(e);
    }
  }

  public updateLandlordProfilePicture(uid: string, photoUrl: string): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.databaseService.setDbDoc<Partial<ILandlordProfile>>({ photoUrl }, path);
  }

  public updateLandlordOrganization(
    uid: string,
    organizationId: string | null,
    isOrganizationMembershipConfirmed: boolean
  ): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.databaseService.setDbDoc<Partial<ILandlordProfile>>(
      { organizationId, isOrganizationMembershipConfirmed },
      path
    );
  }

  public updateLandlordRoles(uid: string, roles: LANDLORD_ROLES[]): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.databaseService.setDbDoc<Partial<ILandlordProfile>>({ roles }, path);
  }

  public updateTenantHousehold(uid: string, householdData: IHousehold): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;

    return this.databaseService.setDbDoc<Partial<ITenantProfile>>({ household: householdData }, path);
  }

  public updateTenantFilterParamsId(uid: string, tenantFilterParamsId: string): Promise<void> {
    const batch = this.databaseService.getBatch();
    const tenantProfilePath = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    const tenantFilterParamsPath = `${FIRESTORE_COLLECTION_PATH.tenantFilterParams.root}/${tenantFilterParamsId}`;
    const tenantProfileDocRef = this.databaseService.getDocRef(tenantProfilePath);
    const tenantFilterParamsDocRef = this.databaseService.getDocRef(tenantFilterParamsPath);

    batch.set(tenantProfileDocRef, { tenantFilterParamsId }, { merge: true });
    batch.set(tenantFilterParamsDocRef, { uid }, { merge: true });
    return batch.commit();
  }

  public updateTenantProfilePicture(uid: string, photoUrl: string): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.databaseService.setDbDoc<Partial<ITenantProfile>>({ photoUrl }, path);
  }

  /**
   * Create roles and profiles for user
   * @param user
   * @param userType
   * @param organization
   * @return Promise<void>
   */
  public async createUserWithProfileAndRoles(
    user: User,
    userType: USER_TYPE,
    organization?: IOrganization
  ): Promise<ITenantProfile | ILandlordProfile> {
    let userWithRoles: IUserWithRoles;
    const organizationId: string | null = null;
    const { uid, email, displayName, photoURL, emailVerified } = user;
    const photoUrl = photoURL ? photoURL : '';
    const batch = this.databaseService.getBatch();
    const tfpId = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_FILTER_PARAMS_ID);
    const isCommercialLandlord: boolean = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.IS_COMMERCIAL_LANDLORD));

    const DEFAULT_USER = {
      uid,
      isAdmin: false,
      isEmailVerified: emailVerified,
      isLandlord: false,
      isTenant: true,
      isPhoneNumberVerified: false,
      notificationSettings: { isWhatsappEnabled: true, isMailEnabled: true, isPushEnabled: true },
      activeUserType: userType,
    };

    let profile: ITenantProfile | ILandlordProfile;
    switch (userType) {
      case USER_TYPE.TENANT:
        const tenantProfileDocPath = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
          '{uid}',
          uid
        )}/${uid}`;

        profile = {
          createdAt: new Date(),
          displayName,
          email,
          household: null,
          aboutMe: null,
          personalInformation: null,
          photoUrl,
          readPermissionGrantedUsers: null,
          tenantFilterParamsId: tfpId ? tfpId : null,
          uid,
          updatedAt: new Date(),
        };

        batch.set(this.databaseService.getDocRef<ITenantProfile>(tenantProfileDocPath), profile);
        userWithRoles = {
          ...DEFAULT_USER,
        };

        break;
      case USER_TYPE.LANDLORD:
        const landlordProfileDocPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace(
          '{uid}',
          uid
        )}/${uid}`;

        profile = {
          createdAt: new Date(),
          displayName,
          email,
          FTPInformation: null,
          organizationId,
          personalInformation: null,
          isVerifiedLandlord: false,
          photoUrl,
          uid,
          isCommercialLandlord,
          roles: [],
          updatedAt: new Date(),
        };

        if (organization) {
          profile = {
            ...profile,
            isCommercialLandlord: true,
            isOrganizationMembershipConfirmed: true,
            organizationId: organization.id,
            isVerifiedLandlord: true,
            roles: [],
          };
        }

        batch.set(this.databaseService.getDocRef<ILandlordProfile>(landlordProfileDocPath), profile);
        userWithRoles = {
          ...DEFAULT_USER,
          isLandlord: true,
          isTenant: false,
          activeUserType: USER_TYPE.LANDLORD,
        };

        break;
      default:
        throw Error(`invalid userType: ${userType}`);
    }

    const userDocPath = `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`;
    batch.set(this.databaseService.getDocRef<IUserWithRoles>(userDocPath), userWithRoles);
    this.mixpanelService.createdUser(uid);
    await batch.commit();

    return profile;
  }

  /**
   * Transfer ratings from localeStorage to user profile at registration point
   * @param {IUserWithRoles} user
   * @param {ITenantProfile} tenantProfile
   */
  public async transferNotLoggedInUserRatings(user: IUserWithRoles, tenantProfile: ITenantProfile) {
    const notLoggedInUserRatings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS));

    if (
      user &&
      notLoggedInUserRatings &&
      (notLoggedInUserRatings?.MAYBE?.length || notLoggedInUserRatings?.NOPE?.length)
    ) {
      // GET APARTMENTS BY ARRAY
      try {
        let NOPE: IApartment[];
        let MAYBE: IApartment[];
        if (notLoggedInUserRatings?.NOPE?.length) {
          const res = await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.root.get, {
            params: { apartmentIdList: notLoggedInUserRatings['NOPE'], transFormSingleResultToArray: false },
          });
          NOPE = res.data as IApartment[];
        }
        if (notLoggedInUserRatings?.MAYBE?.length) {
          const res = await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.root.get, {
            params: { apartmentIdList: notLoggedInUserRatings['MAYBE'], transFormSingleResultToArray: false },
          });
          MAYBE = res.data as IApartment[];
        }

        const applicationList: TApplicationCreate[] = [];

        if (NOPE && NOPE.length) {
          for (const apartment of NOPE as IApartment[]) {
            const application: TApplicationCreate = {
              address: apartment.mainInformation.address,
              apartmentId: apartment.id,
              editorList: apartment.editorList,
              isLandlordTyping: false,
              isTenantTyping: false,
              landlordId: apartment.creatorId,
              landlordRating: MATCHING_MODE.NONE,
              lastMessageSenderId: tenantProfile.uid,
              lastMessage: {
                editorState: '',
                html: '',
              },
              media: apartment.media[0] ? apartment.media[0] : null,
              rating: MATCHING_MODE.NOPE,
              rooms: apartment?.mainInformation?.rooms ?? 0,
              seenByLandlord: false,
              size: apartment?.mainInformation?.size ?? 0,
              tenantProfile,
              warmRent: apartment?.cost?.warmRent ?? 0,
            };

            applicationList.push(application);
          }
        }

        if (MAYBE && MAYBE.length) {
          for (const apartment of MAYBE as IApartment[]) {
            const application: TApplicationCreate = {
              address: apartment.mainInformation.address,
              apartmentId: apartment.id,
              editorList: apartment.editorList,
              isLandlordTyping: false,
              isTenantTyping: false,
              landlordId: apartment.creatorId,
              landlordRating: MATCHING_MODE.NONE,
              lastMessageSenderId: tenantProfile.uid,
              lastMessage: {
                editorState: '',
                html: '',
              },
              media: apartment.media[0] ? apartment.media[0] : null,
              rating: MATCHING_MODE.MAYBE,
              rooms: apartment?.mainInformation?.rooms ?? 0,
              seenByLandlord: false,
              size: apartment?.mainInformation?.size ?? 0,
              tenantProfile,
              warmRent: apartment?.cost?.warmRent ?? 0,
            };

            applicationList.push(application);
          }
        }

        if (applicationList.length) {
          this.applicationService
            .addApplicationList(applicationList)
            .then(() => {
              localStorage.removeItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS);
            })
            .catch(console.error);
        }
      } catch (e) {
        console.error(e, 'ERROR');
      }
    }
  }

  /** Get a list of landlord profiles bei an array of uids
   *
   */
  public async getLandlordProfilesByUids(landlordIds: string[]) {
    const landlordCollection = this.databaseService.getCollectionGroupRef<ILandlordProfile>(`landlordProfiles`, {
      fetchWithId: true,
    });

    const landlordprofilequery = query(landlordCollection, where('uid', 'in', landlordIds));
    const profileQuerySnapshot = await getDocs(landlordprofilequery);
    return profileQuerySnapshot.docs.map((profile) => profile.data());
  }
}

export default UserService;
