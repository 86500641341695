import { MutableRefObject } from 'react';
const scrollTo = (ref: MutableRefObject<any>, position?: number, duration?: number) => {
  if (ref.current) {
    if (position) {
      ref.current.scrollToPoint(0, position, duration ?? 0);
    } else {
      ref.current.scrollToTop(500);
    }
  }
};

export default scrollTo;
