import {
  FIRESTORE_COLLECTION_PATH,
  IApartment,
  IGetLandlordApartmentListQueryParams,
  IMatchListResponse,
  IMedia,
  IOrderByFilterArguments,
  IWhereFilterArguments,
} from '@wohnsinn/ws-ts-lib';
import {
  getDocs,
  query,
  Query,
  QueryDocumentSnapshot,
  startAfter,
  OrderByDirection,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import DatabaseService from './database.service';
import { FirebaseFunctionsService } from './firebase-functions-service';
import FB_FUNCTION_URLS from '../const/fb-function-names';

interface IGetOrganizationApartmentListQueryParams {
  organizationId: string;
  limit?: number;
  directionStr?: OrderByDirection;
  whereFilterArguments?: IWhereFilterArguments;
}
export class ApartmentService {
  constructor(private databaseService: DatabaseService, private firebaseFunctionService: FirebaseFunctionsService) {}

  public getApartmentRef(apartmentId: string): Query<IApartment> {
    return this.databaseService.getCollectionRefWithParams<IApartment>(
      this.databaseService.getCollectionGroupRef('apartments', { fetchWithId: true }),
      { where: { fieldPath: 'id', opStr: '==', value: apartmentId } }
    );
  }

  public async getAllApartmentsDocSnapShots() {
    const apartmentListRef: Query<IApartment> = query(this.getAllApartmentsListRef());

    return getDocs(apartmentListRef)
      .then((apartmentListSnap) => {
        return apartmentListSnap.docs.map((d) => d);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public async getAllApartmentsWithApplicationsQuery(userID: string, limit?: number) {
    try {
      return this.databaseService.getCollectionRefWithParams<IApartment>(
        this.databaseService.getCollectionGroupRef('apartments'),
        {
          where: [
            { fieldPath: 'editorList', opStr: 'array-contains', value: userID },
            { fieldPath: 'applicationRefList', opStr: '!=', value: [] },
          ],
          limit,
        }
      );
    } catch (error) {
      console.error('Error on getAllApartmentsWithApplicationsQuery: ', error);
    }
  }

  public getMatchList = async (tenantFilterParamsId: string): Promise<IMatchListResponse | void> => {
    return this.firebaseFunctionService
      .callFbFunction<{ params: { tenantFilterParamsId: string } }, IMatchListResponse>(FB_FUNCTION_URLS.matches, {
        params: { tenantFilterParamsId },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error('ErrorView on getMatchList', err);
      });
  };

  public async getLandlordApartmentDocSnapShots(
    landlordProfileId: string,
    lastVisibleApartment?: QueryDocumentSnapshot<IApartment>
  ) {
    const apartmentListRef: Query<IApartment> = lastVisibleApartment
      ? query(this.getLandlordApartmentListRef({ uid: landlordProfileId }), startAfter(lastVisibleApartment))
      : this.getLandlordApartmentListRef({ uid: landlordProfileId });

    return await getDocs(apartmentListRef).catch((err) => console.error(err));
  }

  public async getOrganizationApartmentDocSnapShots(
    organizationId: string,
    lastVisibleApartment?: QueryDocumentSnapshot<IApartment>
  ) {
    const apartmentListRef: Query<IApartment> = lastVisibleApartment
      ? query(this.getOrganizationApartmentsListRef({ organizationId }), startAfter(lastVisibleApartment))
      : this.getOrganizationApartmentsListRef({ organizationId });

    return getDocs(apartmentListRef)
      .then((apartmentListSnap) => {
        return apartmentListSnap;
      })
      .catch((err) => console.error(err));
  }

  public async getALlApartmentDocSnapShots(lastVisibleApartment?: QueryDocumentSnapshot<IApartment>) {
    const apartmentListRef: Query<IApartment> = lastVisibleApartment
      ? query(this.getAllApartmentListRef(), startAfter(lastVisibleApartment))
      : this.getAllApartmentListRef();

    return getDocs(apartmentListRef)
      .then((apartmentListSnap) => {
        return apartmentListSnap;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public getAllApartmentsListRef(limit = 100): Query<IApartment> {
    return this.databaseService.getCollectionRefWithParams<IApartment>(
      this.databaseService.getCollectionGroupRef('apartments'),
      {
        where: { fieldPath: 'isPublished', opStr: '==', value: true },
        limit,
      }
    );
  }

  public getOrganizationApartmentsListRef(params: IGetOrganizationApartmentListQueryParams): Query<IApartment> {
    const where: IWhereFilterArguments[] = [
      { fieldPath: 'isPublished', opStr: '==', value: true },
      { fieldPath: 'organizationId', opStr: '==', value: params.organizationId },
    ];
    const orderBy: IOrderByFilterArguments[] = [];
    if (params.whereFilterArguments) {
      where.push(params.whereFilterArguments);
      if (params.whereFilterArguments.opStr === '!=') {
        orderBy.push({ fieldPath: params.whereFilterArguments.fieldPath, directionStr: 'desc' });
      }
    }
    orderBy.push({ fieldPath: 'updatedAt', directionStr: 'desc' });
    return this.databaseService.getCollectionRefWithParams<IApartment>(
      this.databaseService.getCollectionGroupRef('apartments'),
      {
        where,
        orderBy,
        limit: params.limit,
      }
    );
  }

  public getLandlordApartmentListRef(params: IGetLandlordApartmentListQueryParams): Query<IApartment> {
    const where: IWhereFilterArguments[] = [{ fieldPath: 'editorList', opStr: 'array-contains', value: params.uid }];
    const orderBy: IOrderByFilterArguments[] = [];
    if (params.whereFilterArguments) {
      where.push(params.whereFilterArguments);
      if (params.whereFilterArguments.opStr === '!=') {
        orderBy.push({ fieldPath: params.whereFilterArguments.fieldPath, directionStr: 'desc' });
      }
    }
    orderBy.push({ fieldPath: 'updatedAt', directionStr: 'desc' });
    return this.databaseService.getCollectionRefWithParams<IApartment>(
      this.databaseService.getCollectionGroupRef('apartments'),
      {
        where,
        orderBy,
        limit: params.limit,
      }
    );
  }
  public getAllApartmentListRef(limit = 10, directionStr: OrderByDirection = 'desc'): Query<IApartment> {
    const apartmentCollectionRef: Query<IApartment> = this.databaseService.getCollectionRefWithParams<IApartment>(
      this.databaseService.getCollectionGroupRef('apartments'),
      {
        where: [{ fieldPath: 'isPublished', opStr: '==', value: true }],
        limit,
      }
    );

    return apartmentCollectionRef;
  }

  public updateApartmentPublishState(apartment: IApartment, isPublished: boolean) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

    return this.databaseService.setDbDoc<{ isPublished: boolean; publishedAt: Date }>(
      { isPublished, publishedAt: isPublished ? new Date() : null },
      path
    );
  }

  /**
   * Update the list of contact persons for a given apartment
   * Uid which is passed will either be added or removed from contact persons
   * @param apartment
   * @param contactPersonsUid
   * @param removeFromContacts
   */
  public async updateApartmentContactPersons(
    apartment: IApartment,
    contactPersonsUid: string,
    removeFromContacts = false
  ) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

    try {
      await this.databaseService.updateDbDoc<{ contactPersons: string[] }>(
        { contactPersons: removeFromContacts ? arrayRemove(contactPersonsUid) : arrayUnion(contactPersonsUid) },
        path
      );
    } catch (e) {
      console.error('Error on updating apartments contact persons', e);
    }
  }

  /**
   * Puts the passed id at first place of contact persons array
   * @param apartment
   * @param contactPersonsUid
   */
  public async setUidAsFirstContactPerson(apartment: IApartment, contactPersonsUid: string) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

    const apartmentContactPersons = apartment.contactPersons;
    const indexOfUser = apartment.contactPersons.indexOf(contactPersonsUid);
    apartmentContactPersons.splice(indexOfUser, 1);
    apartmentContactPersons.unshift(contactPersonsUid);

    try {
      await this.databaseService.setDbDoc<{ contactPersons: string[] }>(
        { contactPersons: apartmentContactPersons },
        path
      );
    } catch (e) {
      console.error('Error on updating apartments contact persons', e);
    }
  }

  public updateApartmentMediaList(apartment: IApartment, media: IMedia[]): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;
    return this.databaseService.setDbDoc<{ media: IMedia[] }>({ media }, path);
  }

  public updateApartmentCustomNotes(apartment: IApartment, customNotes: string) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

    return this.databaseService.setDbDoc<{ customNotes: string }>({ customNotes }, path);
  }

  /**
   * Create property imagesToResizeCount on apartment, when images are beeing uploaded
   * Necessary for resizing images
   * @param apartment
   * @param imagesToResizeCount
   */
  public updateApartmentImagesToResize(apartment: IApartment, imagesToResizeCount: number) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

    return this.databaseService.setDbDoc<{ imagesToResizeCount: number }>({ imagesToResizeCount }, path);
  }
}
