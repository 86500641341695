import mixpanel from 'mixpanel-browser';
import { IMatchListResponse, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';

class MixpanelTrackingService {
  constructor() {}

  public userHadMatches = (matchListResponse: IMatchListResponse) => {
    try {
      mixpanel.track('Had Matches', {
        matchListResponse: matchListResponse,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };
  public trackPlaceName = (placeName?: string) => {
    try {
      mixpanel.track('Place Name', {
        placeName: placeName ? placeName : 'ohne Stadt',
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public userHadPerfectMatches = (numberOfPerfectMatches: number) => {
    try {
      mixpanel.track('userHadPerfectMatches', {
        numberOfPerfectMatches: numberOfPerfectMatches,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public createdUser = (uid: string) => {
    try {
      mixpanel.track('Signed Up', {
        uid: uid,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };
  public trackEnterPage = (pageTitle: string) => {
    try {
      mixpanel.track(`Entered ${pageTitle}`, {
        pageTitle: pageTitle,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };
  public trackOpenModal = (modalId: string, path?: string) => {
    try {
      mixpanel.track(`Opened ${modalId}`, {
        modalId: modalId,
        path: path,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public trackRatingClick = (rating: MATCHING_MODE, apartmentId: string) => {
    try {
      mixpanel.track('Rating Click', {
        Rating: rating,
        ApartmentId: apartmentId,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public trackClickOnBookmarkNavigation = () => {
    try {
      mixpanel.track('Nope Button');
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };
}

export default MixpanelTrackingService;
