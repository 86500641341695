import styles from './Avatar.module.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

export enum AVATAR_SIZE {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xxl = 'xxl',
}

export interface IAvatarProps {
  size?: AVATAR_SIZE;
  testId?: string;
  avatarUrl?: string;
  bordered?: boolean;
}

const Avatar: FC<IAvatarProps> = ({ size = AVATAR_SIZE.md, testId = 'avatar', avatarUrl, bordered = false }) => {
  const { t: c } = useTranslation('common');

  return (
    <div
      data-testid={testId}
      role="img"
      aria-label={c('profile.image')}
      className={`${styles.avatar} ${styles[size]} ${!!!avatarUrl ? styles.silhouette : ''} ${
        bordered ? styles.border : ''
      }`}
      style={{ backgroundImage: avatarUrl ? `url('${avatarUrl}')` : 'none' }}
    >
      {!!!avatarUrl && <FontAwesomeIcon icon={faUser} />}
    </div>
  );
};

export default Avatar;
