import { IMatchListResponse, ITenantFilterParams, IUserWithRoles } from '@wohnsinn/ws-ts-lib';
import TenantFilterParamsService from '../service/tenant-filter-params.service';
import { useEffect, useState } from 'react';
import { ApartmentService } from '../service/apartment.service';
import LOCAL_STORAGE_KEYS from '../enum/local-storage.enum';

export const DEFAULT_MATCH_LIST_RESPONSE: IMatchListResponse = {
  otherMatches: [],
  perfectMatches: [],
  userApplications: {
    like: [],
    maybe: [],
    nope: [],
    total: 0,
  },
  page: 0,
};

const useTenantFilterParams = (
  tenantFilterParamsService: TenantFilterParamsService,
  apartmentService: ApartmentService,
  user?: IUserWithRoles
): {
  tenantFilterParams: ITenantFilterParams;
  updateTenantFilterParams: (data: ITenantFilterParams) => Promise<void>;
  matchData: IMatchListResponse;
  loadingMatchData: boolean;
  fetchMatchList: any;
} => {
  const [tenantFilterParams, setTenantFilterParams] = useState<ITenantFilterParams>(null);
  const [matchData, setMatchData] = useState<IMatchListResponse>(DEFAULT_MATCH_LIST_RESPONSE);
  const [loadingMatchData, setLoadingMatchData] = useState<boolean>(true);

  const fetchTenantFilterParams = async () => {
    const tenantFilterParams = (await tenantFilterParamsService.getFilterParams(user)) as ITenantFilterParams;
    setTenantFilterParams(tenantFilterParams);
  };

  const updateTenantFilterParams = async (data: ITenantFilterParams): Promise<void> => {
    await tenantFilterParamsService.setFilterParams({
      ...data,
      uid: user?.uid ? user?.uid : null,
    });
    setTenantFilterParams(data);
  };

  function removeNotLoggedInRatings(matchData: IMatchListResponse): void {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS)) {
      const notLoggedInRatings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOT_LOGGED_IN_USER_RATINGS));
      setMatchData({
        ...matchData,
        perfectMatches: matchData.perfectMatches
          .filter((apartment) => !notLoggedInRatings['MAYBE'].includes(apartment.id))
          .filter((apartment) => !notLoggedInRatings['NOPE'].includes(apartment.id)),

        otherMatches: matchData.otherMatches
          .filter((apartment) => !notLoggedInRatings['MAYBE'].includes(apartment.id))
          .filter((apartment) => !notLoggedInRatings['NOPE'].includes(apartment.id)),
      });
    } else {
      setMatchData(matchData);
    }
  }

  const fetchMatchList = async () => {
    setLoadingMatchData(true);
    const matchData = await apartmentService.getMatchList(tenantFilterParams.id);
    if (matchData) {
      if (user) {
        setMatchData(matchData);
      } else {
        removeNotLoggedInRatings(matchData);
      }
      setLoadingMatchData(false);
    }
  };

  useEffect(() => {
    fetchTenantFilterParams();
  }, [user]);

  useEffect(() => {
    if (tenantFilterParams) {
      fetchMatchList();
    } else {
      setLoadingMatchData(false);
    }
  }, [tenantFilterParams, user]);

  return {
    tenantFilterParams,
    updateTenantFilterParams,
    matchData,
    loadingMatchData,
    fetchMatchList,
  };
};

export default useTenantFilterParams;
