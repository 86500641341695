import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { EMAIL_SCHEMA, IOrganization, SIGN_IN_METHOD, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import Joi from 'joi';
import { useSwiper } from 'swiper/react';
import { FC, useContext, useEffect, useState } from 'react';
import UserContext from '../../../../../../core/context/user.context';
import { FormContext } from '../../../../../../core/context/form.context';
import TextInput from '../../../../../atoms/formElement/InputText';
import useToast from '../../../../../../core/hook/toast.hook';
import { decodeToken, isExpired } from 'react-jwt';
import { useLocation } from 'react-router';
import queryString from 'querystring';
import { getAdditionalUserInfo, UserCredential } from 'firebase/auth';
import { useIonRouter } from '@ionic/react';
import useOrganization from '../../../../../../core/hook/organization.hook';
import RegistrationFormWrapper from '../../../../../organisms/forms/signIn/RegistrationFormWrapper';
import { ROUTES } from '../../../../../../core/const/routes';
import { SUBMIT_BUTTON_MODE } from '../../../../../../core/enum/submit-button-mode.enum';
import SwiperTunnelStepsWrapper from '../../../Layout/SwiperTunnelStepsWrapper';

const PASSWORD_SCHEMA = Joi.string().min(8).max(24).regex(/\d/).regex(/\W|_/).required();
export const USER_CREDENTIALS_FORM_SCHEMA = Joi.object({
  email: EMAIL_SCHEMA,
  password: PASSWORD_SCHEMA,
  passwordRepeat: Joi.any().equal(Joi.ref('password')).required(),
  allowMarketingMails: Joi.boolean(),
  organization: Joi.string().optional(),
});

const StepRegisterLandlord: FC = () => {
  const { t } = useTranslation('common');
  const { authService } = useContext(UserContext);
  const swiper = useSwiper();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { sendInfoToast } = useToast();
  const location = useLocation();
  const values = queryString.parse(location.search);
  const encodedToken: string = values.token as string;
  const organizationFromParams: { email: string; organizationId: string; iat: number; exp: number } =
    decodeToken(encodedToken);
  const router = useIonRouter();
  const { organizationService } = useOrganization();
  const [organization, setOrganization] = useState<IOrganization>(null);

  const { control, handleSubmit, watch, setValue, reset } = useForm<{
    organization: string;
    email: string;
    password: string;
    passwordRepeat: string;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
  });

  useEffect(() => {
    // GET ORGANIZATION IF IS INVITATION LINK
    if (organizationFromParams) {
      (async () => {
        try {
          const organization = await organizationService.getOrganizationById(organizationFromParams.organizationId);
          const isOrganizationRequestValid = await organizationService.isOrganizationActivationRequestValid(
            organization.id,
            organizationFromParams.email
          );

          if (isOrganizationRequestValid) {
            setOrganization(organization);
            setValue('organization', organization?.title);
            setValue('email', organizationFromParams?.email);
          } else {
            await sendInfoToast('Diese Einladung ist nicht mehr gültig.');
          }
        } catch (e) {
          console.error('Error on getOrganizationById:', e);
        }
      })();
    }
  }, [location]);

  const handleUserRegistrationWithEmailAndPassword = async (
    email: string,
    password: string,
    organization?: IOrganization
  ) => {
    await authService.handleUserRegistrationWithEmailAndPassword(email, password, USER_TYPE.LANDLORD, organization);

    swiper.allowSlideNext = true;
    if (organization) {
      swiper.slideTo(2, 300);
    } else {
      swiper.slideNext(300);
    }
  };

  const handleUserRegistrationWithAuthProvider = async (signInMethod: SIGN_IN_METHOD): Promise<void> => {
    const credentials: UserCredential = await authService.authProviderLogin(signInMethod);
    const moreInfo = await getAdditionalUserInfo(credentials);

    if (moreInfo.isNewUser) {
      await authService.handleUserRegistrationWithAuthProvider(credentials, USER_TYPE.LANDLORD);
      swiper.allowSlideNext = true;
      swiper.slideNext(300);
    } else {
      router.push(t(ROUTES.landlord.apartment.list.path));
    }
  };

  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const signInMethod = await authService.fetchSignInMethodsForEmail(watch().email);

    if (!signInMethod) {
      return await handleUserRegistrationWithEmailAndPassword(watch().email, watch().password, organization);
    }

    if ('password') {
      await sendInfoToast(t('toast.signIn.emailAccountExistsAlready'));
      reset({ password: '', passwordRepeat: '' });
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      return;
    }

    if ('google.com' || 'apple.com') {
      if (signInMethod.includes('google.com')) {
        await sendInfoToast(t('toast.googleAccountExistsAlready'));
        await handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.GOOGLE);
      }

      if (signInMethod.includes('apple.com')) {
        t('toast.appleAccountExistsAlready');
        await sendInfoToast(t('toast.appleAccountExistsAlready'));
        await handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.APPLE);
      }

      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      return;
    }
  };

  const handleFormError = (errors: FieldErrors) => {
    console.error(errors);
  };

  return (
    <SwiperTunnelStepsWrapper>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
          <RegistrationFormWrapper
            buttonSubmitMode={buttonSubmitMode}
            handleUserRegistrationWithAuthProvider={handleUserRegistrationWithAuthProvider}
            hideAuthProviderLogin={organizationFromParams && !isExpired(encodedToken)}
          >
            {organizationFromParams?.organizationId && (
              <TextInput label={t('organization.title.label')} name={'organization'} type={'text'} disabled required />
            )}

            <TextInput tabIndex={1} label={t('email.label')} name={'email'} type={'email'} required />
            <TextInput tabIndex={2} label={t('password.label')} name={'password'} type={'password'} required />
            <TextInput
              tabIndex={3}
              label={t('passwordRepeat.label')}
              name={'passwordRepeat'}
              type={'password'}
              required
            />
          </RegistrationFormWrapper>
        </form>
      </FormContext.Provider>
    </SwiperTunnelStepsWrapper>
  );
};

export default StepRegisterLandlord;
