import { FC, useContext, useEffect, useState } from 'react';
import { ROUTES } from '../../../../../core/const/routes';
import FormSectionTitle from '../../../../atoms/FormSectionTitle';
import InputText from '../../../../atoms/formElement/InputText';
import styles from '../../../../molecules/OrganizationProfileInformation/OrganizationProfileInformation.module.scss';
import CTAButton, { BUTTON_STYLE } from '../../../../atoms/Buttons/CTAButton';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import MediaUploadDropZone from '../../../../molecules/MediaUploadDropZone';
import { FormContext } from '../../../../../core/context/form.context';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { FileRejection } from 'react-dropzone';
import { FIRESTORE_COLLECTION_PATH, IOrganization, ORGANIZATION_SCHEME } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../../core/hook/toast.hook';
import StorageServiceContext from '../../../../../core/context/storage-service.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import Joi from 'joi';
import UserContext from '../../../../../core/context/user.context';
import useOrganization from '../../../../../core/hook/organization.hook';
import InputWithDebounce from '../../../../atoms/formElement/InputWithDebounce';
import CopyButton from '../../../../atoms/Buttons/CopyButton';
import { slugify } from '../../../../../core/helper/slugify-text';
import FormHeader from '../../../../atoms/FormHeader';
import FormFooter from '../../../../atoms/FormFooter';
import SplitLine from '../../../../atoms/SplitLine';
import { ILandlordAccountFormProps } from '../../../../../view/landlord/account/AccountView';

export const DEFAULT_ORGANIZATION_DATA: IOrganization = {
  id: null,
  title: null,
  website: null,
  domain: null,
  photoUrl: null,
  imprintLink: null,
  imprintText: null,
  dataProtectionLink: null,
  dataProtectionText: null,
  address: {
    street: null,
    postalCode: null,
    houseNumber: null,
    city: null,
    coordinates: null,
    geoHash: null,
  },
  creatorId: null,
  slug: null,
};

const LandlordOrganizationProfileForm: FC<ILandlordAccountFormProps> = ({ scrollToTop }) => {
  const { landlordProfile } = useContext(UserContext);
  const { organizationService, organization } = useOrganization();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t: v } = useTranslation('common', { keyPrefix: 'view.OrganizationProfileView' });
  const { t } = useTranslation('common');
  const { sendSuccessToast, sendInfoToast } = useToast();
  const { storageService } = useContext(StorageServiceContext);
  const [slug, setSlug] = useState<string>('');

  const { control, watch, setValue, handleSubmit, reset } = useForm<IOrganization>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(ORGANIZATION_SCHEME)),
    defaultValues: DEFAULT_ORGANIZATION_DATA,
  });

  const currentSlug = watch('slug');
  const organizationTitle = watch('title');

  useEffect(() => {
    if (organization) {
      reset(organization);
    } else {
      reset();
    }
  }, [organization]);

  const validateSlugName = async (slugName: string, index = 0) => {
    const newSlugName: string = slugify(slugName) + (index > 0 ? `-${index + 1}` : '');

    const organization = await organizationService.getOrganizationBySlug(newSlugName);
    if (organization) {
      if (organization.id === landlordProfile.organizationId) {
        setSlugAndValue(newSlugName);
      } else {
        await validateSlugName(slugName, index + 1);
      }
    } else {
      setSlugAndValue(newSlugName);
    }
  };

  const setSlugAndValue = (newSlugName: string) => {
    setSlug(newSlugName);
    setValue('slug', newSlugName);
  };

  useEffect(() => {
    if (organizationTitle) {
      validateSlugName(organizationTitle);
    }
  }, [organizationTitle]);

  const updateOrganization = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    scrollToTop();
    await organizationService.updateOrganization(watch(), landlordProfile);
    await sendInfoToast('toast.profileUpdated');
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
  };

  const handleError = (err: any): void => {
    console.error(err);
  };

  const deleteOrganizationLogo = async () => {
    organizationService
      .updateOrganizationLogo(organization?.id, null)
      .catch(console.error)
      .then(() => {
        sendSuccessToast(v('logo.deletedSuccessfully'));
      });
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]): Promise<void> => {
    const path = `${FIRESTORE_COLLECTION_PATH.organizations.root}/${landlordProfile.organizationId}/${acceptedFiles[0].name}`;

    try {
      const downloadUrl = await storageService.uploadBlob(
        acceptedFiles[0],
        path,
        landlordProfile.uid,
        acceptedFiles[0].type
      );
      await organizationService.updateOrganizationLogo(landlordProfile.organizationId, downloadUrl);
      await sendInfoToast(v('logo.uploadedSuccessfully'));
    } catch (e) {
      console.error(e);
    }

    if (rejectedFiles.length) {
      // SEND TOAST
      console.error('no accepted files');
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(updateOrganization, handleError)}>
        <FormHeader subtitle={'Hinterlege die Informationen für deine Firma.'} title={'Firmendaten'} />

        <FormSectionTitle title={v('organizationLogo')} />

        {organization?.photoUrl ? (
          <>
            <img
              className={styles.organizationImage}
              src={organization?.photoUrl}
              alt={`${organization?.title} Logo`}
            />
            <CTAButton
              icon={faTrash}
              buttonStyle={BUTTON_STYLE.SECONDARY}
              size={'small'}
              onClick={deleteOrganizationLogo}
              buttonText={v('logo.delete')}
            />
          </>
        ) : (
          <MediaUploadDropZone multiple={false} onDrop={onDrop} />
        )}

        <SplitLine />

        <FormSectionTitle title={v('addressInformation')} />

        <InputWithDebounce debounceTimeout={300} label={t('organization.title.label')} name={'title'} required={true} />

        <CopyButton
          textToCopy={
            slug
              ? ` ${process.env.REACT_APP_BASE_URL}${t(ROUTES.landlord.organization.portfolio.path).replace(
                  ':slug',
                  currentSlug
                )}`
              : ''
          }
          label={t('organization.portfolio.label')}
        />

        <input type={'hidden'} name={'slug'} />
        <InputText label={t('address.street.label')} name={'address.street'} required />
        <InputText label={t('address.houseNumber.label')} name={'address.houseNumber'} required />
        <InputText type={'text'} label={t('address.postalCode.label')} name={'address.postalCode'} required />
        <InputText type={'text'} label={t('address.city.placeholder')} name={'address.city'} required />

        <FormSectionTitle title={v('legalInformation')} />

        <InputText
          required
          label={t('organization.website.label')}
          name={'website'}
          tooltipText={t('organization.website.helperText')}
        />

        <InputText
          label={t('organization.imprintLink.label')}
          name={'imprintLink'}
          required
          tooltipText={v('toolTip.imprint')}
        />

        <InputText
          label={t('organization.dataProtectionLink.label')}
          name={'dataProtectionLink'}
          required
          tooltipText={v('toolTip.privacy')}
        />

        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
    </FormContext.Provider>
  );
};

export default LandlordOrganizationProfileForm;
