import { FC } from 'react';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import TagList from '../TagList';
import { useTranslation } from 'react-i18next';

export interface IApartmentBasicsProps {
  apartment: IApartment;
}

const ApartmentBasics: FC<IApartmentBasicsProps> = ({ apartment }) => {
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentBasics' });
  const basics = [
    { key: 'rooms', label: `🛋 ${apartment?.mainInformation?.rooms} ${t('apartment.mainInformation.rooms.label')}` },
    { key: 'size', label: `️📐 ${apartment?.mainInformation?.size} m²` },
  ];

  if (apartment.cost?.warmRent) {
    basics.push({ key: 'warmRent', label: `💰 ${apartment.cost.warmRent}€ ${t('apartment.cost.warmRent.label')}` });
  }

  if (apartment.desiredTenant) {
    if (apartment.desiredTenant.numberOfPeopleMovingIn) {
      basics.push({
        key: 'maxPeopleMovingIn',
        label: `👨‍👩‍👧‍👦 ${v('maxPeopleMovingIn', {
          amount: apartment.desiredTenant.numberOfPeopleMovingIn,
        })}`,
      });
    }

    if (apartment.desiredTenant.arePetsAllowed !== null) {
      basics.push(
        apartment.desiredTenant.arePetsAllowed
          ? { key: 'arePetsAllowed', label: `🐶🐱 ${v('arePetsAllowed')}` }
          : { key: 'arePetsAllowed', label: `🐶🐱🚫 ${v('petsNotAllowed')}` }
      );
    }

    if (apartment.desiredTenant.isSharedUsagePossible !== null) {
      basics.push(
        apartment.desiredTenant.isSharedUsagePossible
          ? { key: 'sharedUsagePossible', label: `👥 ${v('sharedUsagePossible')}` }
          : { key: 'sharedUsagePossible', label: `👥🚫 ${v('sharedUsageNotPossible')}` }
      );
    }
  }

  if (apartment?.mainInformation?.earliestMoveIn) {
    basics.push({
      key: 'earliestMoveIn',
      label: `🔑 ${v('availableFrom')} ${apartment.mainInformation?.earliestMoveIn}`,
    });
  }

  return <TagList testId={'apartmentBasics'} tags={basics} />;
};

export default ApartmentBasics;
