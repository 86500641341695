import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { useContext, useRef, useState } from 'react';
import UserContext from '../../../../core/context/user.context';
import MobileHeaderButton from '../../../../component/atoms/Buttons/MobileHeaderButton';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import { faBuilding, faGear, faPerson, faShareNodes, faUsers } from '@fortawesome/pro-light-svg-icons';
import ModalContext from '../../../../core/context/modal.context';
import InfoBox from '../../../../component/atoms/InfoBox';
import PageTitle from '../../../../component/molecules/PageTitle';
import styles from '../../../tenant/account/AccountOverviewView/AccountOverviewView.module.scss';
import ActionBanner from '../../../../component/atoms/ActionBanner';
import useToast from '../../../../core/hook/toast.hook';
import LandlordProfileForm from '../../../../component/organisms/forms/landlord/LandlordProfileForm';
import LandlordOrganizationProfileForm from '../../../../component/organisms/forms/landlord/LandlordOrganizationProfileForm';
import Section from '../../../../component/atoms/Section';
import FormTabs, { IFormTab } from '../../../../component/organisms/FormTabs';
import LandlordUserManagementForm from '../../../../component/organisms/forms/landlord/LandlordUserManagementForm';
import LandlordInterfacesForm from '../../../../component/organisms/forms/landlord/LandlordInterfacesForm';
import LandlordCreateOrganizationForm from '../../../../component/organisms/forms/landlord/LandlordCreateOrganizationForm';
import scrollTo from '../../../../core/helper/scroll-to';

export interface ILandlordAccountFormProps {
  scrollToTop: () => void;
}

export enum LANDLORD_FORMS {
  CUSTOMER = 'CUSTOMER',
  ORGANIZATION = 'ORGANIZATION',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  INTERFACE = 'INTERFACE',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
}

const AccountView = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const { user, tenantProfile, landlordProfile, activeUserType, authService } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { sendInfoToast } = useToast();
  const [activeTab, setActiveTab] = useState<LANDLORD_FORMS>(LANDLORD_FORMS.CUSTOMER);

  const FORM_TABS: IFormTab[] = [{ icon: faPerson, label: 'Kundendaten', value: LANDLORD_FORMS.CUSTOMER }];

  if (!landlordProfile.isCommercialLandlord) {
    FORM_TABS.push({ icon: faBuilding, label: 'Firma erstellen', value: LANDLORD_FORMS.CREATE_ORGANIZATION });
  } else {
    FORM_TABS.push({ icon: faBuilding, label: 'Firmendaten', value: LANDLORD_FORMS.ORGANIZATION });
    FORM_TABS.push({ icon: faUsers, label: 'Nutzerverwaltung', value: LANDLORD_FORMS.USER_MANAGEMENT });
    FORM_TABS.push({ icon: faShareNodes, label: 'Schnittstelle', value: LANDLORD_FORMS.INTERFACE });
  }

  const resendVerificationMail = async (): Promise<void> => {
    try {
      await authService.triggerSendVerificationLink(tenantProfile.email, activeUserType);
      await sendInfoToast('toast.resendEmailVerificationMail');
    } catch (e) {
      console.error('Error on resendVerificationMail', e);
    }
  };

  const renderSelectedForm = () => {
    switch (activeTab) {
      case LANDLORD_FORMS.CUSTOMER:
        return <LandlordProfileForm scrollToTop={() => scrollTo(contentRef)} />;
      case LANDLORD_FORMS.ORGANIZATION:
        return <LandlordOrganizationProfileForm scrollToTop={() => scrollTo(contentRef)} />;
      case LANDLORD_FORMS.USER_MANAGEMENT:
        return <LandlordUserManagementForm />;
      case LANDLORD_FORMS.INTERFACE:
        return <LandlordInterfacesForm />;
      case LANDLORD_FORMS.CREATE_ORGANIZATION:
        return <LandlordCreateOrganizationForm setOrganizationFormActive={setActiveTab} />;
      default:
        return <LandlordProfileForm scrollToTop={() => scrollTo(contentRef)} />;
    }
  };

  return (
    <PageLayout
      contentRef={contentRef}
      headerEndButton={
        <MobileHeaderButton
          onClick={() => openModal({ id: MODAL_IDENTIFIER.SETTINGS })}
          icon={faGear}
          text={'open settings'}
          hideText
        />
      }
      hideTabBar={false}
      pageTitle={t(ROUTES.landlord.account.overview.title)}
    >
      {!user.isEmailVerified ? (
        <>
          <div className={styles.banner}>
            <ActionBanner
              label={t('actionBanner.verifyYourEmail.label')}
              buttonText={t('actionBanner.verifyYourEmail.buttonText')}
              onClick={resendVerificationMail}
            />
          </div>
          <div className={styles.bannerSpace} />
        </>
      ) : null}

      {landlordProfile?.isCommercialLandlord && !landlordProfile?.isOrganizationMembershipConfirmed && (
        <InfoBox text={t('view.AccountOverviewView.infobox')} />
      )}

      <Section>
        <PageTitle
          pageTitle={t(ROUTES.landlord.account.overview.title)}
          secondPageTitle={'Hier siehst du dein Vermieterprofil'}
        />
      </Section>

      <FormTabs tabs={FORM_TABS} activeTab={activeTab} setActiveTab={setActiveTab}>
        {renderSelectedForm()}
      </FormTabs>
    </PageLayout>
  );
};

export default AccountView;
