import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../../../component/organisms/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import AccountBreadcrumbs from '../../../../component/molecules/Breadcrumbs/AccountBreadcrumbs';
import { IAccountStepperViewProps } from '../AboutMeView';
import TunnelStepper, {
  ITunnelStepperStepProps,
  TUNNEL_STEP_STATE,
} from '../../../../component/organisms/Tunnels/TunnelStepper';
import TenantIntroductionVideoForm from '../../../../component/organisms/forms/tenant/TenantIntroductionVideoForm';

const IntroductionVideoView: FC<IAccountStepperViewProps> = ({ isApplicationFolderTunnel = false }) => {
  const { t: r } = useTranslation('common');

  const APPLICATION_FOLDER_STEPS_INCOME_PROOF: ITunnelStepperStepProps[] = [
    { label: 'Profil', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenant.account.applicationFolder.profile },
    { label: 'Über mich', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenant.account.applicationFolder.aboutMe },
    { label: 'Haushalt', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenant.account.applicationFolder.household },
    {
      label: 'Vorstellungsvideo',
      state: TUNNEL_STEP_STATE.CURRENT,
      link: ROUTES.tenant.account.applicationFolder.introductionVideo,
    },
    {
      label: 'Dokumente',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenant.account.applicationFolder.incomeProof,
    },
  ];

  return (
    <PageLayout pageTitle={r(ROUTES.tenant.account.introductionVideo.title)} showBackButton>
      {isApplicationFolderTunnel ? (
        <TunnelStepper steps={APPLICATION_FOLDER_STEPS_INCOME_PROOF} />
      ) : (
        <AccountBreadcrumbs page={ROUTES.tenant.account.introductionVideo} />
      )}
      <TenantIntroductionVideoForm isApplicationFolderTunnel={isApplicationFolderTunnel} />
    </PageLayout>
  );
};

export default IntroductionVideoView;
