import {
  connectStorageEmulator,
  FirebaseStorage,
  getStorage,
  uploadBytesResumable,
  UploadTask,
  StorageReference,
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  UploadResult,
} from 'firebase/storage';
import { getApps, initializeApp } from 'firebase/app';
import { isPlatform } from '@ionic/react';
import FIREBASE_OPTIONS from '../../firebase.config';
import { FILE_UPLOAD_STATUS, FIRESTORE_COLLECTION_PATH, IUpload, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import { UPLOAD_TYPE } from '../../component/molecules/DocumentUploadDropZone';
import sanitizeFileName from '../helper/sanitize-file-name';

class StorageService {
  public readonly storage: FirebaseStorage;

  constructor() {
    if (getApps().length === 0 && !isPlatform('mobile')) {
      this.storage = getStorage(initializeApp(FIREBASE_OPTIONS));
    } else {
      this.storage = getStorage(getApps()[0]);
    }

    if (process.env.REACT_APP_USE_EMULATOR === 'true' && process.env.REACT_APP_ENVIRONMENT === 'qas') {
      connectStorageEmulator(this.storage, process.env.REACT_APP_EMULATOR_HOST, 9199);
    }
  }

  public getRef(path: string) {
    return ref(this.storage, path);
  }

  public createFileUpload(
    file: File,
    metaInformation: { uploadType: UPLOAD_TYPE; creatorId: string; mediaType: MEDIA_TYPE; id: string; custom?: any }
  ): Promise<IUpload> {
    let uploadPathPrefix: string;

    switch (metaInformation.uploadType) {
      case UPLOAD_TYPE.APARTMENT_MEDIA:
        uploadPathPrefix = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
          .replace('{uid}', metaInformation.creatorId)
          .replace('{landlordId}', metaInformation.creatorId)}/${metaInformation.custom.apartmentId}`;
        break;
      case UPLOAD_TYPE.INCOME_PROOF:
        uploadPathPrefix = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
          '{uid}',
          metaInformation.creatorId
        )}/${metaInformation.creatorId}/incomeProofDocuments`;
        break;
      case UPLOAD_TYPE.SCHUFA:
        uploadPathPrefix = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
          '{uid}',
          metaInformation.creatorId
        )}/${metaInformation.creatorId}/schufaCheckDocuments`;
        break;
      case UPLOAD_TYPE.INTRODUCTION_VIDEO:
        uploadPathPrefix = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
          '{uid}',
          metaInformation.creatorId
        )}/${metaInformation.creatorId}/introductionVideo`;
        break;
    }

    const extension: string = file.type.split('/')[1];
    const fileName: string = metaInformation.id;

    const uploadPath = `${uploadPathPrefix}/${metaInformation.id}`;

    const storageRef: StorageReference = this.getRef(uploadPath);

    return new Promise((res, reject) => {
      const customMetadata = {
        creatorId: metaInformation.creatorId,
        mediaType: metaInformation.mediaType,
        id: metaInformation.id,
      };

      const uploadTask: UploadTask = uploadBytesResumable(storageRef, file, { customMetadata });
      const fileUpload: IUpload = {
        alt: sanitizeFileName(file.name, '_'),
        errors: [],
        extension,
        fileName: metaInformation.id,
        id: `${fileName}_${new Date().getTime()}`,
        url: null,
        uploadPath,
        uploadTask,
        updatedAt: new Date(),
        status: FILE_UPLOAD_STATUS.CREATED,
        ...customMetadata,
      };

      fileUpload.uploadTask.catch((err) => {
        reject(err);
      });

      return res(fileUpload);
    });
  }

  public deleteFiles(filePaths: string[]): Promise<void> {
    return Promise.all(filePaths.map((path) => deleteObject(ref(this.storage, path))))
      .then(() => {})
      .catch(console.error);
  }

  public getDownloadUrl(path: string): Promise<string> {
    const fileRef = this.getRef(path);
    return getDownloadURL(fileRef);
  }

  public async uploadBlob(
    data: Blob | Uint8Array | ArrayBuffer | File,
    path: string,
    uid: string,
    fileType: string
  ): Promise<string> {
    const fileRef = ref(this.storage, path);

    const uploadResult: UploadResult = await uploadBytes(fileRef, data, {
      contentType: fileType,
      customMetadata: { creatorId: uid },
    });

    return getDownloadURL(uploadResult.ref);
  }
}

export default StorageService;
