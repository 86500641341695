import DatabaseService from './database.service';
import {
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  Query,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import {
  FIRESTORE_COLLECTION_PATH,
  IActivationRequest,
  ILandlordProfile,
  IOrganization,
  LANDLORD_ROLES,
} from '@wohnsinn/ws-ts-lib';
import UserService from './user.service';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import { FirebaseFunctionsService } from './firebase-functions-service';

export type TOrganizationCreate = Omit<IOrganization, 'id'>;

class OrganizationService {
  constructor(
    private databaseService: DatabaseService,
    private userService: UserService,
    private firebaseFunctionsService: FirebaseFunctionsService = new FirebaseFunctionsService()
  ) {}

  public addOrganization(organizationData: TOrganizationCreate, uid: string): void {
    const organizationPath = `${FIRESTORE_COLLECTION_PATH.organizations.root}`;
    const landlordPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;

    const organizationColRef: CollectionReference = this.databaseService.getCollectionRef(
      organizationPath
    ) as CollectionReference;
    const organizationDocRef = doc(organizationColRef);
    const landlordDocRef = this.databaseService.getDocRef(landlordPath);
    setDoc(organizationDocRef, { ...organizationData, creatorId: uid }).catch((err) => console.error(err));

    setDoc(
      landlordDocRef,
      {
        isCommercialLandlord: true,
        roles: [LANDLORD_ROLES.ORGANIZATION_ADMIN],
        isOrganizationMembershipConfirmed: true,
        organizationId: organizationDocRef.id,
      },
      { merge: true }
    ).catch((err) => console.error(err));
  }

  public updateActivationRequest(
    acr: IActivationRequest,
    grantingLandlordProfile: ILandlordProfile,
    isGranted: boolean
  ) {
    const path = `${FIRESTORE_COLLECTION_PATH.organizations.activationRequests.replace(
      '{organizationId}',
      grantingLandlordProfile.organizationId
    )}/${acr.uid}`;

    const editedByUid = grantingLandlordProfile.uid;

    return this.databaseService
      .setDbDoc<{ editedByUid: string; isGranted: boolean }>({ editedByUid, isGranted }, path)
      .catch((err) => console.error(err));
  }

  public updateOrganizationLogo(organizationId: string, photoUrl: string): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.organizations.root}/${organizationId}`;

    return this.databaseService.setDbDoc<{ photoUrl: string }>({ photoUrl }, path).catch((err) => console.error(err));
  }

  public getOrganizationRefById(organizationId: string): DocumentReference<IOrganization> {
    return this.databaseService.getDocRef<IOrganization>(
      `${FIRESTORE_COLLECTION_PATH.organizations.root}/${organizationId}`,
      {
        fetchWithId: true,
      }
    );
  }

  public async getOrganizationBySlug(slug: string): Promise<IOrganization> {
    let organization: IOrganization = null;
    const orgColRef = this.databaseService.getCollectionGroupRef<IOrganization>(`organizations`, {
      fetchWithId: true,
    });
    const organizationQuery = query(orgColRef, where('slug', '==', slug));
    const organizationDocs = await getDocs(organizationQuery);
    const organizationDocLength = organizationDocs.docs.length;

    if (organizationDocLength > 1) {
      return organization;
    } else {
      if (organizationDocLength === 1) {
        organization = organizationDocs.docs[0].data();

        return organization;
      }
    }
  }

  public getActivationRequestsListRef(organizationId: string, isActive = true): Query<IActivationRequest> {
    const path: string = FIRESTORE_COLLECTION_PATH.organizations.activationRequests.replace(
      '{organizationId}',
      organizationId
    );
    return query(
      this.databaseService.getCollectionRef<IActivationRequest>(path, { fetchWithId: true }),
      where('isActive', '==', true)
    );
  }

  public getOrganizationUserListRefById(organizationId: string): Query<ILandlordProfile> {
    return query(
      this.databaseService.getCollectionGroupRef<ILandlordProfile>('landlordProfiles'),
      where('organizationId', '==', organizationId)
    );
  }

  public getOrganizationById(organizationId: string): Promise<IOrganization> {
    return getDoc(this.getOrganizationRefById(organizationId))
      .then((organizationSnap) => {
        const organization: IOrganization = organizationSnap.data();

        return organization;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  /**
   * Checks if an activation request for a given email exists
   * @param organizationId
   * @param toBeActivatedEmail
   */
  public async isOrganizationActivationRequestValid(
    organizationId: string,
    toBeActivatedEmail: string
  ): Promise<boolean> {
    const path = `${FIRESTORE_COLLECTION_PATH.organizations.root}/${organizationId}/activationRequests/${toBeActivatedEmail}`;
    const activationRequest = (await this.databaseService.getDbDoc(path)) as IActivationRequest;
    return !!activationRequest?.isActive;
  }

  /**
   * Deletes an activation request for an organization by email
   * @param organizationId
   * @param toBeActivatedEmail
   */
  public async deleteOrganizationActivationRequest(organizationId: string, toBeActivatedEmail: string): Promise<void> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.organizations.root}/${organizationId}/activationRequests/${toBeActivatedEmail}`;
      await this.databaseService.deleteDbDoc(path);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Removes the given landlord from an organization
   * @param organizationId
   * @param landlordId
   */
  public async removeLandlordFromOrganization(organizationId: string, landlordId: string) {
    try {
      await this.userService.updateLandlordOrganization(landlordId, null, false);
      await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.editorList.update, {
        landlordId,
        organizationId,
      });
    } catch (e) {
      console.error('Error deleting landlord from organization: ', e);
    }
  }

  /**
   * Update organization profile
   * @param organization
   * @param landlordProfile
   */
  public updateOrganization(organization: IOrganization, landlordProfile: ILandlordProfile): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.organizations.root}/${landlordProfile.organizationId}`;
    return this.databaseService.setDbDoc<IOrganization>({ ...organization }, path).catch((err) => console.error(err));
  }
}

export default OrganizationService;
