import { useTranslation } from 'react-i18next';
import ModalWrapper from '../ModalWrapper';
import { useContext } from 'react';
import ModalContext from '../../../../core/context/modal.context';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import styles from './YouveBeenInvitedModal.module.scss';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';

const YouveBeenInvitedModal = () => {
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.YouveBeenInvitedModal' });
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalWrapper noHeadingMargin={true}>
      <div className={styles.modalWrapper}>
        <img src={m('image')} alt={t('profileImagesAlt')} />
        <Headline tag={'h3'} size={HEADLINE_SIZE.H3} align={'center'}>
          {m('title')}
        </Headline>

        <Text align={'center'}>{m('text')}</Text>
        <div className={styles.buttonWrapper}>
          <CTAButton
            buttonStyle={BUTTON_STYLE.SECONDARY}
            color={TEXT_COLOR.TEXT_COLOR_DARK}
            onClick={() => closeModal()}
            buttonText={t('cancel')}
          />
          <CTAButton onClick={() => closeModal()} buttonText={t('getStarted')} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default YouveBeenInvitedModal;
