import HeadlineText from '../../../../../component/molecules/HeadlineText';
import { useTranslation } from 'react-i18next';
import styles from './LandlordLandingCrmSection.module.scss';
import Text from '../../../../../component/atoms/typographie/Text';

const LandlordLandingCrmSection = () => {
  const { t } = useTranslation('common');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.LandlordLanding.crm' });

  return (
    <div className={styles.wrapper}>
      <div className={styles.blob}>
        <img src={t('icons.interface_blob')} alt="crm-interfaces" />
      </div>
      <HeadlineText headline={l('headline')} text={l('subtitle')} />

      <div className={styles.logos}>
        <img src={t('logos.open_immo')} alt="Open Immo Logo" />
        <img src={t('logos.on_office')} alt="On Office Logo" />
        <img src={t('logos.flowfact')} alt="Flowfact Logo" />
        <img src={t('logos.propstack')} alt="Propstack Logo" />
      </div>
      <Text align={'center'}>{l('tag')}</Text>
    </div>
  );
};

export default LandlordLandingCrmSection;
