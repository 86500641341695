import {
  FIRESTORE_COLLECTION_PATH,
  IApartment,
  IApartmentDesiredTenant,
  IApplication,
  IFirestoreQueryParams,
  IGetLandlordApplicationListQueryParams,
  IGetTenantApplicationListQueryParams,
  IHousehold,
  ISortedApplications,
  MATCHING_MODE,
  TApplicationCreate,
  TChatMessageCreate,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import 'firebase/firestore';
import { arrayRemove, arrayUnion, getDocs, Query } from 'firebase/firestore';
import DatabaseService from './database.service';
import { generateUUID } from '@ionic/cli/lib/utils/uuid';

class ApplicationService {
  private readonly databaseService = new DatabaseService();

  constructor() {}

  public filterApplicationListByDesiredTenants(
    applicationList: IApplication[],
    apartment: IApartment
  ): ISortedApplications {
    const desiredTenantApplications: IApplication[] = [];
    const otherApplications: IApplication[] = [];

    applicationList.forEach((application) => {
      const household: IHousehold = application.tenantProfile.household;
      const desiredTenant: IApartmentDesiredTenant = apartment?.desiredTenant;
      const arePetsAllowed: boolean =
        (household?.arePetsAllowed && desiredTenant?.arePetsAllowed) || !household?.arePetsAllowed;
      const isSharedUsagePossible: boolean =
        (household?.isSharedUsagePossible && desiredTenant?.isSharedUsagePossible) || !household?.isSharedUsagePossible;
      const isHousingEntitlementCertificateMandatory: boolean =
        (household?.canBeSubsidized && desiredTenant?.isHousingEntitlementCertificateMandatory) ||
        !desiredTenant?.isHousingEntitlementCertificateMandatory;
      const numberOfPeopleMovingIn: boolean =
        household?.numberOfPeopleMovingIn <= desiredTenant?.numberOfPeopleMovingIn;
      const shouldIncomeBeDoubleWarmRent: boolean =
        (desiredTenant?.shouldIncomeBeDoubleWarmRent && household?.monthlyIncome >= apartment?.cost?.warmRent * 2.5) ||
        !desiredTenant?.shouldIncomeBeDoubleWarmRent;

      if (
        arePetsAllowed &&
        isSharedUsagePossible &&
        numberOfPeopleMovingIn &&
        shouldIncomeBeDoubleWarmRent &&
        isHousingEntitlementCertificateMandatory
      ) {
        desiredTenantApplications.push(application);
      } else {
        otherApplications.push(application);
      }
    });

    return {
      desiredTenantApplications,
      otherApplications,
    };
  }

  public filterApplicantListByRating = (list: IApplication[], rating: MATCHING_MODE): IApplication[] => {
    let ratedApplicantList: IApplication[] = list;
    ratedApplicantList = list.filter((applicant) => applicant.landlordRating === rating);

    return ratedApplicantList;
  };

  public addApplicationList(applicationList: TApplicationCreate[]): Promise<void> {
    const batch = this.databaseService.getBatch();
    const chatBatch = this.databaseService.getBatch();

    applicationList.forEach((applicationData) => {
      const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', applicationData.landlordId)
        .replace('{landlordId}', applicationData.landlordId)}/${applicationData.apartmentId}`;
      const applicationPath = `${apartmentPath}/applications/${applicationData.tenantProfile.uid}`;
      const applicationDocRef = this.databaseService.getDocRef(applicationPath, {
        setCreatedAt: true,
        setUpdatedAt: true,
      });

      let chatDocRef;

      if (applicationData.rating === MATCHING_MODE.LIKE) {
        const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
          .replace('{uid}', applicationData.landlordId)
          .replace('{landlordId}', applicationData.landlordId)}/${applicationData.apartmentId}`;
        const chatPath = `${applicationPath}/chatMessages/${applicationData.tenantProfile.uid}`;
        const tenantProfilePath = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
          '{uid}',
          applicationData.tenantProfile.uid
        )}/${applicationData.tenantProfile.uid}`;
        chatDocRef = this.databaseService.getDocRef(chatPath, { setCreatedAt: true });
        const apartmentDocRef = this.databaseService.getDocRef(apartmentPath);
        const tenantProfileRef = this.databaseService.getDocRef(tenantProfilePath);

        const chatData: TChatMessageCreate = {
          apartmentId: applicationData.apartmentId,
          landlordId: applicationData.landlordId,
          senderAvatar: applicationData.tenantProfile?.photoUrl,
          senderId: applicationData.tenantProfile.uid,
          senderName:
            applicationData?.tenantProfile?.personalInformation?.firstName &&
            applicationData?.tenantProfile?.personalInformation?.lastName
              ? applicationData?.tenantProfile?.personalInformation?.firstName +
                ' ' +
                applicationData?.tenantProfile?.personalInformation?.lastName
              : '',
          tenantId: applicationData.tenantProfile.uid,
          text: applicationData.tenantProfile.aboutMe.description,
          attachment: [],
        };

        chatBatch.set(chatDocRef, chatData, { merge: false });
        batch.set(
          apartmentDocRef,
          {
            applicationRefList: arrayUnion(applicationData.tenantProfile.uid),
            unreadTenantChatsRef: arrayUnion(applicationData.tenantProfile.uid),
          },
          { merge: true }
        );
        applicationData?.editorList.map((editorId) => {
          batch.set(tenantProfileRef, { readPermissionGrantedUsers: arrayUnion(editorId) }, { merge: true });
        });
      }
      batch.set(
        applicationDocRef,
        {
          ...applicationData,
          unreadTenantMessagesRef: chatDocRef?.id ? arrayUnion(chatDocRef.id) : [],
          unreadLandlordMessagesRef: applicationData.rating === MATCHING_MODE.LIKE ? arrayUnion(generateUUID()) : [],
        },
        { merge: true }
      );
    });

    return batch
      .commit()
      .then(() => chatBatch.commit().catch(console.error))
      .catch(console.error);
  }

  public updateApplicationRating(
    landlordId: string,
    apartmentId: string,
    tenantId: string,
    rating: MATCHING_MODE.NOPE | MATCHING_MODE.MAYBE
  ): Promise<void> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${tenantId}`;
    return this.databaseService.setDbDoc({ rating }, applicationPath);
  }

  public getLandlordApplicationListRef(params: IGetLandlordApplicationListQueryParams): Query<IApplication> {
    const queryParams: IFirestoreQueryParams = {
      where: [
        { fieldPath: 'editorList', opStr: 'array-contains', value: params.landlordId },
        { fieldPath: 'rating', opStr: '==', value: MATCHING_MODE.LIKE },
      ],
      orderBy: { fieldPath: 'updatedAt', directionStr: 'desc' },
    };

    if (params.tenantId && Array.isArray(queryParams.where)) {
      queryParams.where.push({ fieldPath: 'tenantProfile.uid', opStr: '==', value: params.tenantId });
    }
    if (params.apartmentId && Array.isArray(queryParams.where)) {
      queryParams.where.push({ fieldPath: 'apartmentId', opStr: '==', value: params.apartmentId });
    }

    return this.databaseService.getCollectionRefWithParams<IApplication>(
      this.databaseService.getCollectionGroupRef('applications', { fetchWithId: true }),
      queryParams
    );
  }

  public getTenantApplicationListRef(params: IGetTenantApplicationListQueryParams): Query<IApplication> {
    const options: IFirestoreQueryParams = {
      where: [{ fieldPath: 'tenantProfile.uid', opStr: '==', value: params.tenantId }],
      orderBy: { fieldPath: 'updatedAt', directionStr: 'desc' },
    };

    if (params?.matchingMode.length && Array.isArray(options.where)) {
      options.where.push({ fieldPath: 'rating', opStr: 'in', value: params.matchingMode });
    }

    return this.databaseService.getCollectionRefWithParams<IApplication>(
      this.databaseService.getCollectionGroupRef('applications'),
      options
    );
  }

  /**
   * Get application for specific user and apartment
   * @param apartmentId
   * @param tenantId
   */
  public async getApplicationListRef(apartmentId: string, tenantId: string) {
    const applicationCollectionRef: Query<IApplication> = this.databaseService.getCollectionRefWithParams<IApplication>(
      this.databaseService.getCollectionGroupRef('applications'),
      {
        where: [
          { fieldPath: 'apartmentId', opStr: '==', value: apartmentId },
          { fieldPath: 'tenantId', opStr: '==', value: tenantId },
        ],
      }
    );

    return await getDocs(applicationCollectionRef).then((applicationSnapShotList) => {
      return applicationSnapShotList.docs[0] as unknown as IApplication;
    });
  }

  public getApplicationRating(landlordId: string, apartmentId: string, tenantId: string): Promise<IApplication> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${tenantId}`;
    return this.databaseService.getDbDoc(applicationPath);
  }

  public updateApplicationLandlordRating(
    landlordId: string,
    apartmentId: string,
    tenantId: string,
    landlordRating: MATCHING_MODE
  ): Promise<void> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${tenantId}`;
    return this.databaseService.updateDbDoc({ landlordRating }, applicationPath);
  }

  public async markUnread(application: IApplication, readByUserType: USER_TYPE): Promise<void> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)}/${application.apartmentId}`;
    const apartmentDocRef = this.databaseService.getDocRef(apartmentPath);
    const applicationPath = `${apartmentPath}/applications/${application.tenantProfile.uid}`;
    const applicationDocRef = this.databaseService.getDocRef(applicationPath);
    const batch = this.databaseService.getBatch();

    switch (readByUserType) {
      case USER_TYPE.TENANT:
        batch.update(applicationDocRef, { unreadLandlordMessagesRef: [] });
        break;
      case USER_TYPE.LANDLORD:
        application.unreadTenantMessagesRef.forEach((unreadTenantMessageId) => {
          batch.update(apartmentDocRef, { unreadTenantMessagesRef: arrayRemove(unreadTenantMessageId) });
        });
        batch.update(applicationDocRef, { unreadTenantMessagesRef: [] });
        break;
      default:
        break;
    }
    return await batch.commit().catch((e) => console.error('Error ApplicationService markUnread', e));
  }
}

export default ApplicationService;
