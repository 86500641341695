import { FC, PropsWithChildren } from 'react';
import styles from './SwiperTunnelStepsWrapper.module.scss';
import { IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import CTAButton from '../../../../atoms/Buttons/CTAButton';

const SwiperTunnelStepsWrapper: FC<PropsWithChildren<{ onCtaClick?: () => any; ctaText?: string }>> = ({
  children,
  onCtaClick,
  ctaText,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={`${styles.wrapper} swiper-no-swiping`}>
      <div className={styles.logoWrapper}>
        <IonRouterLink routerLink={'/'}>
          <img className={styles.logo} src={t('logo.black.logoSrc')} alt="wohnsinn-logo" />
        </IonRouterLink>
      </div>
      {children}
      {onCtaClick ? (
        <div className={styles.ctaButtonWrapper}>
          <CTAButton expand={'block'} onClick={onCtaClick} buttonText={ctaText ?? 'Weiter'} />
        </div>
      ) : null}
    </div>
  );
};

export default SwiperTunnelStepsWrapper;
