import ModalWrapper from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import { ROUTES } from '../../../../core/const/routes';
import { useIonRouter } from '@ionic/react';
import styles from './CreateOrUploadSchufaModal.module.scss';
import { useContext } from 'react';
import ModalContext from '../../../../core/context/modal.context';

const CreateOrUploadSchufaModal = () => {
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.modals.createOrUploadSchufa' });
  const { t: c } = useTranslation('common');
  const router = useIonRouter();
  const { closeModal } = useContext(ModalContext);

  const onSelectSchufaCreate = () => {
    closeModal();
    return router.push(c(ROUTES.services.createSchufa.path));
  };

  const onSelectSchufaUpload = (): void => {
    document.getElementById('schufaCheckFileInput').click();
  };

  return (
    <ModalWrapper title={t('title')}>
      <div className={styles.schufaButtonWrapper}>
        <CTAButton expand={'block'} onClick={onSelectSchufaCreate} buttonText={t('addNewButton')} />
        <CTAButton expand={'block'} buttonText={t('uploadSchufa')} onClick={onSelectSchufaUpload} />
      </div>
    </ModalWrapper>
  );
};

export default CreateOrUploadSchufaModal;
