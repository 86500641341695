import { IApplication, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import styles from '../../../view/tenant/BookmarksView/BookmarksView.module.scss';
import Map from '../../molecules/Map';
import ApartmentInformation from '../ApartmentInformation';
import { FC, useContext, useEffect, useState } from 'react';
import useApartment from '../../../core/hook/apartment.hook';
import { useTranslation } from 'react-i18next';
import ThreeColumnsLayoutLeftSide from '../ThreeColumnsLayout/ThreeColumnsLayoutLeftSide';
import ThreeColumnsLayoutContext from '../../../core/context/three-columns-layout-context';
import ThreeColumnsLayoutMiddle from '../ThreeColumnsLayout/ChatLayoutMiddle';
import ThreeColumnsLayoutRightSide from '../ThreeColumnsLayout/ThreeColumnsLayoutRightSide';
import useWindowSize from '../../../core/hook/windowsize.hook';
import BookmarkApartmentCard from '../../molecules/Cards/BookmarkApartmentCard';
import ApplicationServiceContext from '../../../core/context/application-service.context';
import UserContext from '../../../core/context/user.context';
import useToast from '../../../core/hook/toast.hook';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import ApplicationListContext from '../../../core/context/application-list.context';
import ApartmentLoadingCard from '../../molecules/LoadingElements/ApartmentLoadingCard';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';

const BookmarksWrapper: FC = () => {
  const { t } = useTranslation('common');
  const { t: b } = useTranslation('common', { keyPrefix: 'view.BookmarksView' });
  const { openModal } = useContext(ModalContext);
  const { tenantProfile, user } = useContext(UserContext);
  const { showRight } = useContext(ThreeColumnsLayoutContext);
  const { isGreaterMd } = useWindowSize();
  const { applicationService } = useContext(ApplicationServiceContext);
  const { sendErrorToast, sendInfoToast } = useToast();
  const hasCompletedSelfDisclosure = tenantProfile?.household && tenantProfile?.personalInformation;
  const { nopeList, maybeList, isLoading } = useContext(ApplicationListContext);
  const [matchingMode, setMatchingMode] = useState<MATCHING_MODE>(MATCHING_MODE.MAYBE);
  const [selectedBookmark, setSelectedBookmark] = useState<IApplication>(maybeList[0]);
  const { apartment } = useApartment(selectedBookmark?.apartmentId);
  const [applicationList, setApplicationList] = useState<IApplication[]>(maybeList);

  useEffect(() => {
    if (matchingMode === MATCHING_MODE.MAYBE) {
      setApplicationList(maybeList);
      setSelectedBookmark(maybeList[0]);
    }
    if (matchingMode === MATCHING_MODE.NOPE) {
      setApplicationList(nopeList);
      setSelectedBookmark(nopeList[0]);
    }
  }, [matchingMode, nopeList, maybeList]);

  const clickOnApartment = (application: IApplication) => {
    setSelectedBookmark(application);
    showRight();
  };

  const actionHandler = async (
    application: IApplication,
    matchingMode: MATCHING_MODE.NOPE | MATCHING_MODE.MAYBE
  ): Promise<void | boolean> => {
    try {
      await applicationService
        .updateApplicationRating(application.landlordId, application.apartmentId, tenantProfile.uid, matchingMode)
        .then(() =>
          sendInfoToast(
            matchingMode === MATCHING_MODE.NOPE ? b('messages.addedToDislike') : b('messages.addedToBookmark')
          )
        );
    } catch (e) {
      console.error(e);
      await sendErrorToast(b('messages.error'));
    }
  };

  const applyHandler = (application: IApplication): any => {
    if (!user?.isEmailVerified) {
      openModal({ id: MODAL_IDENTIFIER.EMAIL_VERIFICATION });
    } else if (hasCompletedSelfDisclosure) {
      openModal({ id: MODAL_IDENTIFIER.CONFIRM_APPLICATION, data: application });
    } else {
      openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
    }
  };

  return (
    <>
      <ThreeColumnsLayoutLeftSide>
        <div className={styles.listWrapper}>
          <IonSegment value={matchingMode}>
            <IonSegmentButton value={MATCHING_MODE.MAYBE} onClick={() => setMatchingMode(MATCHING_MODE.MAYBE)}>
              <IonLabel>
                Likes
                <img className={styles.segmentIcon} src={t('icons.heart_like')} alt={'Likes'} />
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={MATCHING_MODE.NOPE} onClick={() => setMatchingMode(MATCHING_MODE.NOPE)}>
              <IonLabel>
                Nopes
                <img className={styles.segmentIcon} src={t('icons.heart_nope')} alt={'Nopes'} />
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {applicationList && applicationList.length && !!apartment ? (
            <ul className={styles.list}>
              {isLoading
                ? [1, 2, 3, 4, 5, 6].map((card, index) => (
                    <li className={styles.listItem} key={index}>
                      <ApartmentLoadingCard />
                    </li>
                  ))
                : applicationList.map((application) => (
                    <li className={styles.listItem} key={application.apartmentId}>
                      <BookmarkApartmentCard
                        application={application}
                        matchingMode={matchingMode}
                        apartmentClick={() => clickOnApartment(application)}
                        actionHandler={() =>
                          actionHandler(
                            application,
                            matchingMode === MATCHING_MODE.NOPE ? MATCHING_MODE.MAYBE : MATCHING_MODE.NOPE
                          )
                        }
                        applyAction={() => applyHandler(application)}
                      />
                    </li>
                  ))}
            </ul>
          ) : (
            <div className={styles.titleWrapper}>
              <Headline size={HEADLINE_SIZE.H2} tag={'span'} align={'left'}>
                {matchingMode === MATCHING_MODE.NOPE
                  ? b('title.like', { count: nopeList && nopeList.length ? nopeList.length : 0 })
                  : null}
                {matchingMode === MATCHING_MODE.MAYBE
                  ? b('title.like', { count: maybeList && maybeList.length ? maybeList.length : 0 })
                  : null}
              </Headline>
            </div>
          )}
        </div>
      </ThreeColumnsLayoutLeftSide>
      <ThreeColumnsLayoutMiddle>
        {apartment && isGreaterMd ? <Map isInteractive={true} apartments={[apartment]} /> : null}
      </ThreeColumnsLayoutMiddle>
      {applicationList.length > 0 && (
        <ThreeColumnsLayoutRightSide>
          {!!apartment && (
            <div className={styles.apartmentInformationWrapper}>
              <ApartmentInformation apartment={apartment} />
            </div>
          )}
        </ThreeColumnsLayoutRightSide>
      )}
    </>
  );
};

export default BookmarksWrapper;
